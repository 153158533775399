import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    EquipmentSystemCreatedId: false,
    personAssign: false,
    areaShared: false,
    EquipmentSystemsSearched: false,
    quantity: false,
    EquipmentSystemsFiltered: false,
    loadingEquipmentSystem: false,
    states: false,
    locations: false,
    equipsWithCaseReports: false,
    loadingEquipsWithCaseReport: false,
    quantityEquipsWithCaseReports: false,
    equipAndReportPDF: false,
    equipsForTimeline: false,
    loadingEquipsForTimeline: false,
    allEquipsForTimeline: false,
    equipsWithTimelines: false,
    loadingEquipsWithTimeline: false,
    quantityEquipsWithTimelines: false,
    fullDataEquipmentSystem: false,
    loadingFullDataEquipmentSystem: false,
    loadingUpdateEquipmentSystem: false,
    loadingCreateEquipmentSystem: false,
    isFiltered: false,
};

const EquipmentSystemSlice = createSlice({
    name: "EquipmentSystem",
    initialState,
    reducers: {
        setEquipmentSystemCreatedId(state, { payload }) {
            state.EquipmentSystemCreatedId = payload
        },
        createEquipmentSystem(state, { payload }) {
            state.loadingCreateEquipmentSystem = true
        },
        updateEquipmentSystem(state, { payload }) {
            state.loadingCreateEquipmentSystem = true
        },
        deleteEquipmentSystem() { },
        setPersonAssign(state, { payload }) {
            state.personAssign = payload
        },
        setAreaShared(state, { payload }) {
            state.areaShared = payload
        },
        setEquipmentSystemsSearched(state, { payload }) {
            state.EquipmentSystemsSearched = payload
        },
        searchEquipmentSystem(state) {
            state.loadingEquipmentSystem = true
        },
        setQuantity(state, { payload }) {
            state.quantity = payload
        },
        setEquipmentSystemsFiltered(state, { payload }) {
            state.EquipmentSystemsFiltered = payload
        },
        getEquipmentSystems(state, { payload }) {
            state.loadingEquipmentSystem = true
            state.EquipmentSystemsSearched = false
        },
        setLoading(state, { payload }) {
            state.loadingEquipmentSystem = payload
        },
        getStates() { },
        getLocations() { },
        setStates(state, { payload }) {
            state.states = payload
        },
        setLocations(state, { payload }) {
            state.locations = payload
        },
        getEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = false
            state.loadingEquipsWithCaseReport = true;
        },
        setEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = payload
        },
        setQuantityEquipsWithCaseReports(state, { payload }) {
            state.quantityEquipsWithCaseReports = payload
        },
        setLoadingEquipsWithCaseReport(state, { payload }) {
            state.loadingEquipsWithCaseReport = payload
        },
        searchCaseReport(state) {
            state.loadingEquipsWithCaseReport = true
        },
        getEquipAndReportPDF() { },
        setEquipAndReportPDF(state, { payload }) {
            state.equipAndReportPDF = payload
        },
        getEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = true
        },
        setEquipsForTimeline(state, { payload }) {
            state.equipsForTimeline = payload
        },
        setAllEquipsForTimeline(state, { payload }) {
            state.allEquipsForTimeline = payload
        },
        setLoadingEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = payload
        },
        getEquipsWithTimelines(state, { payload }) {
            state.loadingEquipsWithTimeline = true
        },
        setEquipsWithTimelines(state, { payload }) {
            state.equipsWithTimelines = payload
            state.loadingEquipsWithTimeline = false
        },
        setQuantityEquipsWithTimelines(state, { payload }) {
            state.quantityEquipsWithTimelines = payload
        },
        getFullDataEquipmentSystem(state, { payload }) {
            state.loadingFullDataEquipmentSystem = true
        },
        setFullDataEquipmentSystem(state, { payload }) {
            state.fullDataEquipmentSystem = payload
        },
        setLoadingFullDataEquipmentSystem(state, { payload }) {
            state.loadingFullDataEquipmentSystem = payload
        },
        setLoadingUpdateEquipmentSystem(state, { payload }) {
            state.loadingUpdateEquipmentSystem = payload
        },
        setLoadingCreateEquipmentSystem(state, { payload }) {
            state.loadingCreateEquipmentSystem = payload
        },
        getEquipmentSystemsByModuleWork() { },
        search(state, { payload }) {
            state.loadingEquipmentSystem = true
        },
        setIsFiltered(state, { payload }) {
            state.isFiltered = payload
        },
        getEquipsWithCaseReportsByModuleWork() { },
        getEquipsWithTimelinesByModuleWork() { },
    },
});

const EquipmentSystemActions = EquipmentSystemSlice.actions;
const EquipmentSystemReducer = EquipmentSystemSlice.reducer;

export { EquipmentSystemActions, EquipmentSystemReducer };
