import { Document, Image, Page, PDFViewer, Text, View, Font } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfCaseReportPowerPlant = ({ fullDataCaseReport, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const initialStatus = Object.entries(fullDataCaseReport.powerPlantCaseReport)
        .filter(([key]) => (key.endsWith('Status')) && !key.endsWith('AtTheEndStatus'))
        .map(([key, value]) => [key, value]);

    const initialDescs = Object.entries(fullDataCaseReport.powerPlantCaseReport)
        .filter(([key]) => (key.startsWith('fuelLevel') ||
            key.startsWith('tankCapacity') ||
            key.startsWith('chargerVoltage') ||
            key.startsWith('alternatorVoltage') ||
            key.startsWith('controlType') ||
            key.startsWith('leaks')))
        .map(([key, value]) => [key, value]);

    const finalValueUnits = Object.entries(fullDataCaseReport.powerPlantCaseReport)
        .filter(([key]) => key.endsWith('Value'))
        .map(([key, value]) => {
            const unitKey = key.replace('Value', 'Unit');
            const unit = fullDataCaseReport.powerPlantCaseReport[unitKey];
            return { key, value, unit };
        });

    const finalDescriptions = Object.entries(fullDataCaseReport.powerPlantCaseReport)
        .filter(([key]) => ((key.endsWith("Description")) && !key.startsWith('fuelLevel') &&
            !key.startsWith('tankCapacity') &&
            !key.startsWith('chargerVoltage') &&
            !key.startsWith('alternatorVoltage') &&
            !key.startsWith('controlType') &&
            !key.startsWith('leaks')))
        .map(([key, value]) => [key, value]);

    const finalStatus = Object.entries(fullDataCaseReport.powerPlantCaseReport)
        .filter(([key]) => key.endsWith('AtTheEndStatus'))
        .map(([key, value]) => [key, value]);

    const finalStatusTranslate = {
        "chargerAtTheEndStatus": "Cargador",
        "controlAtTheEndStatus": "Control",
        "toteAtTheEndStatus": "Totalizador",
        "preheaterAtTheEndStatus": "Precalentador",
    }

    const finalDescriptionsTranslate = {
        "vacPhasesL1L1Description": "Vac Fases L1 L1",
        "vacPhasesL2L2Description": "Vac Fases L2 L2",
        "vacPhasesL3L3Description": "Vac Fases L3 L3",
        "vacPhasesNL1NDescription": "Vac Fases N L1 N",
        "vacPhasesNL2NDescription": "Vac Fases N L2 N",
        "vacPhasesNL3NDescription": "Vac Fases N L3 N",
        "ampsL1Description": "Amperios L1",
        "ampsL2Description": "Amperios L2",
        "ampsL3Description": "Amperios L3",
        "powerKVADescription": "Potencia KVA",
        "powerHzDescription": "Potencia Hz",
        "powerFPDescription": "Potencia FP",
        "lowPressureDescription": "Baja presión",
        "highTemperatureDescription": "Alta temperatura",
        "lowCoolantLevelDescription": "Bajo Nivel Refrigerante",
        "lowVoltageACDescription": "Bajo Voltaje AC",
        "TDSDescription": "TDS",
        "TDNEDescription": "TDNE",
        "TDNPDescription": "TDNP",
        "TDENDescription": "TDEN",
        "TDECDescription": "TDEC",
        "batteryVoltageAtTheEndDescription": "Voltaje de la Batería",
        "batteryVoltageDropAtTheEndDescription": "Caída de Voltaje de la Batería",
        "powerCablesDescription": "Cables de Potencia",
        "terminalsDescription": "Terminales",
        "bodyOfContractorsDescription": "Cuerpo de Contractores",
        "bodyOfSwitchesDescription": "Cuerpo Interruptores",
        "transformersDescription": "Transformadores",
        "hottestSpotDescription": "Punto más Caliente",
        "L1NDescription": "L1-N Voltaje",
        "L2NDescription": "L2-N Voltaje",
        "L3NDescription": "L3-N Voltaje",
        "l1PowerDescription": "L1 Corriente",
        "l2PowerDescription": "L2 Corriente",
        "l3PowerDescription": "L3 Corriente",
        "hzFrequencyDescription": "HZ Frecuencia",
        "kwFrequencyDescription": "KW Frecuencia",
        "l1FrequencyDescription": "L1 Frecuencia",
        "l2FrequencyDescription": "L2 Frecuencia",
        "l3FrequencyDescription": "L3 Frecuencia",
        "avgFrequencyDescription": "AVG Frecuencia",
        "pfPFactorDescription": "PF Factor P",
        "kwaPFactorDescription": "KWA Factor P",
        "l1PFactorDescription": "L1 Factor P",
        "l2PFactorDescription": "L2 Factor P",
        "l3PFactorDescription": "L3 Factor P",
        "avgPFactorDescription": "AVF Factor P",
    }

    const finalValueUnitsTranslate = {
        "oilTemperatureValue": "Temperatura de Aceite",
        "turboTemperatureValue": "Temperatura de Turbo",
        "rpmValue": "RPM",
        "airFilterValue": "Filtro de Aire",
        "oilFilterValue": "Filtro de Aceite",
        "fuelFilterValue": "Filtro de Combustible",
        "separatorFilterValue": "Filtro Separador",
        "waterFilterValue": "Filtro de Agua",
        "oilQuantityValue": "Cantidad de Aceite",
    }


    const descsTranslate = {
        "fuelLevelDescription": "Nivel de Combustible",
        "tankCapacityDescription": "Capacidad Tanque",
        "chargerVoltageDescription": "Voltaje Cargador",
        "alternatorVoltageDescription": "Voltaje Alternador",
        "controlTypeDescription": "Tipo Control",
        "leaksDescription": "Fugas",
    }

    const statusTranslate = {
        "oilLevelStatus": "Nivel de Aceite",
        "coolantLevelStatus": "Nivel de Refrigerante",
        "hosesStatus": "Mangueras",
        "sealsStatus": "Sellos",
        "pipelinesStatus": "Tuberías",
        "radiatorStatus": "Radiador",
        "guardStatus": "Guardas",
        "fanBeltsStatus": "Correas del Ventilador",
        "alternatorBeltsStatus": "Correas del Alternador",
        "shockAbsorbersStatus": "Amortiguadores",
        "preheaterStatus": "Precalentador",
        "batteryStatus": "Batería",
        "electrolyteLevelStatus": "Nivel de Electrolito",
        "batteryVoltageStatus": "Voltaje de Batería",
        "chargerStatus": "Cargador",
        "controlConnectionsStatus": "Conexiones de Control",
        "powerConnectionsStatus": "Conexiones de Potencia",
        "generatorStatus": "Generador",
        "generalCleaningStatus": "Limpieza General",
        "cabinetStatus": "Gabinete",
        "doorsStatus": "Puertas",
        "locksStatus": "Cerraduras",
        "hingesStatus": "Bisagras",
        "GECleaningStatus": "Limpieza de GE",
        "INDIPilotsStatus": "Pilotos INDI",
        "selectorsStatus": "Selectores",
        "relaysStatus": "Relés",
        "timersStatus": "Temporizadores",
        "contactorsStatus": "Contactores",
        "switchesStatus": "Interruptores",
        "controlConnectionStatus": "Conexión de Control",
        "powerConnectionStatus": "Conexión de Potencia",
        "powerBarStatus": "Barraje Potencia",
        "neutralBusbarStatus": "Barraje Neutro",
        "groundBusStatus": "Barraje Tierra",
        "plcStatus": "PLC",
        "atsStatus": "ATS",
        "auxiliarySourcesStatus": "Fuentes Auxiliares",
        "capacitorsStatus": "Capacitores",
        "networkAnalyzersStatus": "Analizadores de Red",
    }

    return (
        <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
            <View style={{ border: '3px solid #000', marginTop: '3%', width: '95%', height: '6%', flexDirection: 'row' }}>
                <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center', borderRight: '3px solid #000' }}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={{ width: '75%', borderRight: '3px solid #000' }}>
                    <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>PROCESO DE GESTION ADMINISTRACIÓN DEL RIESGO</Text></View>
                    <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '30px' }}>Reporte de servicio</Text></View>
                </View>
                <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                    <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                </View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '2%', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '24px' }}>FECHA:</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataCaseReport.date ? padStr(new Date(fullDataCaseReport.date).getDate()) + "/" + padStr(1 + new Date(fullDataCaseReport.date).getMonth()) + "/" + padStr(new Date(fullDataCaseReport.date).getFullYear()) : ''}</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '24px' }}>INGENIERO:</Text></View>
                <View style={{ height: '100%', width: '58%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataCaseReport.userCreator && (fullDataCaseReport.userCreator.person.name + " " + fullDataCaseReport.userCreator.person.lastName)}</Text></View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '1.8%', backgroundColor: "#ebedf0", marginTop: '1.3%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: '24px' }}>DATOS DEL EQUIPO</Text>
            </View>

            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '6%' }}>
                <View style={{ borderBottom: '3px solid #000', flexDirection: 'row', width: '100%', height: '33.3%' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '24px' }}>NOMBRE:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '29%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataCaseReport.equipment.name + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '24px' }}>MARCA:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataCaseReport.equipment.industrialEquipment.brand + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '24px' }}>MODELO:</Text></View>
                    <View style={{ height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataCaseReport.equipment.industrialEquipment.modell + " "}</Text></View>
                </View>
                <View style={{ borderBottom: '3px solid #000', flexDirection: 'row', width: '100%', height: '33.3%' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '24px' }}>SERIE:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '29%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataCaseReport.equipment.industrialEquipment.serie + " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '100%', height: '33.3%' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '24px' }}>DEPENDENCIA:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '29%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataCaseReport.dependencyWhenDone.name + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '16%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '24px' }}>RESPONSABLE:</Text></View>
                    <View style={{ height: '100%', width: '40%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>
                        {fullDataCaseReport.bossDependencyWhenDone ? fullDataCaseReport.bossDependencyWhenDone.person.name + " "
                            + fullDataCaseReport.bossDependencyWhenDone.person.secondName + " "
                            + fullDataCaseReport.bossDependencyWhenDone.person.lastName + " "
                            + fullDataCaseReport.bossDependencyWhenDone.person.secondLastName
                            :
                            ""}
                    </Text></View>
                </View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '1.8%', backgroundColor: "#ebedf0", marginTop: '1.3%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: '24px' }}>TIPO DE SERVICIO</Text>
            </View>

            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '2%', flexDirection: 'row' }}>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>CORRECTIVO</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport && fullDataCaseReport.powerPlantCaseReport.serviceType === "Correctivo" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>PREVENTIVO</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport && fullDataCaseReport.powerPlantCaseReport.serviceType === "Preventivo" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>INSPECCIÓN</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport && fullDataCaseReport.powerPlantCaseReport.serviceType === "Inspección" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>EMERGENCIA</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport && fullDataCaseReport.powerPlantCaseReport.serviceType === "Emergencia" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>OTRO</Text>
                </View>
                <View style={{ height: '100%', width: '5%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport && fullDataCaseReport.powerPlantCaseReport.serviceType === "Otro" ? "X" : " "}</Text>
                </View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '1.8%', flexDirection: 'row', marginTop: '1.3%' }}>
                <Text style={{ fontSize: '24px' }}>OBSERVACIONES INICIALES</Text>
            </View>
            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', minHeight: '20px', flexDirection: 'row' }}>
                <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport.initialObservation}</Text>
            </View>

            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '3px solid #000', width: '95%', height: '1.8%', flexDirection: 'row', marginTop: '1.3%' }}>
                <Text style={{ fontSize: '24px' }}>ESTADO INICIAL</Text>
            </View >
            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '1.5%', flexDirection: 'row' }}>
                {Array.from({ length: 4 }).map((_, i) => (
                    <View key={i} style={{ borderRight: i !== 3 ? '3px solid #000' : 0, borderTop: 0, width: '25%', height: '100%', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '70%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>Descripción</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '10%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>B</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '10%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>R</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: 0, width: '10%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>M</Text>
                        </View>
                    </View>
                ))}
            </View>
            <View style={{ width: '95%', height: '16%', flexDirection: 'column', flexWrap: 'wrap' }}>
                {initialStatus.map(([key, value], i) => (
                    <View key={i} style={{ border: '3px solid #000', borderTop: 0, borderLeft: i >= 10 ? 0 : '3px solid #000', width: '25%', height: '9.2%', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '70%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{statusTranslate[key]}</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '10%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{value === "GOOD" && 'X'}</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '10%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{value === "REGULAR" && 'X'}</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: 0, width: '10%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{value === "BAD" && 'X'}</Text>
                        </View>
                    </View>
                ))}
            </View>
            <View style={{ width: '95%', minHeight: '120px', flexDirection: 'column', marginTop: '-2%' }}>
                {initialDescs.map(([key, value], i) => (
                    <View key={i} style={{ border: '3px solid #000', borderBottom: i === initialDescs.length - 1 ? '3px solid #000' : 0, width: '100%', minHeight: '20px', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000', width: '20%', minHeight: '20px', }}>
                            <Text style={{ fontSize: '24px' }}>{descsTranslate[key]}</Text>
                        </View>
                        <View style={{ display: 'flex', width: '80%', minHeight: '20px', }}>
                            <Text style={{ fontSize: '24px' }}>{value}</Text>
                        </View>
                    </View>))}
            </View>

            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '1.8%', flexDirection: 'row' }}>
                <Text style={{ fontSize: '24px' }}>ACTIVIDAD REALIZADA</Text>
            </View>
            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', minHeight: '20px', flexDirection: 'row' }}>
                <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport.activityPerformed}</Text>
            </View>

            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '3px solid #000', width: '95%', height: '1.8%', flexDirection: 'row', marginTop: '1.3%' }}>
                <Text style={{ fontSize: '24px' }}>PRUEBAS EN OPERACIÓN</Text>
            </View >
            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '1.5%', flexDirection: 'row' }}>
                {Array.from({ length: 3 }).map((_, i) => (
                    <View key={i} style={{ borderRight: i !== 2 ? '3px solid #000' : 0, borderTop: 0, width: i === 0 ? '34%' : '33%', height: '100%', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '50%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>Descripción</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '25%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{i == 0 ? "VALOR" : "CANTIDAD"}</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '25%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{i == 0 ? "UNIDAD" : "REFERENCIA"}</Text>
                        </View>
                    </View>
                ))}
            </View>
            <View style={{ width: '95%', height: '6%', flexDirection: 'column', flexWrap: 'wrap' }}>
                {finalValueUnits.map(({ key, value, unit }, i) => (
                    <View key={i} style={{ border: '3px solid #000', borderTop: 0, borderLeft: i >= 10 ? 0 : '3px solid #000', width: i < 3 ? '34%' : '33%', height: '32%', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '50%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{finalValueUnitsTranslate[key]}</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000', borderTop: 0, width: '25%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{value}</Text>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: 0, width: '25%', height: '100%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '22px' }}>{unit}</Text>
                        </View>
                    </View>
                ))}
            </View>
            <View style={{ width: '95%', height: '22%', flexDirection: 'column', flexWrap: 'wrap' }}>
                {finalDescriptions.map(([key, value], i) => (
                    <View key={i} style={{ border: '3px solid #000', borderBottom: (i === finalDescriptions.length - 1 || i === 16 || i === 33) ? '3px solid #000' : 0, width: '33.33%', height: '30px', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000', width: '50%', height: '30px', }}>
                            <Text style={{ fontSize: '24px' }}>{finalDescriptionsTranslate[key]}</Text>
                        </View>
                        <View style={{ display: 'flex', width: '50%', height: '30px', }}>
                            <Text style={{ fontSize: '24px' }}>{value}</Text>
                        </View>
                    </View>))}
                {finalStatus.map(([key, value], i) => (
                    <View key={i} style={{ border: '3px solid #000', borderBottom: i === finalStatus.length - 1 ? '3px solid #000' : 0, borderTop: i === 0 ? 0 : '3px solid #000', width: '33.33%', height: '30px', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000', width: '50%', height: '30px', }}>
                            <Text style={{ fontSize: '24px' }}>{finalStatusTranslate[key]}</Text>
                        </View>
                        <View style={{ display: 'flex', width: '50%', height: '30px', }}>
                            <Text style={{ fontSize: '24px' }}>{value}</Text>
                        </View>
                    </View>))}
            </View>
            <View style={{ border: '3px solid #000', width: '95%', height: '1.8%', flexDirection: 'row' }}>
                <Text style={{ fontSize: '24px' }}>OBSERVACIONES</Text>
            </View>
            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', minHeight: '20px', flexDirection: 'row' }}>
                <Text style={{ fontSize: '24px' }}>{fullDataCaseReport.powerPlantCaseReport.finalObservation}</Text>
            </View>




            <View style={{ marginTop: '0%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Image style={{ width: '30%', height: '150%' }} src={fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.firm ? fullDataCaseReport.bossDependencyWhenDone.person.firm : blanco}></Image>
                <Image style={{ width: '25%', height: '100%' }} src={(fullDataCaseReport.userCreator && fullDataCaseReport.userCreator.person.firm) ? fullDataCaseReport.userCreator.person.firm : blanco}></Image>
            </View>

            <View style={{ marginTop: '1%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '20px', marginTop: '0.4%' }}>
                    {
                        [
                            fullDataCaseReport.userCreator.person.name,
                            fullDataCaseReport.userCreator.person.secondName,
                            fullDataCaseReport.userCreator.person.lastName,
                            fullDataCaseReport.userCreator.person.secondLastName
                        ].filter(Boolean).join(' ')
                    }
                </Text>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.4%' }}>
                    {
                        [
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.name,
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.secondName,
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.lastName,
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.secondLastName
                        ].filter(Boolean).join(' ')
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.userCreator && fullDataCaseReport.userCreator.position
                    }
                </Text>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.position
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.userCreator && fullDataCaseReport.userCreator.profesion
                    }
                </Text>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '21px', marginTop: '0.1%', fontFamily: 'Arimo-Bold' }}>AGENTE</Text>
                <Text style={{ fontSize: '21px', marginTop: '0.1%', fontFamily: 'Arimo-Bold' }}>JEFE DE DEPENDENCIA</Text>

            </View>
        </Page >
    )
}