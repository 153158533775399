import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import BigIcon from '../../../assets/images/BigIcon.JPG'
import { ModuleWorkCard } from '../../../components/ModuleWorkCard/ModuleWorkCard'
import { useHistory, useLocation } from 'react-router-dom';


export const Home = () => {
    const dispatch = useDispatch()
    const { roleUser, moduleWorks } = useSelector(state => state.Login)
    const { moduleWork } = useSelector(state => state.Area)
    const [moduleWorkActive, setModuleWorkActive] = useState(null)
    const history = useHistory()

    useEffect(() => {
        if (moduleWork && roleUser.id === 36) {
            history.push('/Tickets')
        }
    }, [moduleWork])

    return (
        roleUser.id !== 36 ?
            <div className='homeDiv'>
                <p className='bienvenido'>Bienvenido</p>
                <p className='zeus'>Zeus Help Desk</p>
                <img alt='icon' className='img' src={BigIcon}></img>
            </div>
            :
            <div className='homeDiv' style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center' }}>
                <div className='ModuleWorkCards' style={{ width: '99%' }}>
                    {moduleWorks && moduleWorks.map(d => (
                        <ModuleWorkCard
                            key={d.moduleWork.id}
                            data={d}
                            isActive={moduleWorkActive === d.moduleWork.id}
                        />
                    ))}
                </div>
            </div>
    )
}