import { Button, Layout, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PrivateHeader from './Header/PrivateHeader'
import { PrivateSider } from './Sider/PrivateSider';
import { CaseReportActions } from '../../services/caseReport/CaseReportSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import inventoryIcon from '../../assets/images/inventoryIcon.svg'
import reportsIcon from '../../assets/images/reportsIcon.svg'
import timelineIcon from '../../assets/images/timelineIcon.svg'
import MPPIcon from '../../assets/images/MPPIcon.svg'
import trainingIcon from '../../assets/images/trainingIcon.svg'
import TransfersIcon from '../../assets/images/transfersIcon.svg'
import LendIcon from '../../assets/images/lendIcon.svg'
import DashIcon from '../../assets/images/DashIcon.svg'
import SummaryIcon from '../../assets/images/summaryIcon.svg'
import DailyIcon from '../../assets/images/daily.svg'
import SpecializedCenterIcon from '../../assets/images/specializedCenterIcon.svg'
import driversIcon from '../../assets/images/driversIcon.svg'

const { Content } = Layout;

const PrivateLayout = ({ children }) => {
    const history = useHistory()
    const [init, setInit] = useState(false);
    const [invStyle, setInvStyle] = useState("button")
    const [repStyle, setRepStyle] = useState("button")
    const [cronoStyle, setCronoStyle] = useState("button")
    const [mppStyle, setMppStyle] = useState("button")
    const [trainingStyle, setTrainingStyle] = useState("button")
    const [summaryStyle, setSummaryStyle] = useState("button")
    const [transfersStyle, setTransfersStyle] = useState("button")
    const [certificateStyle, setCertificateStyle] = useState("button")
    const [dailyStyle, setDailyStyle] = useState("button")
    const [specializedCenterStyle, setSpecializedCenterStyle] = useState("button")
    const [ticketsStyle, setTicketsStyle] = useState("button")
    const [driversStyle, setDriversStyle] = useState("button")
    const location = useLocation()
    const { moduleWork } = useSelector(state => state.Area)
    const { roleUser } = useSelector(state => state.Login)
    const dispatch = useDispatch()

    useEffect(() => {
        const styleSetters = {
            "/Cronograma": setCronoStyle,
            "/Reportes": setRepStyle,
            "/Inventarios": setInvStyle,
            "/MPP": setMppStyle,
            "/Capacitaciones": setTrainingStyle,
            "/Informes": setSummaryStyle,
            "/Traslados": setTransfersStyle,
            "/Certificados": setCertificateStyle,
            "/Tickets": setTicketsStyle,
            "/Daily": setDailyStyle,
            "/CentroEspecializado": setSpecializedCenterStyle,
            "/Conductores": setDriversStyle
        };

        Object.values(styleSetters).forEach(setStyle => setStyle("button"));

        if (styleSetters[location.pathname]) {
            styleSetters[location.pathname]("button1")
        }

        dispatch(EquipmentActions.setIsFiltered(false));
    }, [location]);

    return (
        <Layout className="layout">
            <PrivateHeader />
            <Layout>
                <PrivateSider />
                <Content className='private-content-layout'>
                    {roleUser.id !== 36 ? (moduleWork &&
                        <div className='buttonsLay'>
                            <Button className={'buttonNo'} type='primary'>
                                <img src={DashIcon} alt='inventoryIcon' className='icon'></img>
                                Dashboard
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push("/Inventarios")
                                    EquipmentActions.setEquipsWithCaseReports(false)
                                }}
                                className={invStyle} type='primary'>
                                <img src={inventoryIcon} alt='inventoryIcon' className='icon'></img>
                                Inventarios
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push("/Reportes")
                                    EquipmentActions.setEquipsWithCaseReports(false)
                                }}
                                className={repStyle} type='primary'>
                                <img src={reportsIcon} alt='inventoryIcon' className='icon'></img>
                                Reportes
                            </Button>
                            {moduleWork[0].moduleWork.id === 4 &&
                                <Button onClick={() => {
                                    history.push("/Daily")
                                    EquipmentActions.setEquipsWithCaseReports(false)
                                }} className={dailyStyle} type='primary'>
                                    <img src={DailyIcon} alt='inventoryIcon' className='icon'></img>
                                    Check List Diario
                                </Button>}
                            {moduleWork[0].moduleWork.id === 4 &&
                                <Button onClick={() => {
                                    history.push("/CentroEspecializado")
                                    EquipmentActions.setEquipsWithCaseReports(false)
                                }} className={specializedCenterStyle} type='primary'>
                                    <img src={SpecializedCenterIcon} alt='inventoryIcon' className='icon'></img>
                                    Centro Especializado
                                </Button>}
                            {moduleWork[0].moduleWork.id === 4 &&
                                <Button onClick={() => {
                                    history.push("/Conductores")
                                    EquipmentActions.setEquipsWithCaseReports(false)
                                }} className={driversStyle} type='primary'>
                                    <img src={driversIcon} alt='inventoryIcon' className='icon'></img>
                                    Conductores
                                </Button>}
                            {moduleWork[0].moduleWork.id === 1 &&
                                <Button onClick={() => {
                                    history.push("/Certificados")
                                    EquipmentActions.setEquipsWithCaseReports(false)
                                }} className={certificateStyle} type='primary'>
                                    <img src={reportsIcon} alt='inventoryIcon' className='icon'></img>
                                    Calibración
                                </Button>}
                            {moduleWork[0].moduleWork.id !== 4 &&
                                <Button onClick={() => moduleWork[0].moduleWork.id !== 1 ? message.warn("Inhabilitado") : history.push("/Cronograma")} className={moduleWork[0].moduleWork.id !== 1 ? 'buttonNo' : cronoStyle} type='primary'>
                                    <img src={timelineIcon} alt='inventoryIcon' className='icon'></img>
                                    Cronograma
                                </Button>}
                            {moduleWork[0].moduleWork.id !== 4 &&
                                <Button onClick={() => moduleWork[0].moduleWork.id !== 1 ? message.warn("Inhabilitado") : history.push("/MPP")} className={moduleWork[0].moduleWork.id !== 1 ? 'buttonNo' : mppStyle} type='primary'>
                                    <img src={MPPIcon} alt='inventoryIcon' className='icon'></img>
                                    MPP
                                </Button>}
                            <Button onClick={() => moduleWork[0].moduleWork.id !== 1 ? message.warn("Inhabilitado") : history.push("/Capacitaciones")} className={moduleWork[0].moduleWork.id !== 1 ? 'buttonNo' : trainingStyle} type='primary'>
                                <img src={trainingIcon} alt='inventoryIcon' className='icon'></img>
                                Capacitaciones
                            </Button>
                            <Button onClick={() => history.push("/Informes")} className={summaryStyle} type='primary'>
                                <img src={SummaryIcon} alt='inventoryIcon' className='icon'></img>
                                Informes
                            </Button>
                            <Button onClick={() => (moduleWork[0].moduleWork.id !== 1 && moduleWork[0].moduleWork.id !== 2) ? message.warn("Inhabilitado") : history.push("/Traslados")} className={(moduleWork[0].moduleWork.id !== 1 && moduleWork[0].moduleWork.id !== 2) ? 'buttonNo' : transfersStyle} type='primary'>
                                <img src={TransfersIcon} alt='inventoryIcon' className='icon'></img>
                                Traslados
                            </Button>
                            {/* {moduleWork[0].moduleWork.id !== 4 &&
                                <Button className={'buttonNo'} type='primary'>
                                    <img src={LendIcon} alt='inventoryIcon' className='icon'></img>
                                    Prestar
                                </Button>} */}
                        </div>)
                        :
                        <div>
                            <div className='buttonsLay'>
                                <Button className={ticketsStyle} onClick={() => history.push("/Tickets")} type='primary'>
                                    {/*  <img src={LendIcon} alt='inventoryIcon' className='icon'></img> */}
                                    Crear ticket
                                </Button>
                            </div>

                        </div>
                    }
                    <div className="private-container-layout">
                        {children}
                    </div>
                </Content>
            </Layout>

        </Layout>
    )
}

export default PrivateLayout
