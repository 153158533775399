// your code here...

import { useState } from "react"
import { Input } from "../../../components/Input/Input"

export const MyAccount = () => {
    const [passType, setPassType] = useState("password")

    const showPass = (e) => {
        if (e === "password") {
            setPassType("showed")
        } else {
            setPassType("password")
        }
    }

    return (
        <div className="MyAccount">
            <div className='titleDiv'><p className='inventoryText'>Mi cuenta</p></div>
            <div className='body'>
                <div className='form'>
                    <p>Acceso</p>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Input
                            /* {...registerLogin('pass', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("La contraseña es requerida");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })} */
                            height={'3vw'}
                            /* va={watchLogin("pass")} */
                            /* setData={e => setValueLogin("pass", e)}
                            error={errorsLogin.pass && errorsLogin.pass.message} */
                            labelstyle='inputLabel'
                            labelsize='1.1vw'
                            fontsize='1.1vw'
                            /* type={passType} */
                            /* lock={true} */
                            label='Correo electrónico'
                            placeholder='Correo electrónico'
                        />
                        <div>
                            <Input
                                /* {...registerLogin('pass', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("La contraseña es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })} */
                                height={'3vw'}
                                /* va={watchLogin("pass")} */
                                /* setData={e => setValueLogin("pass", e)}
                                error={errorsLogin.pass && errorsLogin.pass.message} */
                                labelstyle='inputLabel'
                                labelsize='1.1vw'
                                fontsize='1.1vw'
                                type={passType}
                                /* lock={true} */
                                label='Contraseña'
                                placeholder='Contraseña'
                            />
                            <div className='checkbox'>
                                <div className={`box ${passType}`} id={passType} onClick={e => showPass(e.target.id)}></div>
                                <p>Mostrar contraseña</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
