import { useHistory, useLocation } from 'react-router-dom';
import { PdfCaseReport } from "../../../components/PdfCaseReport/PdfCaseReport";
import { Page, Document, pdf, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect } from 'react';
import { PdfInventory } from '../../../components/PdfInventory/PdfInventory';
import { useSelector } from 'react-redux';
import { PdfComputersEquipment } from '../../../components/PdfComputersEquipment/PdfComputersEquipment';
import { PdfNetworkingAndCommunicationsEquipment } from '../../../components/PdfNetworkingAndCommunicationsEquipment/PdfNetworkingAndCommunicationsEquipment';
import { PdfPeripheralsAndAccessoriesEquipment } from '../../../components/PdfPeripheralsAndAccessoriesEquipment/PdfPeripheralsAndAccessoriesEquipment';
import { PdfIndustrialEquipment } from '../../../components/PdfIndustrialEquipment/PdfIndustrialEquipment';

export const MasivePDFInventory = () => {
    const location = useLocation()
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { moduleWork } = useSelector(state => state.Area)
    //get the data from the location
    const { data } = location.state

    const CombinedDocuments = () => (
        <>
            {data && data.map((element, index) => {
                switch (moduleWork[0].moduleWork.id) {
                    case 1:
                        return <PdfInventory key={index} fullDataEquipment={element} enterprise={enterpriseImage} />
                    case 2:
                        if (element.computersEquipment) {
                            return <PdfComputersEquipment key={index} fullDataEquipment={element} enterprise={enterpriseImage} />
                        }
                        if (element.networkingAndCommunicationsEquipment) {
                            return <PdfNetworkingAndCommunicationsEquipment key={index} fullDataEquipment={element} enterprise={enterpriseImage} />
                        }
                        if (element.peripheralsAndAccessoriesEquipment) {
                            return <PdfPeripheralsAndAccessoriesEquipment key={index} fullDataEquipment={element} enterprise={enterpriseImage} />
                        }
                        break;
                    case 3:
                        return <PdfIndustrialEquipment key={index} fullDataEquipment={element} enterprise={enterpriseImage} />
                }
            })}
        </>
    );

    if (!data || data.length === 0) {
        return <div>No hay inventarios en esta dependencia</div>;
    }

    return (
        <PDFViewer style={{ width: '80vw', height: '80vw' }}>
            <Document>
                <CombinedDocuments />
            </Document>
        </PDFViewer>
    )
}