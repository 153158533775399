import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lastSummaryNumber: false,
    summaryCreated: false,
    loadingCreateSummary: false,
    summaries: false,
    summarySearched: false,
    loadingSummarySearched: false,
    
};

const SummarySlice = createSlice({
    name: "Summary",
    initialState,
    reducers: {
        getLastSummaryNumber(state, { payload }) {
        },
        setLastSummaryNumber(state, { payload }) {
            state.lastSummaryNumber = payload
        },
        createSummary(state, { payload }) {
            state.loadingCreateSummary = true;
        },
        setSummaryCreated(state, { payload }) {
            state.summaryCreated = payload
        },
        setLoadingCreateSummary(state, { payload }) {
            state.loadingCreateSummary = payload;
        },
        getSummaries(state, { payload }) {
        },
        setSummaries(state, { payload }) {
            state.summaries = payload
        },
        getSummarySearched(state, { payload }) {
            state.loadingSummarySearched = true;
        },
        setSummarySearched(state, { payload }) {
            state.summarySearched = payload
        },
        setLoadingSummarySearched(state, { payload }) {
            state.loadingSummarySearched = payload;
        },
        deleteSummary() { },
    },
});

const SummaryActions = SummarySlice.actions;
const SummaryReducer = SummarySlice.reducer;

export { SummaryActions, SummaryReducer };
