import { Image, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import ArimoBold from '../../assets/fonts/Arimo-Bold.ttf';

Font.register({
    family: 'Arimo-Bold',
    src: ArimoBold,
});

export const PdfAirConditionatersAndRefri = ({ fullDataCaseReport, data, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            alignItems: 'center'
        },
        header: {
            border: '3px solid #000',
            marginTop: '3%',
            width: '95%',
            height: '8%',
            flexDirection: 'row'
        },
        headersub0: {
            width: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '3px solid #000'
        },
        headersub1: {
            width: '60%',
            borderRight: '3px solid #000'
        },
        headerRow: {
            height: '70%',
            width: '100%',
            borderBottom: '3px solid #000',
            alignItems: 'center',
            justifyContent: 'center'
        },
        headerRow1: {
            height: '30%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        bigText: {
            fontSize: '32px'
        },
        text: {
            fontSize: '18px'
        },
        generalInfo: {
            border: '3px solid #000',
            marginTop: '1%',
            width: '93%',
            height: '18%',
            flexDirection: 'row'
        },
        viewImg: {
            width: '25%',
            borderRight: '3px solid #000'
        },
        viewData: {
            width: '75%',
            flexDirection: 'column'
        }

    });

    return (
        <Page size="A1" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.headersub0}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={styles.headersub1}>
                    <View style={styles.headerRow}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>NAZHER CENTRO MEDICO ESPECIALIZADO S.A.S</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>FLORENCIA-CAQUETA</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>NIT: 901244204</Text>
                    </View>
                    <View style={styles.headerRow1}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>HOJA DE REPORTE SERVICIO TÉCNICO</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>AIRES ACONDICIONADOS-NEVERAS</Text>
                    </View>
                </View>
                <View style={styles.headersub0}>
                    <View style={styles.headerRow}>
                        <View style={{ width: '100%', height: '60%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>CODIGO</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>FT-VH-TA-01</Text>
                            </View>
                        </View>
                        <View style={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>VERSION</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>1</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.headerRow1}>
                        <View style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>PAGINAS</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>1 DE 1</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            <View style={{ width: '95%', height: '5%', marginTop: '0.7%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px', textAlign: 'center' }}>REPORTE DE SERVICIO:</Text>
                    <Text style={{ fontSize: '21px', textAlign: 'center' }}>N° IH- 0122690 </Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '1.5%', marginTop: '1%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>1. Clasificación del servicio</Text>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>1.1 Tipo de servicio</Text>
                </View>
                <View style={{ width: '50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>1.2 Carácter</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Preventivo</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Preventivo" && 'X'}</Text>
                    </View>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Correctivo</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Correctivo" && 'X'}</Text>
                    </View>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Instalación</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Instalación" && 'X'}</Text>
                    </View>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Capacitación</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Capacitación" && 'X'}</Text>
                    </View>
                </View>
                <View style={{ width: '50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Garantía</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.character === "Garantía" && 'X'}</Text>
                    </View>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Contrato</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.character === "Contrato" && 'X'}</Text>
                    </View>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Factura</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.character === "Factura" && 'X'}</Text>
                    </View>
                    <View style={{ width: '16.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Otro</Text>
                    </View>
                    <View style={{ width: '8.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.character === "Otro" && 'X'}</Text>
                    </View>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Ingeniero/Técnico</Text>
                </View>
                <View style={{ width: '25%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Ciudad/Municipio</Text>
                </View>
                <View style={{ width: '25%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Fecha (Dia/Mes/Año)</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px' }}>{(fullDataCaseReport.userCreator && fullDataCaseReport.userCreator.person) &&
                        [
                            fullDataCaseReport.userCreator.person.name,
                            fullDataCaseReport.userCreator.person.secondName,
                            fullDataCaseReport.userCreator.person.lastName,
                            fullDataCaseReport.userCreator.person.secondLastName
                        ].filter(Boolean).join(' ')
                    }</Text>
                </View>
                <View style={{ width: '25%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px' }}>FLORENCIA CAQUETA</Text>
                </View>
                <View style={{ width: '25%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px' }}>{fullDataCaseReport.date ? padStr(new Date(fullDataCaseReport.date).getDate()) + "/" + padStr(1 + new Date(fullDataCaseReport.date).getMonth()) + "/" + padStr(new Date(fullDataCaseReport.date).getFullYear()) : ''}</Text>
                </View>
            </View>

            <View style={{ width: '95%', height: '1.5%', marginTop: '1%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>2. Datos del responsable del equipo</Text>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '70%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Nombre del cliente:</Text> NAZHER CENTRO MEDICO ESPECIALIZADO S.A.S</Text>
                </View>
                <View style={{ width: '30%', height: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Telefono:</Text> 4347306</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '70%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Funcionario / Contacto:</Text> {(fullDataCaseReport.coordinatorAreaWhenDone && fullDataCaseReport.userCreator.person) &&
                        [
                            fullDataCaseReport.coordinatorAreaWhenDone.person.name,
                            fullDataCaseReport.coordinatorAreaWhenDone.person.secondName,
                            fullDataCaseReport.coordinatorAreaWhenDone.person.lastName,
                            fullDataCaseReport.coordinatorAreaWhenDone.person.secondLastName
                        ].filter(Boolean).join(' ')
                    }</Text>
                </View>
                <View style={{ width: '30%', height: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Cargo:</Text> {fullDataCaseReport.coordinatorAreaWhenDone && fullDataCaseReport.coordinatorAreaWhenDone.position + " "}</Text>
                </View>
            </View>

            <View style={{ width: '95%', height: '1.5%', marginTop: '1%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>3. Datos del equipo</Text>
            </View>
            <View style={{ width: '95%', minHeight: '50px', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Nombre del equipo:</Text> {fullDataCaseReport.equipment.name}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>BTU:</Text>  {fullDataCaseReport.equipment.industrialEquipment.btu}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Placa de Inventario:</Text> {fullDataCaseReport.equipment.licensePlate}</Text>
                </View>
            </View>
            <View style={{ width: '95%', minHeight: '50px', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '25%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Marca:</Text> {fullDataCaseReport.equipment.industrialEquipment.brand}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Modelo:</Text>  {fullDataCaseReport.equipment.industrialEquipment.modell}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Serie:</Text>  {fullDataCaseReport.equipment.industrialEquipment.serie}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>N° de contrato:</Text> {" "}</Text>
                </View>
            </View>
            <View style={{ width: '95%', minHeight: '50px', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '25%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Tipo:</Text> {fullDataCaseReport.equipment.industrialEquipment.type + " "}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Area:</Text>  {fullDataCaseReport.dependencyWhenDone.area.name + " "}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Dependencia:</Text>  {fullDataCaseReport.dependencyWhenDone.name + " "}</Text>
                </View>
                <View style={{ width: '25%', minHeight: '50px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Ubicación:</Text> {fullDataCaseReport.equipment.location.name + " "}</Text>
                </View>
            </View>

            <View style={{ width: '95%', height: '1.5%', marginTop: '1%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>4. Descripción del servicio</Text>
            </View>
            {fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Preventivo" &&
                <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>4.1. Falla Reportada y/o detectada:</Text> {fullDataCaseReport.airConditionerAndFridgeCaseReport.reportedFailure}</Text>
                </View>}
            {fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Correctivo" &&
                <View style={{ width: '95%', height: '2%', borderBottom: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                    <Text style={{ fontSize: '21px', marginLeft: '1%', fontFamily: 'Arimo-Bold' }}>4.2. Acción correctiva o procedimiento realizado</Text>
                </View>}
            {fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Correctivo" && <View style={{ width: '95%', height: '1.5%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.initialEquipmentReview && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Revisión inicial del equipo</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.pneumaticTests && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Pruebas neumáticas</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.externalAndInternalCleaningOfComponents && 'X'}</Text>
                </View>
                <View style={{ width: '28%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Limpieza externa e interna de componentes</Text>
                </View>
            </View>}
            {fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Correctivo" && <View style={{ width: '95%', height: '1.5%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.maintenanceAccordingToProtocol && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Mantenimiento de acuerdo con el protocolo</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.hydraulicTests && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Pruebas hidráulicas</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.finalTestsOfAllEquipmentFunctions && 'X'}</Text>
                </View>
                <View style={{ width: '28%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Pruebas finales de todas las funciones del equipo</Text>
                </View>
            </View>}
            {fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Correctivo" && <View style={{ width: '95%', height: '1.5%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.electricalSystemReviewAndMaintenance && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Revisión y mantenimiento sistema eléctrico</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.softwareValidationAndConfiguration && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Validación y configuración software</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.validationAndCommissioningOfTheEquipment && 'X'}</Text>
                </View>
                <View style={{ width: '28%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Validación y puesta en marcha del equipo</Text>
                </View>
            </View>}
            {fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Correctivo" && <View style={{ width: '95%', height: '1.5%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.mechanicalSystemReviewAndMaintenance && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Revisión y mantenimiento sistema mecánico</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.validationAndOperationOfTheOS && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Revisión y funcionamiento del S.O.</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.theEquipIsWorkingWell && 'X'}</Text>
                </View>
                <View style={{ width: '28%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>El equipo queda trabajando bien</Text>
                </View>
            </View>}
            {fullDataCaseReport.airConditionerAndFridgeCaseReport.serviceType === "Correctivo" && <View style={{ width: '95%', height: '1.5%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.electronicSystemReviewAndMaintenance && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Revisión y mantenimiento sistema electrónico</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.accessoriesReview && 'X'}</Text>
                </View>
                <View style={{ width: '27%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Revisión de accesorios</Text>
                </View>
                <View style={{ width: '6%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{fullDataCaseReport.airConditionerAndFridgeCaseReport.correctiveActionsSeeObservations && 'X'}</Text>
                </View>
                <View style={{ width: '28%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>Acciones correctivas / ver observaciones</Text>
                </View>
            </View>}

            <View style={{ width: '95%', height: '1.5%', marginTop: '1%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Descripción de la intervención:</Text>
            </View>
            <View style={{ width: '95%', height: '1.5%', marginTop: '1%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>5. Cambio o instalación de partes:</Text> Si</Text>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: '21px', marginLeft: '1%', fontFamily: 'Arimo-Bold' }}>Partes Requeridas</Text>
            </View>
            <View style={{ width: '95%', minHeight: '50px', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '8%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px' }}>Cantidad: </Text>
                </View>
                <View style={{ width: '52%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px' }}>NOMBRE:</Text>
                </View>
                <View style={{ width: '20%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px' }}>N° de parte: </Text>
                </View>
                <View style={{ width: '20%', minHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '21px' }}>N° de serie:</Text>
                </View>
            </View>
            {fullDataCaseReport.reportComponent.map((d, i) => (
                <View key={i} style={{ width: '95%', minHeight: '50px', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <View style={{ width: '8%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Text style={{ fontSize: '21px', marginLeft: '1%' }}>{d.value + " "}</Text>
                    </View>
                    <View style={{ width: '52%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Text style={{ fontSize: '21px', marginLeft: '1%' }}> {d.name + " "}</Text>
                    </View>
                    <View style={{ width: '20%', minHeight: '50px', borderRight: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Text style={{ fontSize: '21px', marginLeft: '1%' }}> {d.partNumber + " "} </Text>
                    </View>
                    <View style={{ width: '20%', minHeight: '50px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Text style={{ fontSize: '21px', marginLeft: '1%' }}> {d.serial + " "}</Text>
                    </View>
                </View>))}

            <View style={{ width: '95%', minHeight: '20px', marginTop: '1%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Text style={{ fontSize: '21px', marginLeft: '1%' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>6. Observaciones:</Text> {fullDataCaseReport.airConditionerAndFridgeCaseReport.observations}</Text>
            </View>

            {/* <View style={{ width: '95%', height: '2%', marginTop: '1%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Fecha y hora de llegada</Text>
                </View>
                <View style={{ width: '50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Fecha y hora de salida</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>12/02/10 9:30 p.m</Text>
                </View>
                <View style={{ width: '50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>12/02/10 9:50 p.m</Text>
                </View>
            </View> */}


            <View style={{ marginTop: '0%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Image style={{ width: '30%', height: '150%' }} src={fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.firm ? fullDataCaseReport.bossDependencyWhenDone.person.firm : blanco}></Image>
                <Image style={{ width: '25%', height: '100%' }} src={(fullDataCaseReport.userCreator && fullDataCaseReport.userCreator.person.firm) ? fullDataCaseReport.userCreator.person.firm : blanco}></Image>
            </View>

            <View style={{ marginTop: '1%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '20px', marginTop: '0.4%' }}>
                    {
                        [
                            fullDataCaseReport.userCreator.person.name,
                            fullDataCaseReport.userCreator.person.secondName,
                            fullDataCaseReport.userCreator.person.lastName,
                            fullDataCaseReport.userCreator.person.secondLastName
                        ].filter(Boolean).join(' ')
                    }
                </Text>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.4%' }}>
                    {
                        [
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.name,
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.secondName,
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.lastName,
                            fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.secondLastName
                        ].filter(Boolean).join(' ')
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.userCreator && fullDataCaseReport.userCreator.position
                    }
                </Text>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.position
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.userCreator && fullDataCaseReport.userCreator.profesion
                    }
                </Text>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '21px', marginTop: '0.1%', fontFamily: 'Arimo-Bold' }}>AGENTE</Text>
                <Text style={{ fontSize: '21px', marginTop: '0.1%', fontFamily: 'Arimo-Bold' }}>JEFE DE DEPENDENCIA</Text>
            </View>
        </Page >
    )
}