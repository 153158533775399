import { message } from 'antd'
import { takeLatest, all, put, call } from 'redux-saga/effects'
import Api from "../../common/api"

import { SpecializedCenterActions } from './SpecializedCenterSlice'

function* createSpecializedCenter({ payload }) {
  const sc = yield Api.post(`/specializedCenter`, payload)
  message[sc.payload.httpStatus](sc.payload.message)
  yield put(SpecializedCenterActions.setLoadinCreateSpecializedCenter(false))
}

function* getSpecializedCenters({ payload }) {
  const sc = yield Api.get(`/specializedCenter/search?name=${payload.name}&nit=${payload.nit}&type=${payload.type}&status=${payload.status}&year=${payload.year}&month=${payload.month}&take=${payload.take}&page=${payload.page}`)
  if (sc.ok) {
    yield put(SpecializedCenterActions.setSpecializedCentersSearched(sc.payload))
  } else {
    message.error("Error al buscar los centros especializados")
  }
  yield put(SpecializedCenterActions.setLoadinGetSpecializedCenters(false))
}

function* deleteSpecializedCenter({ payload }) {
  const sc = yield Api.post(`/specializedCenter/delete?id=${payload}`)
  if (sc.ok) {
    message.success("Centro especializado eliminado correctamente")
  } else {
    message.error("Error al eliminar el centro especializado")
  }
  yield put(SpecializedCenterActions.setLoadinDeleteSpecializedCenter(false))
}

function* getFullDataSpecializedCenter({ payload }) {
  const sc = yield Api.get(`/specializedCenter/getFullDataSpecializedCenterById?id=${payload}`)
  if (sc.ok) {
    yield put(SpecializedCenterActions.setFullDataSpecializedCenter(sc.payload))
  } else {
    message.error("Error al obtener la información del centro especializado")
  }
  yield put(SpecializedCenterActions.setLoadinGetFullDataSpecializedCenter(false))
}

function* updateSpecializedCenter({ payload }) {
  const sc = yield Api.post(`/specializedCenter/update?id=${payload.id}`, payload.body)
  message[sc.payload.httpStatus](sc.payload.message)
  yield put(SpecializedCenterActions.setLoadinCreateSpecializedCenter(false))
}

function* actionWatcher() {
  yield takeLatest(SpecializedCenterActions.createSpecializedCenter, createSpecializedCenter)
  yield takeLatest(SpecializedCenterActions.getSpecializedCenters, getSpecializedCenters)
  yield takeLatest(SpecializedCenterActions.deleteSpecializedCenter, deleteSpecializedCenter)
  yield takeLatest(SpecializedCenterActions.getFullDataSpecializedCenter, getFullDataSpecializedCenter)
  yield takeLatest(SpecializedCenterActions.updateSpecializedCenter, updateSpecializedCenter)

}

export default function* SpecializedCenterSaga() {
  yield all([actionWatcher()])
}