import { Modal } from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { apiUrl } from '../../common/config/Environments'


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PdfInventory } from '../PdfInventory/PdfInventory';
import moment from "moment";
import { SpecializedCenterActions } from '../../services/SpecializedCenter/SpecializedCenterSlice';

export const SpecializedCenterCard = ({ data, setEdit, setEquipData, extraOnClick, width, height }) => {
    const [visibleModal, setVisibleModal] = useState(false)
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { loadingGetFullDataSpecializedCenter } = useSelector(state => state.SpecializedCenter)
    const [pdf, setPdf] = useState(false)
    const [image, setImage] = useState(false)
    const { roleUser, fullUser } = useSelector(state => state.Login)

    const dispatch = useDispatch()
    useEffect(() => {
        fetch(`${apiUrl}/specializedCenter/imageById?id=${data.id}`)
            .then(response => response.json())
            .then(data => setImage(data))
            .catch(error => console.error(error))
    }, [])

    /* useEffect(() => {
        pdf === 'all' && dispatch(SpecializedCenterCardActions.getEquipAndReportPDF(data.id))
    }, [pdf]) */

    /* const MyDocument = () => (
        <Document>
            <PdfInventory fullDataSpecializedCenterCard={fullDataSpecializedCenterCard} data={data} enterprise={enterpriseImage} />
        </Document >
    ); */


    const ModalEquip = () => {
        const onClickEdit = () => {
            setEdit(true)
        }
        return (
            <Modal className='ModalEquip' width={/* (fullDataSpecializedCenterCard || annexesPdf) ? '90vw' : */ '38vw'} visible={visibleModal} footer={null} onCancel={() => {
                setVisibleModal(false)
                /* dispatch(SpecializedCenterCardActions.setFullDataSpecializedCenterCard(false))
                dispatch(SpecializedCenterCardActions.setEquipAndReportPDF(false))
                dispatch(SpecializedCenterCardActions.setAnnexesPdf(false)) */
            }}>
                {/* (!fullDataSpecializedCenterCard && !annexesPdf) &&  */<p className="title">{data.name}</p>}
                {/* (!fullDataSpecializedCenterCard && !annexesPdf) && */ <div className='div'>
                    {/* <button className='button' onClick={() => {
                        dispatch(SpecializedCenterCardActions.getFullDataSpecializedCenterCard(data.id))
                    }}>
                        {loadingFullDataSpecializedCenterCard ? <LoadingOutlined /> : "Ver Hoja de Vida"}
                    </button> */}
                    {fullUser.canCreate && <button className='button' onClick={() => {
                        onClickEdit()
                        dispatch(SpecializedCenterActions.getFullDataSpecializedCenter(data.id))
                    }}>
                        {loadingGetFullDataSpecializedCenter ? <LoadingOutlined /> : "Editar Centro Especializado"}
                    </button>}

                    {/* <button className='button' onClick={() => {
                        dispatch(SpecializedCenterCardActions.setFullDataSpecializedCenterCard(
                            {
                                "biomedicSpecializedCenterCard": {
                                    "active": '',
                                    "brand": '',
                                    "serie": '',
                                    "adquisitionDate": null,
                                    "modell": '',
                                    "service": '',
                                    "instalationDate": null,
                                    "healthRegister": '',
                                    "fabricationDate": null,
                                    "startOperationDate": null,
                                    "location": '',
                                    "representative": '',
                                    "warrantyExpirationDate": null,
                                    "maker": '',
                                    "cost": '',
                                    "webPage": '',
                                    "makerCountry": '',
                                    "adquisitionWay": '',
                                    'voltageRange': '',
                                    'pressureRange': '',
                                    'powerSource': '',
                                    'currentRange': '',
                                    'speedRange': '',
                                    'riskClasification': '',
                                    'powerRange': '',
                                    'humidityRange': '',
                                    'frequency': '',
                                    'temperatureRange': '',
                                    'weight': '',
                                    'dimensions': '',
                                    'biomedicComponent': [{
                                        'name': '',
                                        'brand': '',
                                        'reference': '',
                                        'partNumber': '',
                                    }],
                                    "manual": '',
                                    "plane": '',
                                    'use': '',
                                    'biomedicClasification': '',
                                    'tecnology': '',
                                    'operation': '',
                                    'electricSecurity': '',
                                    'maintenance': '',
                                    'SpecializedCenterCardOwner': '',
                                    'irregularCause': '',
                                },
                                "licensePlate": '',
                                "name": '',
                                "municipality": null,

                            }
                        ))
                    }}>
                        {loadingFullDataSpecializedCenterCard ? <LoadingOutlined /> : "Formato vacio"}
                    </button> */}
                    {/*  <button className='button' onClick={() => setPdf('all')}>Descargar </button> */}
                </div>}

                {/* {fullDataSpecializedCenterCard && <PDFViewer className='pdf'>
                    <MyDocument></MyDocument>
                </PDFViewer>} */}
                {/* {equipAndReportPDF &&
                    <div>
                        <PDFViewer className='pdf'>
                            <MyDocument></MyDocument>
                        </PDFViewer>
                        {equipAndReportPDF.caseReport && equipAndReportPDF.caseReport.map(d => (
                            <embed className='pdf' src={d.pdf}></embed>
                        ))}
                    </div>} */}

            </Modal >
        )
    }

    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este centro especializado?')) {
            dispatch(SpecializedCenterActions.deleteSpecializedCenter(data.id))
        }
    }

    // Obtén una referencia a todos los elementos
    const elements = document.querySelectorAll('.dataDiv');

    elements.forEach(element => {
        // Obtén una referencia al elemento padre
        const parent = element.parentElement;

        // Calcula el tamaño de la fuente como un porcentaje del ancho del elemento padre
        const fontSize = parent.offsetWidth * 0.014; // 1.4% del ancho del padre

        // Establece el tamaño de la fuente del elemento
        element.style.fontSize = `${fontSize}px`;
    });

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const days = moment.utc().diff(moment.utc(data.specializedCenterContract[0].endDate), 'days') * -1

    return (
        <div className='SpecializedCenterCard' style={{ width: width, height: height }}>
            {image ?
                <img className='SpecializedCenterCardImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                if (extraOnClick) {
                    extraOnClick(data)
                } else {
                    setVisibleModal(true)
                }
            }}>
                <div><p className='pFontData name'>{data.type}</p></div>
                <div><p className='pFontData'>{data.name}</p></div>
                <div><p className='pFontData'>{data.nit}</p></div>
                <div><p className='pFontData'>{data.legalRepresentative ? data.legalRepresentative : ''}</p></div>
                <div><p className='pFontData'>{data.specializedCenterContract &&
                    padStr(new Date(data.specializedCenterContract[0].startDate).getDate()) + "/" +
                    padStr(1 + new Date(data.specializedCenterContract[0].startDate).getMonth()) + "/" +
                    padStr(new Date(data.specializedCenterContract[0].startDate).getFullYear())}
                </p></div>
                <div><p className='pFontData'>{days}</p></div>
                {days > 15 ? <div className='status'><p className='pFontStatus'>Activo</p></div>
                    : (days <= 15 && days > 0) ? <div className='status1'><p className='pFontStatus'>Activo</p></div>
                        : days <= 0 && <div className='status2'><p className='pFontStatus'>Inactivo</p></div>}
            </div>
            {roleUser.id !== 36 && <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>}
            <ModalEquip />
        </div >
    )
}