import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"
import { dailyVehicleActions } from './dailyVehicleSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(dailyVehicleActions.setMunicipalitys(muns.payload))
  }
  yield put(dailyVehicleActions.setLoading(false))
} */

function* createDailyVehicle({ payload }) {
  const e = yield Api.post('/dailyVehicle', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
  yield put(dailyVehicleActions.setLoadingcreateDailyVehicle(false))
}

function* getDailyVehicles({ payload }) {
  const dv = yield Api.get(`/dailyVehicle?take=${payload.take}&page=${payload.page}`)
  if (dv.ok) {
    yield put(dailyVehicleActions.setDailyVehicles(dv.payload))
  } else {
    message.error("Error el consultar check list diarios")
  }
  yield put(dailyVehicleActions.setLoadingGetDailyVehicles(false))
}

function* getFullDataDailyVehicle({ payload }) {
  const dv = yield Api.get(`/dailyVehicle/getFullData?id=${payload.id}`)
  if (dv.ok) {
    yield put(dailyVehicleActions.setFullDataDailyVehicle(dv.payload))
  } else {
    message.error("Error el consultar check list diarios")
  }
  yield put(dailyVehicleActions.setLoadingFullDataDailyVehicle(false))
}

function* actionWatcher() {
  yield takeLatest(dailyVehicleActions.createDailyVehicle, createDailyVehicle)
  yield takeLatest(dailyVehicleActions.getDailyVehicles, getDailyVehicles)
  yield takeLatest(dailyVehicleActions.getFullDataDailyVehicle, getFullDataDailyVehicle)
}

export default function* dailyVehicleSaga() {
  yield all([actionWatcher()])
}