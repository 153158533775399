import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loadingEnterprise: false,
    enterpriseCreatedId: false,
    areaCreatedId: [],
    isAreaCreated: false,
    usersCreated: false,
    enterpriseImage: false,
    headquarters: false,
};

const EnterpriseSlice = createSlice({
    name: "Enterprise",
    initialState,
    reducers: {
        createEnterprise(state) {
            state.loadingEnterprise = true
        },
        setLoading(state, { payload }) {
            state.loadingEnterprise = payload
        },
        setCreatedId(state, { payload }) {
            state.enterpriseCreatedId = payload
        },
        createHeadquarters() { },
        createAdmins() { },
        setAreaCreatedId(state, { payload }) {
            state.areaCreatedId = payload
        },
        createArea(state, { payload }) {
            state.isAreaCreated = false
        },
        createDependency() { },
        setIsAreaCreated(state, { payload }) {
            state.isAreaCreated = payload
        },
        createUsers() { },
        setUsersCreated(state, { payload }) {
            state.usersCreated = payload
        },
        getEnterpriseImage() { },
        setEnterpriseImage(state, { payload }) {
            state.enterpriseImage = payload
        },
        getHeadquarters() { },
        setHeadquarters(state, { payload }) {
            state.headquarters = payload
        },
    },
});

const EnterpriseActions = EnterpriseSlice.actions;
const EnterpriseReducer = EnterpriseSlice.reducer;

export { EnterpriseActions, EnterpriseReducer };
