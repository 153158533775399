/* import { CloseOutlined } from "@ant-design/icons";
 */import { Document, Image, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import { Modal, Radio } from "antd";
import { useEffect, useState } from "react";
import blanco from '../../../assets/images/blanco.png'
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { CaseReportActions } from "../../../services/caseReport/CaseReportSlice";
import { useLocation } from "react-router-dom";
import { PdfCaseReport } from "../../../components/PdfCaseReport/PdfCaseReport";
import { PdfCaseReportSystem } from '../../../components/PdfCaseReportSystem/PdfCaseReportSystem';
import { PdfCaseReportPowerPlant } from '../../../components/PdfCaseReportPowerPlant/PdfCaseReportPowerPlant';
import { PdfAirConditionatersAndRefri } from '../../../components/PdfAirConditionatersAndRefri/PdfAirConditionatersAndRefri';

export const ReportToFirm = (enterprise) => {
    const [visibleModal, setVisibleModal] = useState(true)
    const [caseReportHigh, setCaseReportHigh] = useState(false)
    const [firm, setFirm] = useState(false)
    const [firm1, setFirm1] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const moduleWork = queryParams.get('moduleWork')
    const { enterpriseImage } = useSelector(state => state.Enterprise)

    useEffect(() => {
        moduleWork && dispatch(CaseReportActions.getFullDataCaseReport({ "id": id, "moduleWork": moduleWork }))
    }, [id, moduleWork])

    const { fullDataCaseReport, idEquipmentSelected } = useSelector(state => state.CaseReport)
    const dispatch = useDispatch()

    useEffect(() => {
        visibleModal === false && dispatch(CaseReportActions.setFullDataCaseReport(false))
    }, [visibleModal])

    useEffect(() => {
        dispatch(CaseReportActions.setAllCaseReportsByEquipmentId(false))
        idEquipmentSelected && dispatch(CaseReportActions.getAllCaseReportsByEquipmentId({ "id": idEquipmentSelected, "page": currentPage }))
    }, [currentPage])

    const renderPdfReport = () => {
        switch (moduleWork) {
            case "1":
                return <PdfCaseReport fullDataCaseReport={fullDataCaseReport} enterprise={enterpriseImage} />;
            case "2":
                if (fullDataCaseReport.equipment.computersEquipment ||
                    fullDataCaseReport.equipment.networkingAndCommunicationsEquipment ||
                    fullDataCaseReport.equipment.peripheralsAndAccessoriesEquipment) {
                    return <PdfCaseReportSystem fullDataCaseReport={fullDataCaseReport} enterprise={enterpriseImage} />;
                }
                break;
            case "3":
                if (fullDataCaseReport.equipment.powerPlantCaseReport) {
                    return <PdfCaseReportPowerPlant fullDataCaseReport={fullDataCaseReport} enterprise={enterpriseImage} />;
                }
                if (fullDataCaseReport.equipment.airConditionerAndFridgeCaseReport) {
                    return <PdfAirConditionatersAndRefri fullDataCaseReport={fullDataCaseReport} enterprise={enterpriseImage} />;
                }
                break;
            default:
                return null;
        }
    }

    const ModalEquip = () => {
        return (
            <Modal className='ModalEquip' width={'90vw'} visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
                {fullDataCaseReport && <PDFViewer className='pdf'>
                    <Document>
                        {renderPdfReport()}
                    </Document>
                </PDFViewer>}
            </Modal >
        )
    }

    return (
        <div className='CaseReport' >
            <ModalEquip />
        </div>
    )
}