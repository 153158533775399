import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    countries: [],
    departments: [],
    municipalitys: []
};

const DepMunSlice = createSlice({
    name: "DepMun",
    initialState,
    reducers: {
        setCountries(state, { payload }) {
            state.countries = payload
        },
        getCountries() { },
        setDepartments(state, { payload }) {
            state.departments = payload
        },
        getDepartments() { },
        getDepartmentsFromCountry() { },
        setMunicipalitys(state, { payload }) {
            state.municipalitys = payload
        },
        getMunicipalitys() { },
    },
});

const DepMunActions = DepMunSlice.actions;
const DepMunReducer = DepMunSlice.reducer;

export { DepMunActions, DepMunReducer };
