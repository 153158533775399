import {
  Document,
  Image,
  Page,
  PDFViewer,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import blanco from "../../assets/images/blanco.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfCaseReportSystem = ({ fullDataCaseReport, enterprise }) => {
  function padStr(i) {
    return i < 10 ? "0" + i : "" + i;
  }

  console.log(fullDataCaseReport)
  console.log(enterprise)

  const licensePlate =
    fullDataCaseReport.equipment.computersEquipment.licensePlateLaptop ||
    fullDataCaseReport.equipment.computersEquipment.licensePlateAllInOne ||
    fullDataCaseReport.equipment.computersEquipment.licensePlateCPU ||
    fullDataCaseReport.equipment.peripheralsAndAccessoriesEquipment
      .licensePlate ||
    fullDataCaseReport.equipment.networkingAndCommunicationsEquipment
      .licensePlate;
  const translations = {
    Laptop: "PORTATIL",
    Diadems: "DIADEMAS",
    FingerprintReader: "LECTOR DE HUELLA",
    SignaturePad: "PAD DE FIRMAS",
    Source: "FUENTE",
    Webcam: "CAMARA WEB",
    Mouse: "MOUSE",
    Keyboard: "TECLADO",
    Monitor: "MONITOR",
    CPU: "CPU",
    AllInOne: "EQUIPO TODO EN UNO INTEGRADO",
    PowerAdapter: "ADAPTADOR DE CORRIENTE",
    BarcodeReader: "LECTOR DE CODIGO DE BARRAS",
  };

  const brandNames = Object.keys(fullDataCaseReport.systemCaseReport)
    .filter((key) => key.startsWith("brand"))
    .map((key) => key.slice(5));

  const brandTranslation = brandNames.map((name) => translations[name] || name);

  return (
    <Page
      size="A1"
      style={{
        flexDirection: "column",
        backgroundColor: "#fff",
        alignItems: "center",
      }}
    >
      <View
        style={{
          border: "5px solid #000",
          marginTop: "4%",
          width: "95%",
          height: "6%",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "20%",
            alignItems: "center",
            justifyContent: "center",
            borderRight: "3px solid #000",
          }}
        >
          <Image
            style={{ width: "60%", height: "60%" }}
            src={enterprise[0].image}
          ></Image>
        </View>
        <View style={{ width: "75%", borderRight: "3px solid #000" }}>
          <View
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: "5%",
            }}
          >
            <Text
              style={{
                fontSize: "38px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Acta de Mantenimiento de Equipos de Infraestructura distribuida
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "20%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              height: "30%",
              width: "100%",
              borderBottom: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "15px" }}>
              Proceso Asociado: Gestión de Relacionamiento con el cliente
            </Text>
          </View>
          <View
            style={{
              height: "22.33%",
              width: "100%",
              borderBottom: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "15px" }}>Codigo: </Text>
          </View>
          <View
            style={{
              height: "22.33%",
              width: "100%",
              borderBottom: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "15px" }}>Version:</Text>
          </View>
          <View
            style={{
              height: "22.33%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "15px" }}>Aprobado:</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          border: "5px solid #000",
          marginTop: "0.5%",
          width: "95%",
          height: "2%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#E5E5E5",
        }}
      >
        <Text style={{ fontSize: "24px" }}>Información del Servicio</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          border: "5px solid #000",
          width: "95%",
          height: "13%",
          borderTop: "0",
        }}
      >
        <View
          style={{
            height: "12%",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
            N° De caso:
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "18%",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Nombre de la sede
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "35%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {enterprise[0].name}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "50%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Nombre jefe de Dependencia:{" "}
              {fullDataCaseReport.bossDependencyWhenDone &&
                [
                  fullDataCaseReport.bossDependencyWhenDone.person.name,
                  fullDataCaseReport.bossDependencyWhenDone.person.secondName,
                  fullDataCaseReport.bossDependencyWhenDone.person.lastName,
                  fullDataCaseReport.bossDependencyWhenDone.person.secondLastName
                ].filter(Boolean).join(' ')}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "15%",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Direccion
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "35%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Avenida circunvalar
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "50%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Nombre del Equipo: {fullDataCaseReport.equipment.name}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "14%",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>Ciudad</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "35%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Florencia
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "50%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Contraseña del Equipo:{" "}
              {fullDataCaseReport.systemCaseReport.passEquip}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "14%",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>Area</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "35%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.dependencyWhenDone.area.name}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "50%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              IP: {fullDataCaseReport.systemCaseReport.ipAddress}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "14%",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Dependencia
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "35%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.dependencyWhenDone.name}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "50%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Numero de Anydesk:{" "}
              {fullDataCaseReport.systemCaseReport.anydeskNumber} Contraseña
              Anydesk: {fullDataCaseReport.systemCaseReport.anydeskPass}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "14%",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Telefono
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "35%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>4345510</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "50%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Tipo de Mantenimiento:{" "}
              {fullDataCaseReport.systemCaseReport.service}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "14%",
            width: "100%",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Tipo de Equipo
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "35%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.equipment.name + " "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "50%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Numero de Reporte: {fullDataCaseReport.systemCaseReport.number}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          border: "5px solid #000",
          width: "95%",
          height: "2%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#E5E5E5",
        }}
      >
        <Text style={{ fontSize: "24px" }}>Descripción del Servicio</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          border: "5px solid #000",
          width: "95%",
          minHeigth: "150px",
          borderTop: "0",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "60px",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "18%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Fecha de Inicio
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : String(new Date(fullDataCaseReport.date).getDate()).padStart(
                  2,
                  "0"
                )}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : String(
                  new Date(fullDataCaseReport.date).getMonth() + 1
                ).padStart(2, "0")}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : new Date(fullDataCaseReport.date).getFullYear()}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>Hora</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "8%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : String(new Date(fullDataCaseReport.date).getHours()).padStart(
                  2,
                  "0"
                ) +
                ":" +
                String(
                  new Date(fullDataCaseReport.date).getMinutes()
                ).padStart(2, "0")}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "18%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Fecha de Finalización
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : String(
                  new Date(fullDataCaseReport.endDate).getDate()
                ).padStart(2, "0")}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : String(
                  new Date(fullDataCaseReport.endDate).getMonth() + 1
                ).padStart(2, "0")}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : new Date(fullDataCaseReport.endDate).getFullYear()}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "6%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>Hora</Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "8%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.date === ""
                ? " "
                : String(
                  new Date(fullDataCaseReport.endDate).getHours()
                ).padStart(2, "0") +
                ":" +
                String(
                  new Date(fullDataCaseReport.endDate).getMinutes()
                ).padStart(2, "0")}
            </Text>
          </View>
        </View>
        <View
          style={{
            minHeight: "50px",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
            Falla reportada: {fullDataCaseReport.systemCaseReport.failReported}
          </Text>
        </View>
        <View
          style={{
            minHeight: "50px",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
            Estado fisico: {fullDataCaseReport.systemCaseReport.physicStatus}
          </Text>
        </View>
        <View
          style={{
            minHeight: "50px",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
            Diagnóstico: {fullDataCaseReport.systemCaseReport.diagnostic}
          </Text>
        </View>
        <View
          style={{
            minHheight: "50px",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <Text style={{ fontSize: "30px", marginLeft: "3px" }}>
            Verifica el estado de las partes del equipo:
          </Text>
        </View>
        <View
          style={{
            borderBottom: "5px solid #000",
            width: "100%",
            height: "2.3%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#595959",
          }}
        >
          <Text style={{ fontSize: "20px", color: "white" }}>HARDWARE</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "50px",
            width: "100%",
            borderBottom: "3px solid #000",
            backgroundColor: "#595959",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Tipo
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Marca
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Modelo (Capacidad)
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Serial
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "8%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Placa
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Foto
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Foto serial
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Estado
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Novedad
            </Text>
          </View>
        </View>

        {brandTranslation.map((d, i) => {
          const brand =
            fullDataCaseReport.systemCaseReport[`brand${brandNames[i]}`];
          if (!brand) {
            return null;
          }
          const model =
            fullDataCaseReport.systemCaseReport[`modell${brandNames[i]}`];
          let serie =
            fullDataCaseReport.systemCaseReport[`serie${brandNames[i]}`];
          const licensePlate =
            fullDataCaseReport.systemCaseReport[`licensePlate${brandNames[i]}`];
          const image =
            fullDataCaseReport.systemCaseReport[`image${brandNames[i]}`];
          const imageSerial =
            fullDataCaseReport.systemCaseReport[`image${brandNames[i]}Serial`];
          const status =
            fullDataCaseReport.systemCaseReport[`status${brandNames[i]}`];
          const novelty =
            fullDataCaseReport.systemCaseReport[`novelty${brandNames[i]}`];
          if (serie) {
            var result = "";
            var str = serie;
            while (str.length > 7) {
              var pos = str.substring(0, 7).lastIndexOf(" ");
              pos = pos <= 0 ? 7 : pos;
              result += str.substring(0, pos) + "\n";
              str = str.substring(pos);
            }
            serie = result + str;
          }
          return (
            <View
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "60px",
                width: "100%",
                borderBottom: "3px solid #000",
              }}
            >
              <View
                style={{
                  height: "100%",
                  width: "15%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color: brand === "No funcional" ? "red" : "black",
                  }}
                >
                  {d}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color: brand === "No funcional" ? "red" : "black",
                  }}
                >
                  {brand}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color: brand === "No funcional" ? "red" : "black",
                  }}
                >
                  {model}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color: brand === "No funcional" ? "red" : "black",
                  }}
                >
                  {serie}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "8%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color: brand === "No funcional" ? "red" : "black",
                  }}
                >
                  {licensePlate}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image src={image ? image : blanco}></Image>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image src={imageSerial ? imageSerial : blanco}></Image>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color: brand === "No funcional" ? "red" : "black",
                  }}
                >
                  {status}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color: brand === "No funcional" ? "red" : "black",
                  }}
                >
                  {novelty}
                </Text>
              </View>
            </View>
          );
        })}
        {fullDataCaseReport.systemCaseReport.processors &&
          fullDataCaseReport.systemCaseReport.processors.map((d, i) => (
            <View
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "60px",
                width: "100%",
                borderBottom: "3px solid #000",
              }}
            >
              <View
                style={{
                  height: "100%",
                  width: "15%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color:
                      d !== fullDataCaseReport.systemCaseReport.newProcessors[i]
                        ? "red"
                        : "black",
                  }}
                >
                  {"PROCESADOR"}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textOverflow: "ellipsis",
                    fontSize: "20px",
                    marginLeft: "3px",
                    color:
                      d !== fullDataCaseReport.systemCaseReport.newProcessors[i]
                        ? "red"
                        : "black",
                  }}
                >
                  {d}
                </Text>
              </View>
            </View>
          ))}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "50px",
            width: "100%",
            borderBottom: "3px solid #000",
            backgroundColor: "#595959",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "15%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Tipo
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Marca
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Tipo
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Serie
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "8%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              Capacidad
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              {" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              {" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              {" "}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "11%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontSize: "25px", marginLeft: "3px", color: "white" }}
            >
              {" "}
            </Text>
          </View>
        </View>
        {fullDataCaseReport &&
          fullDataCaseReport.equipment &&
          fullDataCaseReport.equipment.systemCaseReport &&
          fullDataCaseReport.equipment.systemCaseReport.diskComputer &&
          Array.isArray(fullDataCaseReport.equipment.systemCaseReport.diskComputer) &&
          fullDataCaseReport.equipment.systemCaseReport.diskComputer.length > 0 ?
          fullDataCaseReport.equipment.systemCaseReport.diskComputer.map(
            (d, i) => (
              <View
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50px",
                  width: "100%",
                  borderBottom: "3px solid #000",
                }}
              >
                <View
                  style={{
                    height: "100%",
                    width: "15%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {"DISCO DURO"}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.brand}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.type}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.serie}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "8%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.capacity}
                  </Text>
                </View>
              </View>
            ))
          :
          fullDataCaseReport &&
          fullDataCaseReport.equipment &&
          fullDataCaseReport.equipment.computersEquipment &&
          fullDataCaseReport.equipment.computersEquipment.diskComputer &&
          Array.isArray(fullDataCaseReport.equipment.computersEquipment.diskComputer) &&
          fullDataCaseReport.equipment.computersEquipment.diskComputer.length > 0 &&
          fullDataCaseReport.equipment.computersEquipment.diskComputer.map(
            (d, i) => (
              <View
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50px",
                  width: "100%",
                  borderBottom: "3px solid #000",
                }}
              >
                <View
                  style={{
                    height: "100%",
                    width: "15%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {"DISCO DURO"}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.brand}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.type}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.serie}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "8%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.capacity}
                  </Text>
                </View>
              </View>
            )
          )}
        {fullDataCaseReport &&
          fullDataCaseReport.equipment &&
          fullDataCaseReport.equipment.systemCaseReport &&
          fullDataCaseReport.equipment.systemCaseReport.ramComputer &&
          Array.isArray(fullDataCaseReport.equipment.systemCaseReport.ramComputer) &&
          fullDataCaseReport.equipment.systemCaseReport.ramComputer.length > 0 ?
          fullDataCaseReport.equipment.systemCaseReport.ramComputer.map(
            (d, i) => (
              <View
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50px",
                  width: "100%",
                  borderBottom: "3px solid #000",
                }}
              >
                <View
                  style={{
                    height: "100%",
                    width: "15%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {"RAM"}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.brand}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.type}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.serie}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "8%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "red",
                    }}
                  >
                    {d.capacity}
                  </Text>
                </View>
              </View>
            ))
          : fullDataCaseReport &&
          fullDataCaseReport.equipment &&
          fullDataCaseReport.equipment.computersEquipment &&
          fullDataCaseReport.equipment.computersEquipment.ramComputer &&
          Array.isArray(fullDataCaseReport.equipment.computersEquipment.ramComputer) &&
          fullDataCaseReport.equipment.computersEquipment.ramComputer.length > 0 &&
          fullDataCaseReport.equipment.computersEquipment.ramComputer.map(
            (d, i) => (
              <View
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50px",
                  width: "100%",
                  borderBottom: "3px solid #000",
                }}
              >
                <View
                  style={{
                    height: "100%",
                    width: "15%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {"RAM"}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.brand}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.type}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.serie}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "8%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", marginLeft: "3px" }}>
                    {d.capacity}
                  </Text>
                </View>
              </View>
            )
          )}
        {fullDataCaseReport.equipment.computersEquipment.processors !== null &&
          fullDataCaseReport.equipment.computersEquipment.processors !== "" &&
          fullDataCaseReport.equipment.computersEquipment.processors.length >
          0 &&
          fullDataCaseReport.equipment.computersEquipment.processors.map(
            (item, index) => (
              <View
                key={index}
                style={{
                  width: "100%",
                  height: "4.3%",
                  flexDirection: "row",
                  borderBottom: "2px solid #000",
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "2px solid #000",
                    heigth: "100%",
                    width: "15.1%",
                  }}
                >
                  <Text
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "bolder",
                    }}
                  >
                    PROCESADOR
                  </Text>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "2px solid #000",
                    heigth: "100%",
                    width: "11%",
                  }}
                >
                  <Text
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "bolder",
                    }}
                  >
                    {item + " "}
                  </Text>
                </View>
              </View>
            )
          )}
        <View
          style={{
            border: "5px solid #000",
            width: "100%",
            height: "2.3%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#595959",
          }}
        >
          <Text style={{ fontSize: "20px", color: "white" }}>
            PARTES INSTALADAS
          </Text>
        </View>
        {fullDataCaseReport.systemCaseReport.computerReportComponent &&
          fullDataCaseReport.systemCaseReport.computerReportComponent.length >
          0 &&
          fullDataCaseReport.systemCaseReport.computerReportComponent.map(
            (d, i) => {
              return (
                <View
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "50px",
                    width: "100%",
                    borderBottom: "3px solid #000",
                  }}
                >
                  <View
                    style={{
                      height: "100%",
                      width: "15%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.type}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "11%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.brand}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "11%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.modell}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "11%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.serie}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "8%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.licensePlate}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "11%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image src={d.image ? d.image : blanco}></Image>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "11%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image src={d.imageSerial ? d.imageSerial : blanco}></Image>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "11%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.status}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "11%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {" "}
                    </Text>
                  </View>
                </View>
              );
            }
          )}
        {fullDataCaseReport.systemCaseReport.newProcessors &&
          fullDataCaseReport.systemCaseReport.newProcessors.map((d, i) => (
            <View
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "50px",
                width: "100%",
                borderBottom: "3px solid #000",
              }}
            >
              <View
                style={{
                  height: "100%",
                  width: "15%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "#628F60",
                  }}
                >
                  {"PROCESADOR"}
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "11%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "#628F60",
                  }}
                >
                  {d}
                </Text>
              </View>
            </View>
          ))}
        {fullDataCaseReport.equipment.computersEquipment.diskComputer &&
          fullDataCaseReport.systemCaseReport.diskComputer.length > 0 &&
          fullDataCaseReport.equipment.computersEquipment.diskComputer.map(
            (d, i) => (
              <View
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50px",
                  width: "100%",
                  borderBottom: "3px solid #000",
                }}
              >
                <View
                  style={{
                    height: "100%",
                    width: "15%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {"DISCO DURO"}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "8%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.capacity}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.brand}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.serie}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.type}
                  </Text>
                </View>
              </View>
            )
          )}
        {fullDataCaseReport.equipment.computersEquipment.ramComputer &&
          fullDataCaseReport.systemCaseReport.ramComputer.length > 0 &&
          fullDataCaseReport.equipment.computersEquipment.ramComputer.map(
            (d, i) => (
              <View
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50px",
                  width: "100%",
                  borderBottom: "3px solid #000",
                }}
              >
                <View
                  style={{
                    height: "100%",
                    width: "15%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {"RAM"}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "8%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.capacity}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.brand}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.serie}
                  </Text>
                </View>
                <View
                  style={{
                    height: "100%",
                    width: "11%",
                    borderRight: "3px solid #000",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "25px",
                      marginLeft: "3px",
                      color: "#628F60",
                    }}
                  >
                    {d.type}
                  </Text>
                </View>
              </View>
            )
          )}
        <View
          style={{
            border: "5px solid #000",
            width: "100%",
            height: "2.3%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#595959",
          }}
        >
          <Text style={{ fontSize: "20px", color: "white" }}>
            SOFTWARE INSTALADO
          </Text>
        </View>
        {fullDataCaseReport.systemCaseReport.computerReportSoftware &&
          fullDataCaseReport.systemCaseReport.computerReportSoftware.length >
          0 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                height: "50px",
                width: "100%",
                borderBottom: "3px solid #000",
                backgroundColor: "#595959",
              }}
            >
              <View
                style={{
                  height: "100%",
                  width: "14%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "white",
                  }}
                >
                  Tipo
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "15%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "white",
                  }}
                >
                  Nombre
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "18%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "white",
                  }}
                >
                  Versión
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "25%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "white",
                  }}
                >
                  Serial
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "13%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "white",
                  }}
                >
                  Vigencia
                </Text>
              </View>
              <View
                style={{
                  height: "100%",
                  width: "15%",
                  borderRight: "3px solid #000",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    marginLeft: "3px",
                    color: "white",
                  }}
                >
                  Tipo de licencia
                </Text>
              </View>
            </View>
          )}
        {fullDataCaseReport.systemCaseReport.computerReportSoftware &&
          fullDataCaseReport.systemCaseReport.computerReportSoftware.length >
          0 &&
          fullDataCaseReport.systemCaseReport.computerReportSoftware.map(
            (d, i) => {
              return (
                <View
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "50px",
                    width: "100%",
                    borderBottom: "3px solid #000",
                  }}
                >
                  <View
                    style={{
                      height: "100%",
                      width: "14%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.type}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "15%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.name.option}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "18%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.version}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "25%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.serial}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "13%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.validity
                        ? padStr(new Date(d.validity).getDate()) +
                        "/" +
                        padStr(1 + new Date(d.validity).getMonth()) +
                        "/" +
                        padStr(new Date(d.validity).getFullYear())
                        : ""}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: "15%",
                      borderRight: "3px solid #000",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "25px",
                        marginLeft: "3px",
                        color: "#628F60",
                      }}
                    >
                      {d.licenseType ? d.LicenseType.split("Licencia de software ")[1] : "No aplica"}
                    </Text>
                  </View>
                </View>
              );
            }
          )}
        <View
          style={{
            borderBottom: "3px solid #000",
            width: "100%",
            minHeight: "80px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingBottom: "0",
          }}
        >
          <Text
            style={{ fontSize: "28px", marginTop: "1%", marginLeft: "0.4%" }}
          >
            ¿Se evidencia manipulación?{" "}
            {fullDataCaseReport.systemCaseReport.isTampered === "Si"
              ? "Si _X_  No ___"
              : "Si ___  No _X_ "}{" "}
            ¿Por qué? {fullDataCaseReport.systemCaseReport.whyIsTampered}
          </Text>
        </View>
        <View
          style={{
            borderBottom: "3px solid #000",
            width: "100%",
            minHeight: "80px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingBottom: "0",
          }}
        >
          <Text
            style={{ fontSize: "28px", marginTop: "1%", marginLeft: "0.4%" }}
          >
            Pruebas realizadas:{" "}
            {fullDataCaseReport.systemCaseReport.testsPerformed}
          </Text>
        </View>
        <View
          style={{
            borderBottom: "3px solid #000",
            width: "100%",
            minHeight: "80px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingBottom: "0",
          }}
        >
          <Text
            style={{ fontSize: "28px", marginTop: "1%", marginLeft: "0.4%" }}
          >
            Solución entregada: NOTA: Antes de cualquier cambio en las
            configuraciones o reset de maquina contactar al coordinador del área
            de sistemas
          </Text>
        </View>
        <View
          style={{
            borderBottom: "3px solid #000",
            width: "100%",
            minHeight: "80px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingBottom: "0",
          }}
        >
          <Text
            style={{ fontSize: "28px", marginTop: "1%", marginLeft: "0.4%" }}
          >
            {fullDataCaseReport.systemCaseReport.solutionDelivered}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "50px",
            width: "100%",
            borderBottom: "3px solid #000",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "13%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Servicio
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "13%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Correctivo
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "13%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.systemCaseReport.service === "Correctivo"
                ? "X"
                : ""}
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "13%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              Preventivo
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "13%",
              borderRight: "3px solid #000",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: "25px", marginLeft: "3px" }}>
              {fullDataCaseReport.systemCaseReport.service === "Preventivo"
                ? "X"
                : ""}
            </Text>
          </View>
        </View>
        <View
          style={{
            borderBottom: "3px solid #000",
            width: "100%",
            minHeight: "60px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingBottom: "0",
          }}
        >
          <Text
            style={{ fontSize: "28px", marginTop: "1%", marginLeft: "0.4%" }}
          >
            Observacion del cliente
          </Text>
        </View>
        <View
          style={{
            width: "100%",
            minHeight: "50px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingBottom: "0",
          }}
        >
          <Text
            style={{ fontSize: "28px", marginTop: "1%", marginLeft: "0.4%" }}
          >
            {fullDataCaseReport.systemCaseReport.clientObservation}
          </Text>
        </View>
      </View>
      <View
        style={{
          marginTop: "3%",
          marginBottom: "-5%",
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Image
          style={{ width: "30%", height: "150%" }}
          src={
            fullDataCaseReport.userCreator.person.firm
              ? fullDataCaseReport.userCreator.person.firm
              : blanco
          }
        ></Image>
      </View>
      <View
        style={{
          marginTop: "3%",
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={{ width: "30%", borderBottom: "3px solid #00" }}></View>
      </View>
      <View
        style={{
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: "-3%",
        }}
      >
        <Text style={{ fontSize: "20px", color: "black" }}>
          {fullDataCaseReport.userCreator &&
            fullDataCaseReport.userCreator.profesion}
        </Text>
      </View>
      <View
        style={{
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: "0%",
        }}
      >
        <Text style={{ fontSize: "18px" }}>
          {fullDataCaseReport.userCreator.person &&
            fullDataCaseReport.userCreator.person.firm &&
            [
              fullDataCaseReport.userCreator.person.name,
              fullDataCaseReport.userCreator.person.secondName,
              fullDataCaseReport.userCreator.person.lastName,
              fullDataCaseReport.userCreator.person.secondLastName
            ].filter(Boolean).join(' ')}
        </Text>
      </View>

      <View
        style={{
          width: "95%",
          marginTop: "-3%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Text style={{ fontSize: "18px" }}>AGENTE QUE REALIZA EL REPORTE</Text>
      </View>
    </Page>
  );
};
