import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from '../../common/api'
import { DepMunActions } from './DepMunSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(DepMunActions.setMunicipalitys(muns.payload))
  }
  yield put(DepMunActions.setLoading(false))
} */

function* getDepartments() {
    const DepMun = yield Api.get(`/department/all`)
    if (DepMun.ok) {
        yield put(DepMunActions.setDepartments(DepMun.payload))
    }
}

function* getMunicipalitys({ payload }) {
    const ep = yield Api.get(`/municipality/byDepartment?id=${payload}`)
    if (ep.ok) {
        yield put(DepMunActions.setMunicipalitys(ep.payload))
    }
}

function* getCountries({ payload }) {
    const c = yield Api.get(`/country/all`)
    if (c.ok) {
        yield put(DepMunActions.setCountries(c.payload))
    } else {
        message.error("Error al obtener paises")
    }
}

function* getDepartmentsFromCountry({ payload }) {
    const d = yield Api.get(`/department/country/${payload}`)
    if (d.ok) {
        yield put(DepMunActions.setDepartments(d.payload))
    } else {
        message.error("Error al obtener departamentos")
    }
}

function* actionWatcher() {
    yield takeLatest(DepMunActions.getCountries, getCountries)
    yield takeLatest(DepMunActions.getDepartments, getDepartments)
    yield takeLatest(DepMunActions.getDepartmentsFromCountry, getDepartmentsFromCountry)
    yield takeLatest(DepMunActions.getMunicipalitys, getMunicipalitys)
}

export default function* DepMunSaga() {
    yield all([actionWatcher()])
}