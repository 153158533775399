import ModuleWorkCardSystem from '../../assets/images/ModuleWorkCardSystem.png'
import ModuleWorkCardBiomedic from '../../assets/images/ModuleWorkCardBiomedic.png'
import blanco from '../../assets/images/blanco.png'
import { useDispatch } from 'react-redux';
import { AreaActions } from '../../services/Area/AreaSlice';

export const ModuleWorkCard = ({ data, onClick, isActive }) => {
    const { name } = data.moduleWork;
    const dispatch = useDispatch()

    const moduleImages = {
        "Biomedico": ModuleWorkCardBiomedic,
        "Sistemas": ModuleWorkCardSystem
    };
    const moduleDescription = {
        "Biomedico": "Pidele al equipo de biomedicos y diagnostico de equipos que te ayude con la revisión ",
        "Sistemas": "Pidele al equipo de sistemas y diagnostico de equipos de que te ayude con la revisión computo e impresoras."
    }

    const imageSrc = moduleImages[name] || blanco;
    const description = moduleDescription[name] || "Descripción del modulo";

    return (
        <div
            className={"ModuleWorkCard" + (isActive ? " isActiveShadowModuleWorkCard" : "")}
            onClick={() => dispatch(AreaActions.setModuleWork(data.moduleWork))}
        >
            <img
                alt="imagenLogo"
                src={imageSrc}
                className="imgModule"
            />
            <h2>{name.toUpperCase()}</h2>
            <p>{description}</p>
        </div>
    );
};