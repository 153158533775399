import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"

import { LoginActions } from './LoginSlices'
import { message } from 'antd'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(LoginActions.setMunicipalitys(muns.payload))
  }
  yield put(LoginActions.setLoading(false))
} */

function* login({ payload }) {
  const Login = yield Api.post(`/login`, payload)
  if (Login.ok) {
    yield localStorage.setItem('token', Login.payload.access_token)
  } else {
    message.error(Login.payload.message)
  }
  yield put(LoginActions.setLoading(false))
}

function* verifyLogin() {
  const L = yield Api.post('/login/verify')
  if (!L.ok) {
    message.warn("Sesión finalizada")
    localStorage.removeItem('token')
  }
  if (L.ok) {
    yield put(LoginActions.setId(L.payload.userId))
    yield put(LoginActions.setRoleUser(L.payload.role))
    yield put(LoginActions.setModuleWorks(L.payload.moduleWorks))
    yield put(LoginActions.setEnterprise(L.payload.enterprise))
    yield put(LoginActions.setPhoto(L.payload.photo))
    yield put(LoginActions.setFullUser(L.payload))
    const dependencys = yield Api.get(`/dependency/getDependencyByAgent?id=${L.payload.userId}`)
    if (dependencys.ok) {
      yield put(LoginActions.setDependencys(dependencys.payload))
    } else {
      message.error("Error al obtener dependencias")
    }
  }
}

function* getRoles({ payload }) {
  const r = yield Api.get("/role/all")
  if (r.ok) {
    yield put(LoginActions.setRoles(r.payload))
  } else {
    message.error("Error al obtener roles")
  }
}

function* getPersonInfo({ payload }) {
  const p = yield Api.get(`/user/getPersonInfoById?id=${payload}`)
  if (p.ok) {
    yield put(LoginActions.setPersonInfo(p.payload))
  } else {
    message.error("Error al obtener información de la persona")
  }
}

function* getCoordinator({ payload }) {
  const c = yield Api.get(`/person/coordinatorByArea?area=${payload}`)
  if (c.ok) {
    yield put(LoginActions.setCoordinator(c.payload))
  } else {
    message.error("Error al obtener información del coordinador")
  }
}

function* getBoss({ payload }) {
  const c = yield Api.get(`/person/bossByDependency?dependency=${payload}`)
  if (c.ok) {
    yield put(LoginActions.setBoss(c.payload))
  } else {
    message.error("Error al obtener información del jefe")
  }
}

function* getAllCoordinatorsAndBosses() {
  const c = yield Api.get(`/user/getAllCoordinatorsAndBosses`)
  if (c.ok) {
    yield put(LoginActions.setAllCoordinatorsAndBosses(c.payload))
  } else {
    message.error("Error al obtener información de los coordinadores y jefes")
  }
}

function* getUserAndDependencies({ payload }) {
  const u = yield Api.get(`/user/getUserAndDependencies?id=${payload}`)
  if (u.ok) {
    yield put(LoginActions.setUserAndDependencies(u.payload))
  } else {
    message.error("Error al obtener información del usuario y sus dependencias")
  }
}

function* actionWatcher() {
  yield takeLatest(LoginActions.login, login)
  yield takeLatest(LoginActions.verifyLogin, verifyLogin)
  yield takeLatest(LoginActions.getRoles, getRoles)
  yield takeLatest(LoginActions.getPersonInfo, getPersonInfo)
  yield takeLatest(LoginActions.getCoordinator, getCoordinator)
  yield takeLatest(LoginActions.getBoss, getBoss)
  yield takeLatest(LoginActions.getAllCoordinatorsAndBosses, getAllCoordinatorsAndBosses)
  yield takeLatest(LoginActions.getUserAndDependencies, getUserAndDependencies)
}

export default function* LoginSaga() {
  yield all([actionWatcher()])
}