import { Steps, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { useForm, Controller } from "react-hook-form"
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import SaveIcon from '../../assets/images/save.svg'
import { EquipmentVehicleActions } from '../../services/EquipmentVehicle/EquipmentVehicleSlice';
import { useStepsVehicle } from './StepsVehicle';
import { useStepsMoto } from './StepsMoto';

export const FormEquipmentVehicle = ({ edit, data, setOption }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { loadingUpdateEquipment, loadingCreateEquipment } = useSelector(state => state.Equipment)
    const { EquipmentVehicleCreatedId } = useSelector(state => state.EquipmentVehicle)
    const [equipType, setEquipType] = useState('')
    const [dependency, setDependency] = useState(false)
    const [location, setLocation] = useState(false)

    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        if (EquipmentVehicleCreatedId) {
            setOption('Search')
            dispatch(EquipmentVehicleActions.setEquipmentVehicleCreatedId(false))
        }
    }, [EquipmentVehicleCreatedId])

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const {
        register: registerVehicle,
        handleSubmit: handleSubmitVehicle,
        setValue: setValueVehicle,
        watch: watchVehicle,
        control: controlVehicle,
        formState: { errors: errorsVehicle },
        trigger: triggerVehicle,
    } = useForm({
        defaultValues: {
            frontalImage: edit ? data.frontalImage : photo,
            backImage: edit ? data.backImage : photo,
            leftImage: edit ? data.leftImage : photo,
            rightImage: edit ? data.rightImage : photo,
            aboveImage: edit ? data.aboveImage : photo,
            licensePlate: edit ? data.licensePlate : "",
            class: edit ? data.class : "",
            color: edit ? data.color : "",
            fuel: edit ? data.fuel : "",
            vehiclePlate: edit ? data.vehiclePlate : "",
            cylinders: edit ? data.cylinders : "",
            engineNumber: edit ? data.engineNumber : "",
            numberSerie: edit ? data.numberSerie : "",
            chassisNumber: edit ? data.chassisNumber : "",
            brand: edit ? data.brand : "",
            line: edit ? data.line : "",
            modell: edit ? data.modell : "",
            type: edit ? data.type : "",
            transitLicense: false,
            transitLicenseBuyDate: "",
            transitLicenseRegistrationDate: "",
            transitLicenseImage: photo,
            numberLicense: "",
            SOAT: false,
            SOATBuyDate: "",
            SOATExpirationDate: "",
            SOATValidity: "",
            SOATImage: photo,
            policy: false,
            policyBuyDate: "",
            policyExpirationDate: "",
            policyValidity: "",
            policyImage: photo,
            mechaTechReview: false,
            mechaTechReviewBuyDate: "",
            mechaTechReviewExpirationDate: "",
            mechaTechReviewValidity: "",
            mechaTechReviewImage: photo,
            tireFrontalRightBrand: "",
            tireFrontalRightReference: "",
            tireFrontalLeftBrand: "",
            tireFrontalLeftReference: "",
            tireBackRightBrand: "",
            tireBackRightReference: "",
            tireBackLeftBrand: "",
            tireBackLeftReference: "",
            tireSpareBrand: "",
            tireSpareReference: "",
            observations: edit ? data.observations : undefined,
        }
    })

    const {
        register: registerMoto,
        handleSubmit: handleSubmitMoto,
        setValue: setValueMoto,
        watch: watchMoto,
        control: controlMoto,
        formState: { errors: errorsMoto },
        trigger: triggerMoto,
    } = useForm({
        defaultValues: {
            frontalImage: edit ? data.frontalImage : photo,
            backImage: edit ? data.backImage : photo,
            leftImage: edit ? data.leftImage : photo,
            rightImage: edit ? data.rightImage : photo,
            aboveImage: edit ? data.aboveImage : photo,
            licensePlate: edit ? data.licensePlate : "",
        }
    })

    const StepsVehicle = useStepsVehicle(
        registerVehicle,
        handleSubmitVehicle,
        setValueVehicle,
        watchVehicle,
        controlVehicle,
        errorsVehicle,
        triggerVehicle,
    );

    const StepsMoto = useStepsMoto(
        registerMoto,
        handleSubmitMoto,
        setValueMoto,
        watchMoto,
        controlMoto,
        errorsMoto,
        triggerMoto,
    );

    const [areaSelected, setAreaSelected] = useState(false)
    const [Dep, setDep] = useState(false);


    const nextVehicle = async () => {
        const fieldsToValidate = {
            0: ['dependency', 'location', 'frontalImage', 'backImage', 'equipType', 'licensePlate',
                'class', 'color', 'fuel', 'vehiclePlate', 'cylinders', 'engineNumber',
                'numberSerie', 'chassisNumber', 'brand', 'line',
                'modell', 'type', 'tireFrontalRightBrand', 'tireFrontalRightReference',
                'tireFrontalLeftBrand', 'tireFrontalLeftReference', 'tireBackRightBrand', 'tireBackRightReference',
                'tireBackLeftBrand', 'tireBackLeftReference', 'tireSpareBrand', 'tireSpareReference',
            ],
            1: ['rightImage', 'leftImage', 'aboveImage',
            ],
            2: ['frontalImageFace', 'backImageFace', 'rightImageFace', 'leftImageFace', 'aboveImageFace', 'observation'],
        };

        const isValid = await triggerVehicle(fieldsToValidate[current]);

        if (isValid) {
            setCurrent(current + 1)
            window.scrollTo(0, 0)
        } else {
            message.warn("Falta")
        }
    };

    const nextMoto = async () => {
        const fieldsToValidate = {
            0: ['dependency', 'location', 'frontalImage', 'backImage', 'equipType', 'licensePlate',
                'class', 'color', 'fuel', 'vehiclePlate', 'cylinders', 'engineNumber',
                'numberSerie', 'chassisNumber', 'brand', 'line',
                'modell', 'type', 'tireFrontalRightBrand', 'tireFrontalRightReference',
                'tireFrontalBrand', 'tireFrontalReference', 'tireBackBrand', 'tireBackReference',
            ],
            1: ['rightImage', 'leftImage', 'aboveImage',
            ],
            2: ['frontalImageFace', 'backImageFace', 'rightImageFace', 'leftImageFace', 'aboveImageFace', 'observation'],
        };

        const isValid = await triggerMoto(fieldsToValidate[current]);

        if (isValid) {
            setCurrent(current + 1)
            window.scrollTo(0, 0)
        } else {
            message.warn("Falta")
        }
    };

    const prev = () => {
        setCurrent(current - 1);
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (dependency) {
            dispatch(LoginActions.getBoss(dependency))
        }
    }, [dependency])

    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    const Stepss = {
        "Auto": StepsVehicle,
        "Motocicleta": StepsMoto,
    }

    const Next = {
        "Auto": nextVehicle,
        "Motocicleta": nextMoto,
    }

    return (
        <div className='FormEquipmentVehicle'>
            <p className='titleSection'>Hoja de Vida</p>
            {!edit && <div className='initialSelects'>
                <Select
                    className="select"
                    placeholder='Area'
                    onChange={e => {
                        setAreaSelected(e)
                        setDependency(false)
                    }}
                >
                    {dependencys && areas.map(d => (
                        <Option key={d.id} value={d.id} >{d.name}</Option>
                    ))}
                </Select>
                {areaSelected &&
                    <Select
                        placeholder='Dependencia'
                        value={dependency}
                        className="select"
                        onChange={e => setDependency(e)}
                    >
                        {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                            <Option key={d.id} value={d.id}>{d.name}</Option>
                        ))}
                    </Select>
                }
                {
                    boss &&
                    <Select
                        value="valor"
                        disabled
                        className='select'
                    >
                        <Option value="valor">
                            {boss && boss.name
                                ?
                                boss.name + " " +
                                boss.secondName + " " +
                                boss.lastName + " " +
                                boss.secondLastName
                                :
                                "No hay coordinador"
                            }
                        </Option>
                    </Select>
                }
                <Controller
                    name="headquarter"
                    control={controlVehicle}
                    render={({ field }) => (
                        <Select
                            {...field}
                            placeholder='Sede'
                            value={watchVehicle('headquarter')}
                            className="select"
                        >
                            <Option value={null}>Sede principal</Option>
                            {headquarters && headquarters.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    )}
                />
                <Select
                    placeholder='Ubicación'
                    value={location}
                    className="select"
                    onChange={e => setLocation(e)}
                >
                    {locations && locations.map(d => (
                        <Option key={d.id} value={d.name}>{d.name}</Option>
                    ))}
                </Select>

            </div>}
            <p className='titleSection'>Seleccione vehículo</p>
            {((dependency && location) || edit) &&
                <Select
                    className="select"
                    placeholder='Tipo de Vehiculo'
                    value={equipType}
                    onChange={e => {
                        setEquipType(e)
                    }}
                    disabled={edit}
                    style={{ width: '20vw' }}
                >
                    <Option value={"Auto"}>Auto</Option>
                    <Option value={"Maquinaria"}>Maquinaria</Option>
                    <Option value={"Motocicleta"}>Motocicleta</Option>
                </Select>}
            {errorsVehicle.equipType && <p className='errorMessage'>{errorsVehicle.equipType.message}</p>}
            {Stepss[equipType] &&
                <Steps current={current} >
                    {Stepss[equipType].map(item => (
                        <Step key={item.title} title={item.title} icon={item.icon} />
                    ))}
                </Steps>
            }
            {Stepss[equipType] &&
                <div className="steps-content" >
                    {Stepss[equipType][current].content}
                </div>
            }
            {Stepss[equipType] &&
                <div className="steps-action" >
                    {current > 0 && (
                        <Button style={{ margin: '0 8px', backgroundColor: '#C4C4C4', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => prev()}>
                            Atras
                        </Button>
                    )}
                    {current < Stepss[equipType].length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => Next[equipType]()}>
                            Continuar
                        </Button>
                    )}
                    {current === Stepss[equipType].length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }}
                            onClick={() => {
                                if (equipType === "Auto") {
                                    handleSubmitVehicle((dataSubmit) => {
                                        message.warn("Guardando Equipo")
                                        dataSubmit.userCreator = idUser
                                        dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
                                        dataSubmit.equipType = equipType
                                        dataSubmit.dependency = dependency
                                        dataSubmit.location = location
                                        dispatch(EquipmentVehicleActions.createEquipmentVehicle(dataSubmit));

                                    })()
                                } else if (equipType === "Motocicleta") {
                                    handleSubmitMoto((dataSubmit) => {
                                        message.warn("Guardando Equipo")
                                        dataSubmit.userCreator = idUser
                                        dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
                                        dataSubmit.equipType = equipType
                                        dataSubmit.dependency = dependency
                                        dataSubmit.location = location
                                        dispatch(EquipmentVehicleActions.createEquipmentVehicle(dataSubmit));
                                    })()
                                }
                            }
                            }
                        >
                            <img src={SaveIcon} alt='inventoryIcon' className='icon' style={{ marginRight: '0.5vw' }}></img>
                            {(loadingUpdateEquipment || loadingCreateEquipment) ? <LoadingOutlined /> : "Hecho"}
                        </Button>
                    )}
                </div>
            }
        </div >
    )
}