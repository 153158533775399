import { Document, Image, Page, PDFViewer, Text, View, Font } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfDailyVehicle = ({ fullDataDailyVehicle, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const groups = [
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'internalToilet' ||
            d.description === 'externalToilet' ||
            d.description === 'cans' ||
            d.description === 'paint'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'airCoinditioning' ||
            d.description === 'chairs' ||
            d.description === 'lighter' ||
            d.description === 'interiorOrCeilingLight'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'engineOilLevel' ||
            d.description === 'brakeFluidLevel' ||
            d.description === 'radiatorWaterLevel' ||
            d.description === 'batteryWaterLevel' ||
            d.description === 'hydraulicOilLevel' ||
            d.description === 'ACPMLeaks' ||
            d.description === 'waterLeaks' ||
            d.description === 'transmissionOilLeaks' ||
            d.description === 'boxOilLeaks' ||
            d.description === 'brakeFluidLeaks'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'instrumentos' ||
            d.description === 'tableLight' ||
            d.description === 'fuelLevel' ||
            d.description === 'odometer' ||
            d.description === 'horn' ||
            d.description === 'tachometer' ||
            d.description === 'speedometer' ||
            d.description === 'oilIndicator' ||
            d.description === 'temperatureIndicator'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'seatBelts' ||
            d.description === 'airbags' ||
            d.description === 'chassisAndBody' ||
            d.description === 'crystals' ||
            d.description === 'headrest' ||
            d.description === 'stateMirrors' ||
            d.description === 'rightSideMirror' ||
            d.description === 'leftSideMirror' ||
            d.description === 'rearViewMirror'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'turnSignalStatus' ||
            d.description === 'conditionFrontSuspensionShockAbsorber' ||
            d.description === 'conditionBackSuspensionShockAbsorber' ||
            d.description === 'windshieldStatus' ||
            d.description === 'frontalGlass' ||
            d.description === 'leftWindshieldWiper' ||
            d.description === 'windshieldWasher'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'mediumLights' ||
            d.description === 'highLights' ||
            d.description === 'lowLights' ||
            d.description === 'frontLeftTurnSignal' ||
            d.description === 'frontRightTurnSignal' ||
            d.description === 'backLeftTurnSignal' ||
            d.description === 'backRightTurnSignal' ||
            d.description === 'parkingLights' ||
            d.description === 'brakeLight' ||
            d.description === 'reverseLight' ||
            d.description === 'explorerFogLight'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'tireFrontRight' ||
            d.description === 'tireFrontLeft' ||
            d.description === 'tireBackRight' ||
            d.description === 'tireBackLeft' ||
            d.description === 'tireSpare' ||
            d.description === 'tireAirPressure' ||
            d.description === 'fabricationDateDOT' ||
            d.description === 'wheels'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'brakeStatus' ||
            d.description === 'handBrake' ||
            d.description === 'brakePads'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'electricalInstallations' ||
            d.description === 'clutch' ||
            d.description === 'exhaustPipe' ||
            d.description === 'reverseAudibleAlarm' ||
            d.description === 'shiftJump' ||
            d.description === 'smoothChanges' ||
            d.description === 'throttleGuide' ||
            d.description === 'clutchSystem' ||
            d.description === 'ignition' ||
            d.description === 'synchronization' ||
            d.description === 'plates'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'oneJackWithCapacityToRaiseVehicle' ||
            d.description === 'twoBlocksForVehicle' ||
            d.description === 'aPairOfCanvasWorkGloves' ||
            d.description === 'aStarterCable' ||
            d.description === 'aFireExtinguisher' ||
            d.description === 'aToolbox'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'twoBagsOfSerums' ||
            d.description === 'fourSetsOfSurgicalGloves' ||
            d.description === 'aPackageOfCottonBandages' ||
            d.description === 'tenCopitos' ||
            d.description === 'threeTriangularFabricBandages' ||
            d.description === 'twoCompresses' ||
            d.description === 'aFaceMask' ||
            d.description === 'fourPacketsOfGauze' ||
            d.description === 'microporeTape' ||
            d.description === 'aFiveInchTape' ||
            d.description === 'twoMalleableImmobilizersForLower' ||
            d.description === 'twoMalleableImmobilizersForHigher' ||
            d.description === 'tenAdhesiveTape' ||
            d.description === 'tenFaceMasks' ||
            d.description === 'aNotebook' ||
            d.description === 'emergencyPhoneList' ||
            d.description === 'microporeTapePencil' ||
            d.description === 'firstAidManualBooklet'
        ),
        /* fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'EPPForCleaning' ||
            d.description === 'cleaningKitGloves' ||
            d.description === 'containerWithBlackBag' ||
            d.description === 'signaling'
        ),
        fullDataDailyVehicle.vehiclePart.filter(d =>
            d.description === 'handWashing' ||
            d.description === 'interiorCleaning' ||
            d.description === 'disinfectTheVehicle' ||
            d.description === 'handWashingAfter' ||
            d.description === 'noRugs'
        ), */
    ]

    const titleGroupsParts = {
        0: "Estado de presentación",
        1: "Estado Comodidad",
        2: "Niveles y perdidas de líquidos",
        3: "Tablero de Control",
        4: "Seguridad Pasiva",
        5: "Seguridad Activa",
        6: "Estado de Luces",
        7: "Estado Llantas (Labrado 3mm, Presión de Aire, No Reencauchada)",
        8: "Frenos",
        9: "Otros",
        10: "Equipo de Carretera",
        11: "Botiquín",
    }

    const docs = {
        "Licencia": "Licencia de Transito del Vehiculo",
        "SOAT": "Seguro Obligatorio de Accidentes de Transito SOAT",
        "Póliza": "Póliza de REsponsabilidad Civil Extracontractual",
        "Certificado": "Certificado de Revisión Técnico Mecánica y de Gases",
    }

    const statusTranslate = {
        'internalToilet': 'Aseo Interno',
        'externalToilet': 'Aseo Externo',
        'cans': 'Latas',
        'paint': 'Pintura',
        'airCoinditioning': 'Aire Acondicionado',
        'chairs': 'Sillas',
        'lighter': 'Encendedor',
        'interiorOrCeilingLight': 'Luz Interior o de Techo',
        'engineOilLevel': 'Nivel de Aceite de Motor',
        'brakeFluidLevel': 'Nivel de Líquido de Frenos',
        'radiatorWaterLevel': 'Nivel de Agua de Radiador',
        'batteryWaterLevel': 'Nivel de Agua de Batería',
        'hydraulicOilLevel': 'Nivel de Aceite Hidráulico',
        'ACPMLeaks': 'Fugas de ACPM',
        'waterLeaks': 'Fugas de Agua',
        'transmissionOilLeaks': 'Fugas de Aceite de Transmisión',
        'boxOilLeaks': 'Fugas de Aceite de Caja',
        'brakeFluidLeaks': 'Fugas de Líquido de Frenos',
        'instrumentos': 'Instrumentos',
        'tableLight': 'Luz de Tablero',
        'fuelLevel': 'Nivel de Combustible',
        'odometer': 'Odómetro',
        'horn': 'Pito',
        'tachometer': 'Tacómetro',
        'speedometer': 'Velocímetro',
        'oilIndicator': 'Indicador de Aceite',
        'temperatureIndicator': 'Indicador de Temperatura',
        'seatBelts': 'Cinturones de Seguridad',
        'airbags': 'Airbags',
        'chassisAndBody': 'Chasis y Carrocería',
        'crystals': 'Cristales (Vidrios)',
        'headrest': 'Apoya Cabezas',
        'stateMirrors': 'Espejos Estado',
        'rightSideMirror': 'Espejo Lateral Derecho',
        'leftSideMirror': 'Espejo Lateral Izquierdo',
        'rearViewMirror': 'Espejo Retrovisor',
        'turnSignalStatus': 'Estado de la dirección',
        'conditionFrontSuspensionShockAbsorber': 'Estado Suspensión Delantera Amortiguadores',
        'conditionBackSuspensionShockAbsorber': 'Estado Suspensión Trasera Amortiguadores',
        'windshieldStatus': 'Estado Parabrisas',
        'frontalGlass': 'Vidrio Frontal',
        'leftWindshieldWiper': 'Limpiaparabrisas Izquierdo',
        'windshieldWasher': 'Lavaparabrisas',
        'mediumLights': 'Luces Medias',
        'highLights': 'Luces Altas',
        'lowLights': 'Luces Bajas',
        'frontLeftTurnSignal': 'Direccional Izquierda Delantera',
        'frontRightTurnSignal': 'Direccional Derecha Delantera',
        'backLeftTurnSignal': 'Direccional Izquierda Trasera',
        'backRightTurnSignal': 'Direccional Derecha Trasera',
        'parkingLights': 'Luces de Parqueo',
        'brakeLight': 'Luz de Freno',
        'reverseLight': 'Luz de Reversa',
        'explorerFogLight': 'L. Antiniebla Exploradoras',
        'tireFrontRight': 'Llanta Delantera Derecha',
        'tireFrontLeft': 'Llanta Delantera Izquierda',
        'tireBackRight': 'Llanta Trasera Derecha',
        'tireBackLeft': 'Llanta Trasera Izquierda',
        'tireSpare': 'Llanta de Repuesto',
        'tireAirPressure': 'Presión de Aire de Llantas',
        'fabricationDateDOT': 'Fecha de Fabricación DOT',
        'wheels': 'Rines',
        'brakeStatus': 'Estado de Frenos',
        'handBrake': 'Freno de Mano',
        'brakePads': 'Pastillas de Freno',
        'electricalInstallations': 'Instalaciones Eléctricas',
        'clutch': 'Clutch',
        'exhaustPipe': 'Exhosto',
        'reverseAudibleAlarm': 'Alarma Audible de Reversa',
        'shiftJump': 'Salto de Cambios',
        'smoothChanges': 'Cambios Suaves',
        'throttleGuide': 'Guaya del Acelerador',
        'clutchSystem': 'Sistema de embrague',
        'ignition': 'Encendido',
        'synchronization': 'Sincronización',
        'plates': 'Placas',
        'oneJackWithCapacityToRaiseVehicle': '1 gato con capacidad para elevar el vehículo',
        'twoBlocksForVehicle': '2 tacos para bloquear el vehículo',
        'aPairOfCanvasWorkGloves': '1 par de guantes de trabajo en lona',
        'aStarterCable': '1 cable de Iniciar',
        'aFireExtinguisher': '1 extinguidor de fuego (capacidad min. 10lb), Tipo BC preferiblemente CO2',
        'aToolbox': '1 caja de herramientas (alicates, destornilladores de pala y estrella, llave de expansión y fijas)',
        'twoBagsOfSerums': '2 bolsas de suero fisiológico o de solución salina normal de 125ml',
        'fourSetsOfSurgicalGloves': '4 juegos de guantes quirúrgicos',
        'aPackageOfCottonBandages': '1 paq de vendas de algodón',
        'tenCopitos': '10 aplicadores/copitos',
        'threeTriangularFabricBandages': '3 venajes triangulares de tela 1,20 base x 70 altura',
        'twoCompresses': '2 compresas',
        'aFaceMask': '1 mascarilla facial bolsillo para reanimación cerebro cardiovascular',
        'fourPacketsOfGauze': '4 paquetes de gasa x 5 unidades',
        'microporeTape': '1 cinta micropore 2 pulgadas',
        'aFiveInchTape': '1 esparadrapo 5 pulgadas',
        'twoMalleableImmobilizersForLower': '2 inmovilizadores maleables para extremidades inferiores',
        'twoMalleableImmobilizersForHigher': '2 inmovilizadores maleables para extremidades superiores',
        'tenAdhesiveTape': '10 unidades de vendas adhesivas o curitas',
        'tenFaceMasks': '10 tapabocas desechable con bolsa individual',
        'aNotebook': '1 Libreta',
        'emergencyPhoneList': 'listado de teléfonos de emergencia',
        'microporeTapePencil': '1 cinta micropore 1 lapiz',
        'firstAidManualBooklet': 'manual o folleto de primeros auxilios'
    }

    return (
        <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
            <View style={{ border: '3px solid #000', marginTop: '3%', width: '95%', height: '6%', flexDirection: 'row' }}>
                <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center', borderRight: '3px solid #000' }}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={{ width: '75%', borderRight: '3px solid #000' }}>
                    <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>FORMATO</Text></View>
                    <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '30px' }}>INSPECCIÓN DIARIA PREOPERACIONAL VEHÍCULOS</Text></View>
                </View>
                <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                    <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                </View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '2%', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '24px' }}>FECHA:</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataDailyVehicle.date ? padStr(new Date(fullDataDailyVehicle.date).getDate()) + "/" + padStr(1 + new Date(fullDataDailyVehicle.date).getMonth()) + "/" + padStr(new Date(fullDataDailyVehicle.date).getFullYear()) : ''}</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '24px' }}>INGENIERO:</Text></View>
                <View style={{ height: '100%', width: '58%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>{fullDataDailyVehicle.userCreator && (fullDataDailyVehicle.userCreator.person.name + " " + fullDataDailyVehicle.userCreator.person.lastName)}</Text></View>
            </View>

            <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Número de Placa de Inventario: </Text>{fullDataDailyVehicle.vehicleEquipment.equipment.licensePlate}</Text>
                </View>
            </View>

            <View style={{ width: '95%', height: '5%', border: '3px solid #000', borderBottom: 0, marginTop: '0.2%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={{ width: '100%', height: '34%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '21px', fontFamily: 'Arimo-Bold' }}>CLASE</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.class + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>MARCA</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.brand + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>TIPO</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.type + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>MODELO</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.modell + " "}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '33%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>PLACAS</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.vehiclePlate + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>COLOR</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.color + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>COMBUSTIBLE</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.fuel + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>CILINDROS</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.cylinders + " "}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'row', borderBottom: '3px solid #000' }}>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>N° DEL MOTOR</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.engineNumber + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>N° DE CHASIS</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.chassisNumber + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>N° DE SERIE</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.numberSerie + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>LINEA</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.line + " "}</Text>
                    </View>
                </View>
            </View>

            <View style={{ width: '95%', minHeight: '50px', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '13%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>AREA</Text>
                </View>
                <View style={{ width: '21%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.equipment.dependency.area.name + " "}</Text>
                </View>
                <View style={{ width: '11%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>DEPENDENCIA</Text>
                </View>
                <View style={{ width: '23%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px' }}>{fullDataDailyVehicle.vehicleEquipment.equipment.dependency.name + " "}</Text>
                </View>
                <View style={{ width: '12%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>UBICACIÓN</Text>
                </View>
                <View style={{ width: '20%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '25px' }}>{fullDataDailyVehicle.vehicleEquipment.equipment.location.name + " "}</Text>
                </View>
            </View>

            <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DOCUMENTOS DEL VEHICULO</Text>
                </View>
                <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA COMPRA</Text>
                </View>
                <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA DE MATRICULA</Text>
                </View>
                <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>N° DE LICENCIA</Text>
                </View>
            </View>
            {fullDataDailyVehicle.vehicleEquipment.vehiclePaper.filter(f => f.type === "Licencia").map((d, i) => (
                <View key={i} style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', }}>
                    <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{docs[d.type]}</Text>
                    </View>
                    <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.buyDate ? padStr(new Date(d.buyDate).getDate()) + "/" + padStr(1 + new Date(d.buyDate).getMonth()) + "/" + padStr(new Date(d.buyDate).getFullYear()) : ''}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.registrationDate ? padStr(new Date(d.registrationDate).getDate()) + "/" + padStr(1 + new Date(d.registrationDate).getMonth()) + "/" + padStr(new Date(d.registrationDate).getFullYear()) : padStr(new Date(d.expirationDate).getDate()) + "/" + padStr(1 + new Date(d.expirationDate).getMonth()) + "/" + padStr(new Date(d.expirationDate).getFullYear())}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.numberLicense || d.validity}</Text>
                    </View>
                </View>
            ))}

            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DOCUMENTOS DEL VEHICULO</Text>
                </View>
                <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA COMPRA</Text>
                </View>
                <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA VENCIMIENTO</Text>
                </View>
                <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>VIGENCIA</Text>
                </View>
            </View>
            {fullDataDailyVehicle.vehicleEquipment.vehiclePaper.filter(f => f.type !== "Licencia").map((d, i) => (
                <View key={i} style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', }}>
                    <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{docs[d.type]}</Text>
                    </View>
                    <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.buyDate ? padStr(new Date(d.buyDate).getDate()) + "/" + padStr(1 + new Date(d.buyDate).getMonth()) + "/" + padStr(new Date(d.buyDate).getFullYear()) : ''}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.registrationDate ? padStr(new Date(d.registrationDate).getDate()) + "/" + padStr(1 + new Date(d.registrationDate).getMonth()) + "/" + padStr(new Date(d.registrationDate).getFullYear()) : padStr(new Date(d.expirationDate).getDate()) + "/" + padStr(1 + new Date(d.expirationDate).getMonth()) + "/" + padStr(new Date(d.expirationDate).getFullYear())}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.numberLicense || d.validity}</Text>
                    </View>
                </View>
            ))}

            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderBottom: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>ELEMENTOS</Text>
                </View>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA VENCIMIENTO</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderBottom: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>BOTIQUIN</Text>
                </View>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataDailyVehicle.firstAidKitDueDate ? padStr(new Date(fullDataDailyVehicle.firstAidKitDueDate).getDate()) + "/" + padStr(1 + new Date(fullDataDailyVehicle.firstAidKitDueDate).getMonth()) + "/" + padStr(new Date(fullDataDailyVehicle.firstAidKitDueDate).getFullYear()) : ''}</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderBottom: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>EXTINTOR</Text>
                </View>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataDailyVehicle.extinguisherDueDate ? padStr(new Date(fullDataDailyVehicle.extinguisherDueDate).getDate()) + "/" + padStr(1 + new Date(fullDataDailyVehicle.extinguisherDueDate).getMonth()) + "/" + padStr(new Date(fullDataDailyVehicle.extinguisherDueDate).getFullYear()) : ''}</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>BATERIA-GARANTIA</Text>
                </View>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}>{fullDataDailyVehicle.batteryDueDate ? padStr(new Date(fullDataDailyVehicle.batteryDueDate).getDate()) + "/" + padStr(1 + new Date(fullDataDailyVehicle.batteryDueDate).getMonth()) + "/" + padStr(new Date(fullDataDailyVehicle.batteryDueDate).getFullYear()) : ''}</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderBottom: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Kilometraje: </Text>{fullDataDailyVehicle.mileage}</Text>
                </View>
            </View>
            <View style={{ width: '95%', height: '55.5%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', flexWrap: 'wrap' }}>
                {groups.map((d, i) => {
                    return [
                        <View key={i} style={{ width: '33.33%', height: '2.62%', border: '3px solid #000', borderTop: 0, borderRight: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', backgroundColor: 'gray' }}>
                            <View style={{ width: '65%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{titleGroupsParts[i]}</Text>
                            </View>
                            <View style={{ width: '35%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                                <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>B</Text>
                                </View>
                                <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>R</Text>
                                </View>
                                <View style={{ width: '33.4%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>M</Text>
                                </View>
                            </View>
                        </View>,
                        ...d.flatMap((part, partIndex) => (
                            <View key={partIndex + "pi"} style={{ width: '33.33%', height: '2.62%', border: '3px solid #000', borderTop: 0, borderRight: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', }}>
                                <View style={{ width: '65%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{statusTranslate[part.description]}</Text>
                                </View>
                                <View style={{ width: '35%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                                    <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>{part.state === "Good" ? 'X' : ' '}</Text>
                                    </View>
                                    <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>{part.state === "Regular" ? 'X' : ' '}</Text>
                                    </View>
                                    <View style={{ width: '33.4%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>{part.state === "Bad" ? 'X' : ' '}</Text>
                                    </View>
                                </View>
                            </View>))]
                }
                )}
            </View>
            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', }}>
                <View style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Observacion: </Text>{fullDataDailyVehicle.observation}</Text>
                </View>
            </View>

            <View style={{ marginTop: '8%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Image style={{ width: '30%', height: '100%' }} src={fullDataDailyVehicle.userCreator.person.firm ? fullDataDailyVehicle.userCreator.person.firm : blanco}></Image>
            </View>
            <View style={{ marginTop: '3%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-3%' }}>
                <Text
                    style={{ fontSize: '20px', color: 'black' }}>
                    {
                        fullDataDailyVehicle.userCreator && fullDataDailyVehicle.userCreator.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-2.7%' }}>
                <Text
                    style={{ fontSize: '18px' }}>
                    {(fullDataDailyVehicle.userCreator.person && fullDataDailyVehicle.userCreator.person.firm) && fullDataDailyVehicle.userCreator.person.name + " " +
                        fullDataDailyVehicle.userCreator.person.secondName + " " +
                        fullDataDailyVehicle.userCreator.person.lastName + " " +
                        fullDataDailyVehicle.userCreator.person.secondLastName}
                </Text>
            </View>

            <View style={{ width: '95%', marginTop: '-1%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '18px' }}>AGENTE QUE REALIZA EL REPORTE</Text>
            </View>
        </Page >
    )
}