import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    option: 'Search',
    loadingcreateDailyVehicle: false,
    dailyVehicleCreatedId: false,
    dailyVehicles: false,
    loadingGetDailyVehicles: false,
    fullDataDailyVehicle: false,
    loadingFullDataDailyVehicle: false,
};

const dailyVehicleSlice = createSlice({
    name: "dailyVehicle",
    initialState,
    reducers: {
        setOption(state, { payload }) {
            state.option = payload
        },
        setdailyVehicleCreatedId(state, { payload }) {
            state.dailyVehicleCreatedId = payload
        },
        createDailyVehicle(state, { payload }) {
            state.loadingcreateDailyVehicle = true
        },
        setLoadingcreateDailyVehicle(state, { payload }) {
            state.loadingcreateDailyVehicle = payload
        },
        getDailyVehicles(state, { payload }) {
            state.loadingGetDailyVehicles = true
        },
        setDailyVehicles(state, { payload }) {
            state.dailyVehicles = payload
        },
        setLoadingGetDailyVehicles(state, payload) {
            state.loadingGetDailyVehicles = payload
        },
        getFullDataDailyVehicle(state, { payload }) {
            state.loadingFullDataDailyVehicle = true
        },
        setFullDataDailyVehicle(state, { payload }) {
            state.fullDataDailyVehicle = payload
        },
        setLoadingFullDataDailyVehicle(state, { payload }) {
            state.loadingFullDataDailyVehicle = payload
        }
    },
});

const dailyVehicleActions = dailyVehicleSlice.actions;
const dailyVehicleReducer = dailyVehicleSlice.reducer;

export { dailyVehicleActions, dailyVehicleReducer };
