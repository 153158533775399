import { DatePicker, message, Modal, Radio, Select } from "antd"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import backIcon from "../../../assets/images/backIcon.png"
import logoregister from "../../../assets/images/LogoRegister.png"
import photo from "../../../assets/images/photo.png"
import { Input } from "../../../components/Input/Input"
import PhoneInput from "react-phone-number-input"
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from "react-redux"
import { DepMunActions } from "../../../services/DepMun/DepMunSlice"
import Icon, { UserAddOutlined, EditOutlined, DeleteOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons"
import CreatableSelect from 'react-select/creatable';
import { EnterpriseActions } from "../../../services/Enterprise/EnterpriseSlice"
import { Tree, TreeNode } from "react-organizational-chart"
import { LoginActions } from "../../../services/Login/LoginSlices"

export const Register = () => {
    const history = useHistory()
    const canvas = useRef(null)
    const canvas1 = useRef(null)
    const dispatch = useDispatch()
    const [page, setPage] = useState(0)
    const [enterpriseName, setEnterpriseName] = useState("")
    const [errorEnterpriseName, setErrorEnterpriseName] = useState("")
    const [nitrut, setNitrut] = useState("")
    const [errorNitrut, setErrorNitrut] = useState("")
    const [email, setEmail] = useState("")
    const [errorEmail, setErrorEmail] = useState("")
    const [country, setCountry] = useState(false)
    const [errorCountry, setErrorCountry] = useState("")
    const [dep, setDep] = useState(false)
    const [errorDep, setErrorDep] = useState("")
    const [mun, setMun] = useState(false)
    const [errorMun, setErrorMun] = useState("")
    const [image, setImage] = useState(photo)
    const [imgToUplo, setImgToUplo] = useState('')
    const [errorImage, setErrorImage] = useState("")
    const [color, setColor] = useState("#017c5b")
    const [web, setWeb] = useState("")
    const [errorWeb, setErrorWeb] = useState()
    const [phone, setPhone] = useState("")
    const [errorPhone, setErrorPhone] = useState("")
    const [visibleModal, setVisibleModal] = useState(false)
    const [headquarterInfo, setHeadquarterInfo] = useState([])
    const [headquarterName, setHeadquarterName] = useState("")
    const [headquarterNameError, setHeadquarterNameError] = useState("")
    const [headquarterNit, setHeadquarterNit] = useState("")
    const [headquarterNitError, setHeadquarterNitError] = useState("")
    const [headquarterPhone, setHeadquarterPhone] = useState("")
    const [headquarterPhoneError, setHeadquarterPhoneError] = useState("")
    const [headquarterAddress, setHeadquarterAddress] = useState("")
    const [headquarterAddressError, setHeadquarterAddressError] = useState("")
    const [headquarterEmail, setHeadquarterEmail] = useState("")
    const [headquarterEmailError, setHeadquarterEmailError] = useState("")
    const [editingHeadquarter, setEditingHeadquarter] = useState(false)
    const [adminInfo, setAdminInfo] = useState([]);
    const [adminName, setAdminName] = useState("")
    const [adminNameError, setAdminNameError] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [adminEmailError, setAdminEmailError] = useState("")
    const [adminPassword, setAdminPassword] = useState("")
    const [adminPasswordError, setAdminPasswordError] = useState("")
    const [adminID, setAdminID] = useState("")
    const [adminIDError, setAdminIDError] = useState("")
    const [adminPhone, setAdminPhone] = useState("")
    const [adminPhoneError, setAdminPhoneError] = useState("")
    const [adminConfirmPassword, setAdminConfirmPassword] = useState("")
    const [adminConfirmPasswordError, setAdminConfirmPasswordError] = useState("")
    const [editing, setEditing] = useState(false)
    const [areasInSelect, setAreasInSelect] = useState([{ "label": "Gerencia", "value": "gerencia" },
    { "label": "Subgerencia Cientifica", "value": "subgerencia cientifica" },
    { "label": "Subgerencia Administrativa y Financiera", "value": "subgerencia administrativa y financiera" }])
    const [areas, setAreas] = useState(
        [{
            "name": "Gerencia", "dependency":
                [{ "label": "Oficina Asesora de Control Interno", "value": "Oficina Asesora de Control Interno" },
                { "label": "Oficina Asesora de control Interno Disciplinario", "value": "Oficina Asesora de control Interno Disciplinario" },
                { "label": "Oficina Asesora de Planeacion", "value": "Oficina Asesora de Planeacion" },
                { "label": "Oficina Asesora Juridica", "value": "Oficina Asesora Juridica" }
                ]
        },
        {
            "name": "Subgerencia Cientifica", "dependency":
                [{ "label": "Consulta Externa", "value": "Consulta Externa" },
                { "label": "Cirugia", "value": "Cirugia" },
                { "label": "Laboratorio Clinico", "value": "Laboratorio Clinico" },
                { "label": "Banco de sangre", "value": "Banco de sangre" },
                { "label": "Imagenologia", "value": "Imagenologia" },
                { "label": "Nutricion", "value": "Nutricion" },
                { "label": "Cardiologia", "value": "Cardiologia" },
                { "label": "Endoscopia", "value": "Endoscopia" },
                { "label": "Patologia", "value": "Patologia" },
                { "label": "Oncologia", "value": "Oncologia" },
                { "label": "Madre Canguro", "value": "Madre Canguro" },
                { "label": "Genetica e infusion", "value": "Genetica e infusion" },
                { "label": "Prevencion y Control de Infecciones", "value": "Prevencion y Control de Infecciones" },
                { "label": "Rehabilitacion", "value": "Rehabilitacion" },
                { "label": "Servicio Farmaceutico", "value": "Servicio Farmaceutico" },
                { "label": "Atencion al Usuario", "value": "Atencion al Usuario" },
                { "label": "Trabajo Social", "value": "Trabajo Social" },
                { "label": "Vigilancia Epidemiologia", "value": "Vigilancia Epidemiologia" },
                { "label": "Central de Esterilizacion", "value": "Central de Esterilizacion" },
                { "label": "Referencia y contra Referencia", "value": "Referencia y contra Referencia" },
                { "label": "Servicio de Ambulancia", "value": "Servicio de Ambulancia" },
                { "label": "UCI ADULTOS", "value": "UCI ADULTOS" },
                { "label": "UCI - UCINT PEDIATRICA", "value": "UCI - UCINT PEDIATRICA" },
                { "label": "Neonatos y banco de leche", "value": "Neonatos y banco de leche" },
                { "label": "Unidad de cuidado intermedio adultos", "value": "Unidad de cuidado intermedio adultos" },
                { "label": "Unidad de quemados", "value": "Unidad de quemados" },
                { "label": "Medicina interna", "value": "Medicina interna" },
                { "label": "Quirurgicas", "value": "Quirurgicas" },
                { "label": "Traumatologia y ortopedia", "value": "Traumatologia y ortopedia" },
                { "label": "Salud mental", "value": "Salud mental" },
                { "label": "Pediatria", "value": "Pediatria" },
                { "label": "Ginecobstetricia", "value": "Ginecobstetricia" },
                { "label": "Coordinacion de Enfermeria", "value": "Coordinacion de Enfermeria" },
                { "label": "Gestion de convenio docencia servicio", "value": "Gestion de convenio docencia servicio" },
                { "label": "etico legal e Investigaciones", "value": "etico legal e Investigaciones" },
                { "label": "Urgencias adultos", "value": "Urgencias adultos" },
                { "label": "Urgencias pediatricas", "value": "Urgencias pediatricas" },
                { "label": "Urgencias ginecobstreticia y partos", "value": "Urgencias ginecobstreticia y partos" }]
        },
        {
            "name": "Subgerencia Administrativa y Financiera", "dependency":
                [{ "label": "Talento Humano", "value": "Talento Humano" },
                { "label": "Seguridad y Salud en el Trabajo", "value": "Seguridad y Salud en el Trabajo" },
                { "label": "Vigilancia y Control de Contratos", "value": "Vigilancia y Control de Contratos" },
                { "label": "Infraestructura y Tecnologia", "value": "Infraestructura y Tecnologia" },
                { "label": "Gestion Ambiental", "value": "Gestion Ambiental" },
                { "label": "Almacen", "value": "Almacen" },
                { "label": "Servicios Generales", "value": "Servicios Generales" },
                { "label": "Mercadeo y Venta de Servicios", "value": "Mercadeo y Venta de Servicios" },
                { "label": "Contabilidad", "value": "Contabilidad" },
                { "label": "Presupuesto", "value": "Presupuesto" },
                { "label": "Costos", "value": "Costos" },
                { "label": "Auditoria de Cuentas", "value": "Auditoria de Cuentas" },
                { "label": "Facturacion", "value": "Facturacion" },
                { "label": "Cartera", "value": "Cartera" },
                { "label": "Tesoreria", "value": "Tesoreria" },
                { "label": "Comunicaciones", "value": "Comunicaciones" },
                { "label": "Gestion Documental", "value": "Gestion Documental" },
                { "label": "Sistemas de informacion", "value": "Sistemas de informacion" },
                { "label": "Estadistica", "value": "Estadistica" },
                ]
        }])
    const [areaSelected, setAreaSelected] = useState(false)
    const [visibleModal1, setVisibleModal1] = useState(false)
    const [userInfo, setUserInfo] = useState([])
    const [userFirstName, setUserFirstName] = useState("")
    const [userSecondName, setUserSecondName] = useState("")
    const [userLastname, setUserLastname] = useState("")
    const [userSecondLastname, setUserSecondLastname] = useState("")
    const [userIdType, setUserIdType] = useState(false)
    const [userId, setUserId] = useState("")
    const [userSex, setUserSex] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPhone, setUserPhone] = useState("")
    const [userBirthdate, setUserBirthdate] = useState("")
    const [userHeadquarter, setUserHeadquarter] = useState(false)
    const [userArea, setUserArea] = useState(false)
    const [dependencysToChoose, setDependencysToChoose] = useState([])
    const [userDependency, setUserDependency] = useState(false)
    const [userPosition, setUserPosition] = useState(false)
    const [userRole, setUserRole] = useState(false)
    const [userProfesion, setUserProfesion] = useState(false)
    const [editingUser, setEditingUser] = useState(false)

    const [sex, setSex] = useState()
    const [image1, setImage1] = useState(photo)
    const [imgToUplo1, setImgToUplo1] = useState('')

    const { Option } = Select

    const { roles } = useSelector(state => state.Login)
    const { countries, departments, municipalitys } = useSelector(state => state.DepMun)
    const { loadingEnterprise, enterpriseCreatedId, areaCreatedId, isAreaCreated, usersCreated } = useSelector(state => state.Enterprise)

    const dependencyInArea = areaSelected && areas.filter(d => d.name === areaSelected.label)

    useEffect(() => {
        dispatch(DepMunActions.getCountries())
        dispatch(LoginActions.getRoles())
    }, [])

    useEffect(() => {
        country && dispatch(DepMunActions.getDepartmentsFromCountry(country))
    }, [country])

    useEffect(() => {
        dep && dispatch(DepMunActions.getMunicipalitys(dep))
    }, [dep])
    
    const onCreateHeadquarter = () => {
        if (editingHeadquarter !== false) {
            var aux = headquarterInfo
            aux[editingHeadquarter] = [headquarterName, headquarterNit, headquarterPhone, headquarterEmail, headquarterAddress]
            setHeadquarterInfo(aux)
            setEditingHeadquarter(false)
            setHeadquarterName("")
            setHeadquarterNit("")
            setHeadquarterEmail("")
            setHeadquarterAddress("")
            setHeadquarterPhone("")
        } else {
            setHeadquarterInfo([...headquarterInfo, [headquarterName, headquarterNit, headquarterPhone, headquarterEmail, headquarterAddress]])
            setHeadquarterName("")
            setHeadquarterNit("")
            setHeadquarterEmail("")
            setHeadquarterAddress("")
            setHeadquarterPhone("")
        }
    }

    const onCreateAdmin = () => {
        if (editing !== false) {
            var aux = adminInfo
            aux[editing] = [adminName, adminID, adminPhone, adminEmail, adminPassword]
            setAdminInfo(aux)
            setEditing(false)
            setAdminName("")
            setAdminID("")
            setAdminPhone("")
            setAdminEmail("")
            setAdminPassword("")
            setAdminConfirmPassword("")
        } else {
            setAdminInfo([...adminInfo, [adminName, adminID, adminPhone, adminEmail, adminPassword]])
            setAdminName("")
            setAdminID("")
            setAdminPhone("")
            setAdminEmail("")
            setAdminPassword("")
            setAdminConfirmPassword("")
        }
    }

    const onSelectArea = (e) => {
        setUserArea(e)
        const dependencyInArea = areas.length > 0 && areas.filter(d => d.name === e)
        setDependencysToChoose(dependencyInArea)
    }

    const onCreateUser = () => {
        if (editingUser !== false) {
            var aux = userInfo
            aux[editingUser] = [userFirstName, userSecondName,
                userLastname, userSecondLastname, userIdType, userId,
                userSex, userEmail, userPhone, userBirthdate, userHeadquarter,
                userArea, userDependency, userRole, userPosition, userProfesion, image1]
            setUserInfo(aux)
            setUserFirstName("")
            setUserSecondName("")
            setUserLastname("")
            setUserSecondLastname("")
            setSex(false)
            setUserIdType(false)
            setUserId("")
            setUserSex("")
            setUserEmail("")
            setUserPhone("")
            setUserBirthdate("")
            setUserHeadquarter(false)
            setUserArea(false)
            setUserDependency(false)
            setUserRole(false)
            setUserPosition(false)
            setUserProfesion(false)
            setImage1(false)
        } else {
            setUserInfo([...userInfo, [userFirstName, userSecondName,
                userLastname, userSecondLastname, userIdType, userId,
                userSex, userEmail, userPhone, userBirthdate, userHeadquarter,
                userArea, userDependency, userRole, userPosition, userProfesion, image1]])
            setUserFirstName("")
            setUserSecondName("")
            setUserLastname("")
            setUserSecondLastname("")
            setSex(false)
            setUserIdType(false)
            setUserId("")
            setUserSex("")
            setUserEmail("")
            setUserPhone("")
            setUserBirthdate("")
            setUserHeadquarter(false)
            setUserArea(false)
            setUserDependency(false)
            setUserRole(false)
            setUserPosition(false)
            setUserProfesion(false)
            setImage1(false)
        }
    }

    useEffect(() => {
        if (page === 0) {
            var ctx = canvas.current.getContext('2d')
            var img = new Image()

            canvas.current.width = 350
            canvas.current.height = 230

            img.onload = function () {
                ctx.drawImage(img, 0, 0, 350, 230)
            }
            var dataImg;
            setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
            img.src = image
        }
    }, [page])

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setImgToUplo(dataImg) }, 1000)
    };

    const uploadImage1 = (e) => {
        setImage1(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas1.current.getContext('2d')
        var img = new Image()

        canvas1.current.width = 350
        canvas1.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas1.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setImgToUplo1(dataImg) }, 1000)
    };

    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const onCreateOption = (label) => {
        setAreasInSelect([...areasInSelect, createOption(label)])
        setAreas([...areas, { name: label, dependency: [] }])
    }

    const onDeleteOption = () => {
        var aux = areasInSelect
        var aux1 = areas.slice()
        var index = false
        var index1 = false
        for (let i = 0; i < areasInSelect.length; i++) {
            if (areasInSelect[i] === areaSelected) {
                index = i
            }
        }
        aux.splice(index, 1)
        setAreasInSelect(aux)
        setAreaSelected(false)

        for (let i = 0; i < areas.length; i++) {
            if (areas[i].name === areaSelected.label) {

                index1 = i
            }
        }
        aux1.splice(index1, 1)
        setAreas(aux1)
    }

    const onCreateOptionMultiple = (label) => {
        dependencyInArea[0].dependency.push(createOption(label))
    }

    const ExcelSvg = () => (
        <svg width="1.7vw" height="1.7vw" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2529_5035)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.89347 1.51438H6.41128V0.693848L0.6875 1.57729V9.32094L6.41128 10.3068V9.09063H9.89347C9.99893 9.09597 10.1022 9.05934 10.1807 8.98874C10.2593 8.91813 10.3066 8.81931 10.3125 8.71388V1.89079C10.3066 1.78541 10.2591 1.68668 10.1806 1.61615C10.1021 1.54562 9.99887 1.50903 9.89347 1.51438ZM9.94847 8.77644H6.39959L6.39375 8.1271H7.24866V7.37085H6.38722L6.38309 6.92397H7.24866V6.16772H6.37656L6.37244 5.72085H7.24866V4.9646H6.36969V4.51772H7.24866V3.76147H6.36969V3.3146H7.24866V2.55835H6.36969V1.87085H9.94847V8.77644Z" fill="#20744A" />
                <path d="M7.72998 2.55713H9.21601V3.31338H7.72998V2.55713ZM7.72998 3.7606H9.21601V4.51685H7.72998V3.7606ZM7.72998 4.96407H9.21601V5.72032H7.72998V4.96407ZM7.72998 6.16753H9.21601V6.92379H7.72998V6.16753ZM7.72998 7.371H9.21601V8.12725H7.72998V7.371Z" fill="#20744A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.1819 3.66889L2.91959 3.62661L3.38331 4.90158L3.93125 3.57402L4.66893 3.53174L3.77312 5.34193L4.66893 7.15658L3.88896 7.10399L3.36234 5.72074L2.83537 7.05139L2.11865 6.98814L2.95121 5.38524L2.1819 3.66889Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2529_5035">
                    <rect width="11" height="11" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );

    const Card = ({ text, icon, excel }) => {
        return (
            <div className="Card" onClick={() => setPage(5)}>
                <div>
                    {icon}
                    <p>{text}</p>
                </div>
                {excel && <Icon style={{ position: "relative", top: "0.2vw", left: "0.3vw" }} component={ExcelSvg} />}
            </div>
        )
    }

    const onFinish = () => {
        if (userInfo.length > 0) {
            const data = {
                "name": enterpriseName,
                "nitrut": nitrut,
                "email": email,
                "web": web,
                "phone": phone,
                "image": image === photo ? null : image,
                "color": color,
                "municipality": mun
            }
            dispatch(EnterpriseActions.createEnterprise(data))
        } else {
            message.warn("No has ingresado usuarios")
        }
    }

    useEffect(() => {
        if (enterpriseCreatedId !== false) {
            headquarterInfo.map(d => {
                const data = {
                    "name": d[0],
                    "nitrut": d[1],
                    "phone": d[2],
                    "email": d[3],
                    "address": d[4],
                    "enterprise": enterpriseCreatedId
                }
                dispatch(EnterpriseActions.createHeadquarters(data))
            })
            adminInfo.map(d => {
                const data = {
                    "dbState": true,
                    "name": d[0],
                    "cardId": d[1],
                    "phone": d[2],
                    "email": d[3],
                    "password": d[4],
                    "enterprise": enterpriseCreatedId
                }
                dispatch(EnterpriseActions.createAdmins(data))
                areas.map(d => {
                    const payload = {
                        "enterprise": enterpriseCreatedId,
                        "name": d.name,
                        "dependency": d.dependency
                    }
                    dispatch(EnterpriseActions.createArea([payload,userInfo]))
                })
            })

        }
    }, [enterpriseCreatedId])

    useEffect(() => {
        if (usersCreated) {
            history.push("/")
        }
    }, [usersCreated])

    return (
        <div className="Register">
            <div className="container">
                <div className="enterpriseDiv">
                    <div className="imgDiv">
                        <img src={backIcon} onClick={() => page === 0 ? history.goBack() : setPage(prev => prev - 1)}></img>
                    </div>
                    <img className="logoregister" src={logoregister}></img>
                    <p>Crear una nueva organización.</p>
                </div>
                {page === 0 && <div className="formDiv">
                    <div className="section">
                        <p className="titleSection">1. Información básica</p>
                        <Input
                            border={errorEnterpriseName}
                            labelsize='1vw'
                            labelstyle='inputLabel'
                            va={enterpriseName}
                            lock={true}
                            label='Nombre de la empresa *'
                            setData={setEnterpriseName}
                            placeholder='Nombre'
                        />
                        <Input
                            border={errorNitrut}
                            labelsize='1vw'
                            va={nitrut}
                            labelstyle='inputLabel'
                            label='NIT/RUT *'
                            setData={setNitrut}
                        />
                        <Input
                            border={errorEmail}
                            labelsize='1vw'
                            labelstyle='inputLabel'
                            va={email}
                            lock={true}
                            label='Tu correo corporativo *'
                            setData={setEmail}
                            placeholder={"correo@ejemplo.com"}
                        />
                        <div className="selects">
                            <Select
                                className='select'
                                style={{ border: errorCountry }}
                                defaultValue={false}
                                onChange={e => setCountry(e)}
                                color='primary'
                            >
                                <Option value={false}>
                                    <em>País</em>
                                </Option>
                                {countries.map(d => (
                                    <Select.Option key={d.id} value={d.id}>
                                        {d.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                className='select'
                                style={{ border: errorCountry }}
                                id="demo-controlled-open-select"
                                defaultValue={false}
                                onChange={e => setDep(e)}
                                color='primary'
                            >
                                <Select.Option value={false}>
                                    <em>Departamento</em>
                                </Select.Option>
                                {departments.map(d => (
                                    <Select.Option key={d.id} value={d.nombre}>
                                        {d.nombre}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                className='select'
                                style={{ border: errorCountry }}
                                id="demo-controlled-open-select"
                                defaultValue={false}
                                onChange={e => setMun(e)}
                                color='primary'
                            >
                                <Select.Option value={false}>
                                    <em>Municipio</em>
                                </Select.Option>
                                {municipalitys.map(d => (
                                    <Select.Option key={d.id} value={d.id}>
                                        {d.nombre}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="section1">
                        <canvas ref={canvas} className='canvas' style={{ border: errorImage }}> </canvas>

                        <div className='imageButtons'>
                            <label className="custom-file-upload">
                                <input accept="image/png, image/jpeg" onClick={(event) => { event.target.value = null }} onChange={uploadImage} type='file'></input>
                                <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                            </label>
                        </div>
                        <p className="pColor" >Color institucional</p>
                        <input type="color" className="color" value={color} onChange={e => setColor(e.target.value)}></input>
                        <Input
                            labelsize='1vw'
                            labelstyle='inputLabel'
                            va={web}
                            lock={true}
                            label='Sitio web de la organización *'
                            setData={setWeb}
                            border={errorWeb}
                        />
                        <PhoneInput
                            className="phoneSelector"
                            style={{ border: errorPhone }}
                            placeholder="Numero de celular"
                            value={phone}
                            onChange={setPhone}
                        />
                        <div className="continue" onClick={() => {
                            let ok = true
                            if (enterpriseName === "") {
                                setErrorEnterpriseName("1px solid red")
                                ok = false
                            } else {
                                setErrorEnterpriseName("")
                            }
                            if (nitrut === "") {
                                setErrorNitrut("1px solid red")
                                ok = false
                            } else {
                                setErrorNitrut("")
                            }
                            if (email === '' || !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(email)) {
                                message.warn("Correo invalido")
                                setErrorEmail("1px solid red")
                                ok = false
                            } else {
                                setErrorEmail("")
                            }
                            if (country === false) {
                                setErrorCountry("1px solid red")
                                ok = false
                            } else {
                                setErrorCountry("")
                            }
                            if (dep === false) {
                                setErrorDep("1px solid red")
                                ok = false
                            } else {
                                setErrorDep("")
                            }
                            if (mun === false) {
                                setErrorMun("1px solid red")
                                ok = false
                            } else {
                                setErrorMun("")
                            }
                            if (web === "") {
                                setErrorWeb("1px solid red")
                                ok = false
                                message.warn("Sitio web invalido")
                            } else {
                                setErrorWeb("")
                            }
                            if (phone === "") {
                                setErrorPhone("1px solid red")
                                ok = false
                            } else {
                                setErrorPhone("")
                            }

                            if (ok) {
                                setPage(1)
                            }
                        }
                        }>Continuar</div>

                    </div>
                </div>}
                {page === 1 && <div className="formDiv column">
                    <p className="titleSection margintop">2. Sedes de la entidad o puestos de salud</p>
                    <div className="head">
                        <input className="searchHeadquarter" placeholder="Buscar Sede"></input>
                        <div className="addHeadquarter" onClick={() => setVisibleModal(true)}>Añadir sede</div>
                    </div>
                    <hr className="hr"></hr>
                    <div className="columnsName">
                        <p>Nombre</p>
                        <p>Nit</p>
                        <p>Contacto</p>
                        <p>Dirección</p>
                    </div>
                    <Modal width={'60vw'} className="ModalHeadquarter" visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
                        <div className="header">
                            <p>Registrar sede</p>
                        </div>
                        <div className="body">
                            <Input va={headquarterName} border={headquarterNameError} label={"Nombre sede"} setData={setHeadquarterName} />
                            <Input va={headquarterNit} border={headquarterNitError} label={"Nit"} setData={setHeadquarterNit} />
                            <Input va={headquarterPhone} border={headquarterPhoneError} label={"Número telefonico"} setData={setHeadquarterPhone} />
                            <Input va={headquarterAddress} border={headquarterAddressError} label={"Dirección"} setData={setHeadquarterAddress} />
                            <Input va={headquarterEmail} border={headquarterEmailError} label={"Correo"} setData={setHeadquarterEmail} />
                        </div>
                        <div className="footer">
                            <p onClick={() => {
                                let ok = true
                                if (headquarterName === "") {
                                    setHeadquarterNameError("1px solid red")
                                    ok = false
                                } else {
                                    setHeadquarterNameError("")
                                }
                                if (headquarterNit === "") {
                                    setHeadquarterNitError("1px solid red")
                                    ok = false
                                } else {
                                    setHeadquarterNitError("")
                                }
                                if (headquarterPhone === "") {
                                    setHeadquarterPhoneError("1px solid red")
                                    ok = false
                                } else {
                                    setHeadquarterPhoneError("")
                                }
                                if (headquarterAddress === "") {
                                    setHeadquarterAddressError("1px solid red")
                                    ok = false
                                } else {
                                    setHeadquarterAddressError("")
                                }
                                if (headquarterEmail === "" || !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(headquarterEmail)) {
                                    message.warning("Correo invalido")
                                    setHeadquarterEmailError("1px solid red")
                                    ok = false
                                } else {
                                    setHeadquarterEmail("")
                                }

                                if (ok) {
                                    onCreateHeadquarter()
                                    setVisibleModal(false)
                                }
                            }
                            }>Siguiente</p>
                        </div>
                    </Modal>
                    {headquarterInfo.map((d, i) => (
                        <div className="admin" key={d[1]} style={{ width: '88%' }} id={i + "headquarter"}>
                            <p>{d[0]}</p>
                            <p>{d[1]}</p>
                            <div className="contact">
                                <p>{d[2]}</p>
                                <p className="email">{d[3]}</p>
                            </div>
                            <p>{d[4]}</p>
                            <div className="buttons">
                                <EditOutlined className="icon"
                                    onClick={() => {
                                        setVisibleModal(true)
                                        setHeadquarterName(d[0])
                                        setHeadquarterNit(d[1])
                                        setHeadquarterPhone(d[2])
                                        setHeadquarterEmail(d[3])
                                        setHeadquarterAddress(d[4])
                                        setEditingHeadquarter(i)
                                    }}
                                />
                                <DeleteOutlined className="icon"
                                    onClick={() => {
                                        var aux = headquarterInfo
                                        aux.splice(i, 1)
                                        setHeadquarterInfo(aux)
                                        document.getElementById(i + "headquarter").style.display = "none"
                                    }
                                    } />
                            </div>
                        </div>
                    ))}
                    <div className="foot">
                        <div className="continue" onClick={() => {
                            setPage(2)
                        }}>Continuar</div>
                    </div>
                </div>}
                {page === 2 && <div className="formDiv column">
                    <p className="titleSection margintop">3. Información Personal Administrador</p>
                    <div className="sections">
                        <div className="section">
                            <p>Nombre *</p>
                            <input value={adminName} style={{ border: adminNameError }} onChange={e => setAdminName(e.target.value)} onBlur={e => setAdminName(e.target.value)}></input>
                            <p>Correo electrónico *</p>
                            <input value={adminEmail} style={{ border: adminEmailError }} onChange={e => setAdminEmail(e.target.value)} onBlur={e => setAdminEmail(e.target.value)}></input>
                            <p>Contraseña *</p>
                            <input type="password" value={adminPassword} style={{ border: adminPasswordError }} onChange={e => setAdminPassword(e.target.value)} onBlur={e => setAdminPassword(e.target.value)}></input>
                        </div>
                        <div className="section1">
                            <p>Documento de identidad *</p>
                            <input value={adminID} style={{ border: adminIDError }} onChange={e => setAdminID(e.target.value)} onBlur={e => setAdminID(e.target.value)}></input>
                            <PhoneInput
                                className="phoneSelector"
                                style={{ border: adminPhoneError }}
                                placeholder="Numero de celular"
                                value={adminPhone}
                                onChange={setAdminPhone}
                            />
                            <p>Confirmar contraseña *</p>
                            <input type="password" value={adminConfirmPassword} style={{ border: adminConfirmPasswordError }} onChange={e => setAdminConfirmPassword(e.target.value)} onBlur={e => setAdminConfirmPassword(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="userAdmin">
                        <p>Usuario Administrador</p>
                        <div onClick={() => {
                            let ok = true
                            if (adminName === "") {
                                setAdminNameError("1px solid red")
                                ok = false
                            } else {
                                setAdminNameError("")
                            }

                            if (adminEmail === "" && !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(adminEmail)) {
                                message.warning("Email invalido")
                                setAdminEmailError("1px solid red")
                                ok = false
                            } else {
                                setAdminEmailError("")
                                adminInfo.map(d => {
                                    if (d[3] === adminEmail) {
                                        message.error("Correo repetido")
                                        setAdminEmailError("1px solid red")
                                        ok = false
                                    } else {
                                        setAdminEmailError("")
                                    }
                                })
                            }
                            if (adminPassword === "") {
                                setAdminPasswordError("1px solid red")
                                ok = false
                            } else {
                                setAdminPasswordError("")
                            }
                            if (adminID === "") {
                                setAdminIDError("1px solid red")
                                ok = false
                            } else {
                                setAdminIDError("")
                            }
                            if (adminPhone === "") {
                                setAdminPhoneError("1px solid red")
                                ok = false
                            } else {
                                setAdminPhoneError("")
                            }
                            if (adminConfirmPassword !== adminPassword) {
                                setAdminConfirmPasswordError("1px solid red")
                                ok = false
                            } else {
                                setAdminConfirmPasswordError("")
                            }
                            if (ok) {
                                onCreateAdmin()
                            }
                        }}><UserAddOutlined /> {editing !== false ? "Editar" : "Crear"}</div>
                    </div>
                    <hr></hr>
                    <div className="adminHeaders">
                        <p>Nombre</p>
                        <p>Documento de identidad</p>
                        <p>Contacto</p>
                        <p>Contraseña</p>
                        {editing}
                    </div>
                    <div className="adminsCreated">
                        {adminInfo.map((d, i) => (
                            <div className="admin" id={i + "admin"} key={i}>
                                <p>{d[0]}</p>
                                <p>{d[1]}</p>
                                <div className="contact">
                                    <p>{d[2]}</p>
                                    <p className="email">{d[3]}</p>
                                </div>
                                <p>{"*".repeat(d[4].length)}</p>
                                <div className="buttons">
                                    <EditOutlined className="icon"
                                        onClick={() => {
                                            setAdminName(d[0])
                                            setAdminID(d[1])
                                            setAdminPhone(d[2])
                                            setAdminEmail(d[3])
                                            setAdminPassword(d[4])
                                            setAdminConfirmPassword(d[4])
                                            setEditing(i)
                                        }}
                                    />
                                    <DeleteOutlined className="icon"
                                        onClick={() => {
                                            var aux = adminInfo
                                            aux.splice(i, 1)
                                            setAdminInfo(aux)
                                            document.getElementById(i + "admin").style.display = "none"
                                        }
                                        } />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="foot">
                        <div className="continue" onClick={() => {
                            if (adminInfo.length === 0) {
                                message.warn("No has ingresado ningun administrador")
                            } else {
                                setPage(3)
                            }
                        }}>Continuar</div>
                    </div>
                </div>}
                {page === 3 && <div className="formDiv column">
                    <p className="titleSection margintop">4. Crear Areas y Dependencias del Hospital</p>
                    <p className="pCreate">Crear Area</p>
                    <div className="divCreate">
                        <CreatableSelect
                            className="create"
                            options={areasInSelect}
                            value={areaSelected}
                            onChange={(newValue) => setAreaSelected(newValue)}
                            onCreateOption={(l) => onCreateOption(l)}
                        />
                        {areaSelected && <div className="delete" onClick={() => onDeleteOption()}>Eliminar</div>}
                    </div>
                    {areaSelected && <p className="pCreate">Crear Dependencias</p>}
                    <div className="divCreate">
                        {areaSelected && <CreatableSelect
                            className="createMulti"
                            maxMenuHeight={4}
                            isMulti
                            options={dependencyInArea[0].dependency}
                            value={dependencyInArea[0].dependency}
                            onCreateOption={(l) => onCreateOptionMultiple(l)}
                            onChange={e => {
                                const aux = areas.slice()
                                aux.map((d, i) => {
                                    if (d.name === dependencyInArea[0].name) {
                                        aux[i].dependency = e
                                        setAreas(aux)
                                    }
                                })
                            }}
                        />}

                        {/* {areaSelected && <div className="delete" onClick={() => onDeleteOptionMultiple()}>Eliminar</div>} */}
                        <Tree
                            lineWidth={'2px'}
                            lineColor={'green'}
                            lineBorderRadius={'10px'}
                            label={<div className="treeNode">{enterpriseName}</div>}>
                            {areas.map((d, i) => (
                                <TreeNode key={i} label={<div className="treeNode">{d.name}</div>}>
                                    {d.dependency.map((de, i) => (
                                        <TreeNode key={i} label={<div className="treeNode">{de.label}</div>} />
                                    ))}
                                </TreeNode>
                            ))}
                        </Tree>
                    </div>
                    <div className="foot">
                        <div className="continue " onClick={() => {
                            if (areas.length === 0) {
                                message.warn("No has ingresado ninguna area")
                            } else if (areas.filter(d => d.dependency.length === 0).length > 0) {
                                message.warn("Hay un area sin dependencias")
                            } else {
                                setPage(4)
                            }
                        }}>Continuar</div>
                    </div>
                </div>}
                {page === 4 && <div className="formDiv column">
                    <p className="titleSection margintop">5. Carga o registro de usuarios</p>
                    <div className="Cards">
                        {/*                         <Card icon={<PlusOutlined className="icon" />} text={"Cargar Usuarios XLS"} excel />
 */}                        <Card icon={<PlusOutlined className="icon" />} text={"Registrar Usuarios"} />
                    </div>

                </div>}
                {page === 5 && <div className="formDiv column">
                    <p className="titleSection margintop">6. Usuarios cargados</p>
                    <div className="head">
                        <input className="searchHeadquarter" placeholder="Buscar Usuario"></input>
                        <div className="addHeadquarter" onClick={() => setVisibleModal1(true)}><PlusOutlined />Nuevo usuario</div>
                    </div>
                    <hr className="hr"></hr>
                    <div className="columnsNameUser">
                        <p>Nombre</p>
                        <p id="p1">Sede</p>
                        <p id="p2">Contacto</p>
                        <p id="p3">Area</p>
                        <p id="p4">Dependencia</p>
                        <p id="p5">Cargo</p>
                    </div>
                    <Modal width={'60vw'} className="ModalHeadquarter" visible={visibleModal1} footer={null} onCancel={() => {
                        setVisibleModal1(false)
                        setEditingUser(false)
                    }}>
                        <div className="header">
                            <p>{editingUser === false ? "Registrar Usuario" : "Editar Usuario"}</p>
                        </div>
                        <div className="bodyUser">
                            <Input label={"Primer Nombre *"} setData={setUserFirstName} va={userFirstName} />
                            <Input label={"Segundo Nombre"} setData={setUserSecondName} va={userSecondName} />
                            <Input label={"Primer Apellido *"} setData={setUserLastname} va={userLastname} />
                            <Input label={"Segundo Apellido"} setData={setUserSecondLastname} va={userSecondLastname} />
                            <Select
                                className='select'
                                value={userIdType}
                                onChange={e => setUserIdType(e)}
                                color='primary'
                            >
                                <Option value={false}>
                                    <em>Tipo de identificación</em>
                                </Option>
                                <Option value={"Cedula de ciudadanía"}>
                                    <em>Cedula de ciudadanía</em>
                                </Option>
                            </Select>
                            <Input label={"Numero de identificación"} setData={setUserId} va={userId} />
                            <div>
                                <p className="label">Sexo</p>
                                <Radio.Group className="radio" onChange={e => setUserSex(e.target.value)} value={userSex}>
                                    <Radio value={"Femenino"}>Femenino</Radio>
                                    <Radio value={"Masculino"}>Masculino</Radio>
                                </Radio.Group>
                            </div>
                            <Input label={"Correo electrónico"} setData={setUserEmail} va={userEmail} />
                            <Input label={"Celular"} setData={setUserPhone} va={userPhone} />
                            <div>
                                <p className="label">Fecha de nacimiento</p>
                                <DatePicker onChange={(e => setUserBirthdate(e))} value={userBirthdate} />
                            </div>
                            <Select
                                className='select'
                                value={userHeadquarter}
                                onChange={e => setUserHeadquarter(e)}
                                color='primary'
                            >
                                <Option value={false}>
                                    <em>Sede</em>
                                </Option>
                                {headquarterInfo.map(d => (
                                    <Select.Option key={d[1]} value={d[1]}>
                                        {d[0]}
                                    </Select.Option>
                                ))}

                            </Select>
                            <Select
                                className='select'
                                value={userArea}
                                onChange={e => onSelectArea(e)}
                                color='primary'
                            >
                                <Option value={false}>
                                    <em>Area</em>
                                </Option>
                                {areas.map(d => (
                                    <Select.Option key={d.name} value={d.name}>
                                        {d.name}
                                    </Select.Option>
                                ))}

                            </Select>
                            <Select
                                className='select'
                                value={userDependency}
                                onChange={e => setUserDependency(e)}
                                color='primary'
                            >
                                <Option value={false}>
                                    <em>Dependencia</em>
                                </Option>
                                {dependencysToChoose.length > 0 && dependencysToChoose[0].dependency.map(d => (
                                    <Select.Option key={d.label} value={d.label}>
                                        {d.label}
                                    </Select.Option>
                                ))}

                            </Select>
                            <Select
                                className='select'
                                value={userRole}
                                onChange={e => setUserRole(e)}
                                color='primary'
                            >
                                <Option value={false}>
                                    <em>Rol de usuario</em>
                                </Option>
                                {roles && roles.map(d => (
                                    <Select.Option key={d.id} value={d.id}>
                                        {d.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Input label={"Cargo que ocupa en la entidad"} setData={setUserPosition} va={userPosition} />
                            <Input label={"Profesion"} setData={setUserProfesion} va={userProfesion} />
                            <div>
                                <p className="label">Cargar Firma</p>
                                <canvas ref={canvas1} className='canvas' > </canvas>

                                <div className='imageButtons'>
                                    <label className="custom-file-upload">
                                        <input accept="image/png, image/jpeg" onClick={(event) => { event.target.value = null }} onChange={uploadImage1} type='file'></input>
                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="footer">
                            <p onClick={() => {
                                setVisibleModal1(false)
                                onCreateUser()
                            }
                            }>Añadir usuario</p>
                        </div>
                    </Modal>
                    {userInfo.map((d, i) => (
                        <div className="user" id={i + "user"} key={i}>
                            <p>{d[0] + " " + d[2]}</p>
                            <p>{!d[10] ? "Sede principal" : d[10]}</p>
                            <div className="contact">
                                <p>{d[8]}</p>
                                <p className="email">{d[7]}</p>
                            </div>
                            <p>{d[11]}</p>
                            <p>{d[12]}</p>
                            <p>{d[13] === 37 ? "Jefe de dependencia" :
                                d[13] === 1 ? "Coordinador" :
                                    d[13] === 3 ? "Agente" :
                                        "Jefe de dependencia"}</p>
                            <div className="buttons">
                                <EditOutlined className="icon"
                                    onClick={() => {
                                        setUserFirstName(d[0])
                                        setUserSecondName(d[1])
                                        setUserLastname(d[2])
                                        setUserSecondLastname(d[3])
                                        setUserIdType(d[4])
                                        setUserId(d[5])
                                        setUserSex(d[6])
                                        setUserEmail(d[7])
                                        setUserPhone(d[8])
                                        setUserBirthdate(d[9])
                                        setUserHeadquarter(d[10])
                                        setUserArea(d[11])
                                        setUserDependency(d[12])
                                        setUserRole(d[13])
                                        setUserPosition(d[14])
                                        setUserProfesion(d[15])
                                        setImage1(d[16])
                                        setEditingUser(i)
                                        setVisibleModal1(true)
                                        var img = new Image()
                                        var ctx = canvas1.current.getContext('2d')

                                        img.onload = function () {
                                            ctx.drawImage(img, 0, 0, 350, 280)
                                        }
                                        var dataImg;
                                        img.src = d[15]
                                    }}
                                />
                                <DeleteOutlined className="icon"
                                    onClick={() => {
                                        var aux = userInfo
                                        aux.splice(i, 1)
                                        setUserInfo(aux)
                                        document.getElementById(i + "user").style.display = "none"
                                    }
                                    } />
                            </div>
                        </div>
                    ))}
                    <div className="foot">
                        {!loadingEnterprise ?
                            <div className="continue " onClick={() => onFinish()}>Finalizar</div>
                            :
                            <div className="continue ">{<LoadingOutlined />}</div>
                        }
                    </div>
                </div>}
            </div>

        </div >
    )
}