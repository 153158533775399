import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalBitacora } from "../ModalBitacora/ModalBitacora";
import { apiUrl } from '../../common/config/Environments'
import { CaseReportActions } from "../../services/caseReport/CaseReportSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { pdf } from "@react-pdf/renderer";

export const CalibrationCertificate = ({ data, setEdit, setEquipData }) => {
    const [visibleModal, setVisibleModal] = useState(false)
    const [CalibrationCertificateHigh, setCalibrationCertificateHigh] = useState(false)
    const [image, setImage] = useState(false)
    const [visiblePdf, setVisiblePdf] = useState(false)
    const { pdfFromCalibrationCertificate, loadingPdfFromCalibrationCertificate, allCalibrationCertificateByEquipmentId, loadingAllCalibrationCertificateByEquipmentId } = useSelector(state => state.CaseReport)
    const [visibleModalBitacora, setVisibleModalBitacora] = useState(false)

    const { idUser } = useSelector(state => state.Login)
    const dispatch = useDispatch()

    useEffect(() => {
        let aux = false
        aux = data.calibrationCertificate.map(d => new Date(d.date))
        var maximumDate = new Date(Math.max.apply(null, aux));
        for (let index = 0; index < data.calibrationCertificate.length; index++) {
            if (new Date(data.calibrationCertificate[index].date).getTime() == maximumDate.getTime()) {
                setCalibrationCertificateHigh(data.calibrationCertificate[index])
            }
        }
    }, [])

    useEffect(() => {
        fetch(`${apiUrl}/biomedicEquipment/imageById?id=${data.id}`)
            .then(response => response.json())
            .then(data => setImage(data))
            .catch(error => console.error(error))
    }, [])

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    useEffect(() => {
        if (allCalibrationCertificateByEquipmentId) {
            setVisibleModal(false)
            setVisibleModalBitacora(true)
        }
    }, [allCalibrationCertificateByEquipmentId])

    useEffect(() => {
        pdfFromCalibrationCertificate && pdfFromCalibrationCertificate.equipment && pdfFromCalibrationCertificate.equipment.id === data.id && setVisibleModal(true)
    }, [pdfFromCalibrationCertificate])

    const ModalEquip = () => {

        return (
            <Modal
                className='ModalEquip'
                width={pdfFromCalibrationCertificate ? '90vw' : '40vw'}
                visible={visibleModal} footer={null}
                onCancel={() => {
                    setVisibleModal(false)
                    dispatch(CaseReportActions.setIdEquipmentSelected(false))
                    dispatch(CaseReportActions.setPdfFromCalibrationCertificate(false))
                }}
            >
                {!pdfFromCalibrationCertificate && <p className="title">{data.name}</p>}
                {!pdfFromCalibrationCertificate && <div className='div'>
                    <button className='button' onClick={() => dispatch(CaseReportActions.getPdfFromCalibrationCertificate(data.calibrationCertificate[0].id))}
                    >
                        {loadingPdfFromCalibrationCertificate ? <LoadingOutlined /> : "Ver certificado"}
                    </button>
                    <button className='button' onClick={() => dispatch(CaseReportActions.getAllCalibrationCertificateByEquipmentId({ "id": data.id, "take": 6, "page": 0 }))}
                    >
                        {loadingAllCalibrationCertificateByEquipmentId ? <LoadingOutlined /> : "Ver más certificados"}
                    </button>
                </div>}
                {pdfFromCalibrationCertificate && <embed className="pdf" src={pdfFromCalibrationCertificate.pdf} type="application/pdf"></embed>}
            </Modal >
        )
    }

    /* const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este certificado?')) {
            const dataDelete = {
                "id": CalibrationCertificateHigh.id
            }
            dispatch(CaseReportActions.deleteCalibrationCertificate(dataDelete))
        }
    } */

    return (
        <div className='CalibrationCertificate' >
            {image ?
                <img className='equipmentImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                setVisibleModal(true)
                /* dispatch(CaseReportActions.setIdEquipmentSelected(data.id)) */
            }}>
                <div><p className='pFontData name'>{data.name}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.brand}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.modell}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.serie}</p></div>
                <div><p className='pFontData'>{data.licensePlate}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.location}</p></div>
                <div className="status"><p className='pFontStatus'>Cert. Calibración</p></div>
                <div><p className='pFontData'>{CalibrationCertificateHigh && padStr(new Date(CalibrationCertificateHigh.date).getDate()) + "/" +
                    padStr(1 + new Date(CalibrationCertificateHigh.date).getMonth()) + "/" +
                    padStr(new Date(CalibrationCertificateHigh.date).getFullYear())
                }</p></div>
            </div>
            {/* <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div> */}
            <ModalEquip />
            <ModalBitacora
                data={allCalibrationCertificateByEquipmentId}
                setEdit={setEdit}
                setEquipData={setEquipData}
                visibleModalBitacora={visibleModalBitacora}
                setVisibleModalBitacora={setVisibleModalBitacora}
            />
        </div>
    )
}