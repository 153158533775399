import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../common/config/Environments'
import { useDispatch } from 'react-redux';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';


export const EquipmentCard = ({ onChange, setVisibleModal, img, name, brand, model, serie, plate, location, id, equipmentType, moduleWork }) => {
    const [image, setImage] = useState(img)
    const dispatch = useDispatch()

    const urlImage = {
        1: `${apiUrl}/biomedicEquipment/imageById?id=${id}`,
        2: `${apiUrl}/${equipmentType}/imageById?id=${id}`,
        3: `${apiUrl}/industrialEquipment/imageById?id=${id}`,
        4: `${apiUrl}/vehicleEquipment/imageById?id=${id}`
    }

    useEffect(() => {
        if (id) {
            fetch(urlImage[moduleWork])
                .then(response => response.json())
                .then(data => setImage(data))
                .catch(error => console.error(error))
        }
    }, [id])

    const click = () => {
        onChange && onChange()
        setVisibleModal && setVisibleModal(true)
        switch (equipmentType) {
            case 'networkingAndCommunicationsEquipment':
                dispatch(EquipmentActions.getFullDataNetworkingAndCommunicationsEquipment(id))
                break;
            case 'peripheralsAndAccessoriesEquipment':
                dispatch(EquipmentActions.getFullDataPeripheralsAndAccessoriesEquipment(id))
                break;
            case 'computersEquipment':
                dispatch(EquipmentActions.getFullDataComputersEquipment({ "id": id, "parts": 'false' }))
                break;
        }
    }

    return (
        <>
            < div className='cardDiv' onClick={() => click()}>
                <img className='equipmentImage' src={image && image.image} alt='img' />
                <div className='dataDiv'>
                    <div className='row'>
                        <div className='big'> <p className='pFont'>Nombre: </p> <p className='pFontData'>{name}</p></div>
                        <div> <p className='pFont'>Marca: </p> <p className='pFontData'>{brand}</p></div>
                    </div>
                    <div> <p className='pFont'>Modelo: </p> <p className='pFontData'>{model}</p></div>
                    <div className='row'>
                        <div className='big'> <p className='pFont'>Serie: </p> <p className='pFontData'>{serie}</p></div>
                        <div> <p className='pFont'>Placa: </p> <p className='pFontData'>{plate}</p></div>
                    </div>
                    <div> <p className='pFont'>Ubicación: </p> <p className='pFontData'>{location}</p></div>
                </div>
            </div >
        </>
    )
}
