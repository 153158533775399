import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buttonsSider: [['Gestionar usuarios', 'boton'], ['Mi Trabajo', 'boton'], ['Gerente', 'boton'], ['Peticiones', 'boton'],
  ['Solicitado', 'boton'], ['Inventarios', 'boton'], ['Reportes', 'boton'], ['Ajustes', 'boton']],
  styleDash: 'boton',
  selectOptions: false,
};

const PagSlice = createSlice({
  name: "Pag",
  initialState,
  reducers: {
    setButtonsSider(state, { payload }) {
      state.buttonsSider = payload
    },
    setStyleDash(state, { payload }) {
      state.styleDash = payload
    },
    setSelectOptions(state, { payload }) {
      state.selectOptions = payload
    },
    getSelectOptions() {},
  },
});

const PagActions = PagSlice.actions;
const PagReducer = PagSlice.reducer;

export { PagActions, PagReducer };
