import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfPeripheralsAndAccessoriesEquipment = ({ fullDataEquipment }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    /* function convertNullStringsToEmpty(obj) {
        Object.keys(obj).forEach(key => {
          if (obj[key] === "null" || obj[key] === null){
            obj[key] = "";
          }
        });
      }
      
      convertNullStringsToEmpty(fullDataEquipment.peripheralsAndAccessoriesEquipment); */

      return (
        <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
            <View style={{ width: '100%', height: '3.7%', flexDirection: 'row', borderBottom: '3.2px solid #000' }}>
                {/* <View style={{ width: '20%', height: '100%', borderRight: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Image style={{ width: '60%', height: '60%' }} src={enterpriseImage[0].image}></Image></View> */}
                <View style={{ width: '80%', height: '100%', backgroundColor: '#008080', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>Ficha Tecnica de Inventarios</Text></View>
            </View>
            <View style={{ width: '100%', height: '2.4%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#008080', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS BÁSICOS</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Fecha de Registro</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{new Date(fullDataEquipment.createdDate).getDate() + "/" + (new Date(fullDataEquipment.createdDate).getMonth() + 1) + "/" + new Date(fullDataEquipment.createdDate).getFullYear()}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Nombre Sede</Text></View>
                    {/* <View style={{ width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{enterpriseImage[0].name} SEDE CENTRAL</Text></View> */}
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Ciudad</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>FLORENCIA</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Departamento</Text></View>
                    <View style={{ width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CAQUETA</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '12.5%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Dirección Sede</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '87.5%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}> </Text></View>
                </View>
            </View>

            <View style={{ marginTop: '0.7%', width: '100%', height: '2.4%', flexDirection: 'row', borderBottom: '3.2px solid #000', borderTop: '3.2px solid #000', backgroundColor: '#008080', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS DEL PERSONAL QUE REGISTRA EL EQUIPO</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Nombres y Apellidos del técnico</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.userCreator.person.name + " " + fullDataEquipment.userCreator.person.secondName + " " + fullDataEquipment.userCreator.person.lastName + " " + fullDataEquipment.userCreator.person.secondLastName + " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Tipo de Documento</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CEDULA</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Telefono Corporativo (Celular y fijo)</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.userCreator.person.phone + " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Numero de identificación</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.userCreator.person.cardId + " "}</Text></View>
                </View>
            </View>
            <View style={{ width: '100%', height: '2.4%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#008080', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>PARTE I: INFRAESTRUCTURA DISTRIBUIDA</Text>
            </View>
            <View style={{ width: '100%', height: '2.4%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#008080', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS DEL RESPONSABLE QUE RECIBIÓ EL EQUIPO</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Nombre y Apellidos del custodio</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.dependencyUser.length > 0 ? (fullDataEquipment.dependency.dependencyUser[0].user.person.name + " " + fullDataEquipment.dependency.dependencyUser[0].user.person.secondName + " " + fullDataEquipment.dependency.dependencyUser[0].user.person.lastName + " " + fullDataEquipment.dependency.dependencyUser[0].user.person.secondLastName + " ") : "No hay Jefe Asignado"}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Tipo de Documento:</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CEDULA</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Cargo:</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.dependencyUser.length > 0 ? (fullDataEquipment.dependency.dependencyUser[0].user.position + " ") : "No hay Jefe Asignado"}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Numero de identificación</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.dependencyUser.length > 0 ? (fullDataEquipment.dependency.dependencyUser[0].user.person.cardId + " ") : "No tiene Jefe Asignado"}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Télefono (celular y fijo)</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.dependencyUser.length > 0 ? (fullDataEquipment.dependency.dependencyUser[0].user.person.phone + " ") : "No tiene Jefe Asignado"}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Placa del equipo:</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.licensePlate}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '22.75%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Ubicación</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '77.25%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.location.name}</Text></View>
                </View>
            </View>
            {/* <View style={{ width: '100%', height: '2.4%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#008080', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS DE QUIEN ATIENDE LA VISITA</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Nombre y Apellidos del custodio</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CLAUDIA MARCELA SILVA RIOS</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Tipo de Documento:</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CEDULA</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Cargo:</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>JEFE DE DEPENDENCIA ODONTOLOGIA</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Numero de identificación</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>1111111111</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Télefono (celular y fijo)</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>43405321</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Placa del equipo:</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>7412</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                    <View style={{ backgroundColor: '#008080', borderRight: '2px solid #000', width: '22.75%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Ubicación</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '77.25%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CONSULTORIO 1</Text></View>
                </View>
            </View> */}
            <View style={{ marginTop: '2%', width: '100%', height: '2.4%', flexDirection: 'row', backgroundColor: '#008080', borderBottom: '2px solid #000' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Tipo</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Marca</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Modelo</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Serial</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Placa de Inventario</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '18%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Registro fotografico</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Tipo de equipo</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Conección</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '8%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Instalación compartida</Text></View>
            </View>
            <View style={{ width: '100%', height: '4.3%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.name + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.brand + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.modell + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.serie + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.licensePlate + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '18%' }}><Image style={{ width: '100%', height: '100%' }} src={fullDataEquipment.peripheralsAndAccessoriesEquipment.image}></Image></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.printerType ? fullDataEquipment.peripheralsAndAccessoriesEquipment.printerType :  " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.connection ? fullDataEquipment.peripheralsAndAccessoriesEquipment.connection :  " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '8%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.isInstalationShared ? "SI" : "NO"}</Text></View>
            </View>
            {fullDataEquipment.name==="UPS" && <View style={{ marginTop: '0.5%', width: '100%', height: '2%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#008080', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>UPS</Text>
            </View>}
            {fullDataEquipment.name==="UPS" && <View style={{ width: '100%', height: '2.4%', flexDirection: 'row', backgroundColor: '#008080', borderBottom: '2px solid #000', alignSelf: 'flex-start' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '33%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Numero de baterias</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '33%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Tiempo de respaldo</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '34%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Carga</Text></View>
            </View>}
            {fullDataEquipment.name==="UPS" && <View style={{ width: '100%', height: '4.3%', flexDirection: 'row', borderBottom: '2px solid #000', alignSelf: 'flex-start' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '33%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.batteryNumber ? fullDataEquipment.peripheralsAndAccessoriesEquipment.batteryNumber :  " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '33%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.backupTime ? fullDataEquipment.peripheralsAndAccessoriesEquipment.backupTime :  " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '34%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.load ? fullDataEquipment.peripheralsAndAccessoriesEquipment.load :  " "}</Text></View>
            </View>}


            <View style={{ marginTop: '0%', width: '100%', minHeigth: '50px', alignItems: 'flex-start', justifyContent: 'flex-start', border: '2px solid #000',borderTop:0 }}>
                <Text style={{ color: 'black', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder' }}>Observaciones:</Text>
                <Text style={{ color: 'black', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.peripheralsAndAccessoriesEquipment.observation + " "}</Text>
            </View>
            <View style={{ marginTop: '1%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Image style={{ width: '30%', height: '150%' }} src={fullDataEquipment.userCreator.person.firm ? fullDataEquipment.userCreator.person.firm : blanco}></Image>
            </View>
            <View style={{ marginTop: '3%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-3%' }}>
                <Text
                    style={{ fontSize: '20px', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-2.7%' }}>
                <Text
                    style={{ fontSize: '18px' }}>
                    {(fullDataEquipment.userCreator.person && fullDataEquipment.userCreator.person.firm) && fullDataEquipment.userCreator.person.name + " " +
                        fullDataEquipment.userCreator.person.secondName + " " +
                        fullDataEquipment.userCreator.person.lastName + " " +
                        fullDataEquipment.userCreator.person.secondLastName}
                </Text>
            </View>

            <View style={{ width: '95%', marginTop: '0%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '18px' }}>AGENTE QUE REALIZA EL REPORTE</Text>
            </View>
        </Page>
    )
}