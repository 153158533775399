import { Steps, Button, Radio, DatePicker, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../Input/Input'
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from "react-hook-form"
import photo from '../../assets/images/photo.png'

const PaperSection = ({ fieldName, name, watchVehicle, setValueVehicle, controlVehicle, registerVehicle, errorsVehicle }) => {
    return (
        <div className='papersSection'>
            <p className='papersName'>{name}</p>
            <Controller
                control={controlVehicle} // control viene de useForm()
                name={fieldName}
                rules={{
                    validate: (value) => {
                        const errors = [];
                        if (!value || value === '') {
                            errors.push("Es requerido elegir una opcion");
                        }
                        return errors.length === 0 || errors.join(', ');
                    },
                }}
                render={({ field }) => (
                    <Radio.Group
                        className='radioGroup'
                        style={{ marginLeft: '2vw' }}
                        {...field} // field contiene onChange, onBlur, name, y value
                        onChange={e => {
                            setValueVehicle(fieldName, e.target.value)
                            setValueVehicle(fieldName + 'BuyDate', "")
                            setValueVehicle(fieldName + 'ExpirationDate', "")
                            setValueVehicle(fieldName + 'Validity', "")
                        }}
                    >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                )}
            />
            <div style={{ visibility: watchVehicle(fieldName) ? 'visible' : 'hidden' }} className='divInputsPapers'>
                <div className='divDate'>
                    <p className='label'>Fecha de Compra</p>
                    <DatePicker
                        value={watchVehicle(fieldName + 'BuyDate')}
                        {...registerVehicle(fieldName + 'BuyDate', {
                        })}
                        style={{ width: '9vw', height: '2.4vw', marginRight: '0.7vw' }}
                        onChange={d => setValueVehicle(fieldName + 'BuyDate', d)}
                    />
                </div>
                <div className='divDate'>
                    <p className='label'>Fecha Inicio Vigencia</p>
                    <DatePicker
                        value={watchVehicle(fieldName + 'StartExpirationDate')}
                        {...registerVehicle(fieldName + 'StartExpirationDate', {
                        })}
                        style={{ width: '9vw', height: '2.4vw', marginRight: '0.7vw' }}
                        onChange={d => setValueVehicle(fieldName + 'StartExpirationDate', d)}
                    />
                </div>
                <div className='divDate'>
                    <p className='label'>Fecha Fin Vigencia</p>
                    <DatePicker
                        value={watchVehicle(fieldName + 'ExpirationDate')}
                        {...registerVehicle(fieldName + 'ExpirationDate', {
                        })}
                        style={{ width: '9vw', height: '2.4vw', marginRight: '0.7vw' }}
                        onChange={d => setValueVehicle(fieldName + 'ExpirationDate', d)}
                    />
                </div>
            </div>
        </div>
    );
};

const TireSection = ({ fieldName, name, watchVehicle, setValueVehicle, controlVehicle, registerVehicle, errorsVehicle }) => {
    return (
        <div className='tireSection'>
            <p className='tireName'>{name}</p>
            <Input
                {...registerVehicle(fieldName + 'Brand', {
                    validate: (value) => {
                        const errors = [];
                        if (!value || value === '') {
                            errors.push("La marca es requerida");
                        }
                        return errors.length === 0 || errors.join(', ');
                    },
                })}
                label={"Marca"}

                mt={'0.2vw'}
                width={'7vw'}
                height={'2.4vw'}
                labelstyle='inputLabelBordered'
                fontSize={'1vw'}
                errorFontSize={'0.7vw'}
                labelMB={'-0.1vw'}
                va={watchVehicle(fieldName + 'Brand')}
                setData={e => setValueVehicle(fieldName + 'Brand', e)}
                error={errorsVehicle[fieldName + 'Brand'] && errorsVehicle[fieldName + 'Brand'].message}
            />
            <Input
                {...registerVehicle(fieldName + 'Reference', {
                    validate: (value) => {
                        const errors = [];
                        if (!value || value === '') {
                            errors.push("La referencia es requerida");
                        }
                        return errors.length === 0 || errors.join(', ');
                    },
                })}
                label={"Referencia"}
                mr={"1.3vw"}
                mt={'0.2vw'}
                width={'7vw'}
                height={'2.4vw'}
                labelstyle='inputLabelBordered'
                fontSize={'1vw'}
                errorFontSize={'0.7vw'}
                labelMB={'-0.1vw'}
                va={watchVehicle(fieldName + 'Reference')}
                setData={e => setValueVehicle(fieldName + 'Reference', e)}
                error={errorsVehicle[fieldName + 'Reference'] && errorsVehicle[fieldName + 'Reference'].message}
            />
        </div>
    )
};

const Accessory = ({ category, options, watchVehicle, setValueVehicle, controlVehicle, registerVehicle, errorsVehicle }) => {
    return (
        <div className='accessory'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', visibility: category ? "visible" : "hidden", marginBottom: category ? '0' : '1.2vw' }}>
                <p className='category' style={{ fontSize: category && (category.length <= 19 ? '0.8vw' : category.length <= 24 ? '0.7vw' : '0.6vw') }}>{category}</p>
            </div>
            <div className='divColumnsNames'>
                <p className='columnName'>Descripción:</p>
                <p className='columnName'>Estado:</p>
                <p className='columnName'>Cantidad:</p>
            </div>
            <div className='divOptions'>
                {options.map((option, index) => (
                    <div className='option' key={index}>
                        <p className='nameOption'>{option.name}</p>
                        <Controller
                            control={controlVehicle} // control viene de useForm()
                            name={option.fieldName + "Status"}
                            rules={{
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("Es requerido elegir una opcion");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            }}
                            render={({ field }) => (
                                <Radio.Group
                                    className='radioGroup'
                                    {...field}
                                    onChange={e => {
                                        setValueVehicle(option.fieldName + "Status", e.target.value)
                                    }}
                                >
                                    <Radio value={"GOOD"}>Bueno</Radio>
                                    <Radio value={"REGULAR"}>Regular</Radio>
                                    <Radio value={"BAD"}>Malo</Radio>
                                </Radio.Group>
                            )}
                        />
                        <Input
                            {...registerVehicle(option.fieldName + "Quantity", {
                            })}
                            label={"none"}
                            width={'3.2vw'}
                            height={'2vw'}
                            labelstyle='inputLabelBordered'
                            fontSize={'1vw'}
                            errorFontSize={'0.7vw'}
                            labelMB={'-0.1vw'}
                            type={"number"}
                            va={watchVehicle(option.fieldName + "Quantity")}
                            setData={e => {
                                setValueVehicle(option.fieldName + "Quantity", e)
                                if (Number(e) === 0) {
                                    setValueVehicle(option.fieldName + "Status", null)
                                }
                            }}
                            error={errorsVehicle[option.fieldName + "Quantity"] && errorsVehicle[option.fieldName + "Quantity"].message}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
};

const VehicleFaces = ({ options, watchVehicle, setValueVehicle, controlVehicle, registerVehicle, errorsVehicle }) => {

    useEffect(() => {
        options.forEach(option => {
            const img = new Image();
            img.onload = () => {
                const canvas = option.canvas.current;
                canvas.width = 350
                canvas.height = 280
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, 350, 280);
            };
            img.src = option.image;
        });
    }, [options]);

    return (
        <div className='accessory'>
            <div className='divColumnsNames faces'>
                <p className='columnName'>Descripción:</p>
                <p className='columnName' style={{ marginRight: '4.5vw' }}>Estado:</p>
                <p className='columnName' > </p>
            </div>
            <div className='divOptions'>
                {options.map((option, index) => (
                    <div className='option' key={index}>
                        <p className='nameOption'>{option.name}</p>
                        <Controller
                            control={controlVehicle} // control viene de useForm()
                            name={option.fieldName + "StatusFace"}
                            rules={{
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("Es requerido elegir una opcion");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            }}
                            render={({ field }) => (
                                <Radio.Group
                                    className='radioGroup'
                                    {...field}
                                    onChange={e => {
                                        setValueVehicle(option.fieldName + "StatusFace", e.target.value)
                                    }}
                                >
                                    <Radio value={"GOOD"}>Bueno</Radio>
                                    <Radio value={"REGULAR"}>Regular</Radio>
                                    <Radio value={"BAD"}>Malo</Radio>
                                </Radio.Group>
                            )}
                        />
                        <canvas ref={option.canvas} className='canvasFace'> </canvas>
                    </div>
                ))}
            </div>
        </div>
    )
}

export function useStepsVehicle(
    registerVehicle,
    handleSubmitVehicle,
    setValueVehicle,
    watchVehicle,
    controlVehicle,
    errorsVehicle,
    triggerVehicle,
) {

    const dispatch = useDispatch()
    const { equipmentsSearched } = useSelector(state => state.Equipment)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [compInfo, setCompInfo] = useState([])
    const frontalCanvas = useRef(null)
    const backCanvas = useRef(null)
    const rightCanvas = useRef(null)
    const leftCanvas = useRef(null)
    const aboveCanvas = useRef(null)
    const transitLicenseCanvas = useRef(null)
    const soatCanvas = useRef(null)
    const policyCanvas = useRef(null)
    const mechaTechReviewCanvas = useRef(null)

    const [frontalImage, setFrontalImage] = useState(photo)
    const [backImage, setBackImage] = useState(photo)
    const [leftImage, setLeftImage] = useState(photo)
    const [rightImage, setRightImage] = useState(photo)
    const [aboveImage, setAboveImage] = useState(photo)
    const [transitLicenseImage, setTransitLicenseImage] = useState(photo)
    const [soatImage, setSoatImage] = useState(photo)
    const [policyImage, setPolicyImage] = useState(photo)
    const [mechaTechReviewImage, setMechaTechReviewImage] = useState(photo)
    const { Option } = Select;

    const { departments, municipalitys } = useSelector(state => state.DepMun)

    const uploadImage = (e, imageType, width, height) => {
        const canvass = {
            "frontalImage": frontalCanvas,
            "backImage": backCanvas,
            "leftImage": leftCanvas,
            "rightImage": rightCanvas,
            "aboveImage": aboveCanvas,
            "transitLicenseImage": transitLicenseCanvas,
            "SOATImage": soatCanvas,
            "policyImage": policyCanvas,
            "mechaTechReviewImage": mechaTechReviewCanvas
        }
        const setState = {
            "frontalImage": setFrontalImage,
            "backImage": setBackImage,
            "leftImage": setLeftImage,
            "rightImage": setRightImage,
            "aboveImage": setAboveImage,
            "transitLicenseImage": setTransitLicenseImage,
            "SOATImage": setSoatImage,
            "policyImage": setPolicyImage,
            "mechaTechReviewImage": setMechaTechReviewImage
        }

        const setImage = setState[imageType];
        const canvas = canvass[imageType];

        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = width
        canvas.current.height = height

        img.onload = function () {
            ctx.drawImage(img, 0, 0, width, height)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueVehicle(imageType, dataImg) }, 1000)
    };

    const StepsVehicle = [
        {
            title: 1,
            content:
                <div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <div style={{ width: '31%' }}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen de frente del vehiculo</p>
                                <Controller
                                    name="frontalImage"
                                    control={controlVehicle}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen frontal es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={frontalCanvas} className='canvas'> </canvas>
                                            <p>Imagen frontal</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setFrontalImage(photo)
                                                        setValueVehicle('frontalImage', undefined)
                                                        var ctx = frontalCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        frontalCanvas.current.width = 350
                                                        frontalCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = frontalCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'frontalImage', 350, 280);
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsVehicle.frontalImage && <p className='errorMessage'>{errorsVehicle.frontalImage.message}</p>}
                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen trasera del vehiculo</p>
                                <Controller
                                    name="backImage"
                                    control={controlVehicle}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen trasera es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={backCanvas} className='canvas'> </canvas>
                                            <p>Imagen trasera</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setBackImage(photo)
                                                        setValueVehicle('backImage', undefined)
                                                        var ctx = backCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        backCanvas.current.width = 350
                                                        backCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = backCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'backImage', 350, 280);
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsVehicle.backImage && <p className='errorMessage'>{errorsVehicle.backImage.message}</p>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '68%', flexDirection: 'column', marginLeft: '1%' }}>
                            <p className='titleSection'>Datos del vehiculo</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw', boxShadow: '0px 4px 4px 0px #00000040', paddingLeft: '1vw', paddingRight: '1vw' }}>
                                <div style={{ display: 'flex', gap: '1vw' }}>
                                    <Input
                                        {...registerVehicle('licensePlate', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La placa de inventario es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Placa Inventario"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('licensePlate')}
                                        setData={e => setValueVehicle('licensePlate', e)}
                                        error={errorsVehicle.licensePlate && errorsVehicle.licensePlate.message}
                                    />
                                    <Input
                                        {...registerVehicle('class', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La clase es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Clase"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('class')}
                                        setData={e => setValueVehicle('class', e)}
                                        error={errorsVehicle.class && errorsVehicle.class.message}
                                    />
                                    <Input
                                        {...registerVehicle('color', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El color es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Color"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('color')}
                                        setData={e => setValueVehicle('color', e)}
                                        error={errorsVehicle.color && errorsVehicle.color.message}
                                    />
                                    <div className='inputDiv'>
                                        <p className='label'>Combustible</p>
                                        <Controller
                                            name="fuel"
                                            control={controlVehicle}
                                            className="select"
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={watchVehicle('fuel')}
                                                    className="select"
                                                >
                                                    <Option value={"Gasolina"}>Gasolina</Option>
                                                    <Option value={"ACPM"}>ACPM</Option>
                                                </Select>
                                            )}
                                        />
                                    </div>
                                    <Input
                                        {...registerVehicle('vehiclePlate', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Placas del vehiculo");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Placas del Vehiculo"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('vehiclePlate')}
                                        setData={e => setValueVehicle('vehiclePlate', e)}
                                        error={errorsVehicle.vehiclePlate && errorsVehicle.vehiclePlate.message}
                                    />
                                </div>
                                <div style={{ display: 'flex', gap: '1vw' }}>
                                    <Input
                                        {...registerVehicle('cylinders', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Cilindros es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Cilindros"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('cylinders')}
                                        setData={e => setValueVehicle('cylinders', e)}
                                        error={errorsVehicle.cylinders && errorsVehicle.cylinders.message}
                                    />
                                    <Input
                                        {...registerVehicle('engineNumber', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El numero de motor es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Numero de Motor"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('engineNumber')}
                                        setData={e => setValueVehicle('engineNumber', e)}
                                        error={errorsVehicle.engineNumber && errorsVehicle.engineNumber.message}
                                    />
                                    <Input
                                        {...registerVehicle('numberSerie', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El numero de serie es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Numero De Serie"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('numberSerie')}
                                        setData={e => setValueVehicle('numberSerie', e)}
                                        error={errorsVehicle.numberSerie && errorsVehicle.numberSerie.message}
                                    />
                                    <Input
                                        {...registerVehicle('chassisNumber', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El numero de chasis es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Numero De Chasis"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('chassisNumber')}
                                        setData={e => setValueVehicle('chassisNumber', e)}
                                        error={errorsVehicle.chassisNumber && errorsVehicle.chassisNumber.message}
                                    />
                                    <div className='inputDiv'>
                                        <p className='label'>Marca</p>
                                        <Controller
                                            name="brand"
                                            control={controlVehicle}
                                            className="select"
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={watchVehicle('brand')}
                                                    className="select"
                                                >
                                                    <Option value={"Toyota"}>Toyota</Option>
                                                    <Option value={"Chevrolet"}>Chevrolet</Option>
                                                    <Option value={"Mazda"}>Mazda</Option>
                                                    <Option value={"Renault"}>Renault</Option>
                                                    <Option value={"Nissan"}>Nissan</Option>
                                                    <Option value={"Kia"}>Kia</Option>
                                                    <Option value={"Hyundai"}>Hyundai</Option>
                                                    <Option value={"Suzuki"}>Suzuki</Option>
                                                    <Option value={"Honda"}>Honda</Option>
                                                    <Option value={"Yamaha"}>Yamaha</Option>
                                                    <Option value={"Bajaj"}>Bajaj</Option>
                                                    <Option value={"Otra"}>Otra</Option>
                                                </Select>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: '1vw' }}>
                                    <Input
                                        {...registerVehicle('line', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La linea es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Linea"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('line')}
                                        setData={e => setValueVehicle('line', e)}
                                        error={errorsVehicle.line && errorsVehicle.line.message}
                                    />
                                    <Input
                                        {...registerVehicle('modell', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El modelo es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Modelo"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('modell')}
                                        setData={e => setValueVehicle('modell', e)}
                                        error={errorsVehicle.modell && errorsVehicle.modell.message}
                                    />
                                    <Input
                                        {...registerVehicle('type', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El tipo es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Tipo"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchVehicle('type')}
                                        setData={e => setValueVehicle('type', e)}
                                        error={errorsVehicle.type && errorsVehicle.type.message}
                                    />
                                </div>
                                <p className='titleSection'>Documentos del vehiculo</p>
                                <div className='papersSection'>
                                    <p className='papersName'>Licencia de Transito del Vehiculo</p>
                                    <Radio.Group
                                        className='radioGroup'
                                        {...registerVehicle('transitLicense', {
                                        })}
                                        style={{ marginLeft: '2vw' }}
                                        value={watchVehicle('transitLicense')}
                                        onChange={e => {
                                            setValueVehicle('transitLicense', e.target.value)
                                            setValueVehicle('transitLicenseBuyDate', "")
                                            setValueVehicle('transitLicenseRegistrationDate', "")
                                            setValueVehicle('numberLicense', "")

                                        }}>
                                        <Radio value={true}>Si</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                    <div style={{ visibility: watchVehicle('transitLicense') ? 'visible' : 'hidden' }} className='divInputsPapers'>
                                        <div className='divDate'>
                                            <p className='label'>Fecha de Compra</p>
                                            <DatePicker
                                                value={watchVehicle('transitLicenseBuyDate')}
                                                {...registerVehicle('transitLicenseBuyDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueVehicle('transitLicenseBuyDate', d)}
                                            />
                                        </div>
                                        <div className='divDate'>
                                            <p className='label'>Fecha de Matricula</p>
                                            <DatePicker
                                                value={watchVehicle('transitLicenseRegistrationDate')}
                                                {...registerVehicle('transitLicenseRegistrationDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueVehicle('transitLicenseRegistrationDate', d)}
                                            />
                                        </div>
                                        <Input
                                            {...registerVehicle('numberLicense', {
                                            })}
                                            label={"Numero de licencia"}

                                            mt={'0.2vw'}
                                            width={'8vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            errorFontSize={'0.7vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchVehicle('numberLicense')}
                                            setData={e => setValueVehicle('numberLicense', e)}
                                            error={errorsVehicle.numberLicense && errorsVehicle.numberLicense.message}
                                        />
                                    </div>
                                </div>
                                <PaperSection fieldName='SOAT' name='Seguro Obligatorio de Accidentes de Transito SOAT' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />
                                <PaperSection fieldName='policy' name='Póliza de Responsabilidad Civil Extracontractual' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />
                                <PaperSection fieldName='mechaTechReview' name='Certificado de Revisión Técnico Mecánica y de Gases' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />

                                <p className='titleSection'>Llantas</p>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <TireSection fieldName='tireFrontalRight' name='Llanta Delantera Derecha' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />
                                    <TireSection fieldName='tireFrontalLeft' name='Llanta Delantera Izquierda' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />
                                    <TireSection fieldName='tireBackRight' name='Llanta Trasera Derecha' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />
                                    <TireSection fieldName='tireBackLeft' name='Llanta Trasera Izquierda' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />
                                    <TireSection fieldName='tireSpare' name='Llanta Repuesto' watchVehicle={watchVehicle} registerVehicle={registerVehicle} setValueVehicle={setValueVehicle} errorsVehicle={errorsVehicle} controlVehicle={controlVehicle} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        },
        {
            title: 2,
            content:
                <div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <div style={{ width: '31%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen Derecha del vehiculo</p>
                                <Controller
                                    name="rightImage"
                                    control={controlVehicle}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen derecha es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={rightCanvas} className='canvas'> </canvas>
                                            <p>Imagen Derecha</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setRightImage(photo)
                                                        setValueVehicle('rightImage', undefined)
                                                        var ctx = rightCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        rightCanvas.current.width = 350
                                                        rightCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = rightCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'rightImage', 350, 280);
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsVehicle.rightImage && <p className='errorMessage'>{errorsVehicle.rightImage.message}</p>}
                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen Izquierda del vehiculo</p>
                                <Controller
                                    name="leftImage"
                                    control={controlVehicle}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen izquierda es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={leftCanvas} className='canvas'> </canvas>
                                            <p>Imagen Izquierda</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setLeftImage(photo)
                                                        setValueVehicle('leftImage', undefined)
                                                        var ctx = leftCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        leftCanvas.current.width = 350
                                                        leftCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = leftCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'leftImage', 350, 280);
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsVehicle.leftImage && <p className='errorMessage'>{errorsVehicle.leftImage.message}</p>}
                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen arriba del vehiculo</p>
                                <Controller
                                    name="aboveImage"
                                    control={controlVehicle}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen arriba es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={aboveCanvas} className='canvas'> </canvas>
                                            <p>Imagen Arriba</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setAboveImage(photo)
                                                        setValueVehicle('aboveImage', undefined)
                                                        var ctx = aboveCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        aboveCanvas.current.width = 350
                                                        aboveCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = aboveCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'aboveImage', 350, 280);
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsVehicle.aboveImage && <p className='errorMessage'>{errorsVehicle.aboveImage.message}</p>}
                            </div>
                            <p className='titleSection'>Cargue Documentos del Vehiculo</p>
                            {watchVehicle("transitLicense") &&
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                    <p className='titleSection'>LICENCIA DE TRANSITO DEL VEHICULO</p>
                                    <Controller
                                        name="transitLicenseImage"
                                        control={controlVehicle}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === photo) {
                                                    errors.push("La imagen de la licencia de transito es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            }
                                        }}
                                        render={({ field }) => (
                                            <div className='imageDiv'>
                                                <canvas ref={transitLicenseCanvas} className='canvas'> </canvas>
                                                <p>Imagen Licencia de Transito</p>
                                                <div className='imageButtons'>
                                                    <Button
                                                        onClick={() => {
                                                            setTransitLicenseImage(photo)
                                                            setValueVehicle('transitLicenseImage', undefined)
                                                            var ctx = rightCanvas.current.getContext('2d')
                                                            var img = new Image()
                                                            transitLicenseCanvas.current.width = 350
                                                            transitLicenseCanvas.current.height = 280
                                                            img.onload = function () {
                                                                ctx.drawImage(img, 0, 0, 350, 280)
                                                            }
                                                            var dataImg;
                                                            setTimeout(function () { dataImg = transitLicenseCanvas.current.toDataURL() }, 1000)
                                                            img.src = photo
                                                        }}
                                                        style={{
                                                            backgroundColor: '#ffff',
                                                            color: '#C4C4C4',
                                                            borderRadius: '10px',
                                                            fontSize: '1vw'
                                                        }}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <label className="custom-file-upload">
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            onClick={(event) => { event.target.value = null }}
                                                            onChange={e => {
                                                                uploadImage(e, 'transitLicenseImage', 630, 440);
                                                            }}
                                                            type='file'
                                                        ></input>
                                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errorsVehicle.transitLicenseImage && <p className='errorMessage'>{errorsVehicle.transitLicenseImage.message}</p>}
                                </div>}
                            {watchVehicle("SOAT") &&
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                    <p className='titleSection'>SEGURO OBLIGATORIO DE ACCIDENTES DE TRANSITO SOAT</p>
                                    <Controller
                                        name="SOATImage"
                                        control={controlVehicle}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === photo) {
                                                    errors.push("La imagen del SOAT es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            }
                                        }}
                                        render={({ field }) => (
                                            <div className='imageDiv'>
                                                <canvas ref={soatCanvas} className='canvas'> </canvas>
                                                <p>Imagen SOAT</p>
                                                <div className='imageButtons'>
                                                    <Button
                                                        onClick={() => {
                                                            setSoatImage(photo)
                                                            setValueVehicle('soat', undefined)
                                                            var ctx = rightCanvas.current.getContext('2d')
                                                            var img = new Image()
                                                            soatCanvas.current.width = 350
                                                            soatCanvas.current.height = 280
                                                            img.onload = function () {
                                                                ctx.drawImage(img, 0, 0, 350, 280)
                                                            }
                                                            var dataImg;
                                                            setTimeout(function () { dataImg = soatCanvas.current.toDataURL() }, 1000)
                                                            img.src = photo
                                                        }}
                                                        style={{
                                                            backgroundColor: '#ffff',
                                                            color: '#C4C4C4',
                                                            borderRadius: '10px',
                                                            fontSize: '1vw'
                                                        }}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <label className="custom-file-upload">
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            onClick={(event) => { event.target.value = null }}
                                                            onChange={e => {
                                                                uploadImage(e, 'SOATImage', 630, 440);
                                                            }}
                                                            type='file'
                                                        ></input>
                                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errorsVehicle.SOATImage && <p className='errorMessage'>{errorsVehicle.SOATImage.message}</p>}
                                </div>
                            }
                            {watchVehicle("policy") &&
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                    <p className='titleSection'>POLIZA DE RESPONSABILIDAD CIVIL Y CONTRACTUAL</p>
                                    <Controller
                                        name="policyImage"
                                        control={controlVehicle}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === photo) {
                                                    errors.push("La imagen del SOAT es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            }
                                        }}
                                        render={({ field }) => (
                                            <div className='imageDiv'>
                                                <canvas ref={policyCanvas} className='canvas'> </canvas>
                                                <p>Imagen Poliza de Responsabilidad Civil y Contractual</p>
                                                <div className='imageButtons'>
                                                    <Button
                                                        onClick={() => {
                                                            setSoatImage(photo)
                                                            setValueVehicle('policyImage', undefined)
                                                            var ctx = rightCanvas.current.getContext('2d')
                                                            var img = new Image()
                                                            policyCanvas.current.width = 350
                                                            policyCanvas.current.height = 280
                                                            img.onload = function () {
                                                                ctx.drawImage(img, 0, 0, 350, 280)
                                                            }
                                                            var dataImg;
                                                            setTimeout(function () { dataImg = policyCanvas.current.toDataURL() }, 1000)
                                                            img.src = photo
                                                        }}
                                                        style={{
                                                            backgroundColor: '#ffff',
                                                            color: '#C4C4C4',
                                                            borderRadius: '10px',
                                                            fontSize: '1vw'
                                                        }}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <label className="custom-file-upload">
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            onClick={(event) => { event.target.value = null }}
                                                            onChange={e => {
                                                                uploadImage(e, 'policyImage', 630, 440);
                                                            }}
                                                            type='file'
                                                        ></input>
                                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errorsVehicle.policyImage && <p className='errorMessage'>{errorsVehicle.policyImage.message}</p>}
                                </div>
                            }
                            {watchVehicle("mechaTechReview") &&
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                    <p className='titleSection'>CERTFICADO DE REVISION TECNICO MECANICA Y DE GASES</p>
                                    <Controller
                                        name="mechaTechReviewImage"
                                        control={controlVehicle}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === photo) {
                                                    errors.push("La imagen de la Revisión Tecnico Mecanica es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            }
                                        }}
                                        render={({ field }) => (
                                            <div className='imageDiv'>
                                                <canvas ref={mechaTechReviewCanvas} className='canvas'> </canvas>
                                                <p>Imagen Revisión Tecnico Mecanica</p>
                                                <div className='imageButtons'>
                                                    <Button
                                                        onClick={() => {
                                                            setSoatImage(photo)
                                                            setValueVehicle('mechaTechReviewImage', undefined)
                                                            var ctx = rightCanvas.current.getContext('2d')
                                                            var img = new Image()
                                                            mechaTechReviewCanvas.current.width = 350
                                                            mechaTechReviewCanvas.current.height = 280
                                                            img.onload = function () {
                                                                ctx.drawImage(img, 0, 0, 350, 280)
                                                            }
                                                            var dataImg;
                                                            setTimeout(function () { dataImg = mechaTechReviewCanvas.current.toDataURL() }, 1000)
                                                            img.src = photo
                                                        }}
                                                        style={{
                                                            backgroundColor: '#ffff',
                                                            color: '#C4C4C4',
                                                            borderRadius: '10px',
                                                            fontSize: '1vw'
                                                        }}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <label className="custom-file-upload">
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            onClick={(event) => { event.target.value = null }}
                                                            onChange={e => {
                                                                uploadImage(e, 'mechaTechReviewImage', 630, 440);
                                                            }}
                                                            type='file'
                                                        ></input>
                                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errorsVehicle.mechaTechReviewImage && <p className='errorMessage'>{errorsVehicle.mechaTechReviewImage.message}</p>}
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex', width: '68%', flexDirection: 'column', marginLeft: '1%' }}>
                            <p className='titleSection'>Partes y Accesorios</p>
                            <div className='divAccessories'>
                                <div className='accessorySection'>
                                    <p className='text'>Puede seleccionar marcar el estado y cantidad de manera Total con un clic para todas las partes y accesorios del vehículo.</p>
                                </div>
                                <div className='accessorySection'>
                                    <div className='accessory yellow'>
                                        <div className='divColumnsNames'>
                                            <p className='columnName'>Descripción:</p>
                                            <p className='columnName'>Estado:</p>
                                            <p className='columnName'>Cantidad:</p>
                                        </div>
                                        <div className='divOptions'>
                                            <div className='option'>
                                                <p className='nameOption'>Marcar Todo: </p>
                                                <Radio.Group
                                                    className='radioGroup'
                                                    onChange={e => {
                                                        let aux = watchVehicle()
                                                        let options = Object.keys(aux).filter(key => key.endsWith('Status'))
                                                        options.forEach(option => {
                                                            setValueVehicle(option, e.target.value)
                                                        })
                                                    }}
                                                >
                                                    <Radio value={"GOOD"}>Bueno</Radio>
                                                    <Radio value={"REGULAR"}>Regular</Radio>
                                                    <Radio value={"BAD"}>Malo</Radio>
                                                </Radio.Group>
                                                <Input
                                                    label={"none"}
                                                    width={'3.2vw'}
                                                    height={'2vw'}
                                                    labelstyle='inputLabelBordered'
                                                    fontSize={'1vw'}
                                                    errorFontSize={'0.7vw'}
                                                    labelMB={'-0.1vw'}
                                                    type={"number"}
                                                    setData={e => {
                                                        //find all the options and set the value
                                                        let aux = watchVehicle()
                                                        let options = Object.keys(aux).filter(key => key.endsWith('Quantity'))
                                                        let optionsStatus = Object.keys(aux).filter(key => key.endsWith('Status'))
                                                        if (Number(e) === 0) {
                                                            optionsStatus.forEach(option => {
                                                                setValueVehicle(option, 0)
                                                            })
                                                        }
                                                        options.forEach(option => {
                                                            setValueVehicle(option, e)
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Frente Exterior'}
                                        options={[
                                            { name: 'Emblemas: ', fieldName: 'emblemsFront' },
                                            { name: 'Defensa Delantera: ', fieldName: 'frontBumper' },
                                            { name: 'Unidades: ', fieldName: 'units' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Persianas: ', fieldName: 'blind' },
                                            { name: 'Cocuyos: ', fieldName: 'cocuyo' },
                                            { name: 'Direccionales: ', fieldName: 'directionalFront' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Interior del Motor'}
                                        options={[
                                            { name: 'Bateria Marca: ', fieldName: 'brandBattery' },
                                            { name: 'Tapa Radiador: ', fieldName: 'radiatorCover' },
                                            { name: 'Tapa Aceite: ', fieldName: 'oilCap' },
                                            { name: 'Varilla Medidora de Aceite: ', fieldName: 'oilDipstick' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Correas de Ventilador: ', fieldName: 'fanBelts' },
                                            { name: 'Pitos: ', fieldName: 'horn' },
                                            { name: 'Sirenas: ', fieldName: 'mermaids' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Frente Superior'}
                                        options={[
                                            { name: 'Vidrio Panoramico: ', fieldName: 'panoramicGlass' },
                                            { name: 'Brazos Limpia Brisas: ', fieldName: 'wiperArms' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Cuchillas Limpia Brisas: ', fieldName: 'wiperBlades' },
                                            { name: 'Antena Radio: ', fieldName: 'radioAntenna' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Costado Izquierdo'}
                                        options={[
                                            { name: 'Vidrios Laterales: ', fieldName: 'sideWindowsLeft' },
                                            { name: 'Manija: ', fieldName: 'handleLeft' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Cerraduras: ', fieldName: 'locksLeft' },
                                            { name: 'Copas Ruedas: ', fieldName: 'wheelCupsLeft' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Estribos'}
                                        options={[
                                            { name: 'Derecho: ', fieldName: 'stirrupsRight' },
                                            { name: 'Izquierdo: ', fieldName: 'stirrupsLeft' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>

                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Costado Trasero'}
                                        options={[
                                            { name: 'Emblemas: ', fieldName: 'emblemsBack' },
                                            { name: 'Defensa Trasera: ', fieldName: 'backBumper' },
                                            { name: 'Tercer Stop: ', fieldName: 'thirdStop' },
                                            { name: 'Vidrios Traseros: ', fieldName: 'rearGlass' },
                                            { name: 'Tapa Tanque Gasolina: ', fieldName: 'fuelTankCap' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Stops Frenos: ', fieldName: 'stopsBrakes' },
                                            { name: 'Luces de Parqueo: ', fieldName: 'parkingLights' },
                                            { name: 'Direccionales: ', fieldName: 'directionalBack' },
                                            { name: 'Reversos: ', fieldName: 'reverses' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Costado Derecho'}
                                        options={[
                                            { name: 'Vidrios Laterales: ', fieldName: 'sideWindowsRight' },
                                            { name: 'Manija: ', fieldName: 'handleRight' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Cerraduras: ', fieldName: 'locksRight' },
                                            { name: 'Copas Ruedas: ', fieldName: 'wheelCupsRight' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Llaves'}
                                        options={[
                                            { name: 'Puertas: ', fieldName: 'doorKeys' },
                                            { name: 'Baúl: ', fieldName: 'trunkKeys' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Ignición: ', fieldName: 'ignitionKeys' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Interior del Vehículo'}
                                        options={[
                                            { name: 'Consola: ', fieldName: 'console' },
                                            { name: 'Radio Marca: ', fieldName: 'radioBrand' },
                                            { name: 'Guantera: ', fieldName: 'gloveBox' },
                                            { name: 'Seguro Puerta: ', fieldName: 'doorLock' },
                                            { name: 'Manija Puerta: ', fieldName: 'handleDoor' },
                                            { name: 'Manija Vidrio: ', fieldName: 'handleGlass' },
                                            { name: 'Luz Interior: ', fieldName: 'interiorLight' },
                                            { name: 'Cojinería: ', fieldName: 'cushions' },
                                            { name: 'Intercomunicador: ', fieldName: 'intercom' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Forros: ', fieldName: 'linings' },
                                            { name: 'Tapetes: ', fieldName: 'mats' },
                                            { name: 'Cenicero: ', fieldName: 'ashtray' },
                                            { name: 'Descansa Brazos: ', fieldName: 'armRest' },
                                            { name: 'Stops Frenos: ', fieldName: 'stopsBrakesInside' },
                                            { name: 'Luces de Parqueo: ', fieldName: 'parkingLightsInside' },
                                            { name: 'Descansa cabezas: ', fieldName: 'headRest' },
                                            { name: 'Radio Teléfono: ', fieldName: 'walkyTalky' },
                                            { name: 'Espejo Retrovisor: ', fieldName: 'rear-viewMirror' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Tablero de Controles'}
                                        options={[
                                            { name: 'Switch Ignición: ', fieldName: 'ignitionSwitch' },
                                            { name: 'Interruptor Luces Delanteras: ', fieldName: 'headlightSwitch' },
                                            { name: 'Pito: ', fieldName: 'hornInside' },
                                            { name: 'Calefacción: ', fieldName: 'heating' },
                                            { name: 'Encendedor Cigarrillos: ', fieldName: 'cigarreteLighter' },
                                            { name: 'Medidor de Gasolina: ', fieldName: 'fuelGauge' },
                                            { name: 'Medidor de Aceite: ', fieldName: 'oilMeter' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Interruptor Luces Parqueo: ', fieldName: 'parkinglightSwitch' },
                                            { name: 'Direccionales: ', fieldName: 'directionalInside' },
                                            { name: 'Sirena: ', fieldName: 'mermaidsInside' },
                                            { name: 'Tacómetro: ', fieldName: 'tachometer' },
                                            { name: 'Velocímetro: ', fieldName: 'speedometer' },
                                            { name: 'Medidor de Temperatura: ', fieldName: 'temperatureMeter' },
                                            { name: 'Baliza: ', fieldName: 'beacon' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Herramientas'}
                                        options={[
                                            { name: 'Gato: ', fieldName: 'hydraulicJack' },
                                            { name: 'Crucetas: ', fieldName: 'crossheads' },
                                            { name: 'Pinzas: ', fieldName: 'tweezers' },
                                            { name: 'Palancas: ', fieldName: 'levers' },
                                            { name: 'Destornilladores: ', fieldName: 'screwdrivers' },
                                            { name: 'Estrellas: ', fieldName: 'startTool' },
                                            { name: 'Llaves fijas: ', fieldName: 'fixedKeys' },
                                            { name: 'Llaves de Expansión: ', fieldName: 'expansionKeys' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Mixtas: ', fieldName: 'mixed' },
                                            { name: 'Alicate: ', fieldName: 'pliers' },
                                            { name: 'Hombresolo: ', fieldName: 'lockingPliers' },
                                            { name: 'Bujías: ', fieldName: 'plugs' },
                                            { name: 'Lampara (Pilas o Conexión): ', fieldName: 'lamp' },
                                            { name: 'Cable de Iniciar: ', fieldName: 'startCable' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Señales de advertencia de peligro'}
                                        options={[
                                            { name: 'Triangulos: ', fieldName: 'triangles' },
                                            { name: 'Lamparas de Luz Intermitente: ', fieldName: 'flashinglightLamp' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Extintor: ', fieldName: 'extinguisher' },
                                            { name: 'Tacos para Bloquear Vehiculo: ', fieldName: 'blocks' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        category={'Otros'}
                                        options={[
                                            { name: 'Microchip de Tanqueo: ', fieldName: 'tankMicrochip' },
                                            { name: 'Manual de Vehiculo: ', fieldName: 'vehicleManual' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Botiquín: ', fieldName: 'firstaidKit' },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        },
        {
            title: 3,
            content:
                <div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <div style={{ width: '31%', display: 'flex', flexDirection: 'column' }}>

                        </div>
                        <div style={{ display: 'flex', width: '68%', flexDirection: 'column', marginLeft: '1%' }}>
                            <p className='titleSection'>Verificación de las caras del vehiculo</p>
                            <div className='divAccessories'>
                                <div className='accessorySection'>
                                    <VehicleFaces
                                        options={[
                                            { name: 'Frente del Vehiculo', fieldName: 'frontalImageFace', image: watchVehicle('frontalImage'), canvas: frontalCanvas },
                                            { name: 'Lado Izquierdo del Vehiculo', fieldName: 'leftImageFace', image: watchVehicle('leftImage'), canvas: leftCanvas },
                                            { name: 'Arriba del Vehiculo', fieldName: 'aboveImageFace', image: watchVehicle('aboveImage'), canvas: aboveCanvas },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}

                                    />
                                </div>
                                <div className='accessorySection'>
                                    <VehicleFaces
                                        options={[
                                            { name: 'Atras del Vehiculo', fieldName: 'backImageFace', image: watchVehicle('backImage'), canvas: backCanvas },
                                            { name: 'Lado Derecho del Vehiculo', fieldName: 'rightImageFace', image: watchVehicle('rightImage'), canvas: rightCanvas },
                                        ]}
                                        watchVehicle={watchVehicle}
                                        registerVehicle={registerVehicle}
                                        setValueVehicle={setValueVehicle}
                                        errorsVehicle={errorsVehicle}
                                        controlVehicle={controlVehicle}
                                    />
                                </div>
                            </div>
                            <Input
                                {...registerVehicle('observation', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("La placa de inventario es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Observación"}

                                mt={"2vw"}
                                width={'100%'}
                                height={'8vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                errorFontSize={'0.7vw'}
                                labelMB={'-0.1vw'}
                                va={watchVehicle('observation')}
                                setData={e => setValueVehicle('observation', e)}
                                textArea
                                error={errorsVehicle.observation && errorsVehicle.observation.message}
                            />
                        </div>
                    </div>
                </div >
        },
    ];
    return StepsVehicle
}