import { Steps, Button, Radio, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Input } from "../Input/Input";
import {
  PlusOutlined,
  CloseCircleFilled,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import photo from "../../assets/images/photo.png";
import { DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DepMunActions } from "../../services/DepMun/DepMunSlice";
import { EquipmentActions } from "../../services/Equipment/EquipmentSlice";
import { useForm, Controller } from "react-hook-form";
import { apiUrl } from "../../common/config/Environments";
import moment from "moment";
import { AreaActions } from "../../services/Area/AreaSlice";
import { EnterpriseActions } from "../../services/Enterprise/EnterpriseSlice";
import { LoginActions } from "../../services/Login/LoginSlices";
import SaveIcon from "../../assets/images/save.svg";
import { EquipmentIndustrialActions } from "../../services/EquipmentIndustrial/EquipmentIndustrialSlice";
import { EquipmentVehicleActions } from "../../services/EquipmentVehicle/EquipmentVehicleSlice";
import { Hidden } from "@material-ui/core";
import { ModalSearchEquipment } from "../ModalSearchEquipment/ModalSearchEquipment";
import { EquipmentCard } from "../EquipmentCard/EquipmentCard";

const Accessory = ({
  category,
  options,
  nameColumns,
  watchCreatePowerPlantServiceReport,
  setValueCreatePowerPlantServiceReport,
  controlCreatePowerPlantServiceReport,
  registerCreatePowerPlantServiceReport,
  errorsCreatePowerPlantServiceReport,
  type,
}) => {
  return (
    <div className="accessory">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          visibility: category ? "visible" : "hidden",
          marginBottom: category ? "0" : "1.2vw",
        }}
      >
        <p
          className="category"
          style={{
            fontSize:
              category &&
              (category.length <= 19
                ? "0.8vw"
                : category.length <= 24
                ? "0.7vw"
                : "0.6vw"),
          }}
        >
          {category}
        </p>
      </div>
      <div className="divColumnsNames">
        {nameColumns &&
          nameColumns.map((d) => <p className="columnName">{d}</p>)}
      </div>
      <div className="divOptions">
        {options.map((option, index) => (
          <div className="option" key={index}>
            <p className="nameOption">{option.name}</p>
            {(() => {
              switch (option.type) {
                case "radio":
                  return (
                    <Controller
                      control={controlCreatePowerPlantServiceReport} // control viene de useForm()
                      name={option.fieldName + "Status"}
                      rules={{
                        validate: (value) => {
                          const errors = [];
                          if (!value || value === "") {
                            errors.push("Es requerido elegir una opcion");
                          }
                          return errors.length === 0 || errors.join(", ");
                        },
                      }}
                      render={({ field }) => (
                        <Radio.Group
                          className="radioGroup"
                          {...field}
                          onChange={(e) => {
                            setValueCreatePowerPlantServiceReport(
                              option.fieldName + "Status",
                              e.target.value
                            );
                          }}
                        >
                          {option.radios &&
                            option.radios.map((d) => (
                              <Radio value={d.value}>{d.text}</Radio>
                            ))}
                        </Radio.Group>
                      )}
                    />
                  );
                case "input":
                  return (
                    <Input
                      {...registerCreatePowerPlantServiceReport(
                        option.fieldName + "Description",
                        {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("Requerido");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        }
                      )}
                      label={"none"}
                      width={"12vw"}
                      height={"1.8vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      errorFontSize={"0.7vw"}
                      labelMB={"-0.1vw"}
                      ml={"4.5vw"}
                      setData={(e) =>
                        setValueCreatePowerPlantServiceReport(
                          option.fieldName + "Description",
                          e
                        )
                      }
                      error={
                        errorsCreatePowerPlantServiceReport[
                          option.fieldName + "Description"
                        ] &&
                        errorsCreatePowerPlantServiceReport[
                          option.fieldName + "Description"
                        ].message
                      }
                    />
                  );
                case "textArea":
                  return (
                    <Input
                      {...registerCreatePowerPlantServiceReport(
                        option.fieldName + "Description",
                        {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("Requerido");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        }
                      )}
                      label={"none"}
                      width={"12vw"}
                      height={"16vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      errorFontSize={"0.7vw"}
                      labelMB={"-0.1vw"}
                      textArea
                      setData={(e) =>
                        setValueCreatePowerPlantServiceReport(
                          option.fieldName + "Description",
                          e
                        )
                      }
                      error={
                        errorsCreatePowerPlantServiceReport[
                          option.fieldName + "Description"
                        ] &&
                        errorsCreatePowerPlantServiceReport[
                          option.fieldName + "Description"
                        ].message
                      }
                    />
                  );
                case "dobleInput":
                  return (
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        left: "4vw",
                      }}
                    >
                      <Input
                        {...registerCreatePowerPlantServiceReport(
                          option.fieldName + "Value",
                          {
                            validate: (value) => {
                              const errors = [];
                              if (!value || value === "") {
                                errors.push("Requerido");
                              }
                              return errors.length === 0 || errors.join(", ");
                            },
                          }
                        )}
                        label={"none"}
                        width={"7vw"}
                        height={"1.8vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        errorFontSize={"0.7vw"}
                        labelMB={"-0.1vw"}
                        mr={"1vw"}
                        setData={(e) =>
                          setValueCreatePowerPlantServiceReport(
                            option.fieldName + "Value",
                            e
                          )
                        }
                        error={
                          errorsCreatePowerPlantServiceReport[
                            option.fieldName + "Value"
                          ] &&
                          errorsCreatePowerPlantServiceReport[
                            option.fieldName + "Value"
                          ].message
                        }
                      />
                      <Input
                        {...registerCreatePowerPlantServiceReport(
                          option.fieldName + "Unit",
                          {
                            validate: (value) => {
                              const errors = [];
                              if (!value || value === "") {
                                errors.push("Requerido");
                              }
                              return errors.length === 0 || errors.join(", ");
                            },
                          }
                        )}
                        label={"none"}
                        width={"7vw"}
                        height={"1.8vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        errorFontSize={"0.7vw"}
                        labelMB={"-0.1vw"}
                        setData={(e) =>
                          setValueCreatePowerPlantServiceReport(
                            option.fieldName + "Unit",
                            e
                          )
                        }
                        error={
                          errorsCreatePowerPlantServiceReport[
                            option.fieldName + "Unit"
                          ] &&
                          errorsCreatePowerPlantServiceReport[
                            option.fieldName + "Unit"
                          ].message
                        }
                      />
                    </div>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        ))}
      </div>
    </div>
  );
};

export function useStepsPowerPlant(
  registerCreatePowerPlantServiceReport,
  handleSubmitCreatePowerPlantServiceReport,
  setValueCreatePowerPlantServiceReport,
  watchCreatePowerPlantServiceReport,
  controlCreatePowerPlantServiceReport,
  errorsCreatePowerPlantServiceReport,
  triggerCreatePowerPlantServiceReport,
  equipSelected,
  setEquipSelected
) {
  const dispatch = useDispatch();
  const { equipmentsSearched } = useSelector((state) => state.Equipment);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleExitSearch, setVisibleExitSearch] = useState(false);

  const StepsPowerPlant = [
    {
      title: 1,
      content: (
        <div>
          <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
            <div style={{ width: "31%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  marginRight: "1%",
                }}
              >
                {equipSelected && (
                  <EquipmentCard
                    onChange={() => setEquipSelected(false)}
                    id={equipSelected.id}
                    img={equipSelected.image}
                    name={equipSelected.name}
                    brand={equipSelected.brand}
                    model={equipSelected.modell}
                    serie={equipSelected.serie}
                    location={equipSelected.location}
                    moduleWork={equipSelected.moduleWork}
                  ></EquipmentCard>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "68%",
                flexDirection: "column",
                marginLeft: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1vw",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  paddingLeft: "1vw",
                  paddingRight: "1vw",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="divDate">
                    <p className="label">Fecha de Reporte</p>
                    <DatePicker
                      value={watchCreatePowerPlantServiceReport("date")}
                      {...registerCreatePowerPlantServiceReport("date", {
                        validate: (value) => {
                          const errors = [];
                          if (!value || value === "") {
                            errors.push("La fecha es requerida");
                          }
                          return errors.length === 0 || errors.join(", ");
                        },
                      })}
                      style={{
                        width: "9.5vw",
                        height: "2.4vw",
                        marginRight: "0.7vw",
                      }}
                      onChange={(d) =>
                        setValueCreatePowerPlantServiceReport("date", d)
                      }
                    />
                    {errorsCreatePowerPlantServiceReport.date && (
                      <p className="errorMessage">
                        {errorsCreatePowerPlantServiceReport.date.message}
                      </p>
                    )}
                  </div>
                </div>
                <p className="titleSection">Tipo del servicio</p>
                <div style={{ display: "flex" }}>
                  <Radio.Group
                    className="radioGroup"
                    {...registerCreatePowerPlantServiceReport("serviceType", {
                      validate: (value) => {
                        const errors = [];
                        if (!value || value === "") {
                          errors.push("Es requerido elegir una opcion");
                        }
                        return errors.length === 0 || errors.join(", ");
                      },
                    })}
                    style={{ marginLeft: "2vw" }}
                    value={watchCreatePowerPlantServiceReport("serviceType")}
                    onChange={(e) =>
                      setValueCreatePowerPlantServiceReport(
                        "serviceType",
                        e.target.value
                      )
                    }
                  >
                    <Radio value={"Correctivo"}>Correctivo</Radio>
                    <Radio value={"Preventivo"}>Preventivo</Radio>
                    <Radio value={"Inspección"}>Inspección</Radio>
                    <Radio value={"Emergencia"}>Emergencia</Radio>
                    <Radio value={"Otro"}>Otro</Radio>
                  </Radio.Group>
                  {errorsCreatePowerPlantServiceReport.serviceType && (
                    <p className="errorMessage">
                      {errorsCreatePowerPlantServiceReport.serviceType.message}
                    </p>
                  )}
                </div>
                <p className="titleSection">Observaciones iniciales</p>
                <Input
                  {...registerCreatePowerPlantServiceReport(
                    "initialObservation",
                    {
                      validate: (value) => {
                        const errors = [];
                        if (!value || value === "") {
                          errors.push("Observaciones iniciales es requerido");
                        }
                        return errors.length === 0 || errors.join(", ");
                      },
                    }
                  )}
                  mr={"0.7vw"}
                  mt={"-1vw"}
                  width={"100%"}
                  height={"8vw"}
                  labelstyle="inputLabelBordered"
                  placeholder={"Describa las observaciones iniciales"}
                  fontSize={"1vw"}
                  errorFontSize={"0.7vw"}
                  labelMB={"-0.1vw"}
                  textArea
                  va={watchCreatePowerPlantServiceReport("initialObservation")}
                  setData={(e) =>
                    setValueCreatePowerPlantServiceReport(
                      "initialObservation",
                      e
                    )
                  }
                  error={
                    errorsCreatePowerPlantServiceReport.initialObservation &&
                    errorsCreatePowerPlantServiceReport.initialObservation
                      .message
                  }
                />
                <p className="titleSection">Estado inicial</p>
                <div className="divAccessories">
                  <div className="accessorySection">
                    <p className="text">
                      Puede seleccionar marcar el estado y cantidad de manera
                      Total con un clic para todas las partes y accesorios del
                      vehículo.
                    </p>
                  </div>
                  <div className="accessorySection">
                    <div className="accessory yellow">
                      <div className="divColumnsNames">
                        <p className="columnName">Descripción:</p>
                        <p className="columnName">Estado:</p>
                      </div>
                      <div className="divOptions">
                        <div className="option">
                          <p className="nameOption">Marcar Todo: </p>
                          <Radio.Group
                            className="radioGroup"
                            onChange={(e) => {
                              let aux = watchCreatePowerPlantServiceReport();
                              let options = Object.keys(aux).filter((key) =>
                                key.endsWith("Status")
                              );
                              options.forEach((option) => {
                                setValueCreatePowerPlantServiceReport(
                                  option,
                                  e.target.value
                                );
                              });
                            }}
                          >
                            <Radio value={"GOOD"}>Bueno</Radio>
                            <Radio value={"REGULAR"}>Regular</Radio>
                            <Radio value={"BAD"}>Malo</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Registre el estado inicial N°1"}
                      nameColumns={["Descripción:", "Estado:"]}
                      options={[
                        {
                          name: "Nivel de Aceite: ",
                          fieldName: "oilLevel",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Nivel de refrigerante: ",
                          fieldName: "coolantLevel",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Mangueras: ",
                          fieldName: "hoses",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Sellos: ",
                          fieldName: "seals",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Tuberías: ",
                          fieldName: "pipelines",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Radiador: ",
                          fieldName: "radiator",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Guardas: ",
                          fieldName: "guard",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Correas Ventilador: ",
                          fieldName: "fanBelts",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Correas alternador: ",
                          fieldName: "alternatorBelts",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Amortiguadores: ",
                          fieldName: "shockAbsorbers",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Precalentador: ",
                          fieldName: "preheater",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Bateria: ",
                          fieldName: "battery",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      nameColumns={["Descripción:", "Estado:"]}
                      options={[
                        {
                          name: "Nivel Electrolito: ",
                          fieldName: "electrolyteLevel",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Voltaje Bateria: ",
                          fieldName: "batteryVoltage",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Cargador: ",
                          fieldName: "charger",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Conexiones de Control: ",
                          fieldName: "controlConnections",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Conexiones de potencia: ",
                          fieldName: "powerConnections",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Estado del generador: ",
                          fieldName: "generator",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Limpieza General: ",
                          fieldName: "generalCleaning",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Nivel de Combustible: ",
                          fieldName: "fuelLevel",
                          type: "input",
                        },
                        {
                          name: "Capacidad Tanque: ",
                          fieldName: "tankCapacity",
                          type: "input",
                        },
                        {
                          name: "Voltaje Cargador: ",
                          fieldName: "chargerVoltage",
                          type: "input",
                        },
                        {
                          name: "Voltaje Alternador: ",
                          fieldName: "alternatorVoltage",
                          type: "input",
                        },
                        {
                          name: "Tipo Control: ",
                          fieldName: "controlType",
                          type: "input",
                        },
                        {
                          name: "Fugas: ",
                          fieldName: "leaks",
                          type: "textArea",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 2,
      content: (
        <div>
          <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
            <div style={{ width: "31%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  marginRight: "1%",
                }}
              >
                {equipSelected && (
                  <EquipmentCard
                    onChange={() => console.log("change")}
                    id={equipSelected.id}
                    img={equipSelected.image}
                    name={equipSelected.name}
                    brand={equipSelected.brand}
                    model={equipSelected.modell}
                    serie={equipSelected.serie}
                    location={equipSelected.location}
                    moduleWork={equipSelected.moduleWork}
                  ></EquipmentCard>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "68%",
                flexDirection: "column",
                marginLeft: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1vw",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  paddingLeft: "1vw",
                  paddingRight: "1vw",
                  paddingBottom: "2vw",
                }}
              >
                <div className="divAccessories">
                  <div className="accessorySection">
                    <p className="text">
                      Puede seleccionar marcar el estado y cantidad de manera
                      Total con un clic para todas las partes y accesorios del
                      vehículo.
                    </p>
                  </div>
                  <div className="accessorySection">
                    <div className="accessory yellow">
                      <div className="divColumnsNames">
                        <p className="columnName">Descripción:</p>
                        <p className="columnName">Estado:</p>
                      </div>
                      <div className="divOptions">
                        <div className="option">
                          <p className="nameOption">Marcar Todo: </p>
                          <Radio.Group
                            className="radioGroup"
                            onChange={(e) => {
                              let aux = watchCreatePowerPlantServiceReport();
                              let options = Object.keys(aux).filter((key) =>
                                key.endsWith("Status")
                              );
                              options.forEach((option) => {
                                setValueCreatePowerPlantServiceReport(
                                  option,
                                  e.target.value
                                );
                              });
                            }}
                          >
                            <Radio value={"GOOD"}>Bueno</Radio>
                            <Radio value={"REGULAR"}>Regular</Radio>
                            <Radio value={"BAD"}>Malo</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Registre el estado inicial N°2"}
                      nameColumns={["Descripción:", "Estado:"]}
                      options={[
                        {
                          name: "Gabinete: ",
                          fieldName: "cabinet",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Puertas: ",
                          fieldName: "doors",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Cerraduras: ",
                          fieldName: "locks",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Bisagras: ",
                          fieldName: "hinges",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Limpieza GE: ",
                          fieldName: "GECleaning",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Pilotos INDI: ",
                          fieldName: "INDIPilots",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Selectores: ",
                          fieldName: "selectors",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Relés: ",
                          fieldName: "relays",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Temporizadores: ",
                          fieldName: "timers",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Contactores: ",
                          fieldName: "contactors",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      nameColumns={["Descripción:", "Estado:"]}
                      options={[
                        {
                          name: "Interruptores: ",
                          fieldName: "switches",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Conexión de Control: ",
                          fieldName: "controlConnection",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Conexión de Potencia: ",
                          fieldName: "powerConnection",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Barraje Potencia: ",
                          fieldName: "powerBar",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Barraje Neutro: ",
                          fieldName: "neutralBusbar",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Barraje Tierra: ",
                          fieldName: "groundBus",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Plc: ",
                          fieldName: "plc",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Ats: ",
                          fieldName: "ats",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Fuentes Auxiliares: ",
                          fieldName: "auxiliarySources",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Capacitores: ",
                          fieldName: "capacitors",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                        {
                          name: "Analizadores de Red: ",
                          fieldName: "networkAnalyzers",
                          type: "radio",
                          radios: [
                            { text: "Bueno", value: "GOOD" },
                            { text: "Regular", value: "REGULAR" },
                            { text: "Malo", value: "BAD" },
                          ],
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p className="titleSection">Actividad Realizada</p>
                <Input
                  {...registerCreatePowerPlantServiceReport(
                    "activityPerformed",
                    {
                      validate: (value) => {
                        const errors = [];
                        if (!value || value === "") {
                          errors.push("Actividad realizada es requerido");
                        }
                        return errors.length === 0 || errors.join(", ");
                      },
                    }
                  )}
                  mr={"0.7vw"}
                  mt={"-1vw"}
                  width={"50vw"}
                  height={"6vw"}
                  labelstyle="inputLabelBordered"
                  placeholder={"Describa la actividad"}
                  fontSize={"1vw"}
                  errorFontSize={"0.7vw"}
                  labelMB={"-0.1vw"}
                  textArea
                  va={watchCreatePowerPlantServiceReport("activityPerformed")}
                  setData={(e) =>
                    setValueCreatePowerPlantServiceReport(
                      "activityPerformed",
                      e
                    )
                  }
                  error={
                    errorsCreatePowerPlantServiceReport.activityPerformed &&
                    errorsCreatePowerPlantServiceReport.activityPerformed
                      .message
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 3,
      content: (
        <div>
          <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
            <div style={{ width: "31%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  marginRight: "1%",
                }}
              >
                {equipSelected && (
                  <EquipmentCard
                    onChange={() => console.log("change")}
                    id={equipSelected.id}
                    img={equipSelected.image}
                    name={equipSelected.name}
                    brand={equipSelected.brand}
                    model={equipSelected.modell}
                    serie={equipSelected.serie}
                    location={equipSelected.location}
                    moduleWork={equipSelected.moduleWork}
                  ></EquipmentCard>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "68%",
                flexDirection: "column",
                marginLeft: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1vw",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  paddingLeft: "1vw",
                  paddingRight: "1vw",
                  paddingBottom: "2vw",
                }}
              >
                <p className="titleSection">
                  Pruebas Con El Equipo En Operación N°1
                </p>
                <div className="divAccessories">
                  <div className="accessorySection">
                    <Accessory
                      category={"Motor"}
                      nameColumns={["Descripción:", "Valor:", "Unidad:"]}
                      options={[
                        {
                          name: "Temperatura Aceite: ",
                          fieldName: "oilTemperature",
                          type: "dobleInput",
                        },
                        {
                          name: "Temperatura del Turbo: ",
                          fieldName: "turboTemperature",
                          type: "dobleInput",
                        },
                        { name: "Rpm: ", fieldName: "rpm", type: "dobleInput" },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Generador"}
                      nameColumns={["Descripción:"]}
                      options={[
                        {
                          name: "Vac Fases L1 L1: ",
                          fieldName: "vacPhasesL1L1",
                          type: "input",
                        },
                        {
                          name: "Vac Fases L2 L2: ",
                          fieldName: "vacPhasesL2L2",
                          type: "input",
                        },
                        {
                          name: "Vac Fases L3 L3: ",
                          fieldName: "vacPhasesL3L3",
                          type: "input",
                        },
                        {
                          name: "Vac Fases N L1 N: ",
                          fieldName: "vacPhasesNL1N",
                          type: "input",
                        },
                        {
                          name: "Vac Fases N L2 N: ",
                          fieldName: "vacPhasesNL2N",
                          type: "input",
                        },
                        {
                          name: "Vac Fases N L3 N: ",
                          fieldName: "vacPhasesNL3N",
                          type: "input",
                        },
                        {
                          name: "Amperios L1: ",
                          fieldName: "ampsL1",
                          type: "input",
                        },
                        {
                          name: "Amperios L2: ",
                          fieldName: "ampsL2",
                          type: "input",
                        },
                        {
                          name: "Amperios L3: ",
                          fieldName: "ampsL3",
                          type: "input",
                        },
                        {
                          name: "Potencia KVA: ",
                          fieldName: "powerKVA",
                          type: "input",
                        },
                        {
                          name: "Potencia Hz: ",
                          fieldName: "powerHz",
                          type: "input",
                        },
                        {
                          name: "Potencia FP: ",
                          fieldName: "powerFP",
                          type: "input",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Motor"}
                      nameColumns={["Descripción:", "Cantidad:", "Referencia:"]}
                      options={[
                        {
                          name: "Filtro Aire: ",
                          fieldName: "airFilter",
                          type: "dobleInput",
                        },
                        {
                          name: "Filtro Aceite: ",
                          fieldName: "oilFilter",
                          type: "dobleInput",
                        },
                        {
                          name: "Filtro Combustible: ",
                          fieldName: "fuelFilter",
                          type: "dobleInput",
                        },
                        {
                          name: "Filtro Separador: ",
                          fieldName: "separatorFilter",
                          type: "dobleInput",
                        },
                        {
                          name: "Filtro de Agua: ",
                          fieldName: "waterFilter",
                          type: "dobleInput",
                        },
                        {
                          name: "Cantidad de Aceite: ",
                          fieldName: "oilQuantity",
                          type: "dobleInput",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Protecciones"}
                      nameColumns={["Descripción:"]}
                      options={[
                        {
                          name: "Baja presión: ",
                          fieldName: "lowPressure",
                          type: "input",
                        },
                        {
                          name: "Alta temperatura: ",
                          fieldName: "highTemperature",
                          type: "input",
                        },
                        {
                          name: "Bajo Nivel Refrigerante: ",
                          fieldName: "lowCoolantLevel",
                          type: "input",
                        },
                        {
                          name: "Bajo Voltaje AC: ",
                          fieldName: "lowVoltageAC",
                          type: "input",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={
                        "Posición de Instrumentos al Concluir el Servicio"
                      }
                      nameColumns={["Descripción:", "Estado:"]}
                      options={[
                        {
                          name: "Control: ",
                          fieldName: "controlAtTheEnd",
                          type: "radio",
                          radios: [
                            { text: "Manual", value: "Manual" },
                            { text: "Automático", value: "Auto" },
                            { text: "Off", value: "Off" },
                          ],
                        },
                        {
                          name: "Cargador: ",
                          fieldName: "chargerAtTheEnd",
                          type: "radio",
                          radios: [
                            { text: "On", value: "On" },
                            { text: "Off", value: "Off" },
                          ],
                        },
                        {
                          name: "Totalizador: ",
                          fieldName: "toteAtTheEnd",
                          type: "radio",
                          radios: [
                            { text: "On", value: "On" },
                            { text: "Off", value: "Off" },
                          ],
                        },
                        {
                          name: "Precalentador: ",
                          fieldName: "preheaterAtTheEnd",
                          type: "radio",
                          radios: [
                            { text: "On", value: "On" },
                            { text: "Off", value: "Off" },
                          ],
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 4,
      content: (
        <div>
          <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
            <div style={{ width: "31%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  marginRight: "1%",
                }}
              >
                {equipSelected && (
                  <EquipmentCard
                    onChange={() => console.log("change")}
                    id={equipSelected.id}
                    img={equipSelected.image}
                    name={equipSelected.name}
                    brand={equipSelected.brand}
                    model={equipSelected.modell}
                    serie={equipSelected.serie}
                    location={equipSelected.location}
                    moduleWork={equipSelected.moduleWork}
                  ></EquipmentCard>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "68%",
                flexDirection: "column",
                marginLeft: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1vw",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  paddingLeft: "1vw",
                  paddingRight: "1vw",
                  paddingBottom: "2vw",
                }}
              >
                <p className="titleSection">
                  Pruebas Con El Equipo En Operación N°2
                </p>
                <div className="divAccessories">
                  <div className="accessorySection">
                    <Accessory
                      category={"Tiempos"}
                      nameColumns={["Descripción:", "Segundos:"]}
                      options={[
                        { name: "TDS: ", fieldName: "TDS", type: "input" },
                        { name: "TDNE: ", fieldName: "TDNE", type: "input" },
                        { name: "TDNP: ", fieldName: "TDNP", type: "input" },
                        { name: "TDEN: ", fieldName: "TDEN", type: "input" },
                        { name: "TDEC: ", fieldName: "TDEC", type: "input" },
                        {
                          name: "Voltaje de la Batería: ",
                          fieldName: "batteryVoltageAtTheEnd",
                          type: "input",
                        },
                        {
                          name: "Caída Voltaje de Batería: ",
                          fieldName: "batteryVoltageDropAtTheEnd",
                          type: "input",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Temperatura"}
                      nameColumns={["Descripción:", "°C"]}
                      options={[
                        {
                          name: "Cables de Potencia: ",
                          fieldName: "powerCables",
                          type: "input",
                        },
                        {
                          name: "Terminales: ",
                          fieldName: "terminals",
                          type: "input",
                        },
                        {
                          name: "Cuerpo de Contractores: ",
                          fieldName: "bodyOfContractors",
                          type: "input",
                        },
                        {
                          name: "Cuerpo Interruptores: ",
                          fieldName: "bodyOfSwitches",
                          type: "input",
                        },
                        {
                          name: "Transformadores: ",
                          fieldName: "transformers",
                          type: "input",
                        },
                        {
                          name: "Punto mas caliente: ",
                          fieldName: "hottestSpot",
                          type: "input",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Voltaje"}
                      options={[
                        { name: "L1-N: ", fieldName: "L1N", type: "input" },
                        { name: "L2-N: ", fieldName: "L2N", type: "input" },
                        { name: "L3-N: ", fieldName: "L3N", type: "input" },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Corriente"}
                      options={[
                        { name: "L1: ", fieldName: "l1Power", type: "input" },
                        { name: "L2: ", fieldName: "l2Power", type: "input" },
                        { name: "L3: ", fieldName: "l3Power", type: "input" },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Frecuencia"}
                      options={[
                        {
                          name: "HZ: ",
                          fieldName: "hzFrequency",
                          type: "input",
                        },
                        {
                          name: "KW: ",
                          fieldName: "kwFrequency",
                          type: "input",
                        },
                        {
                          name: "L1: ",
                          fieldName: "l1Frequency",
                          type: "input",
                        },
                        {
                          name: "L2: ",
                          fieldName: "l2Frequency",
                          type: "input",
                        },
                        {
                          name: "L3: ",
                          fieldName: "l3Frequency",
                          type: "input",
                        },
                        {
                          name: "AVG: ",
                          fieldName: "avgFrequency",
                          type: "input",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                  <div className="accessorySection">
                    <Accessory
                      category={"Factor P"}
                      options={[
                        { name: "PF: ", fieldName: "pfPFactor", type: "input" },
                        {
                          name: "KWA: ",
                          fieldName: "kwaPFactor",
                          type: "input",
                        },
                        { name: "L1: ", fieldName: "l1PFactor", type: "input" },
                        { name: "L2: ", fieldName: "l2PFactor", type: "input" },
                        { name: "L3: ", fieldName: "l3PFactor", type: "input" },
                        {
                          name: "AVG: ",
                          fieldName: "avgPFactor",
                          type: "input",
                        },
                      ]}
                      watchCreatePowerPlantServiceReport={
                        watchCreatePowerPlantServiceReport
                      }
                      registerCreatePowerPlantServiceReport={
                        registerCreatePowerPlantServiceReport
                      }
                      setValueCreatePowerPlantServiceReport={
                        setValueCreatePowerPlantServiceReport
                      }
                      errorsCreatePowerPlantServiceReport={
                        errorsCreatePowerPlantServiceReport
                      }
                      controlCreatePowerPlantServiceReport={
                        controlCreatePowerPlantServiceReport
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p className="titleSection">Observaciónes</p>
                <Input
                  {...registerCreatePowerPlantServiceReport(
                    "finalObservation",
                    {
                      validate: (value) => {
                        const errors = [];
                        if (!value || value === "") {
                          errors.push("Actividad realizada es requerido");
                        }
                        return errors.length === 0 || errors.join(", ");
                      },
                    }
                  )}
                  mr={"0.7vw"}
                  mt={"-1vw"}
                  width={"50vw"}
                  height={"6vw"}
                  labelstyle="inputLabelBordered"
                  placeholder={
                    "Si tiene Observaciones o recomendaciones realícelas aquí"
                  }
                  fontSize={"1vw"}
                  errorFontSize={"0.7vw"}
                  labelMB={"-0.1vw"}
                  textArea
                  va={watchCreatePowerPlantServiceReport("finalObservation")}
                  setData={(e) =>
                    setValueCreatePowerPlantServiceReport("finalObservation", e)
                  }
                  error={
                    errorsCreatePowerPlantServiceReport.finalObservation &&
                    errorsCreatePowerPlantServiceReport.finalObservation.message
                  }
                />
              </div>
              <p className="titleSection">Estado del equipo</p>
              <div style={{ display: "flex" }}>
                <Radio.Group
                  className="radioGroup"
                  {...registerCreatePowerPlantServiceReport("statusEquip", {
                    validate: (value) => {
                      const errors = [];
                      if (!value || value === "") {
                        errors.push("Es requerido elegir una opcion");
                      }
                      return errors.length === 0 || errors.join(", ");
                    },
                  })}
                  style={{ marginLeft: "2vw" }}
                  value={watchCreatePowerPlantServiceReport("statusEquip")}
                  onChange={(e) =>
                    setValueCreatePowerPlantServiceReport(
                      "statusEquip",
                      e.target.value
                    )
                  }
                >
                  <Radio value={"Funcional"}>Funcional</Radio>
                  <Radio value={"No funcional"}>No funcional</Radio>
                  <Radio value={"Dado de baja"}>Dado de baja</Radio>
                </Radio.Group>
                {errorsCreatePowerPlantServiceReport.statusEquip && (
                  <p className="errorMessage">
                    {errorsCreatePowerPlantServiceReport.statusEquip.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return StepsPowerPlant;
}
