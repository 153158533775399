import { Modal } from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import blanco from '../../assets/images/blanco.png'
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { apiUrl } from '../../common/config/Environments'


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { PdfInventory } from '../PdfInventory/PdfInventory';
import { TransferActions } from '../../services/Transfer/TransferSlice';

export const TransferCard = ({ data, setEdit, setEquipData, extraOnClick, extraButtons }) => {
    const { loadingMakeTransfer } = useSelector(state => state.Transfer)
    const { dependenciesFromBoss, dependenciesFromUser, moduleWork } = useSelector(state => state.Area)
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { idUser } = useSelector(state => state.Login)
    const [visibleModal, setVisibleModal] = useState(false)
    const [pdf, setPdf] = useState(false)
    const [visibleModalTransfer, setVisibleModalTransfer] = useState(false)

    const [image, setImage] = useState(false)

    let equipmentType;

    if (data.networkingAndCommunicationsEquipment) {
        equipmentType = "networkingAndCommunicationsEquipment";
    } else if (data.peripheralsAndAccessoriesEquipment) {
        equipmentType = "peripheralsAndAccessoriesEquipment";
    } else if (data.computersEquipment) {
        equipmentType = "computersEquipment";
    }
    const dispatch = useDispatch()
    useEffect(() => {
        moduleWork === 1 &&
            fetch(`${apiUrl}/biomedicEquipment/imageById?id=${data.equipment.biomedicEquipment.id}`)
                .then(response => response.json())
                .then(data => setImage(data))
                .catch(error => console.error(error))
        moduleWork === 2 &&
            fetch(`${apiUrl}/${equipmentType}/imageById?id=${data.id}`)
                .then((response) => response.json())
                .then((data) => setImage(data))
                .catch((error) => console.error(error));
    }, [])

    let brand, serie, modell, licensePlate;
    console.log(data)
    if (data.equipment.networkingAndCommunicationsEquipment) {
        brand = data.equipment.networkingAndCommunicationsEquipment.brand;
        serie = data.equipment.networkingAndCommunicationsEquipment.serie;
        modell = data.equipment.networkingAndCommunicationsEquipment.modell;
        licensePlate = data.equipment.licensePlate;
    } else if (data.equipment.peripheralsAndAccessoriesEquipment) {
        brand = data.equipment.peripheralsAndAccessoriesEquipment.brand;
        serie = data.equipment.peripheralsAndAccessoriesEquipment.serie;
        modell = data.equipment.peripheralsAndAccessoriesEquipment.modell;
        licensePlate = data.equipment.licensePlate;
    } else if (data.equipment.computersEquipment) {
        if (data.equipment.computersEquipment.brandCPU) {
            brand = data.equipment.computersEquipment.brandCPU;
            serie = data.equipment.computersEquipment.serieCPU;
            modell = data.equipment.computersEquipment.modellCPU;
            licensePlate = data.equipment.computersEquipment.licensePlateCPU;
        } else if (data.equipment.computersEquipment.brandAllInOne) {
            brand = data.equipment.computersEquipment.brandAllInOne;
            serie = data.equipment.computersEquipment.serieAllInOne;
            modell = data.equipment.computersEquipment.modellAllInOne;
            licensePlate = data.equipment.computersEquipment.licensePlateAllInOne;
        } else if (data.equipment.computersEquipment.brandLaptop) {
            brand = data.equipment.computersEquipment.brandLaptop;
            serie = data.equipment.computersEquipment.serieLaptop;
            modell = data.equipment.computersEquipment.modellLaptop;
            licensePlate = data.equipment.computersEquipment.licensePlateLaptop;
        }
    } else if (data.equipment.biomedicEquipment) {
        brand = data.equipment.biomedicEquipment.brand;
        serie = data.equipment.biomedicEquipment.serie;
        modell = data.equipment.biomedicEquipment.modell;
        licensePlate = data.equipment.licensePlate;

    }

    useEffect(() => {
        pdf === 'all' && dispatch(EquipmentActions.getEquipAndReportPDF(data.id))
    }, [pdf])

    const MyDocument = () => (
        <Document>
            <Page size='A1' style={{
                flexDirection: 'column',
                backgroundColor: '#fff',
                alignItems: 'center'
            }}>
                <View style={{
                    border: '3px solid #000',
                    marginTop: '3%',
                    width: '95%',
                    height: '8%',
                    flexDirection: 'row'
                }}>
                    <View style={{
                        width: '20%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRight: '3px solid #000'
                    }}>
                        <Image style={{ width: '60%', height: '60%' }} src={enterpriseImage[0].image}></Image>
                    </View>
                    <View style={{
                        width: '75%',
                        borderRight: '3px solid #000'
                    }}>
                        <View style={{
                            height: '50%',
                            width: '100%',
                            borderBottom: '3px solid #000',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><Text style={{ fontSize: '32px' }}>PROCESO GESTION ADMINISTRATIVA</Text></View>
                        <View style={{
                            height: '50%',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><Text style={{ fontSize: '25px' }}>Devolución de elementos al área de Almacen y/o traslados a otras dependencias</Text></View>
                    </View>
                    <View style={{
                        width: '20%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRight: '3px solid #000'
                    }}>
                        <View style={{
                            height: '50%',
                            width: '100%',
                            borderBottom: '3px solid #000',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><Text style={{ fontSize: '32px' }}>GA-ALM-F-16</Text></View>
                        <View style={{
                            height: '50%',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><Text style={{ fontSize: '32px' }}>Ver.01</Text></View>
                    </View>
                </View>
                <View style={{ width: '80%', alignSelf: 'flex-start', marginLeft: '3%', marginTop: '3%' }}>
                    <View style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginRight: '4%' }}><Text style={{ fontSize: '30px' }}>PLACA: </Text></View>
                        <View style={{ borderBottom: '1px solid #000000' }}><Text style={{ fontSize: '30px' }}>{data.equipment.licensePlate + " "}</Text></View>
                    </View>
                    <View style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginRight: '4%' }}><Text style={{ fontSize: '30px' }}>FECHA: </Text></View>
                        <View style={{ borderBottom: '1px solid #000000' }}>
                            <Text style={{ fontSize: '30px' }}>
                                {data.dateCreated ?
                                    (() => {
                                        const date = new Date(data.dateCreated);
                                        return String(date.getDate()).padStart(2, '0') + '/' +
                                            String(date.getMonth() + 1).padStart(2, '0') + '/' +
                                            date.getFullYear();
                                    })()
                                    : ''}
                            </Text></View>
                    </View>
                    <View style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginRight: '4%' }}><Text style={{ fontSize: '30px' }}>DEPENDENCIA: </Text></View>
                        <View style={{ borderBottom: '1px solid #000000' }}><Text style={{ fontSize: '30px' }}>{data.dependencyFrom.name + " "}</Text></View>
                    </View>
                    <View style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginRight: '4%' }}><Text style={{ fontSize: '30px' }}>RESPONSABLE: </Text></View>
                        <View style={{ borderBottom: '1px solid #000000' }}><Text style={{ fontSize: '30px' }}>
                            {(data.userCreator) &&
                                data.userCreator.person.name + " " +
                                data.userCreator.person.secondName + " " +
                                data.userCreator.person.lastName + " " +
                                data.userCreator.person.secondLastName
                            }</Text></View>
                    </View>
                    <View style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginRight: '4%' }}><Text style={{ fontSize: '30px' }}>DEVOLUCION POR: </Text></View>
                        <View style={{ borderBottom: '1px solid #000000' }}><Text style={{ fontSize: '30px' }}>{data.reasonDescription && data.reasonDescription + " "}</Text></View>
                    </View>
                    {data.dependencyFrom.name !== data.dependencyTo.name &&
                        <View style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={{ marginRight: '4%' }}><Text style={{ fontSize: '30px' }}>A LA DEPENDENCIA: </Text></View>
                            <View style={{ borderBottom: '1px solid #000000' }}><Text style={{ fontSize: '30px' }}>{data.dependencyTo && data.dependencyTo.name + " "}</Text></View>
                        </View>}
                    <View style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2%' }}>
                        <View style={{ marginRight: '4%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '30px' }}>Inservible: </Text>
                            <View style={{ border: '1px solid #00000' }}><Text style={{ fontSize: '30px' }}>{data.reasonReturn === 'Inservible' && 'X'}</Text></View>
                        </View>
                        <View style={{ marginRight: '4%', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '30px' }}>Traslado: </Text>
                            <View style={{ border: '1px solid #00000' }}><Text style={{ fontSize: '30px' }}>{data.reasonReturn === 'Traslado' && 'X'}</Text></View>
                        </View>
                    </View>

                </View>
                <Text style={{ fontSize: '26px', marginBottom: '4%' }}>Atentamente,</Text>
                <View style={{ marginTop: '0%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Image style={{ width: '25%', height: '100%' }} src={(data.userCreator && data.userCreator.person.firm) ? data.userCreator.person.firm : blanco}></Image>
                </View>

                <View style={{ marginTop: '1%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                </View>
                <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {(data.userCreator && data.userCreator.person.firm) &&
                            data.userCreator.person.name + " " +
                            data.userCreator.person.secondName + " " +
                            data.userCreator.person.lastName + " " +
                            data.userCreator.person.secondLastName
                        }
                    </Text>
                </View>
                <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                        {
                            data.userCreator && data.userCreator.position
                        }
                    </Text>
                </View>
                <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                        {
                            data.userCreator && data.userCreator.profesion
                        }
                    </Text>
                </View>
            </Page>
        </Document >
    );

    const MyDocument1 = () => (
        <Document>
            <Page size='A1' style={{
                flexDirection: 'column',
                backgroundColor: '#fff',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <View style={{
                    border: '3px solid #000',
                    marginTop: '5%',
                    width: '90%',
                    height: '30%',
                    flexDirection: 'column'
                }}>
                    <View
                        style={{
                            width: '100%',
                            height: '20%',
                            flexDirection: 'row',
                            display: 'flex',
                        }}
                    >
                        <View
                            style={{
                                width: '30%',
                                height: '100%',
                            }}>
                            <Image
                                style={{
                                    width: '100%', height: '100%'
                                }}
                                src={enterpriseImage[0].image}
                            ></Image>
                        </View>
                        <View
                            style={{
                                width: '70%',
                                height: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <Text style={{ fontSize: '30px', }}>{enterpriseImage[0].name}</Text>
                            <Text style={{ fontSize: '35px', fontWeight: 'bold' }}>TRASPASOS ENTRE FUNCIONARIOS</Text>
                        </View>
                    </View>
                    <View
                        style={{
                            width: '100%',
                            height: '20%',
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: '4%',
                            marginLeft: '3%'
                        }}
                    >
                        <Text style={{ fontSize: '30px', marginRight: '1%' }}>LUGAR Y FECHA:</Text>
                        <Text style={{ fontSize: '28px', }}>E.S.E {enterpriseImage[0].name},{
                            data.dateApproved ?
                                (() => {
                                    const date = new Date(data.dateApproved);
                                    return String(date.getDate()).padStart(2, '0') + '/' +
                                        String(date.getMonth() + 1).padStart(2, '0') + '/' +
                                        date.getFullYear();
                                })()
                                : ''}
                        </Text>
                    </View>
                    <View
                        style={{
                            width: '93%',
                            height: '20%',
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: '1%',
                            marginLeft: '3%',
                            justifyContent: 'space-between'
                        }}
                    >
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '30px', marginRight: '1%' }}>El (La):</Text>
                            <Text style={{ fontSize: '28px', }}>
                                {(data.userCreator) &&
                                    data.userCreator.person.name.toUpperCase() + " " +
                                    data.userCreator.person.secondName.toUpperCase() + " " +
                                    data.userCreator.person.lastName.toUpperCase() + " " +
                                    data.userCreator.person.secondLastName.toUpperCase()
                                }
                            </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '30px', marginRight: '1%' }}>DE:</Text>
                            <Text style={{ fontSize: '28px', }}>
                                {data.dependencyFrom.name}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            width: '93%',
                            height: '20%',
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: '-5%',
                            marginLeft: '3%',
                            justifyContent: 'space-between'
                        }}
                    >
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '30px', marginRight: '1%' }}>A:</Text>
                            <Text style={{ fontSize: '28px', }}>
                                {(data.userCreator) &&
                                    data.userApprover.person.name.toUpperCase() + " " +
                                    data.userApprover.person.secondName.toUpperCase() + " " +
                                    data.userApprover.person.lastName.toUpperCase() + " " +
                                    data.userApprover.person.secondLastName.toUpperCase()
                                }
                            </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '30px', marginRight: '1%', }}>DE:</Text>
                            <Text style={{ fontSize: '28px', }}>
                                {data.dependencyTo.name}
                            </Text>
                        </View>
                    </View>
                    <View style={{
                        width: '100%',
                        height: '7%',
                        borderTop: '3px solid #000000',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                    >
                        <View style={{ width: '30%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>NOMBRE</Text></View>
                        <View style={{ width: '15%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>MARCA</Text></View>
                        <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>MODELO</Text></View>
                        <View style={{ width: '15%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>SERIE</Text></View>
                        <View style={{ width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '24px' }}>PLACA</Text></View>
                    </View>
                    <View style={{
                        width: '100%',
                        height: '7%',
                        borderTop: '3px solid #000000',
                        borderBottom: '3px solid #000000',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                    >
                        <View style={{ width: '30%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{data.equipment.name}</Text></View>
                        <View style={{ width: '15%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{brand}</Text></View>
                        <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{modell}</Text></View>
                        <View style={{ width: '15%', height: '100%', borderRight: '3px solid #000000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{serie}</Text></View>
                        <View style={{ width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{licensePlate}</Text></View>
                    </View>
                </View>

                <View style={{
                    width: '90%',
                    height: '30%',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}>
                    <View style={{ width: '95%', height: '20%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-7%' }}>
                        <Text style={{ fontSize: '28px' }}>ENTREGADO POR:</Text>
                        <Text style={{ fontSize: '28px' }}>RECIBIDO POR:</Text>
                    </View>
                    <View style={{ marginTop: '3%', width: '95%', height: '20%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-7%' }}>
                        <Image style={{ width: '30%', height: '150%' }} src={data.userCreator && data.userCreator.person.firm ? data.userCreator.person.firm : blanco}></Image>
                        <Image style={{ width: '30%', height: '150%' }} src={data.userApprover.person.firm ? data.userApprover.person.firm : blanco}></Image>
                    </View>
                    <View style={{ marginTop: '3%', width: '95%', height: '20%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-0%' }}>
                        <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                        <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                    </View>
                    <View style={{ width: '95%', height: '20%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-7%' }}>
                        <Text
                            style={{ fontSize: '30px', color: 'black' }}>
                            {
                                data.userCreator && data.userCreator.profesion
                            }
                        </Text>
                        <Text
                            style={{ fontSize: '30px', color: 'black' }}>
                            {
                                data.userApprover && data.userApprover.profesion
                            }
                        </Text>
                    </View>
                    <View style={{ width: '95%', height: '20%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-5%' }}>
                        <Text
                            style={{ fontSize: '30px' }}>
                            {data.userCreator && data.userCreator.person && data.userCreator.person.name + " " +
                                data.userCreator.person.secondName + " " +
                                data.userCreator.person.lastName + " " +
                                data.userCreator.person.secondLastName}
                        </Text>
                        <Text
                            style={{ fontSize: '30px' }}>
                            {(data.userApprover.person) && data.userApprover.person.name + " " +
                                data.userApprover.person.secondName + " " +
                                data.userApprover.person.lastName + " " +
                                data.userApprover.person.secondLastName}
                        </Text>
                    </View>
                </View>
            </Page>
        </Document >
    );

    const ModalTransfer = () => {
        const [visiblePDF, setVisiblePDF] = useState(false)
        return (
            <Modal className='ModalEquip' width={visiblePDF ? '90vw' : '38vw'} visible={visibleModalTransfer} footer={null} onCancel={() => {
                setVisibleModalTransfer(false)
            }}>
                {!visiblePDF && <p className="title">{data ? data.equipment.name : ''}</p>}
                {!visiblePDF && <div className='div'>
                    <button className='button' onClick={() => {
                        setVisiblePDF(true)
                    }}>
                        Ver detalles
                    </button>
                    {!extraButtons && data.status !== "Aprobado" && dependenciesFromBoss &&
                        <button className='button' onClick={() => {
                            if (window.confirm("¿Seguro deseas aceptar este traslado?")) {
                                dispatch(TransferActions.makeTransfer({ "id": data.id, "idUser": idUser }))
                            }
                        }}>
                            {loadingMakeTransfer ? <LoadingOutlined /> : "Aceptar traslado"}
                        </button>}
                    {extraButtons && extraButtons}
                </div>}

                {visiblePDF &&
                    <PDFViewer className='pdf'>
                        {data.status === "Aprobado" ? <MyDocument1 /> : <MyDocument />}
                    </PDFViewer>}
            </Modal >
        )
    }

    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este equipo?')) {
            const dataDelete = {
                "id": data.id
            }
            dispatch(EquipmentActions.deleteEquipment(dataDelete))
        }
    }

    return (
        <div className='Transfer' >
            {image ?
                <img className='equipmentImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                if (extraOnClick) {
                    extraOnClick(data)
                } else {
                    setVisibleModalTransfer(true)
                }
            }}>
                <div><p className='pFontData name'>{data.equipment.name}</p></div>
                <div><p className='pFontData'>{brand}</p></div>
                <div><p className='pFontData'>{modell}</p></div>
                <div><p className='pFontData'>{serie}</p></div>
                <div><p className='pFontData'>{licensePlate}</p></div>
                <div><p className='pFontData'>{data.reasonReturn ? data.reasonReturn : ''}</p></div>
                <div>
                    <p className='pFontData'>
                        {data.dateCreated ?
                            (() => {
                                const date = new Date(data.dateCreated);
                                return String(date.getDate()).padStart(2, '0') + '/' +
                                    String(date.getMonth() + 1).padStart(2, '0') + '/' +
                                    date.getFullYear();
                            })()
                            : ''}
                    </p>
                </div>
                {data.status === "Aprobado" ? <div className='status'><p className='pFontStatus'>Aprobado</p></div>
                    : data.status === "Pendiente" && <div className='status1'><p className='pFontStatus'>{data.status}</p></div>}
            </div>
            <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>
            <ModalTransfer />
        </div>
    )
}