import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfNetworkingAndCommunicationsEquipment = ({ fullDataEquipment, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    console.log(fullDataEquipment)

    return (
        <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
            <View style={{ width: '100%', height: '3.7%', flexDirection: 'row', borderBottom: '3.2px solid #000' }}>
                <View style={{ width: '20%', height: '100%', borderRight: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image></View>
                <View style={{ width: '80%', height: '100%', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>Hoja de Inventarios Equipos</Text></View>
            </View>
            <View style={{ width: '100%', height: '1.8%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS BÁSICOS</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Fecha de Visita</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{new Date(fullDataEquipment.createdDate).getDate() + "/" + (new Date(fullDataEquipment.createdDate).getMonth() + 1) + "/" + new Date(fullDataEquipment.createdDate).getFullYear()}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Nombre Sede</Text></View>
                    <View style={{ width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{enterprise[0].name} SEDE CENTRAL</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Ciudad</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>FLORENCIA</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Departamento</Text></View>
                    <View style={{ width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CAQUETA</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Dirección</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>FLORENCIA</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '50%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '25%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>N° Consecutivo</Text></View>
                    <View style={{ width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>CAQUETA</Text></View>
                </View>
            </View>

            <View style={{ marginTop: '0.7%', width: '100%', height: '1.8%', flexDirection: 'row', borderBottom: '3.2px solid #000', borderTop: '3.2px solid #000', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS DE QUIEN REALIZA LA VISITA</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Nombres y Apellidos</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.userCreator.person.name + " " + fullDataEquipment.userCreator.person.secondName + " " + fullDataEquipment.userCreator.person.lastName + " " + fullDataEquipment.userCreator.person.secondLastName + " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Correo</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.userCreator.email + " "}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Cargo</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.userCreator.position + " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Télefono</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.userCreator.person.phone + " "}</Text></View>
                </View>
            </View>
            <View style={{ width: '100%', height: '1.8%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS DE QUIEN ATIENDE LA VISITA</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '23.076923076923077%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Nombre y Apellidos</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '76.92307692307693%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.dependencyUser.length > 0 ? (fullDataEquipment.dependency.dependencyUser[0].user.person.name + " " + fullDataEquipment.dependency.dependencyUser[0].user.person.secondName + " " + fullDataEquipment.dependency.dependencyUser[0].user.person.lastName + " " + fullDataEquipment.dependency.dependencyUser[0].user.person.secondLastName + " ") : "No hay Jefe asignado"}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '23.076923076923077%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Cargo:</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '76.92307692307693%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.dependencyUser.length > 0 ? (fullDataEquipment.dependency.dependencyUser[0].user.position + " ") : "No hay Jefe asignado"}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Télefono</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.dependencyUser.length > 0 ? (fullDataEquipment.dependency.dependencyUser[0].user.person.phone + " ") : "No hay Jefe asignado"}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Ubicación:</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.location.name + " "}</Text></View>
                </View>
            </View>
            <View style={{ width: '100%', height: '1.8%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS DEL EQUIPO</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Fecha Inicio Garantia</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{new Date(fullDataEquipment.networkingAndCommunicationsEquipment.startWarranty).getDate() + "/" + (new Date(fullDataEquipment.networkingAndCommunicationsEquipment.startWarranty).getMonth() + 1) + "/" + new Date(fullDataEquipment.networkingAndCommunicationsEquipment.startWarranty).getFullYear()}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Fecha Fin Garantia</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{new Date(fullDataEquipment.networkingAndCommunicationsEquipment.endWarranty).getDate() + "/" + (new Date(fullDataEquipment.networkingAndCommunicationsEquipment.endWarranty).getMonth() + 1) + "/" + new Date(fullDataEquipment.networkingAndCommunicationsEquipment.endWarranty).getFullYear()}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>IP</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.networkingAndCommunicationsEquipment.ipAddress}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Mac</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.networkingAndCommunicationsEquipment.macAddress}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '23.076923076923077%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Correo electrónico:</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '76.92307692307693%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.email + " "}</Text></View>
                </View>
            </View>

            <View style={{ width: '100%', height: '1.8%', flexDirection: 'row', backgroundColor: '#525252', borderBottom: '2px solid #000' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Tipo</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Marca</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '14%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Modelo (Version)</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Serial</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Placa de Inventario</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Registro fotografico</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Registro fotografico Serial</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '7%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Estado</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '8%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Novedad</Text></View>
            </View>
            <View style={{ width: '100%', height: '4.3%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.name + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.networkingAndCommunicationsEquipment.brand + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '14%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.networkingAndCommunicationsEquipment.modell + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.networkingAndCommunicationsEquipment.serie + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.licensePlate + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Image style={{ width: '100%', height: '100%' }} src={fullDataEquipment.networkingAndCommunicationsEquipment.image}></Image></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Image style={{ width: '100%', height: '100%' }} src={fullDataEquipment.networkingAndCommunicationsEquipment.imageSerial}></Image></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '7%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.state + " "}</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '8%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.networkingAndCommunicationsEquipment.novelty + " "}</Text></View>
            </View>


            <View style={{ marginTop: '0%', width: '100%', minHeigth: '50px', alignItems: 'flex-start', justifyContent: 'flex-start', border: '2px solid #000', borderTop: 0 }}>
                <Text style={{ color: 'black', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder' }}>Observaciones:</Text>
                <Text style={{ color: 'black', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.networkingAndCommunicationsEquipment.clientObservation}</Text>
            </View>
            <View style={{ marginTop: '1%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Image style={{ width: '30%', height: '150%' }} src={fullDataEquipment.userCreator.person.firm ? fullDataEquipment.userCreator.person.firm : blanco}></Image>
            </View>
            <View style={{ marginTop: '3%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-3%' }}>
                <Text
                    style={{ fontSize: '20px', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-2.7%' }}>
                <Text
                    style={{ fontSize: '18px' }}>
                    {(fullDataEquipment.userCreator.person && fullDataEquipment.userCreator.person.firm) && fullDataEquipment.userCreator.person.name + " " +
                        fullDataEquipment.userCreator.person.secondName + " " +
                        fullDataEquipment.userCreator.person.lastName + " " +
                        fullDataEquipment.userCreator.person.secondLastName}
                </Text>
            </View>

            <View style={{ width: '95%', marginTop: '0%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '18px' }}>AGENTE QUE REALIZA EL REPORTE</Text>
            </View>
        </Page>
    )
}