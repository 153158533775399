import { Input } from '../../../components/Input/Input'
import { useForm } from 'react-hook-form'
import { Select, message } from 'antd'
import { useState, useEffect } from 'react'
import { EquipmentActions } from '../../../services/Equipment/EquipmentSlice'
import Icon, { PlusOutlined, SearchOutlined, LoadingOutlined, CloseCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux"
import { ModuleWorkCard } from '../../../components/ModuleWorkCard/ModuleWorkCard'
import { TicketOption } from '../../../components/TicketOption/TicketOption'
import { useHistory, useLocation } from 'react-router-dom';
import { Equipment } from '../../../components/Equipment/Equipment'
import { EquipmentSystem } from '../../../components/EquipmentSystem/EquipmentSystem'
import { TicketActions } from '../../../services/Ticket/TicketSlice'

export const Tickets = () => {
    const { loadingEquipment, equipmentsSearched } = useSelector(state => state.Equipment)
    const { roleUser, moduleWorks } = useSelector(state => state.Login)
    const { moduleWork } = useSelector(state => state.Area)
    const { lastNumber } = useSelector(state => state.Ticket)
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        register: registerSearchEquipsForTicket,
        handleSubmit: handleSubmitSearchEquipsForTicket,
        setValue: setValueSearchEquipsForTicket,
        watch: watchSearchEquipsForTicket,
        control: controlSearchEquipsForTicket,
        formState: { errors: errorsSearchEquipsForTicket },
        trigger: triggerSearchEquipsForTicket,
        reset: resetSearchEquipsForTicket
    } = useForm({
        defaultValues: {
            searchPlateOrSerie: null
        }
    })

    const {
        register: registerCreateTicket,
        handleSubmit: handleSubmitCreateTicket,
        setValue: setValueCreateTicket,
        watch: watchCreateTicket,
        control: controlCreateTicket,
        formState: { errors: errorsCreateTicket },
        trigger: triggerCreateTicket,
        reset: resetCreateTicket
    } = useForm({
        defaultValues: {
            serviceType: null,
            equipment: null,
            subject: null,
            description: null,
            number: null,
            image: null,
        }
    })

    useEffect(() => {
        dispatch(TicketActions.getLastNumber())
    }, [])

    useEffect(() => {
        if (lastNumber.max) {
            resetCreateTicket({
                ...watchCreateTicket(),
                number: lastNumber.max + 1
            });
        } else {
            resetCreateTicket({
                ...watchCreateTicket(),
                number: "1"
            });
        }
    }, [lastNumber]);

    useEffect(() => {
        moduleWork === false && history.push('/Home')
    }, [moduleWork])

    const componentMap = {
        1: Equipment,
        2: EquipmentSystem,
        // añade más mapeos si necesitas
    };
    const Component = componentMap[moduleWork.id ? moduleWork.id : 1];

    return (
        <div className="Tickets">
            <div className='Form'>
                <div className='grayHeader'>
                    <h1>Nueva solicitud para {moduleWork.name}</h1>
                    <h1>Ticket {watchCreateTicket('number') ? watchCreateTicket('number') : '...'}</h1>
                </div>
                <div className='subPanel'>
                    <Select
                        className='select'
                        placeholder="Tipo de servicio"
                        onChange={e => {
                            setValueCreateTicket('serviceType', e)
                        }}
                    >
                        <Select.Option value="Pregunta">Pregunta</Select.Option>
                        <Select.Option value="Incidente">Incidente</Select.Option>
                        <Select.Option value="Petición de servicio">Petición de servicio</Select.Option>
                        <Select.Option value="Problema">Problema</Select.Option>
                        <Select.Option value="Solicitud de cambio">Solicitud de cambio</Select.Option>
                    </Select>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1> Prioridad-Alta</h1>
                        <div style={{
                            backgroundColor: 'red',
                            borderRadius: '50px',
                            width: '2vw',
                            height: '2vw',
                            border: '0.3vw solid #FFFFFF',
                            boxShadow: '0px 4px 4px 0px #00000040',
                            marginLeft: '0.5vw'
                        }}>
                        </div>
                    </div>
                </div>
                <div className='grayHeader'>
                    <h1 style={{ fontWeight: '600' }}>Dispositivos</h1>
                </div>
                <form
                    className='subPanel'
                    style={{ justifyContent: 'center' }}
                    onSubmit={handleSubmitSearchEquipsForTicket(e => {
                        if (moduleWork) {
                            dispatch(EquipmentActions.search(
                                {
                                    "plateOrSerie": e.searchPlateOrSerie,
                                    "take": 8,
                                    "page": 0,
                                    "moduleWork": moduleWork.id
                                }))
                        } else {
                            message.warn("Debe elegir un módulo")
                        }
                    })}>
                    {!loadingEquipment ?
                        <Input
                            {...registerSearchEquipsForTicket(`searchPlateOrSerie`, {

                            })}
                            height={'2vw'}
                            mt={'0.125vw'}
                            width={'37vw'}
                            textAlign={'center'}
                            bgColor={'#D9D9D9'}
                            color={'#6A6A6A'}
                            fontWeight={'600'}
                            va={watchSearchEquipsForTicket(`searchPlateOrSerie`)}
                            setData={e => setValueSearchEquipsForTicket(`searchPlateOrSerie`, e)}
                            labelstyle='inputLabelBordered'
                            placeholder='Buscar Equipo por placa o serie'
                            error={errorsSearchEquipsForTicket[`searchPlateOrSerie`] && errorsSearchEquipsForTicket[`searchPlateOrSerie`].message}
                        />
                        :
                        <LoadingOutlined />}

                </form>
                {equipmentsSearched &&
                    <div className='subPanel' style={{ marginBottom: '-2vw' }}>
                        <h3>Imagen:</h3>
                        <h3>Nombre:</h3>
                        <h3>Marca:</h3>
                        <h3>Modelo:</h3>
                        <h3>Serie:</h3>
                        <h3>Placa:</h3>
                        <h3>Ubicación:</h3>
                    </div >}
                {!watchCreateTicket("equipment")
                    ? equipmentsSearched && equipmentsSearched.map(d => (
                        <Component
                            key={d.id}
                            data={d}
                            width={'50vw'}
                            height={'4vw'}
                            extraOnClick={() => {
                                setValueCreateTicket('equipment', d)
                            }}
                        />
                    ))
                    : equipmentsSearched &&
                    <Component
                        data={watchCreateTicket("equipment")}
                        width={'50vw'}
                        height={'4vw'}
                        extraOnClick={() => {
                            setValueCreateTicket('equipment', null)
                        }}
                    />
                }
                <div className='subPanel' style={{ flexDirection: 'column', marginLeft: '1vw' }}>
                    <Input
                        {...registerCreateTicket(`subject`, {
                        })}
                        label={'Asunto'}
                        textArea
                        width={'50vw'}
                        height={'4vw'}
                        setData={e => setValueCreateTicket(`subject`, e)}
                        labelstyle='inputLabelBordered'
                        va={watchCreateTicket(`subject`)}
                    />
                    <Input
                        {...registerCreateTicket(`description`, {
                        })}
                        label={'Descripcion'}
                        textArea
                        width={'50vw'}
                        height={'7vw'}
                        setData={e => setValueCreateTicket(`description`, e)}
                        labelstyle='inputLabelBordered'
                        va={watchCreateTicket(`description`)}
                    />
                    <div style={{
                        marginTop: '-0.80vw',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <h3>Foto</h3>
                        {!watchCreateTicket('image') ?
                            <input
                                {...registerCreateTicket('image', {
                                })}
                                type="file"
                                accept="image/*"
                                className='inputPhoto'
                                onChange={(event) => {
                                    const file = event.target.files[0];
                                    const reader = new FileReader();
                                    reader.addEventListener('load', () => {
                                        const base64 = reader.result;
                                        setValueCreateTicket('image', base64)
                                    });
                                    reader.readAsDataURL(file);
                                }}
                            />
                            :
                            <img src={watchCreateTicket("image")} className='inputPhoto' onClick={() => setValueCreateTicket('image', undefined)}></img>
                        }
                    </div>
                    <button className='button'>
                        Crear ticket
                    </button>
                </div>
            </div>
            {/* <div className='ModuleWorkCards' style={{ width: '40%' }}>
                <TicketOption
                    name={'Crear ticket'}
                />
            </div> */}
        </div >
    )
}