import { Layout } from 'antd'
/* import PrivateHeader from '../Private/Header/PrivateHeader' */

const PublicLayout = ({ children }) => {

    return (
        <Layout className='public-container-layout'>
            {/* <PrivateHeader /> */}
            <div className='login-container'>
                {children}
            </div>
        </Layout>
    )
}

export default PublicLayout