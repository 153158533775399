import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    dependencys: false,
    moduleWorks: false,
    enterprise: false,
    idUser: false,
    roles: false,
    roleUser: false,
    personInfo: false,
    coordinator: false,
    boss: false,
    allCoordinatorsAndBosses: false,
    userAndDependencies: false,
    photo: false,
    fullUser: false,
};

const LoginSlice = createSlice({
    name: "Login",
    initialState,
    reducers: {
        login(state, { payload }) {
            state.loading = true
        },
        setLoading(state, { payload }) {
            state.loading = payload
        },
        verifyLogin() { },
        setDependencys(state, { payload }) {
            state.dependencys = payload
        },
        setModuleWorks(state, { payload }) {
            state.moduleWorks = payload
        },
        setEnterprise(state, { payload }) {
            state.enterprise = payload
        },
        setId(state, { payload }) {
            state.idUser = payload
        },
        getRoles() { },
        setRoles(state, { payload }) {
            state.roles = payload
        },
        setRoleUser(state, { payload }) {
            state.roleUser = payload
        },
        getPersonInfo() { },
        setPersonInfo(state, { payload }) {
            state.personInfo = payload
        },
        getCoordinator() { },
        setCoordinator(state, { payload }) {
            state.coordinator = payload
        },
        getBoss() { },
        setBoss(state, { payload }) {
            state.boss = payload
        },
        getAllCoordinatorsAndBosses() { },
        setAllCoordinatorsAndBosses(state, { payload }) {
            state.allCoordinatorsAndBosses = payload
        },
        getUserAndDependencies() { },
        setUserAndDependencies(state, { payload }) {
            state.userAndDependencies = payload
        },
        setPhoto(state, { payload }) {
            state.photo = payload
        },
        setFullUser(state, { payload }) {
            state.fullUser = payload
        },
    },
});

const LoginActions = LoginSlice.actions;
const LoginReducer = LoginSlice.reducer;

export { LoginActions, LoginReducer };
