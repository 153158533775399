import Icon, { SearchOutlined, LoadingOutlined, CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import { Button, message, Select, Radio } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { FormTimeline } from "../../../components/FormTimeline/FormTimeline";
import { TimelineActions } from "../../../services/Timeline/TimelineSlice";
import { EquipmentActions } from "../../../services/Equipment/EquipmentSlice";
import { AreaActions } from "../../../services/Area/AreaSlice";


export const Timeline = () => {
    const history = useHistory()
    const [option, setOption] = useState('Add')
    const [visibleModal, setVisibleModal] = useState(false)
    const { equipsWithTimelines, loadingEquipsWithTimeline } = useSelector(state => state.Equipment)
    const { loadingTimeline, timelineSearched, equipsToSubmit, loadingEquipsToSubmit, timelinesByEquipment, loadingTimelinesByEquipment } = useSelector(state => state.Timeline)
    const [currentPage, setCurrentPage] = useState(0);
    const { dependencySelected, moduleWork } = useSelector(state => state.Area)
    const { idUser } = useSelector(state => state.Login)
    const [areaSelected, setAreaSelected] = useState(undefined)
    const { Option } = Select;

    const dispatch = useDispatch()

    const IconAddCronoSvg = () => (
        <svg viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V13H5.022C5.05265 13.3383 5.11458 13.6731 5.207 14H1C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H5.6C5.783 15.358 6.004 15.693 6.257 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H10C10.5304 0 11.0391 0.210714 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2V7.207C11.6731 7.11447 11.3383 7.05254 11 7.022V2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1ZM2 3V4C2 4.26522 2.10536 4.51957 2.29289 4.70711C2.48043 4.89464 2.73478 5 3 5H9C9.26522 5 9.51957 4.89464 9.70711 4.70711C9.89464 4.51957 10 4.26522 10 4V3C10 2.73478 9.89464 2.48043 9.70711 2.29289C9.51957 2.10536 9.26522 2 9 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3ZM3 3H9V4H3V3ZM15 12.5C15 13.6935 14.5259 14.8381 13.682 15.682C12.8381 16.5259 11.6935 17 10.5 17C9.30653 17 8.16193 16.5259 7.31802 15.682C6.47411 14.8381 6 13.6935 6 12.5C6 11.3065 6.47411 10.1619 7.31802 9.31802C8.16193 8.47411 9.30653 8 10.5 8C11.6935 8 12.8381 8.47411 13.682 9.31802C14.5259 10.1619 15 11.3065 15 12.5ZM11 10.5C11 10.3674 10.9473 10.2402 10.8536 10.1464C10.7598 10.0527 10.6326 10 10.5 10C10.3674 10 10.2402 10.0527 10.1464 10.1464C10.0527 10.2402 10 10.3674 10 10.5V12H8.5C8.36739 12 8.24021 12.0527 8.14645 12.1464C8.05268 12.2402 8 12.3674 8 12.5C8 12.6326 8.05268 12.7598 8.14645 12.8536C8.24021 12.9473 8.36739 13 8.5 13H10V14.5C10 14.6326 10.0527 14.7598 10.1464 14.8536C10.2402 14.9473 10.3674 15 10.5 15C10.6326 15 10.7598 14.9473 10.8536 14.8536C10.9473 14.7598 11 14.6326 11 14.5V13H12.5C12.6326 13 12.7598 12.9473 12.8536 12.8536C12.9473 12.7598 13 12.6326 13 12.5C13 12.3674 12.9473 12.2402 12.8536 12.1464C12.7598 12.0527 12.6326 12 12.5 12H11V10.5Z" fill="white" />
        </svg>
    );
    const IconCalendarSvg = () => (
        <svg viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.71289 5.84131H5.30973V7.13942H3.71289V5.84131ZM3.71289 8.43753H5.30973V9.73564H3.71289V8.43753ZM6.90657 5.84131H8.50342V7.13942H6.90657V5.84131ZM6.90657 8.43753H8.50342V9.73564H6.90657V8.43753ZM10.1003 5.84131H11.6971V7.13942H10.1003V5.84131ZM10.1003 8.43753H11.6971V9.73564H10.1003V8.43753Z" fill="white" />
            <path d="M2.11637 12.9811H13.2942C14.1749 12.9811 14.8911 12.3989 14.8911 11.683V3.89434V2.59623C14.8911 1.88032 14.1749 1.29811 13.2942 1.29811H11.6974V0H10.1006V1.29811H5.31005V0H3.71321V1.29811H2.11637C1.23571 1.29811 0.519531 1.88032 0.519531 2.59623V3.89434V11.683C0.519531 12.3989 1.23571 12.9811 2.11637 12.9811ZM13.2942 3.89434L13.295 11.683H2.11637V3.89434H13.2942Z" fill="white" />
        </svg>
    );

    const IconAddCrono = (props) => <Icon component={IconAddCronoSvg} {...props} />;
    const IconCalendar = (props) => <Icon component={IconCalendarSvg} {...props} />;

    const buttons = (t) => {
        t !== "SearchModal" && setOption(t)
    }

    return (
        <div className="Timeline">
            {/* <Tree
                lineWidth={'2px'}
                lineColor={'green'}
                lineBorderRadius={'10px'}
                label={<div className="treeNode">Organización</div>}>
                {timelineSearched && timelineSearched.map((d, i) => (
                    <TreeNode key={i} label={<div className="treeNode">{d.name}</div>}>
                        {d.dependency.map((de, i) => (
                            <TreeNode key={i} label={<div className="treeNode">{de.name}</div>} />
                        ))}
                    </TreeNode>
                ))}
            </Tree> */}
            <div className='buttonsSection'>
                {/* {option === 'Search' ? <div className="buttons">
                    <Button className="Unselected add" onClick={() => buttons('Add')}><IconAddCrono className="plus" /> Nuevo </Button>
                </div>
                    :
                    option === 'Add' ? <div className="buttons">
                        <Button className="Unselected prom" onClick={() => {
                            let aux = []
                            equipsToSubmit.map(d => {
                                aux.push({
                                    "month": d.month,
                                    "location": d.location,
                                    "dbState": true,
                                    "periodicity": d.periodicity,
                                    "state": "Programado",
                                    "equipment": d.id,
                                    "headquarter": d.headquarter === "null" ? null : d.headquarter,
                                    "user": idUser
                                })
                            })
                            if (aux.length > 0) {
                                dispatch(TimelineActions.createMany(aux))
                                history.push("/MPP")
                            } else {
                                message.warn("No hay nada para programar")
                            }
                        }}><IconCalendar className="plus" /> {!loadingEquipsToSubmit ? "Guardar" : <LoadingOutlined />} </Button>
                    </div> : true} */}
            </div>
            {/* <div className="selectsDependency">
                <Select
                    className="select"
                    placeholder='Area'
                    onChange={e => {
                        setAreaSelected(e)
                        dispatch(AreaActions.setDependencySelected(undefined))

                    }}
                >
                    {moduleWork && moduleWork[0].moduleWork.area.map(d => (
                        <Option key={d.id} value={d.id} >{d.name}</Option>
                    ))}
                </Select>
                {areaSelected &&
                    <Select
                        className="select"
                        placeholder='Dependencia'
                        value={dependencySelected}
                        onChange={e => dispatch(AreaActions.setDependencySelected(e))}
                    >
                        {areaSelected && moduleWork[0].moduleWork.area.filter(f => f.id === areaSelected)[0].dependency.map(d => (
                            <Option key={d.id} value={d.id}>{d.name}</Option>
                        ))}
                    </Select>}
            </div> */}
            <FormTimeline />
        </div>
    )
}