import { Button } from "antd";
import tools from "../../../assets/images/Vector (1).png";
import LogoAndTitle from "../../../assets/images/LogoAndTitle.svg";
/* import line from '../../../assets/images/Line 4.png'
import Girl from '../../../assets/images/LoginGirl.png' */
import { Input } from "../../../components/Input/Input";
import { CopyrightTwoTone, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DepMunActions } from "../../../services/DepMun/DepMunSlice";
import { useEffect, useState } from "react";
import { LoginActions } from "../../../services/Login/LoginSlices";
import { useForm } from "react-hook-form";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [passType, setPassType] = useState("password");

  const { loading } = useSelector((state) => state.Login);

  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    setValue: setValueLogin,
    watch: watchLogin,
    control: controlLogin,
    formState: { errors: errorsLogin },
    trigger: triggerLogin,
    reset: resetLogin,
  } = useForm({
    defaultValues: {
      name: "",
      pass: "",
    },
  });

  let t = localStorage.getItem("token");
  useEffect(() => {
    t !== null && history.push("/home");
    t !== null && dispatch(DepMunActions.getDepartments());
  }, [t, history, dispatch]);

  const showPass = (e) => {
    if (e === "password") {
      setPassType("showed");
    } else {
      setPassType("password");
    }
  };

  return (
    <div className="LoginDiv">
      <div className="Login">
        <form
          className="formDiv"
          onSubmit={handleSubmitLogin((data) => {
            dispatch(LoginActions.login(data));
          })}
        >
          <img className="imageLogoAndTitle" src={LogoAndTitle} />
          <p className="welcomeText">
            ¡Inicia sesión para tener acceso a tus datos más recientes!
          </p>
          <div className="inputsDiv">
            <Input
              {...registerLogin("name", {
                validate: (value) => {
                  const errors = [];
                  if (!value || value === "") {
                    errors.push("El correo es requerido");
                  }
                  return errors.length === 0 || errors.join(", ");
                },
              })}
              height={"3.5vw"}
              va={watchLogin("name")}
              setData={(e) => setValueLogin("name", e)}
              error={errorsLogin.name && errorsLogin.name.message}
              labelstyle="inputLabel"
              labelsize="1.7vw"
              placeholder="Usuario"
              border={0}
              bgColor={"#F0F0F0"}
              color={"#3C2F3D"}
              fontSize={"1.4vw"}
              mt={"-2.7vw"}
            />
            <Input
              {...registerLogin("pass", {
                validate: (value) => {
                  const errors = [];
                  if (!value || value === "") {
                    errors.push("La contraseña es requerida");
                  }
                  return errors.length === 0 || errors.join(", ");
                },
              })}
              height={"3.5vw"}
              va={watchLogin("pass")}
              setData={(e) => setValueLogin("pass", e)}
              error={errorsLogin.pass && errorsLogin.pass.message}
              labelstyle="inputLabel"
              type={passType}
              placeholder="Password"
              border={0}
              bgColor={"#F0F0F0"}
              color={"#3C2F3D"}
              fontSize={"1.4vw"}
              mt={"-2.7vw"}
            />
            <div className="checkbox">
              <div
                className={`box ${passType}`}
                id={passType}
                onClick={(e) => showPass(e.target.id)}
              ></div>
              <p style={{width:'10vw'}}>Mostrar contraseña</p>
            </div>
          </div>
          <button
            className="boton"
            onClick={() =>
              handleSubmitLogin((data) => {
                dispatch(LoginActions.login(data));
              })()
            }
          >
            {loading ? <LoadingOutlined /> : "Iniciar"}
          </button>
          {/* <div className="bottomDiv">
            <p className="APassword">¿Olvidaste tu contraseña?</p>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default Login;
