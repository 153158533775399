import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    EquipmentIndustrialCreatedId: false,
    personAssign: false,
    areaShared: false,
    EquipmentIndustrialsSearched: false,
    quantity: false,
    EquipmentIndustrialsFiltered: false,
    loadingEquipmentIndustrial: false,
    states: false,
    locations: false,
    equipsWithCaseReports: false,
    loadingEquipsWithCaseReport: false,
    quantityEquipsWithCaseReports: false,
    equipAndReportPDF: false,
    equipsForTimeline: false,
    loadingEquipsForTimeline: false,
    allEquipsForTimeline: false,
    equipsWithTimelines: false,
    loadingEquipsWithTimeline: false,
    quantityEquipsWithTimelines: false,
    fullDataEquipmentIndustrial: false,
    loadingFullDataEquipmentIndustrial: false,
    loadingUpdateEquipmentIndustrial: false,
    loadingCreateEquipmentIndustrial: false,
    isFiltered: false,
};

const EquipmentIndustrialSlice = createSlice({
    name: "EquipmentIndustrial",
    initialState,
    reducers: {
        setEquipmentIndustrialCreatedId(state, { payload }) {
            state.EquipmentIndustrialCreatedId = payload
        },
        createEquipmentIndustrial(state, { payload }) {
            state.loadingCreateEquipmentIndustrial = true
        },
        updateEquipmentIndustrial(state, { payload }) {
            state.loadingCreateEquipmentIndustrial = true
        },
        deleteEquipmentIndustrial() { },
        setPersonAssign(state, { payload }) {
            state.personAssign = payload
        },
        setAreaShared(state, { payload }) {
            state.areaShared = payload
        },
        setEquipmentIndustrialsSearched(state, { payload }) {
            state.EquipmentIndustrialsSearched = payload
        },
        searchEquipmentIndustrial(state) {
            state.loadingEquipmentIndustrial = true
        },
        setQuantity(state, { payload }) {
            state.quantity = payload
        },
        setEquipmentIndustrialsFiltered(state, { payload }) {
            state.EquipmentIndustrialsFiltered = payload
        },
        getEquipmentIndustrials(state, { payload }) {
            state.loadingEquipmentIndustrial = true
            state.EquipmentIndustrialsSearched = false
        },
        setLoading(state, { payload }) {
            state.loadingEquipmentIndustrial = payload
        },
        getStates() { },
        getLocations() { },
        setStates(state, { payload }) {
            state.states = payload
        },
        setLocations(state, { payload }) {
            state.locations = payload
        },
        getEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = false
            state.loadingEquipsWithCaseReport = true;
        },
        setEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = payload
        },
        setQuantityEquipsWithCaseReports(state, { payload }) {
            state.quantityEquipsWithCaseReports = payload
        },
        setLoadingEquipsWithCaseReport(state, { payload }) {
            state.loadingEquipsWithCaseReport = payload
        },
        searchCaseReport(state) {
            state.loadingEquipsWithCaseReport = true
        },
        getEquipAndReportPDF() { },
        setEquipAndReportPDF(state, { payload }) {
            state.equipAndReportPDF = payload
        },
        getEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = true
        },
        setEquipsForTimeline(state, { payload }) {
            state.equipsForTimeline = payload
        },
        setAllEquipsForTimeline(state, { payload }) {
            state.allEquipsForTimeline = payload
        },
        setLoadingEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = payload
        },
        getEquipsWithTimelines(state, { payload }) {
            state.loadingEquipsWithTimeline = true
        },
        setEquipsWithTimelines(state, { payload }) {
            state.equipsWithTimelines = payload
            state.loadingEquipsWithTimeline = false
        },
        setQuantityEquipsWithTimelines(state, { payload }) {
            state.quantityEquipsWithTimelines = payload
        },
        getFullDataEquipmentIndustrial(state, { payload }) {
            state.loadingFullDataEquipmentIndustrial = true
        },
        setFullDataEquipmentIndustrial(state, { payload }) {
            state.fullDataEquipmentIndustrial = payload
        },
        setLoadingFullDataEquipmentIndustrial(state, { payload }) {
            state.loadingFullDataEquipmentIndustrial = payload
        },
        setLoadingUpdateEquipmentIndustrial(state, { payload }) {
            state.loadingUpdateEquipmentIndustrial = payload
        },
        setLoadingCreateEquipmentIndustrial(state, { payload }) {
            state.loadingCreateEquipmentIndustrial = payload
        },
        getEquipmentIndustrialsByModuleWork() { },
        search(state, { payload }) {
            state.loadingEquipmentIndustrial = true
        },
        setIsFiltered(state, { payload }) {
            state.isFiltered = payload
        },
        getEquipsWithCaseReportsByModuleWork() { },
        getEquipsWithTimelinesByModuleWork() { },
    },
});

const EquipmentIndustrialActions = EquipmentIndustrialSlice.actions;
const EquipmentIndustrialReducer = EquipmentIndustrialSlice.reducer;

export { EquipmentIndustrialActions, EquipmentIndustrialReducer };
