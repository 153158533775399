import { Home } from '../scenes/Private/Home/Home'
import { Inventory } from '../scenes/Private/Inventory/Inventory'
import { MPP } from '../scenes/Private/MPP/MPP.js'
import { Petitions } from '../scenes/Private/Petitions/Petitions'
import { Reports } from '../scenes/Private/Reports/Reports'
import { ReportToFirm } from '../scenes/Private/ReportToFirm/ReportToFirm'
import { Timeline } from '../scenes/Private/Timeline/Timeline'
import { Training } from '../scenes/Private/Training/Training'
import { Summary } from '../scenes/Private/Summary/Summary'
import { Login } from '../scenes/Public'
import { Register } from '../scenes/Public/Register/Register'
import { MasivePDF } from '../scenes/Private/MasivePDF/MasivePDF'
import { MasivePDFInventory } from '../scenes/Private/MasivePDFInventory/MasivePDFInventory'
import { MyAccount } from '../scenes/Private/MyAccount/MyAccount'
import { Transfers } from '../scenes/Private/Transfers/Transfers.js'
import { Certificates } from '../scenes/Private/Certificates/Certificates.js'
import { Tickets } from '../scenes/Private/Tickets/Tickets.js'
import { Daily } from '../scenes/Private/Daily/Daily.js'
import { SpecializedCenter } from '../scenes/Private/SpecializedCenter/SpecializedCenter.js'
import { Drivers } from '../scenes/Private/Drivers/Drivers.js'

const PublicMap = [
  {
    restricted: true,
    exact: true,
    path: '/',
    component: Login
  },
  {
    restricted: true,
    exact: true,
    path: '/Register',
    component: Register
  },
]

const PrivateMap = [
  {
    restricted: true,
    exact: true,
    path: '/home',
    // component: Home
    component: Home
  },
  {
    restricted: true,
    exact: true,
    path: '/Inventarios',
    // component: Home
    component: Inventory
  },
  {
    restricted: true,
    exact: true,
    path: '/Reportes',
    // component: Home
    component: Reports
  },
  {
    restricted: true,
    exact: true,
    path: '/Peticiones',
    component: Petitions
  },
  {
    restricted: true,
    exact: true,
    path: '/Cronograma',
    component: Timeline
  },
  {
    restricted: true,
    exact: true,
    path: '/MPP',
    component: MPP
  },
  {
    restricted: true,
    exact: true,
    path: '/ReporteAFirmar',
    component: ReportToFirm
  },
  {
    restricted: true,
    exact: true,
    path: '/Capacitaciones',
    component: Training
  },
  {
    restricted: true,
    exact: true,
    path: '/Informes',
    component: Summary
  },
  {
    restricted: true,
    exact: true,
    path: '/PdfMasivos',
    component: MasivePDF
  },
  {
    restricted: true,
    exact: true,
    path: '/PdfMasivosInventory',
    component: MasivePDFInventory
  },
  {
    restricted: true,
    exact: true,
    path: '/MiCuenta',
    component: MyAccount
  },
  {
    restricted: true,
    exact: true,
    path: '/Traslados',
    component: Transfers
  },
  {
    restricted: true,
    exact: true,
    path: '/Certificados',
    component: Certificates
  },
  {
    restricted: true,
    exact: true,
    path: '/Tickets',
    component: Tickets
  },
  {
    restricted: true,
    exact: true,
    path: '/Daily',
    component: Daily
  },
  {
    restricted: true,
    exact: true,
    path: '/CentroEspecializado',
    component: SpecializedCenter
  },
  {
    restricted: true,
    exact: true,
    path: '/Conductores',
    component: Drivers
  },
]

export { PublicMap, PrivateMap }