import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dependency: false,
    moduleWork: false,
    equipmentTypes: [],
    agents: [],
    users: [],
    headquarters: [],
    areaUser: false,
    allDependencysFromArea: [],
    dependencySelected: undefined,
    locations: false,
    dependenciesFromBoss: false,
    dependenciesFromUser: false,
    dependenciesFromEnterprise:false,
};

const AreaSlice = createSlice({
    name: "Area",
    initialState,
    reducers: {
        setDependency(state, { payload }) {
            state.dependency = payload
        },
        setModuleWork(state, { payload }) {
            state.moduleWork = payload
        },
        getDependency() { },
        setEquipmentTypes(state, { payload }) {
            state.equipmentTypes = payload
        },
        getEquipmentTypes() { },
        setAgents(state, { payload }) {
            state.agents = payload
        },
        setUsers(state, { payload }) {
            state.users = payload
        },
        getUsers() { },
        getAgents() { },
        getHeadquarters() { },
        setHeadquarters(state, { payload }) {
            state.headquarters = payload
        },
        getAreaUser() { },
        setAreaUser(state, { payload }) {
            state.areaUser = payload
        },
        getAllDependencysFromArea() { },
        setAllDependencysFromArea(state, { payload }) {
            state.allDependencysFromArea = payload
        },
        setDependencySelected(state, { payload }) {
            state.dependencySelected = payload
        },
        getLocations() { },
        setLocations(state, { payload }) {
            state.locations = payload
        },
        getDependenciesFromBoss() { },
        setDependenciesFromBoss(state, { payload }) {
            state.dependenciesFromBoss = payload
        },
        getDependenciesFromUser() { },
        setDependenciesFromUser(state, { payload }) {
            state.dependenciesFromUser = payload
        },
        getDependenciesFromEnterprise() { },
        setDependenciesFromEnterprise(state, { payload }) {
            state.dependenciesFromEnterprise = payload
        },
    },
});

const AreaActions = AreaSlice.actions;
const AreaReducer = AreaSlice.reducer;

export { AreaActions, AreaReducer };
