import { message } from 'antd'
import { takeLatest, all, put, call } from 'redux-saga/effects'
import Api from "../../common/api"

import { TransferActions } from './TransferSlice'

function* getTransferSearched({ payload }) {
  const t = yield Api.get(`/transfer/byDependency?dependency=${payload.dependency}&page=${payload.page}&take=10`)
  /*  const t = yield Api.get("/area/allWithDependency") */
  if (t.ok) {
    yield put(TransferActions.setTransferSearched(t.payload))
  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(TransferActions.setLoadingTransfer(false))
}

function* createTransfer({ payload }) {
  const body = {
    "dateCreated": new Date(),
    "reasonReturn": payload.reasonReturn,
    "reasonDescription": payload.reasonDescription,
    "dbState": true,
    "status": payload.status,
    "userCreator": payload.userCreator,
    "equipment": payload.idEquipment,
    "dependencyFrom": payload.dependencyFrom,
    "dependencyTo": payload.dependencyTo,
  }
  const c = yield Api.post('/transfer', body)
  if (c.ok) {
    message.success("Petición de traslado creada correctamente")
  } else {
    message.error("Error al crear petición de traslado")
  }
  yield put(TransferActions.setLoadinCreateTransfer(false))
}

function* getTransfersByModuleWork({ payload }) {
  let url = `/transfer/byModuleWork?moduleWork=${payload.moduleWork}&page=${payload.page}&take=10`;
  if (payload.status) {
    url += `&status=${payload.status}`;
  }
  if (payload.dependencyTo) {
    url += `&dependencyTo=${payload.dependencyTo}`;
  }

  const t = yield Api.get(url);
  if (t.ok) {
    yield put(TransferActions.setTransfers(t.payload));
  } else {
    message.error("Error al obtener traslados");
  }
  yield put(TransferActions.setLoadinGetTransfers(false));
}

function* getTransferByModuleWorkFromDependenciesTo({ payload }) {
  let url = `/transfer/byModuleWorkFromDependenciesTo?moduleWork=${payload.moduleWork}&page=${payload.page}&take=10`;
  if (payload.status) {
    url += `&status=${payload.status}`;
  }
  if (payload.dependencyTo) {
    url += `&dependencyTo=${payload.dependencyTo}`;
  }
  payload.dependenciesFromBoss.forEach((dependency, i) => {
    url += `&dependenciesFromBoss${i}=${dependency.dependency.id}`;
  });

  const t = yield Api.get(url);
  if (t.ok) {
    yield put(TransferActions.setTransfers(t.payload));
  } else {
    message.error("Error al obtener traslados");
  }
  yield put(TransferActions.setLoadinGetTransfers(false));
}

function* makeTransfer({ payload }) {
  const t = yield Api.post(`/transfer/makeTransfer?id=${payload.id}&idUser=${payload.idUser}`)
  if (t.ok) {
    message.success("Traslado realizado correctamente")
  } else {
    message.error("Error al realizar traslado")
  }
  yield put(TransferActions.setLoadinMakeTransfer(false))
}

function* updateTransfer({ payload }) {
  const t = yield Api.post(`/transfer/update?id=${payload.id}`, payload.body)
  if (t.ok) {
    message.success("Traslado actualizado correctamente")
  } else {
    message.error("Error al actualizar traslado")
  }
}


function* actionWatcher() {
  yield takeLatest(TransferActions.getTransferSearched, getTransferSearched)
  yield takeLatest(TransferActions.createTransfer, createTransfer)
  yield takeLatest(TransferActions.getTransfersByModuleWork, getTransfersByModuleWork)
  yield takeLatest(TransferActions.makeTransfer, makeTransfer)
  yield takeLatest(TransferActions.getTransferByModuleWorkFromDependenciesTo, getTransferByModuleWorkFromDependenciesTo)
  yield takeLatest(TransferActions.updateTransfer, updateTransfer)
}

export default function* TransferSaga() {
  yield all([actionWatcher()])
}