import { useEffect, useState } from "react"
import { FormSummary } from "../../../components/FormSummary/FormSummary"
import { Button, DatePicker, Modal, Select, message } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SummaryActions } from "../../../services/Summary/SummarySlice";
import { SummaryCard } from "../../../components/SummaryCard/SummaryCard";
import { Controller, set, useForm } from "react-hook-form";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import { CaseReportActions } from "../../../services/caseReport/CaseReportSlice";
import { useHistory } from 'react-router-dom'
import { EquipmentActions } from "../../../services/Equipment/EquipmentSlice";


export const Summary = () => {
    const dispatch = useDispatch()
    const [option, setOption] = useState('')
    const { idUser, dependencys } = useSelector(state => state.Login)
    const { moduleWork } = useSelector(state => state.Area)
    const { summaries } = useSelector(state => state.Summary)
    const { loadingMasiveReports, masiveReports } = useSelector(state => state.CaseReport)
    const { masiveInventory, loadingMasiveInventory } = useSelector(state => state.Equipment)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModal1, setVisibleModal1] = useState(false)
    const [areaSelected, setAreaSelected] = useState(false)
    const { Option } = Select
    const [pushPage, setPushPage] = useState(0)
    const history = useHistory()

    const {
        register: registerGenerateReports,
        handleSubmit: handleSubmitGenerateReports,
        setValue: setValueGenerateReports,
        watch: watchGenerateReports,
        control: controlGenerateReports,
        formState: { errors: errorsGenerateReports },
        trigger: triggerGenerateReports,
        reset: resetGenerateReports
    } = useForm({
        defaultValues: {
            month: "",
            dependency: undefined,
        }
    })

    const {
        register: registerGenerateInventory,
        handleSubmit: handleSubmitGenerateInventory,
        setValue: setValueGenerateInventory,
        watch: watchGenerateInventory,
        control: controlGenerateInventory,
        formState: { errors: errorsGenerateInventory },
        trigger: triggerGenerateInventory,
        reset: resetGenerateInventory
    } = useForm({
        defaultValues: {
            dependency: undefined,
        }
    })

    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    useEffect(() => {
        option === "search" && dispatch(SummaryActions.getSummaries({ moduleWork: moduleWork[0].moduleWork.id, userCreator: idUser }))
    }, [option])

    useEffect(() => {
        if (pushPage === 1) {
            setPushPage(prev => prev + 1)
            history.push('/PdfMasivos', { data: masiveReports })
            dispatch(CaseReportActions.setMasiveReports(false))
        }
        if (pushPage === 2) {
            setPushPage(prev => prev + 2)
            history.push('/PdfMasivosInventory', { data: masiveInventory })
            dispatch(EquipmentActions.setMasiveInventory(false))
        }
    }, [pushPage])

    useEffect(() => {
        if (masiveReports) {
            setPushPage(prev => prev + 1)
        } else {
            setPushPage(0)
        }
    }, [masiveReports])

    useEffect(() => {
        if (masiveInventory) {
            setPushPage(prev => prev + 2)
        } else {
            setPushPage(0)
        }
    }, [masiveInventory])

    return (
        <div className="Summary">
            <ArrowLeftOutlined className='arrow' onClick={() => setOption('')} />
            {option === '' &&
                <div className="cards">
                    <div className="card" id='card1' onClick={() => {
                        if (moduleWork) {
                            setOption('add')
                        } else {
                            message.warn("Debe elegir modulo")
                        }
                    }}>
                        <div className="green"></div>
                        <p className="title">Generar Informe Mensual</p>
                    </div>
                    <div className="card" id='card2' onClick={() => {
                        if (moduleWork) {
                            setOption('search')
                        } else {
                            message.warn("Debe elegir modulo")
                        }
                    }}>
                        <div className="green"></div>
                        <p className="title">Histórico Informes</p>
                    </div>
                    <div className="card" id='card3' onClick={() => {
                        if (moduleWork) {
                            setVisibleModal1(true)
                        } else {
                            message.warn("Debe elegir modulo")
                        }
                    }}>
                        <div className="green"></div>
                        <p className="title">Generar Inventarios Masivos Equipos Pdf Mensual</p>
                    </div>
                    <div className="card" id='card4' onClick={() => {
                        if (moduleWork) {
                            setVisibleModal(true)
                        } else {
                            message.warn("Debe elegir modulo")
                        }
                    }}>
                        <div className="green"></div>
                        <p className="title">Generar Reporte Masivos Mantenimientos Pdf Mensual</p>
                    </div>
                </div>
            }
            {option === 'add' &&
                <FormSummary />
            }
            {option === 'search' &&
                <div className="dataDiv">
                    <div className="titleItems">
                        <p>N° Informe</p>
                        <p>Numero de contrato:</p>
                        <p>Nombre agente:</p>
                        <p>Cargo:</p>
                        <p>Año:</p>
                        <p>Mes:</p>
                        <p>Fecha:</p>
                    </div>
                    {summaries && summaries.map((item, index) => (
                        <SummaryCard key={index} data={item} />
                    ))}
                </div>
            }
            <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
                <p className="titleModal">Descargar Reportes</p>
                <div style={{ alignSelf: 'center', justifySelf: 'center' }}>
                    <p className="label" style={{ marginBottom: '0' }}>Mes</p>
                    <DatePicker
                        picker='month'
                        value={watchGenerateReports("month")}
                        {...registerGenerateReports('month', {
                            validate: (value) => {
                                const errors = [];
                                if (!value || value === '') {
                                    errors.push("El mes es requerido");
                                }
                                return errors.length === 0 || errors.join(', ');
                            },
                        })}
                        style={{ width: '35vw', height: '3vw', alignSelf: 'flex-start', marginBottom: '2vw' }}
                        onChange={d => setValueGenerateReports("month", d)}
                    />
                </div>
                {errorsGenerateReports.month && <p style={{ fontSize: '1vw', color: 'red', marginTop: '-1.5vw' }}>{errorsGenerateReports.month.message}</p>}
                <div className="selectsDependency">
                    <p className="label" style={{ marginTop: '-1vw', marginBottom: '0vw' }}>Area</p>
                    <Select
                        className="select"
                        placeholder='Area'
                        onChange={e => {
                            setAreaSelected(e)
                        }}
                    >
                        {dependencys && areas.map(d => (
                            <Option key={d.id} value={d.id} >{d.name}</Option>
                        ))}
                    </Select>
                    <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Dependencia</p>
                    {areaSelected && <Controller
                        name="dependency"
                        control={controlGenerateReports}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder='Dependencia'
                                value={watchGenerateReports('dependency')}
                                className="select"
                            >
                                {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        )}
                    />}
                </div>

                <div className="divButton">
                    {!loadingMasiveReports ?
                        <Button className="button" style={{ marginTop: '1.5vw' }} onClick={() => handleSubmitGenerateReports((dataSubmit) => {
                            dataSubmit.moduleWork = moduleWork[0].moduleWork.id
                            dispatch(CaseReportActions.getMasiveReports(dataSubmit))
                        })()}><DownloadOutlined className="plus" /> Descargar</Button>
                        :
                        <Button className="button" style={{ marginTop: '1.5vw' }}><LoadingOutlined className="plus" /> Cargando</Button>
                    }
                </div>
            </Modal>
            <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModal1} footer={null} onCancel={() => setVisibleModal1(false)}>
                <p className="titleModal">Descargar Inventarios</p>
                <div className="selectsDependency">
                    <p className="label" style={{ marginTop: '-1vw', marginBottom: '0vw' }}>Area</p>
                    <Select
                        className="select"
                        placeholder='Area'
                        onChange={e => {
                            setAreaSelected(e)
                        }}
                    >
                        {dependencys && areas.map(d => (
                            <Option key={d.id} value={d.id} >{d.name}</Option>
                        ))}
                    </Select>
                    <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Dependencia</p>
                    {areaSelected && <Controller
                        name="dependency"
                        control={controlGenerateInventory}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder='Dependencia'
                                value={watchGenerateInventory('dependency')}
                                className="select"
                            >
                                {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        )}
                    />}
                </div>

                <div className="divButton">
                    {!loadingMasiveInventory ?
                        <Button className="button" style={{ marginTop: '1.5vw' }} onClick={() => handleSubmitGenerateInventory((dataSubmit) => {
                            dataSubmit.moduleWork = moduleWork[0].moduleWork.id
                            dispatch(EquipmentActions.getMasiveInventory(dataSubmit))
                        })()}><DownloadOutlined className="plus" /> Descargar</Button>
                        :
                        <Button className="button" style={{ marginTop: '1.5vw' }}><LoadingOutlined className="plus" /> Cargando</Button>
                    }
                </div>
            </Modal>
        </div >
    )
}