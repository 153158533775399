import { useEffect, useRef, useState } from "react"
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker, message, Modal, Radio, Select } from 'antd'
import Icon, { SaveOutlined, SearchOutlined, LoadingOutlined } from "@ant-design/icons";

import { DepMunActions } from "../../../services/DepMun/DepMunSlice";
import { EquipmentActions } from "../../../services/Equipment/EquipmentSlice";
import { Input } from "../../../components/Input/Input";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { Document, Page, PDFDownloadLink, Text, View } from "@react-pdf/renderer";
import { Equipment } from "../../../components/Equipment/Equipment";
import { FormEquipmentSystem } from "../../../components/FormEquipmentSystem/FormEquipmentSystem";
import { EquipmentSystem } from "../../../components/EquipmentSystem/EquipmentSystem";
import { FormEquipmentIndustrial } from "../../../components/FormEquipmentIndustrial/FormEquipmentIndustrial";
import { EquipmentIndustrial } from "../../../components/EquipmentIndustrial/EquipmentIndustrial";
import { FormEquipmentVehicle } from "../../../components/FormEquipmentVehicle/FormEquipmentVehicle";
import ArimoBold from '../../../assets/fonts/Arimo-Bold.ttf';
import { EquipmentVehicle } from "../../../components/EquipmentVehicle/EquipmentVehicle";
import DriversImage from "../../../assets/images/DriversImage.svg";
import AssignDriverImage from "../../../assets/images/AssignDriverImage.svg"
import { FormSpecializedCenter } from "../../../components/FormSpecializedCenter/FormSpecializedCenter";
import { Controller, useForm } from "react-hook-form";
import { SpecializedCenterActions } from "../../../services/SpecializedCenter/SpecializedCenterSlice";
import { SpecializedCenterCard } from "../../../components/SpecializedCenterCard/SpecializedCenterCard";
import { FormAssignDriver } from "../../../components/FormAssignDriver/FormAssignDriver";

export const Drivers = () => {
    const [option, setOption] = useState('Search')
    const [edit, setEdit] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [textXLSX, setTextXLSX] = useState("")
    const [textPDF, setTextPDF] = useState("")
    const [statusToFilter, setStatusToFilter] = useState(false)
    const [yearToFilter, setYearToFilter] = useState(false)
    const [nameToFilter, setNameToFilter] = useState(false)
    const [nitToFilter, setNitToFilter] = useState(false)
    const [typeToFilter, setTypeToFilter] = useState(false)
    const [monthToFilter, setMonthToFilter] = useState(false)

    const { Option } = Select;
    const dispatch = useDispatch()

    const { equipmentsSearched, equipmentsFiltered, loadingEquipment, quantity, fullDataEquipment, isFiltered } = useSelector(state => state.Equipment)
    const { dependency, moduleWork } = useSelector(state => state.Area)
    const { dependencys, fullUser } = useSelector(state => state.Login)
    const { loadingGetSpecializedCenters, isFilteredSpecializedCenters, specializedCentersSearched, fullDataSpecializedCenter } = useSelector(state => state.SpecializedCenter)
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageFiltered, setCurrentPageFiltered] = useState(0);

    useEffect(() => {
        !isFilteredSpecializedCenters && moduleWork && option &&
            dispatch(SpecializedCenterActions.getSpecializedCenters({
                "name": "",
                "nit": "",
                "type": "",
                "status": "",
                "year": "",
                "month": "",
                "take": 4,
                "page": currentPage
            }))
    }, [currentPage, dispatch, moduleWork, option])

    useEffect(() => {
        isFilteredSpecializedCenters && dispatch(SpecializedCenterActions.getSpecializedCenters(
            {
                "name": nameToFilter,
                "nit": nitToFilter,
                "type": typeToFilter,
                "status": statusToFilter,
                "year": yearToFilter,
                "month": monthToFilter,
                "take": 4,
                "page": currentPageFiltered,
            }))
    }, [currentPageFiltered, dispatch])

    useEffect(() => {
        dispatch(EquipmentActions.getLocations())
        dispatch(EquipmentActions.getStates())
    }, [dispatch])

    const onExitSearch = () => {
        setVisibleExitSearch(false)
        dispatch(EquipmentActions.setEquipmentsFiltered(false))
    }

    const buttons = (t) => {
        if (moduleWork !== false) {
            if (t === "SearchModal") {
                setVisibleModal(true);
                setTextXLSX("");
                setTextPDF("");
                setCurrentPage(0)
                setCurrentPageFiltered(0)
            } else {
                setOption(t);
                setEdit(false);
            }
        } else {
            message.warn("Debe elegir un módulo de trabajo")
        }
        dispatch(SpecializedCenterActions.setFilteredSpecializedCenters(false))
    }

    /*  const onSearch = (nameEquip, location, dependencySelected, licensePlate) => {
         setVisibleModal(false)
         dispatch(EquipmentActions.search(
             {
                 "dependency": dependencySelected,
                 "nameEquip": nameEquip,
                 "location": location,
                 "licensePlate": licensePlate,
                 "take": 8,
                 "page": currentPageFiltered,
                 "moduleWork": moduleWork[0].moduleWork.id
             }))
         setDependencySelectedToFilter(dependencySelected)
         setNameEquipToFilter(nameEquip)
         setLocationToFilter(location)
         setLicensePlateToSearchToFilter(licensePlate)
 
     } */

    const createXLSX = (nameEquip, state, location) => {
        dispatch(EquipmentActions.searchEquipment(
            {
                "area": dependency,
                "nameEquip": nameEquip,
                "state": state,
                "location": location
            }))
        setTextXLSX("Descargar")
    }

    const createPDF = (nameEquip, state, location) => {
        dispatch(EquipmentActions.searchEquipment(
            {
                "area": dependency,
                "nameEquip": nameEquip,
                "state": state,
                "location": location
            }))
        setTextPDF("Descargar")
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    async function downloadXLSX() {
        if (textXLSX === "Descargar") {
            var data = [];
            equipmentsFiltered.map(d => {
                data.push({
                    nombre: d.biomedicEquipment.name,
                    marca: d.biomedicEquipment.brand,
                    modelo: d.biomedicEquipment.modell,
                    serie: d.biomedicEquipment.serie,
                    placa: d.licensePlate,
                    ubicacion: d.biomedicEquipment.location,
                    estado: d.state,
                    agente: d.userAssigned.person.name + " " + d.userAssigned.person.lastName
                })
                return null
            })
            let header = ["Nombre", "Marca", "Modelo", "Serie", "Placa", "Ubicación", "Estado", "Agente"];

            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
                const sheet1 = workbook.sheet(0);
                const sheetData = getSheetData(data, header);
                const totalColumns = sheetData[0].length;

                sheet1.cell("A1").value(sheetData);
                const range = sheet1.usedRange();
                const endColumn = String.fromCharCode(64 + totalColumns);
                sheet1.row(1).style("bold", true);
                sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
                range.style("border", true);
                return workbook.outputAsync().then((res) => {
                    saveAs(res, "BusquedaInventario.xlsx");
                });
            });
        }
    }

    const MyDocument = () => (
        <Document>
            <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View style={{ border: '3px solid #000', marginTop: '1%', width: '93%', height: '2%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>NOMBRE</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>MARCA</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>MODELO</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>SERIE</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>PLACA</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>UBICACIÓN</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>ESTADO</Text>
                    </View>
                    <View style={{ height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>AGENTE</Text>
                    </View>
                </View>
                {equipmentsSearched.length > 0 && equipmentsSearched.map(d => (
                    <View style={{ border: '3px solid #000', width: '93%', height: '2%', flexDirection: 'row' }}>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.name}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.brand}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.modell}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.serie}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.licensePlate}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.location}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.state === "Fuera de servicio" ? "Fuera de servicio" : "Funcional"}</Text>
                        </View>
                        {/* <View style={{ height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.dependency.dependencyUser[0].userAssigned.person.name + " " + d.case.userAssigned.person.lastName}</Text>
                        </View> */}
                    </View>
                ))}
            </Page>
        </Document>
    );

    const DownloadSvg = () => (
        <svg width="2vw" height="2vw" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.38247 4.4625V0H1.16852C0.755297 0 0.422852 0.351094 0.422852 0.7875V16.0125C0.422852 16.4489 0.755297 16.8 1.16852 16.8H11.6079C12.0212 16.8 12.3536 16.4489 12.3536 16.0125V5.25H8.12814C7.71802 5.25 7.38247 4.89562 7.38247 4.4625ZM9.75775 11.3977L6.762 14.5379C6.55539 14.7548 6.2217 14.7548 6.01509 14.5379L3.01935 11.3977C2.70399 11.0673 2.92521 10.5 3.36888 10.5H5.39401V7.875C5.39401 7.58494 5.61646 7.35 5.89112 7.35H6.88535C7.16001 7.35 7.38247 7.58494 7.38247 7.875V10.5H9.40759C9.85127 10.5 10.0725 11.0673 9.75775 11.3977ZM12.1361 3.44531L9.09441 0.229687C8.95459 0.0820312 8.76507 0 8.56622 0H8.3767V4.2H12.3536V3.99984C12.3536 3.79312 12.2759 3.59297 12.1361 3.44531Z" fill="white" />
        </svg>
    );

    const ModalSearch = () => {
        const [nameEquip, setNameEquip] = useState(false)
        const [state, setState] = useState(false)
        const [location, setLocation] = useState(false)
        const [year, setYear] = useState(false)
        const [areaSelected, setAreaSelected] = useState(false)
        const [dependencySelected, setDependencySelected] = useState(false)
        const [licensePlateToSearch, setLicensePlateToSearch] = useState(false)

        const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];
        return (
            <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
                <p className="titleModal">Busqueda</p>
                <Input setData={setNameEquip} label={"Nombre del equipo"} width={"35vw"}></Input>
                <div className="selectsDependency">
                    <p className="label" style={{ marginTop: '-1vw', marginBottom: '0vw' }}>Area</p>
                    <Select
                        className="select"
                        placeholder='Area'
                        onChange={e => {
                            setAreaSelected(e)
                        }}
                    >
                        {dependencys && areas.map(d => (
                            <Option key={d.id} value={d.id} >{d.name}</Option>
                        ))}
                    </Select>
                    <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Dependencia</p>
                    {areaSelected &&
                        <Select
                            className="select"
                            placeholder='Dependencia'
                            onChange={e => setDependencySelected(e)}
                        >
                            {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>}
                </div>
                {/*                 <DatePicker onChange={e => setYear(e)} picker='year' style={{width:'35vw', height:'3vw'}}/>
 */}                <Input setData={setLicensePlateToSearch} label={"Placa del equipo"} width={"35vw"}></Input>
                {/* <div>
                    <p className="label">Ubicación</p>
                    <Select
                        className="select"
                        showSearch
                        placeholder="Ubicación"
                        optionFilterProp="children"
                        onChange={setLocation}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {locations && locations.map((d, i) => (
                            <Option key={i} value={d.location}>{d.location}</Option>
                        ))}
                    </Select>
                </div> */}
                {/* <div className="divExports" >
                    {textXLSX === "" ? <div className="divXLSX" onClick={() => createXLSX(nameEquip, state, location)}>
                        <Icon component={DownloadSvg} />
                        Reporte Inventario Excel
                    </div>
                        :
                        <div className="divXLSX" onClick={() => downloadXLSX()}>
                            <Icon component={DownloadSvg} />
                            {textXLSX}
                        </div>}
                    {textPDF === "" ? <div className="divPDF" onClick={() => createPDF(nameEquip, state, location)}>
                        <Icon component={DownloadSvg} />
                        Reporte Inventario PDF
                    </div>
                        :
                        <PDFDownloadLink document={<MyDocument />} fileName={"BusquedaInventario"}>
                            <div className="divPDF">
                                <Icon component={DownloadSvg} />
                                {textPDF}
                            </div>
                        </PDFDownloadLink>}

                </div> */}
                {/* <div className="divButton">
                    <Button className="button" onClick={() => onSearch(nameEquip, location, dependencySelected, licensePlateToSearch)}><SearchOutlined className="plus" /> Buscar</Button>
                </div> */}
            </Modal >
        )
    }
    const pageSize = 4
    const totalPages = Math.ceil(specializedCentersSearched[1] / pageSize);

    const onSides = (n) => {
        if (isFilteredSpecializedCenters) {
            if (currentPageFiltered < totalPages - 1 & n === 1) {
                setCurrentPageFiltered(prev => prev + n)
            }
            if (currentPageFiltered > 0 & n === -1) {
                setCurrentPageFiltered(prev => prev + n)
            }
        } else {
            if (currentPage < totalPages - 1 & n === 1) {
                setCurrentPage(prev => prev + n)
            }
            if (currentPage > 0 & n === -1) {
                setCurrentPage(prev => prev + n)
            }
        }
    }

    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPage === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPage) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPage) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    const Card = ({ text, image, optionTo }) => {
        return (
            <div
                className={"optionCard " + (optionTo === option ? "selected" : "")}
                style={{ backgroundImage: `url(${image})` }}
                onClick={() => buttons(optionTo)}
            >
                <p className="textCard">{text}</p>
            </div>
        )
    }

    const {
        register: registerGetDrivers,
        handleSubmit: handleSubmitGetDrivers,
        setValue: setValueGetDrivers,
        watch: watchGetDrivers,
        control: controlGetDrivers,
        formState: { errors: errorsGetDrivers },
        trigger: triggerGetDrivers,
    } = useForm({
        defaultValues: {

        }
    })

    const specializedTypes = [
        "Taller", "Eléctrico", "Taller de pintura", "Estación de gasolina"
    ]

    return (
        <div className='DriversDiv'>
            <div className="bigDiv">
                <div className="optionsDiv">
                    <Card image={DriversImage} optionTo={"Search"} text={"Conductores"} />
                    <Card image={AssignDriverImage} optionTo={"Add"} />
                </div>
                <div className="contentDiv">
                    <p className="title">{option === "Search" ? "Buscar persona" : "Asignar Vehículo"}</p>
                    {(!edit && option === "Search") ?
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <div className="filtersDiv">
                                <div className='divDate'>
                                    <p className='label' style={{ marginBottom: '2.45vw' }}>Tipo de Centro Especializado</p>
                                    <Controller
                                        name="type"
                                        control={controlGetDrivers}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Selecciona'
                                                value={watchGetDrivers('type')}
                                                className="select"
                                            >
                                                {specializedTypes && specializedTypes.map((d, i) => (
                                                    <Option key={i} value={d}>{d}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errorsGetDrivers.type && <p className='errorMessage'>{errorsGetDrivers.type.message}</p>}
                                </div>
                                <Input
                                    {...registerGetDrivers('name', {
                                    })}
                                    label={"Nombre del establecimiento comercial"}
                                    placeholder={"Digite el nombre"}
                                    width={'13vw'}
                                    height={'2vw'}
                                    labelstyle='inputLabelBordered'
                                    fontSize={'1vw'}
                                    labelMB={'0.2vw'}
                                    va={watchGetDrivers('name')}
                                    setData={e => setValueGetDrivers('name', e)}
                                    error={errorsGetDrivers.name && errorsGetDrivers.name.message}
                                />
                                <Input
                                    {...registerGetDrivers('nit', {
                                    })}
                                    label={"Nit"}
                                    placeholder={"Digite el nit"}
                                    width={'13vw'}
                                    height={'2vw'}
                                    labelstyle='inputLabelBordered'
                                    fontSize={'1vw'}
                                    labelMB={'1.7vw'}
                                    va={watchGetDrivers('nit')}
                                    setData={e => setValueGetDrivers('nit', e)}
                                    error={errorsGetDrivers.nit && errorsGetDrivers.nit.message}
                                />
                                <div className='divDate'>
                                    <p className='label' style={{ marginBottom: '2.45vw' }}>Estado</p>
                                    <Controller
                                        name="status"
                                        control={controlGetDrivers}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Selecciona'
                                                value={watchGetDrivers('status')}
                                                className="select"
                                            >
                                                <Option value={"Activo"}>{"Activo"}</Option>
                                                <Option value={"Inactivo"}>{"Inactivo"}</Option>
                                            </Select>
                                        )}
                                    />
                                    {errorsGetDrivers.status && <p className='errorMessage'>{errorsGetDrivers.status.message}</p>}
                                </div>
                                <div className='divDate'>
                                    <p className='label' style={{ marginBottom: '0vw', marginTop: '-1vw' }}>Año</p>
                                    <Controller
                                        name="year"
                                        control={controlGetDrivers}
                                        render={({ field }) => (
                                            <DatePicker
                                                picker='year'
                                                style={{ width: '6vw' }}
                                                className="select"
                                                onChange={e => setValueGetDrivers("year", e)}
                                            />
                                        )}
                                    />
                                    {errorsGetDrivers.year && <p className='errorMessage'>{errorsGetDrivers.year.message}</p>}
                                </div>
                                <div className='divDate'>
                                    <p className='label' style={{ marginBottom: '0vw', marginTop: '-1vw' }}>Mes</p>
                                    <Controller
                                        name="month"
                                        control={controlGetDrivers}
                                        render={({ field }) => (
                                            <DatePicker
                                                picker='month'
                                                style={{ width: '8vw' }}
                                                className="select"
                                                onChange={e => setValueGetDrivers("month", e)}
                                            />
                                        )}
                                    />
                                    {errorsGetDrivers.month && <p className='errorMessage'>{errorsGetDrivers.month.message}</p>}
                                </div>
                            </div>
                            <div className='buttonSave' style={{ marginTop: Object.keys(errorsGetDrivers).length !== 0 && '-1vw' }} onClick={() => {
                                handleSubmitGetDrivers((dataSubmit) => {
                                    if (!loadingGetSpecializedCenters) {
                                        setNameToFilter(dataSubmit.name)
                                        setNitToFilter(dataSubmit.nit)
                                        setTypeToFilter(dataSubmit.type)
                                        setStatusToFilter(dataSubmit.status)
                                        setYearToFilter(dataSubmit.year)
                                        setMonthToFilter(dataSubmit.month)
                                        SpecializedCenterActions.setFilteredSpecializedCenters(true)
                                        dispatch(SpecializedCenterActions.getSpecializedCenters({ ...dataSubmit, take: 4, page: 0 }))
                                    } else {
                                        message.loading("Espere un momento por favor")
                                    }
                                })()
                            }}>
                                <SearchOutlined className='iconButton' />
                                {loadingGetSpecializedCenters ? <LoadingOutlined /> : "Buscar"}
                            </div>
                            <p className="title">Resultados de la Busqueda Centro Especializado En Convenio</p>
                            <div className="columnsName">
                                <p>Imagen</p>
                                <p>Centro especializado</p>
                                <p>Nombre</p>
                                <p>Nit</p>
                                <p>Representante Legal</p>
                                <p>Fecha Contrato</p>
                                <p>Vigencia</p>
                                <p>Estado</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3vw' }}>
                                {specializedCentersSearched && specializedCentersSearched[0].map((d, i) => (
                                    <SpecializedCenterCard key={i} data={d} setEdit={setEdit} />
                                ))
                                }
                            </div>

                            {specializedCentersSearched &&
                                <div>
                                    <div className='showingText'>
                                        {!isFilteredSpecializedCenters
                                            ? `Mostrando ${(currentPage * pageSize) + 1}-${Math.min((currentPage + 1) * pageSize, specializedCentersSearched[1])} de ${specializedCentersSearched[1]} elementos`
                                            : `Mostrando ${(currentPageFiltered * pageSize) + 1}-${Math.min((currentPageFiltered + 1) * pageSize, specializedCentersSearched[1])} de ${specializedCentersSearched[1]} elementos`
                                        }
                                    </div>
                                    <div className='divButtonsPages'>
                                        <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                                        {!isFilteredSpecializedCenters ?
                                            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                                {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                                            </Radio.Group>
                                            :
                                            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                                {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
                                            </Radio.Group>
                                        }
                                        <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                                    </div>
                                </div>}
                        </div>
                        :
                        option === "Add" ? <FormAssignDriver /> :
                            fullDataSpecializedCenter && <FormSpecializedCenter data={fullDataSpecializedCenter} />
                    }
                </div>
            </div>
            <ModalSearch></ModalSearch>
        </div >
    )

}