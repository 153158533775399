import { Button, Layout, message } from "antd";
import {
  FundOutlined
} from '@ant-design/icons';
import contorno from '../../../assets/images/contornoLogo.svg'
import biomedicModuleImage from '../../../assets/images/biomedicModuleImage.svg'
import systemModuleImage from '../../../assets/images/systemModuleImage.svg'
import industrialModuleImage from '../../../assets/images/industrialModuleImage.svg'
import vehiclesModuleImage from '../../../assets/images/vehiclesModuleImage.svg'
import closeSesionImage from '../../../assets/images/closeSesionImage.svg'
import blanco from '../../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AreaActions } from "../../../services/Area/AreaSlice";
import { useSelector } from "react-redux";
import { EquipmentActions } from '../../../services/Equipment/EquipmentSlice'
import { TimelineActions } from "../../../services/Timeline/TimelineSlice";
import { EnterpriseActions } from "../../../services/Enterprise/EnterpriseSlice";
import { useHistory, useLocation } from 'react-router-dom';

const { Sider } = Layout;
export const PrivateSider = () => {
  const dispatch = useDispatch();
  const [botones, setBotones] = useState([])
  const [styleDash, setStyleDash] = useState('boton')
  const [widthSider, setWidthSider] = useState('')
  const { moduleWorks, enterprise, roleUser } = useSelector(state => state.Login)
  const { moduleWork } = useSelector(state => state.Area)
  const { enterpriseImage } = useSelector(state => state.Enterprise)
  const history = useHistory()

  useEffect(() => {
    let aux = []
    moduleWorks && moduleWorks.length > 0 && roleUser.id !== 36 && moduleWorks.map(d => {
      aux.push(["Equipos " + d.moduleWork.name, 'boton', 8, d.moduleWork.id])
      return null
    })
    setBotones(aux)
  }, [moduleWorks])

  useEffect(() => {
    enterprise && dispatch(EnterpriseActions.getEnterpriseImage(enterprise.id))
  }, [enterprise])

  const imageMap = {
    "Equipos Biomedico": biomedicModuleImage,
    "Equipos Sistemas": systemModuleImage,
    "Equipos Industriales": industrialModuleImage,
    "Equipos Vehiculos": vehiclesModuleImage
  };

  const setAllColorY = (t) => {
    let aux = []
    for (var i = 0; i < botones.length; i++) {
      if (botones[i][3] === t) {
        /* botones[i].splice(1,1,'boton1') */
        botones[i][1] = 'boton1'

      } else {
        botones[i][1] = 'boton'
      }
      aux.push(botones[i])
    }
    setBotones(aux)
    setStyleDash('boton')
    //get from ModuleWorks the id and set it to the store
    dispatch(AreaActions.setModuleWork(moduleWorks.filter(d => d.moduleWork.id === t)))
    /* dispatch(EquipmentActions.getEquipments({ "area": t, "take": 8, "page": 0 })) */
    /* dispatch(EquipmentActions.getEquipsWithCaseReports({ "area": t, "take": 8, "page": 0 })) */
    /* dispatch(TimelineActions.getTimelineSearched({ "area": t, "page": 0 })) */
    /* dispatch(AreaActions.getAgents({ "group": t, "role": "Agente" })) */
  }

  const setAllColorG = (t) => {
    let aux = []
    for (var i = 0; i < botones.length; i++) {
      botones[i][1] = 'boton'
      aux.push(botones[i])
    }
    setBotones(aux)
    setStyleDash('boton1')
  }

  useEffect(() => {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      setWidthSider("16.5vw")
    } else if (window.matchMedia("(min-width: 992px) and (max-width: 1200px)").matches) {
      setWidthSider("16.5vw")
    } else if (window.matchMedia("(min-width: 768px) and (max-width: 992px)").matches) {
      setWidthSider("16.5vw")
    } else if (window.matchMedia("(min-width: 600px) and (max-width: 768px)").matches) {
      setWidthSider("16.5vw")
    } else if (window.matchMedia("(max-width: 600px)").matches) {
      setWidthSider("26vw")
    }
  }, [])

  return (
    <Sider width={widthSider} className='sider'>
      <div className="enterprise">
        <div className="images">
          <img alt="imagenContorno" src={contorno} className="imgContorno"></img>
          {enterpriseImage && enterpriseImage.length > 0 && <img alt="imagenLogo" src={enterpriseImage[0].image} className="imgLogo"></img>}
        </div>
        <div className="texts">
          {enterprise && <p className="name">{enterprise.name}</p>}
          {enterprise && <p className="nit">{enterprise.nitrut}</p>}
        </div>
      </div>

      <div className='siderDiv'>
        {/*  <p className='inicioText'>INICIO</p> */}
        {/* <Button style={{position:'sticky', top:`5vh`}} onClick={() => setAllColorG()} icon={<FundOutlined />} className={styleDash} type="primary">Dashboard</Button> */}
        <p className='inicioText'>Modulos</p>

        {botones.map(data => (
          <Button key={data[0]} /* style={{position:'sticky', top:`${data[2]}vh`}} */ onClick={() => setAllColorY(data[3])} className={data[1]} type="primary">
            <img
              alt="imagenLogo"
              src={imageMap[data[0]] || blanco}
              className="imgModule"
            />
            {data[0]}
          </Button>
        ))}
        {roleUser.id === 36 && moduleWork &&
          <Button
            onClick={() => {
              dispatch(AreaActions.setModuleWork(false))
              /* history.push("/Home") */
            }}
            className={'boton'}
            type="primary">
            Elegir modulo
          </Button>}
        <Button
          onClick={() => {
            localStorage.removeItem("token")
            message.warn("Sesión finalizada")
            window.location.reload()
          }}
          className={'boton'}
          type="primary">
          <img
            alt="imagenLogo"
            src={closeSesionImage}
            className="imgModule"
          />
          Cerrar sesión
        </Button>

      </div>
    </Sider >
  )
}