import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    option: 'Search',
    optionCert: 'SearchCert',
    CaseReportCreatedId: false,
    personAssign: false,
    areaShared: false,
    caseReportsSearched: false,
    quantity: false,
    loadingCaseReport: false,
    loadingPDF: false,
    fullDataCaseReport: false,
    loadingFullDataCaseReport: false,
    allCaseReportsByEquipmentId: false,
    loadingAllCaseReportsByEquipmentId: false,
    idEquipmentSelected: false,
    visibleModalBitacora: false,
    visibleModalBitacoraNotFirmed: false,
    notFirmed: [],
    loadingNotFirmed: false,
    currentPage: 0,
    loadingFirmCaseReport: false,
    masiveReports: false,
    loadingMasiveReports: false,
    loadingFirmAllByDependency: false,
    loadingCreateCalibrationCertificate: false,
    getCalibrationCertificate: false,
    loadingGetCalibrationCertificate: false,
    pdfFromCalibrationCertificate: false,
    loadingPdfFromCalibrationCertificate: false,
    lastCalibrationCertificateByEquipByModuleWork: false,
    loadingLastCalibrationCertificateByEquipByModuleWork: false,
    allCalibrationCertificateByEquipmentId: false,
    loadingAllCalibrationCertificateByEquipmentId: false,
    modulesWorkToFirm: false,
};

const CaseReportSlice = createSlice({
    name: "CaseReport",
    initialState,
    reducers: {
        setOption(state, { payload }) {
            state.option = payload
        },
        setOptionCert(state, { payload }) {
            state.optionCert = payload
        },
        setCaseReportCreatedId(state, { payload }) {
            state.CaseReportCreatedId = payload
        },
        createCaseReport() { },
        createCaseReportPDF(state) {
            state.loadingPDF = true
        },
        createTimelinePDF(state) {
            state.loadingPDF = true
        },
        setLoadingPDF(state, { payload }) {
            state.loadingPDF = payload
        },
        updateCaseReport() { },
        createReportComponent() { },
        deleteCaseReport() { },
        setPersonAssign(state, { payload }) {
            state.personAssign = payload
        },
        setAreaShared(state, { payload }) {
            state.areaShared = payload
        },
        setCaseReportsSearched(state, { payload }) {
            state.caseReportsSearched = payload
        },
        setQuantity(state, { payload }) {
            state.quantity = payload
        },
        getCaseReports(state) {
            state.loadingCaseReport = true
            state.caseReportsSearched = false
        },
        setLoading(state, { payload }) {
            state.loadingCaseReport = payload
        },
        createCaseReportUser() { },
        createImageReport() { },
        getFullDataCaseReport(state) {
            state.loadingFullDataCaseReport = true
        },
        setFullDataCaseReport(state, { payload }) {
            state.fullDataCaseReport = payload
        },
        setLoadingFullDataCaseReport(state, { payload }) {
            state.loadingFullDataCaseReport = payload
        },
        getAllCaseReportsByEquipmentId(state) {
            state.loadingAllCaseReportsByEquipmentId = true
        },
        setAllCaseReportsByEquipmentId(state, { payload }) {
            state.allCaseReportsByEquipmentId = payload
        },
        setLoadingAllCaseReportsByEquipmentId(state) {
            state.loadingAllCaseReportsByEquipmentId = false
        },
        setIdEquipmentSelected(state, { payload }) {
            state.idEquipmentSelected = payload
        },
        setVisibleModalBitacora(state, { payload }) {
            state.visibleModalBitacora = payload
        }, setVisibleModalBitacoraNotFirmed(state, { payload }) {
            state.visibleModalBitacoraNotFirmed = payload
        },
        getNotFirmed(state) {
            state.loadingNotFirmed = true
        },
        setNotFirmed(state, { payload }) {
            state.notFirmed = payload
        },
        setLoadingNotFirmed(state, { payload }) {
            state.loadingNotFirmed = payload
        },
        setCurrentPage(state, { payload }) {
            state.currentPage = payload
        },
        firmCaseReport(state, payload) {
            state.loadingFirmCaseReport = true
        },
        setLoadingFirmCaseReport(state, { payload }) {
            state.loadingFirmCaseReport = payload
        },
        getMasiveReports(state, { payload }) {
            state.loadingMasiveReports = true;
        },
        setMasiveReports(state, { payload }) {
            state.masiveReports = payload
        },
        setLoadingMasiveReports(state, { payload }) {
            state.loadingMasiveReports = payload;
        },
        firmAllByDependency(state, { payload }) {
            state.loadingFirmAllByDependency = true
        },
        setLoadingFirmAllByDependency(state, { payload }) {
            state.loadingFirmAllByDependency = payload
        },
        createCalibrationCertificate(state, { payload }) {
            state.loadingCreateCalibrationCertificate = true
        },
        setLoadingCreateCalibrationCertificate(state, { payload }) {
            state.loadingCreateCalibrationCertificate = payload
        },
        getCalibrationCertificate(state, { payload }) {
            state.loadingGetCalibrationCertificate = true
        },
        setCalibrationCertificate(state, { payload }) {
            state.getCalibrationCertificate = payload
        },
        setLoadingGetCalibrationCertificate(state, { payload }) {
            state.loadingGetCalibrationCertificate = payload
        },
        getPdfFromCalibrationCertificate(state, { payload }) {
            state.loadingPdfFromCalibrationCertificate = true
        },
        setPdfFromCalibrationCertificate(state, { payload }) {
            state.pdfFromCalibrationCertificate = payload
        },
        setLoadingPdfFromCalibrationCertificate(state, { payload }) {
            state.loadingPdfFromCalibrationCertificate = payload
        },
        getLastCalibrationCertificateByEquipByModuleWork(state) {
            state.loadingLastCalibrationCertificateByEquipByModuleWork = true
        },
        setLastCalibrationCertificateByEquipByModuleWork(state, { payload }) {
            state.lastCalibrationCertificateByEquipByModuleWork = payload
        },
        setLoadingLastCalibrationCertificateByEquipByModuleWork(state, { payload }) {
            state.loadingLastCalibrationCertificateByEquipByModuleWork = payload
        },
        getAllCalibrationCertificateByEquipmentId(state) {
            state.loadingAllCalibrationCertificateByEquipmentId = true
        },
        setAllCalibrationCertificateByEquipmentId(state, { payload }) {
            state.allCalibrationCertificateByEquipmentId = payload
        },
        setLoadingAllCalibrationCertificateByEquipmentId(state, { payload }) {
            state.loadingAllCalibrationCertificateByEquipmentId = payload
        },
        getModulesWorkToFirm(state) {
        },
        setModulesWorkToFirm(state, { payload }) {
            state.modulesWorkToFirm = payload
        },
    },
});

const CaseReportActions = CaseReportSlice.actions;
const CaseReportReducer = CaseReportSlice.reducer;

export { CaseReportActions, CaseReportReducer };
