import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import { AreaActions } from './AreaSlice'
import Api from "../../common/api"


/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(AreaActions.setMunicipalitys(muns.payload))
  }
  yield put(AreaActions.setLoading(false))
} */

function* getDependency({ payload }) {
    const dependency = yield Api.get(`/dependency/${payload}`)
    if (dependency.ok) {
        yield put(AreaActions.setDependency(dependency.payload))
    } else {
        message.error("Error al obtener la dependencia")
    }
}

function* getEquipmentTypes({ payload }) {
    const ep = yield Api.get(`/equipmentType/area/${payload}`)
    if (ep.ok) {
        yield put(AreaActions.setEquipmentTypes(ep.payload))
    }
}

function* getUsers({ payload }) {
    const ags = yield Api.get(`/dependencyUser/userByDependencyAndRole/${payload.group}/${payload.role}`)
    if (ags.ok) {
        yield put(AreaActions.setUsers(ags.payload))
    } else {
        message.error("Error al obtener usuarios")
    }
}

function* getAgents({ payload }) {
    const ags = yield Api.get(`/dependencyUser/userByDependencyAndRole/${payload.group}/${payload.role}`)
    if (ags.ok) {
        yield put(AreaActions.setAgents(ags.payload))
    } else {
        message.error("Error al obtener usuarios")
    }
}

function* getHeadquarters({ payload }) {
    const h = yield Api.get(`/headquarter/byDependencyName?name=${payload}`)
    if (h.ok) {
        yield put(AreaActions.setHeadquarters(h.payload))
    } else {
        message.error("Error al obtener sedes")
    }
}

function* getAreaUser({ payload }) {
    const a = yield Api.get(`/dependency/getArea?name=${payload}`)
    if (a.ok) {
        yield put(AreaActions.setAreaUser(a.payload))
    } else {
        message.error("Error al obtener Area del usuario")
    }
}

function* getAllDependencysFromArea({ payload }) {
    const a = yield Api.get(`/dependency/getByArea?id=${payload}`)
    if (a.ok) {
        yield put(AreaActions.setAllDependencysFromArea(a.payload))
    } else {
        message.error("Error al obtener las dependencias")
    }
}

function* getLocations({ payload }) {
    const a = yield Api.get(`/location/all`)
    if (a.ok) {
        yield put(AreaActions.setLocations(a.payload))
    } else {
        message.error("Error al obtener las ubicaciones")
    }
}

function* getDependenciesFromBoss({ payload }) {
    const a = yield Api.get(`/dependencyUser/byBoss?id=${payload}`)
    if (a.ok) {
        yield put(AreaActions.setDependenciesFromBoss(a.payload))
    } else {
        message.error("Error al obtener las dependencias")
    }
}

function* getDependenciesFromUser({ payload }) {
    const a = yield Api.get(`/dependencyUser/byUser?id=${payload}`)
    if (a.ok) {
        yield put(AreaActions.setDependenciesFromUser(a.payload))
    } else {
        message.error("Error al obtener las dependencias")
    }
}

function* getDependenciesFromEnterprise({ payload }) {
    const a = yield Api.get(`/dependency/getByEnterprise?enterprise=${payload}`)
    if (a.ok) {
        yield put(AreaActions.setDependenciesFromEnterprise(a.payload))
    } else {
        message.error("Error al obtener las dependencias")
    }
}


function* actionWatcher() {
    yield takeLatest(AreaActions.getDependency, getDependency)
    yield takeLatest(AreaActions.getEquipmentTypes, getEquipmentTypes)
    yield takeLatest(AreaActions.getUsers, getUsers)
    yield takeLatest(AreaActions.getAgents, getAgents)
    yield takeLatest(AreaActions.getHeadquarters, getHeadquarters)
    yield takeLatest(AreaActions.getAreaUser, getAreaUser)
    yield takeLatest(AreaActions.getAllDependencysFromArea, getAllDependencysFromArea)
    yield takeLatest(AreaActions.getLocations, getLocations)
    yield takeLatest(AreaActions.getDependenciesFromBoss, getDependenciesFromBoss)
    yield takeLatest(AreaActions.getDependenciesFromUser, getDependenciesFromUser)
    yield takeLatest(AreaActions.getDependenciesFromEnterprise, getDependenciesFromEnterprise)
}

export default function* AreasSaga() {
    yield all([actionWatcher()])
}