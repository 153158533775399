import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"

import { EquipmentIndustrialActions } from './EquipmentIndustrialSlice'
import { TimelineActions } from '../Timeline/TimelineSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(EquipmentIndustrialActions.setMunicipalitys(muns.payload))
  }
  yield put(EquipmentIndustrialActions.setLoading(false))
} */

function* createEquipmentIndustrial({ payload }) {
  const e = yield Api.post('/equipment/industrial', payload)
  if (e.ok) {
    message.success(e.payload.message)
    yield put(EquipmentIndustrialActions.setEquipmentIndustrialCreatedId(e.payload.message))
  } else {
    message.error(e.payload.message)
  }
  yield put(EquipmentIndustrialActions.setLoadingCreateEquipmentIndustrial(false))
}

function* deleteEquipmentIndustrial({ payload }) {
  const EquipmentIndustrialDeleted = yield Api.post(`/EquipmentIndustrial/delete?idd=${payload.id}`)
  if (EquipmentIndustrialDeleted.ok) {
    message.success("Equipo eliminado")
  } else {
    message.error("Error al eliminar equipo")
  }
}

function* createBiomedicComponent({ payload }) {
  const bc = yield Api.post(`/biomedicComponent`, payload)
  if (bc.ok) {
    message.success('Componentes añadidos')
  } else {
    message.error("Error creando componentes")
  }
}

function* getEquipmentIndustrials({ payload }) {
  const eq = yield Api.get(`/EquipmentIndustrial/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  yield put(EquipmentIndustrialActions.setEquipmentIndustrialsSearched(eq.payload[0]))
  yield put(EquipmentIndustrialActions.setQuantity(eq.payload[1]))
  yield put(EquipmentIndustrialActions.setLoading(false))
}

function* getEquipmentIndustrialsByModuleWork({ payload }) {
  const eq = yield Api.get(`/EquipmentIndustrial/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  yield put(EquipmentIndustrialActions.setEquipmentIndustrialsSearched(eq.payload[0]))
  yield put(EquipmentIndustrialActions.setQuantity(eq.payload[1]))
  yield put(EquipmentIndustrialActions.setLoading(false))
}

function* searchEquipmentIndustrial({ payload }) {
  const eq = yield Api.get(`/EquipmentIndustrial/byDependencySearch?dependency=${payload.dependency}&nameEquip=${payload.nameEquip}&state=${payload.state}`)
  yield put(EquipmentIndustrialActions.setEquipmentIndustrialsSearched(eq.payload))
  yield put(EquipmentIndustrialActions.setLoading(false))
}

function* search({ payload }) {
  const eq = yield Api.get(`/EquipmentIndustrial/search?dependency=${payload.dependency}&nameEquip=${payload.nameEquip}&licensePlate=${payload.licensePlate}&year=${payload.year}&take=${payload.take}&page=${payload.page}`)
  if (eq.ok) {
    yield put(EquipmentIndustrialActions.setEquipmentIndustrialsSearched(eq.payload))
    yield put(EquipmentIndustrialActions.setIsFiltered(true))
  } else {
    message.error("Error obteniendo equipos filtrados")
  }
  yield put(EquipmentIndustrialActions.setLoading(false))
}

function* getStates() {
  const s = yield Api.get('/EquipmentIndustrial/distinctState')
  if (s.ok) {
    yield put(EquipmentIndustrialActions.setStates(s.payload))
  } else {
    message.error("Error obteniendo estados")
  }
}

function* getLocations() {
  const m = yield Api.get('/biomedicEquipmentIndustrial/distinctLocation')
  if (m.ok) {
    yield put(EquipmentIndustrialActions.setLocations(m.payload))
  } else {
    message.error("Error obteniendo ubicaciones")
  }
}

function* getEquipsWithCaseReports({ payload }) {
  const e = yield Api.get(`/EquipmentIndustrial/getLastCaseReport/byDependency?dependency=${payload.area}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentIndustrialActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentIndustrialActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentIndustrialActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* getEquipsWithCaseReportsByModuleWork({ payload }) {
  const e = yield Api.get(`/EquipmentIndustrial/getLastCaseReport/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentIndustrialActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentIndustrialActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentIndustrialActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* searchCaseReport({ payload }) {
  const c = yield Api.get(`/EquipmentIndustrial/getCaseReportSearch?dependency=${payload.dependency}&take=8&page=${payload.page}&name=${payload.name}&location=${payload.location}&state=${payload.state}&date=${payload.date[0]}&date1=${payload.date[1]}`)
  if (c.ok) {
    yield put(EquipmentIndustrialActions.setEquipsWithCaseReports(c.payload[0]))
    yield put(EquipmentIndustrialActions.setQuantityEquipsWithCaseReports(c.payload[1]))
    yield put(EquipmentIndustrialActions.setLoadingEquipsWithCaseReport(false))
    yield put(EquipmentIndustrialActions.setIsFiltered(true))
  } else {
    message.error("Error al realizar la busqueda")
  }
}

function* getEquipAndReportPDF({ payload }) {
  const e = yield Api.get(`/EquipmentIndustrial/getByIdAndReportsPDF?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentIndustrialActions.setEquipAndReportPDF(e.payload))
  } else {
    message.error("Error al obtener PDFs")
  }
}

function* getEquipsForTimeline({ payload }) {
  const e = yield Api.get(`/EquipmentIndustrial/getForTimeline?dependency=${payload.dependency}&string=${payload.string}`)
  if (e.ok) {
    if (payload.string === "") {
      yield put(EquipmentIndustrialActions.setAllEquipsForTimeline(e.payload))
    } else {
      yield put(EquipmentIndustrialActions.setEquipsForTimeline(e.payload))
    }
  } else {
    message.error("Error al obtener equipos buscados")
  }
  yield put(EquipmentIndustrialActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelines({ payload }) {
  const e = yield Api.get(`/EquipmentIndustrial/getEquipsWithTimelines/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0 })
    })
    yield put(TimelineActions.setTimelinesByEquipmentIndustrial(aux))
    yield put(EquipmentIndustrialActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentIndustrialActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the EquipmentIndustrials

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentIndustrialActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelinesByModuleWork({ payload }) {
  const e = yield Api.get(`/EquipmentIndustrial/getEquipsWithTimelines/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0 })
    })
    yield put(TimelineActions.setTimelinesByEquipmentIndustrial(aux))
    yield put(EquipmentIndustrialActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentIndustrialActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the EquipmentIndustrials

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentIndustrialActions.setLoadingEquipsForTimeline(false))
}

function* getFullDataEquipmentIndustrial({ payload }) {
  const e = yield Api.get(`/EquipmentIndustrial/fullDataEquipmentIndustrialById?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentIndustrialActions.setFullDataEquipmentIndustrial(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentIndustrialActions.setLoadingFullDataEquipmentIndustrial(false))
}

function* updateEquipmentIndustrial({ payload }) {
  const e = yield Api.post(`/equipment/updateIndustrialEquipment?id=${payload.id}`, payload.payload)
  if (e.ok) {
    message.success("Equipo actualizado")
  } else {
    message.error("Error al actualizar equipo")
  }
  yield put(EquipmentIndustrialActions.setLoadingCreateEquipmentIndustrial(false))
}

function* actionWatcher() {
  yield takeLatest(EquipmentIndustrialActions.createEquipmentIndustrial, createEquipmentIndustrial)
  yield takeLatest(EquipmentIndustrialActions.updateEquipmentIndustrial, updateEquipmentIndustrial)
  yield takeLatest(EquipmentIndustrialActions.getEquipmentIndustrials, getEquipmentIndustrials)
  yield takeLatest(EquipmentIndustrialActions.searchEquipmentIndustrial, searchEquipmentIndustrial)
  yield takeLatest(EquipmentIndustrialActions.deleteEquipmentIndustrial, deleteEquipmentIndustrial)
  yield takeLatest(EquipmentIndustrialActions.getStates, getStates)
  yield takeLatest(EquipmentIndustrialActions.getLocations, getLocations)
  yield takeLatest(EquipmentIndustrialActions.getEquipsWithCaseReports, getEquipsWithCaseReports)
  yield takeLatest(EquipmentIndustrialActions.searchCaseReport, searchCaseReport)
  yield takeLatest(EquipmentIndustrialActions.getEquipAndReportPDF, getEquipAndReportPDF)
  yield takeLatest(EquipmentIndustrialActions.getEquipsForTimeline, getEquipsForTimeline)
  yield takeLatest(EquipmentIndustrialActions.getEquipsWithTimelines, getEquipsWithTimelines)
  yield takeLatest(EquipmentIndustrialActions.getFullDataEquipmentIndustrial, getFullDataEquipmentIndustrial)
  yield takeLatest(EquipmentIndustrialActions.getEquipmentIndustrialsByModuleWork, getEquipmentIndustrialsByModuleWork)
  yield takeLatest(EquipmentIndustrialActions.search, search)
  yield takeLatest(EquipmentIndustrialActions.getEquipsWithCaseReportsByModuleWork, getEquipsWithCaseReportsByModuleWork)
  yield takeLatest(EquipmentIndustrialActions.getEquipsWithTimelinesByModuleWork, getEquipsWithTimelinesByModuleWork)
}

export default function* EquipmentIndustrialSaga() {
  yield all([actionWatcher()])
}