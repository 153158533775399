import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loadingTimeline: false,
    timelineSearched: false,
    equipsToSubmit: [],
    loadingEquipsToSubmit: false,
    equipsToTrain: [],
    loadingTraining: false,
    trainings: false,
    timelinesByEquipment: false,
    timelinesQuantity: false,
    loadingTimelinesByEquipment: false,
    loadingCreateTraining: false,
    loadingDeleteTimeline: false,
    timelinesForThisMonth: false,
    loadingTimelinesForThisMonth: false,
    visibleModalTimelinesForThisMonth: false,
    currentPageTimelinesForThisMonth: 0,
};

const TimelineSlice = createSlice({
    name: "Timeline",
    initialState,
    reducers: {
        getTimelineSearched(state) {
            state.loadingTimeline = true
        },
        createTimeline(state) {
            state.loadingTimeline = true
        },
        setTimelineSearched(state, { payload }) {
            state.timelineSearched = payload
        },
        setLoadingTimeline(state, { payload }) {
            state.loadingTimeline = payload
        },
        createMany(state, { payload }) {
            state.loadingEquipsToSubmit = true
        },
        setEquipsToSubmit(state, { payload }) {
            state.equipsToSubmit = payload
        },
        setManyEquipsToSubmit(state, { payload }) {
            let aux = state.equipsToSubmit
            payload.map((equip) => {
                aux.push(equip)
            })
            state.equipsToSubmit = aux
        },
        setLoadingEquipsToSubmit(state, { payload }) {
            state.loadingEquipsToSubmit = payload
        },
        setEquipsToTrain(state, { payload }) {
            state.equipsToTrain = payload
        },
        setManyEquipsToTrain(state, { payload }) {
            let aux = state.equipsToTrain
            payload.map((equip) => {
                aux.push(equip)
            })
            state.equipsToTrain = aux
        },
        createTraining(state) {
            state.loadingCreateTraining = true
        },
        setLoadingCreateTraining(state, { payload }) {
            state.loadingCreateTraining = payload
        },
        setLoadingTraining(state, { payload }) {
            state.loadingTraining = payload
        },
        getTrainings(state) {
            state.loadingTraining = true
        },
        setTrainings(state, { payload }) {
            state.trainings = payload
        },
        getTimelinesByEquipment(state) {
            state.loadingTimelinesByEquipment = true
        },
        setLoadingTimelinesByEquipment(state, { payload }) {
            state.loadingTimelinesByEquipment = payload
        },
        setTimelinesByEquipment(state, { payload }) {
            state.timelinesByEquipment = payload
        },
        setTimelinesQuantity(state, { payload }) {
            state.timelinesQuantity = payload
        },
        updateTimelinesByEquipment(state, { payload }) {
            let aux = state.timelinesByEquipment
            //get one of the objects from aux that have same id as payload.equipId, then set timelines to payload.timelines
            aux.filter((equip) => equip.id === payload.equipId)[0].timelines = payload.timelines
            aux.filter((equip) => equip.id === payload.equipId)[0].quantity = payload.quantity
            aux.filter((equip) => equip.id === payload.equipId)[0].currentPage = payload.currentPage
            aux.filter((equip) => equip.id === payload.equipId)[0].year = payload.year

            state.timelinesByEquipment = aux
            state.loadingTimelinesByEquipment = false
        },
        getAllTrainings(state) { },
        deleteTraining(state) { },
        deleteTimeline(state) {
            state.loadingDeleteTimeline = true
        },
        setLoadingDeleteTimeline(state, { payload }) {
            state.loadingDeleteTimeline = payload
        },
        getTimelinesForThisMonth(state) {
            state.loadingTimelinesForthisMonth = true
        },
        setTimelinesForThisMonth(state, { payload }) {
            state.timelinesForThisMonth = payload
        },
        setLoadingTimelinesForThisMonth(state, { payload }) {
            state.loadingTimelinesForThisMonth = payload
        },
        setVisibleModalTimelinesForThisMonth(state, { payload }) {
            state.visibleModalTimelinesForThisMonth = payload
        },
        setCurrentPageTimelinesForThisMonth(state, { payload }) {
            state.currentPageTimelinesForThisMonth = payload
        },
    },
});

const TimelineActions = TimelineSlice.actions;
const TimelineReducer = TimelineSlice.reducer;

export { TimelineActions, TimelineReducer };
