import { Button, Radio, message, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { Input } from '../Input/Input'
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from "react-hook-form"

import { EquipmentCard } from '../EquipmentCard/EquipmentCard';

const Accessory = ({ category, options, nameColumns, watchCreateAirAndFridgeServiceReport, setValueCreateAirAndFridgeServiceReport, controlCreateAirAndFridgeServiceReport, registerCreateAirAndFridgeServiceReport, errorsCreateAirAndFridgeServiceReport, type }) => {
    return (
        <div className='accessory'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', visibility: category ? "visible" : "hidden", marginBottom: category ? '0' : '1.2vw' }}>
                <p className='category' style={{ fontSize: category && (category.length <= 19 ? '0.8vw' : category.length <= 24 ? '0.7vw' : '0.6vw') }}>{category}</p>
            </div>
            <div className='divColumnsNames'>
                {nameColumns && nameColumns.map(d => (
                    <p className='columnName'>{d}</p>
                ))}
            </div>
            <div className='divOptions'>
                {options.map((option, index) => (
                    <div className='option' key={index}>
                        <p className='nameOption'>{option.name}</p>
                        {
                            (() => {
                                switch (option.type) {
                                    case 'radio':
                                        return (
                                            <Controller
                                                control={controlCreateAirAndFridgeServiceReport} // control viene de useForm()
                                                key={option.fieldName + "Status"}
                                                name={option.fieldName + "Status"}
                                                rules={{
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("Es requerido elegir una opcion");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <Checkbox.Group
                                                        className='checkboxGroup'
                                                        {...field}
                                                        onChange={e => {
                                                            setValueCreateAirAndFridgeServiceReport(option.fieldName + "Status", e)
                                                        }}
                                                    >
                                                        {option.checkboxes && option.checkboxes.map(d => (
                                                            <Checkbox value={d.value}>{d.text}</Checkbox>
                                                        ))}
                                                    </Checkbox.Group>
                                                )}
                                            />
                                        );
                                    case 'input':
                                        return (
                                            <Input
                                                {...registerCreateAirAndFridgeServiceReport(option.fieldName + 'Description', {
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("Requerido");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                })}
                                                label={"none"}
                                                width={'12vw'}
                                                height={'1.8vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                errorFontSize={'0.7vw'}
                                                labelMB={'-0.1vw'}
                                                ml={'4.5vw'}
                                                setData={e => setValueCreateAirAndFridgeServiceReport(option.fieldName + 'Description', e)}
                                                error={errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Description'] && errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Description'].message}
                                            />
                                        );
                                    case 'textArea':
                                        return (
                                            <Input
                                                {...registerCreateAirAndFridgeServiceReport(option.fieldName + 'Description', {
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("Requerido");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                })}
                                                label={"none"}
                                                width={'12vw'}
                                                height={'16vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                errorFontSize={'0.7vw'}
                                                labelMB={'-0.1vw'}
                                                textArea
                                                setData={e => setValueCreateAirAndFridgeServiceReport(option.fieldName + 'Description', e)}
                                                error={errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Description'] && errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Description'].message}
                                            />
                                        );
                                    case 'dobleInput':
                                        return (
                                            <div style={{ display: 'flex', position: 'relative', left: '4vw' }}>
                                                <Input
                                                    {...registerCreateAirAndFridgeServiceReport(option.fieldName + 'Value', {
                                                        validate: (value) => {
                                                            const errors = [];
                                                            if (!value || value === '') {
                                                                errors.push("Requerido");
                                                            }
                                                            return errors.length === 0 || errors.join(', ');
                                                        },
                                                    })}
                                                    label={"none"}
                                                    width={'7vw'}
                                                    height={'1.8vw'}
                                                    labelstyle='inputLabelBordered'
                                                    fontSize={'1vw'}
                                                    errorFontSize={'0.7vw'}
                                                    labelMB={'-0.1vw'}
                                                    mr={'1vw'}
                                                    setData={e => setValueCreateAirAndFridgeServiceReport(option.fieldName + 'Value', e)}
                                                    error={errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Value'] && errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Value'].message}
                                                />
                                                <Input
                                                    {...registerCreateAirAndFridgeServiceReport(option.fieldName + 'Unit', {
                                                        validate: (value) => {
                                                            const errors = [];
                                                            if (!value || value === '') {
                                                                errors.push("Requerido");
                                                            }
                                                            return errors.length === 0 || errors.join(', ');
                                                        },
                                                    })}
                                                    label={"none"}
                                                    width={'7vw'}
                                                    height={'1.8vw'}
                                                    labelstyle='inputLabelBordered'
                                                    fontSize={'1vw'}
                                                    errorFontSize={'0.7vw'}
                                                    labelMB={'-0.1vw'}
                                                    setData={e => setValueCreateAirAndFridgeServiceReport(option.fieldName + 'Unit', e)}
                                                    error={errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Unit'] && errorsCreateAirAndFridgeServiceReport[option.fieldName + 'Unit'].message}
                                                />
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })()
                        }
                    </div>
                ))}
            </div>
        </div>
    )
};

const Comp = ({ index, values, compInfo, setCompInfo, registerCreateAirAndFridgeServiceReport, watchCreateAirAndFridgeServiceReport, setValueCreateAirAndFridgeServiceReport }) => {
    const [exist, setExist] = useState(true);

    const deleteComp = () => {
        setExist(false)
        var aux = compInfo
        aux.splice(index, 1)
        setCompInfo(aux)
    }

    return (
        exist && <div key={index} className='sparePartsDiv'>
            <Input
                {...registerCreateAirAndFridgeServiceReport('quantityPart' + index, {
                })}
                va={watchCreateAirAndFridgeServiceReport('quantityPart' + index)}
                setData={e => setValueCreateAirAndFridgeServiceReport('quantityPart' + index, e)}
                label='Cantidad'
                width={'9vw'}
                labelstyle='inputLabelBordered'
            />
            <Input
                {...registerCreateAirAndFridgeServiceReport('namePart' + index, {
                })}
                va={watchCreateAirAndFridgeServiceReport('namePart' + index)}
                setData={e => setValueCreateAirAndFridgeServiceReport('namePart' + index, e)}
                label='Nombre'
                width={'9vw'}
                labelstyle='inputLabelBordered'
            />
            <Input
                {...registerCreateAirAndFridgeServiceReport('numberPart' + index, {
                })}
                va={watchCreateAirAndFridgeServiceReport('numberPart' + index)}
                setData={e => setValueCreateAirAndFridgeServiceReport('numberPart' + index, e)}
                label='N° de parte'
                width={'9vw'}
                labelstyle='inputLabelBordered'
            />
            <Input
                {...registerCreateAirAndFridgeServiceReport('numberSeriePart' + index, {
                })}
                va={watchCreateAirAndFridgeServiceReport('numberSeriePart' + index)}
                setData={e => setValueCreateAirAndFridgeServiceReport('numberSeriePart' + index, e)}
                label='N° Serie'
                width={'9vw'}
                labelstyle='inputLabelBordered'
            />
            <CloseOutlined onClick={() => deleteComp()} className='closeIcon' />
        </div>
    )
}

export function useStepsAirConditionerAndFridge(
    registerCreateAirAndFridgeServiceReport,
    handleSubmitCreateAirAndFridgeServiceReport,
    setValueCreateAirAndFridgeServiceReport,
    watchCreateAirAndFridgeServiceReport,
    controlCreateAirAndFridgeServiceReport,
    errorsCreateAirAndFridgeServiceReport,
    triggerCreateAirAndFridgeServiceReport,
    equipSelected,
    setEquipSelected,
    data,
    resetCreateAirAndFridgeServiceReport
) {

    const dispatch = useDispatch()
    const { equipmentsSearched } = useSelector(state => state.Equipment)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [changeOrInstalation, setChangeOrInstalation] = React.useState(2)
    const [compInfo, setCompInfo] = React.useState([]);

    const StepsAirConditionerAndFridge = [
        {
            title: 1,
            content:
                <div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <div style={{ width: '31%' }}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                {equipSelected &&
                                    <EquipmentCard
                                        onChange={() => setEquipSelected(false)}
                                        id={equipSelected.id}
                                        img={equipSelected.image}
                                        name={equipSelected.name}
                                        brand={equipSelected.brand}
                                        model={equipSelected.modell}
                                        serie={equipSelected.serie}
                                        location={equipSelected.location}
                                        moduleWork={equipSelected.moduleWork}
                                    >
                                    </EquipmentCard>
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '68%', flexDirection: 'column', marginLeft: '1%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw', boxShadow: '0px 4px 4px 0px #00000040', paddingLeft: '1vw', paddingRight: '1vw' }}>
                                <div style={{ display: 'flex' }}>
                                    <div className='divDate'>
                                        <p className='label'>Fecha de Reporte</p>
                                        <DatePicker
                                            value={watchCreateAirAndFridgeServiceReport("date")}
                                            {...registerCreateAirAndFridgeServiceReport("date", {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La fecha es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                            onChange={d => setValueCreateAirAndFridgeServiceReport("date", d)}
                                        />
                                        {errorsCreateAirAndFridgeServiceReport.date && <p className='errorMessage'>{errorsCreateAirAndFridgeServiceReport.date.message}</p>}
                                    </div>
                                </div>
                                <p className='titleSection'>Tipo del servicio</p>
                                <div style={{ display: 'flex' }}>
                                    <Radio.Group
                                        className='radioGroup'
                                        {...registerCreateAirAndFridgeServiceReport('serviceType', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Es requerido elegir una opcion");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        style={{ marginLeft: '2vw' }}
                                        value={watchCreateAirAndFridgeServiceReport('serviceType')}
                                        onChange={e => {
                                            resetCreateAirAndFridgeServiceReport()
                                            setValueCreateAirAndFridgeServiceReport('serviceType', e.target.value)

                                        }}
                                    >
                                        <Radio value={"Preventivo"}>Preventivo</Radio>
                                        <Radio value={"Correctivo"}>Correctivo</Radio>
                                        <Radio value={"Instalación"}>Instalación</Radio>
                                        <Radio value={"Capacitación"}>Capacitación</Radio>
                                    </Radio.Group>
                                    {errorsCreateAirAndFridgeServiceReport.serviceType && <p className='errorMessage'>{errorsCreateAirAndFridgeServiceReport.serviceType.message}</p>}
                                </div>
                                <p className='titleSection'>Carácter</p>
                                <div style={{ display: 'flex' }}>
                                    <Radio.Group
                                        className='radioGroup'
                                        {...registerCreateAirAndFridgeServiceReport('character', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Es requerido elegir una opcion");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        style={{ marginLeft: '2vw' }}
                                        value={watchCreateAirAndFridgeServiceReport('character')}
                                        onChange={e => setValueCreateAirAndFridgeServiceReport('character', e.target.value)}
                                    >
                                        <Radio value={"Garantia"}>Garantia</Radio>
                                        <Radio value={"Contrato"}>Contrato</Radio>
                                        <Radio value={"Factura"}>Factura</Radio>
                                        <Radio value={"Otro"}>Otro</Radio>
                                    </Radio.Group>
                                    {errorsCreateAirAndFridgeServiceReport.character && <p className='errorMessage'>{errorsCreateAirAndFridgeServiceReport.character.message}</p>}
                                </div>
                                <p className='titleSection'>Descripción del servicio</p>
                                {watchCreateAirAndFridgeServiceReport('serviceType') === "Preventivo" &&
                                    <p className='titleSection'>Falla Reportada y/o dectectada: MANTENIMIENTO PREVENTIVO MENSUAL
                                    </p>}
                                {watchCreateAirAndFridgeServiceReport('serviceType') === "Preventivo" &&
                                    <Input
                                        {...registerCreateAirAndFridgeServiceReport('reportedFailure', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Falla reportada es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        mr={"0.7vw"}
                                        mt={'-1vw'}
                                        width={'100%'}
                                        height={'8vw'}
                                        labelstyle='inputLabelBordered'
                                        placeholder={"Describa la falla reportada"}
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        textArea
                                        va={watchCreateAirAndFridgeServiceReport('reportedFailure')}
                                        setData={e => setValueCreateAirAndFridgeServiceReport('reportedFailure', e)}
                                        error={errorsCreateAirAndFridgeServiceReport.reportedFailure && errorsCreateAirAndFridgeServiceReport.reportedFailure.message}
                                    />}
                                {watchCreateAirAndFridgeServiceReport('serviceType') === "Correctivo" &&
                                    <p className='titleSection'>Acción Correctiva o Procedimiento Realizado</p>}
                                {watchCreateAirAndFridgeServiceReport('serviceType') === "Correctivo" &&
                                    <div className='divAccessories'>
                                        <div className='accessorySection'>
                                            <p className='text'>Puede seleccionar marcar todo con un clic.</p>
                                        </div>
                                        <div className='accessorySection'>
                                            <div className='accessory yellow'>
                                                <div className='divOptions'>
                                                    <div className='option'>
                                                        <p className='nameOption'>Marcar Todo: </p>
                                                        <Checkbox.Group
                                                            className='checkboxGroup'
                                                            style={{ marginLeft: '1vw' }}
                                                            onChange={e => {
                                                                let aux = watchCreateAirAndFridgeServiceReport();
                                                                let options = Object.keys(aux).filter(key => key.endsWith('Status'));
                                                                options.forEach(option => {
                                                                    setValueCreateAirAndFridgeServiceReport(option, e);
                                                                });
                                                            }}
                                                        >
                                                            <Checkbox value={"Marcar Todo"}>Marcar Todo</Checkbox>
                                                        </Checkbox.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='accessorySectionAirAndFridge'>
                                            <Accessory
                                                options={[
                                                    { name: 'Revisión inicial del equipo: ', fieldName: 'initialEquipmentReview', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Mantenimiento de acuerdo con el protocolo: ', fieldName: 'maintenanceAccordingToProtocol', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Revisión y mantenimiento sistema eléctrico: ', fieldName: 'electricalSystemReviewAndMaintenance', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Revisión y mantenimiento sistema Mécanico: ', fieldName: 'mechanicalSystemReviewAndMaintenance', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Revisión y mantenimiento sistema electronico: ', fieldName: 'electronicSystemReviewAndMaintenance', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                ]}
                                                watchCreateAirAndFridgeServiceReport={watchCreateAirAndFridgeServiceReport}
                                                registerCreateAirAndFridgeServiceReport={registerCreateAirAndFridgeServiceReport}
                                                setValueCreateAirAndFridgeServiceReport={setValueCreateAirAndFridgeServiceReport}
                                                errorsCreateAirAndFridgeServiceReport={errorsCreateAirAndFridgeServiceReport}
                                                controlCreateAirAndFridgeServiceReport={controlCreateAirAndFridgeServiceReport}
                                            />
                                        </div>
                                        <div className='accessorySectionAirAndFridge'>
                                            <Accessory
                                                options={[
                                                    { name: 'Pruebas Neumáticas: ', fieldName: 'pneumaticTests', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Pruebas Hidráulicas: ', fieldName: 'hydraulicTests', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Validación y Configuración de Software: ', fieldName: 'softwareValidationAndConfiguration', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Validación y Funcionamiento del S.O: ', fieldName: 'validationAndOperationOfTheOS', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Revisión de accesorios: ', fieldName: 'accessoriesReview', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                ]}
                                                watchCreateAirAndFridgeServiceReport={watchCreateAirAndFridgeServiceReport}
                                                registerCreateAirAndFridgeServiceReport={registerCreateAirAndFridgeServiceReport}
                                                setValueCreateAirAndFridgeServiceReport={setValueCreateAirAndFridgeServiceReport}
                                                errorsCreateAirAndFridgeServiceReport={errorsCreateAirAndFridgeServiceReport}
                                                controlCreateAirAndFridgeServiceReport={controlCreateAirAndFridgeServiceReport}
                                            />
                                        </div>
                                        <div className='accessorySectionAirAndFridge'>
                                            <Accessory
                                                options={[
                                                    { name: 'Limpieza Externa e interna de Componentes: ', fieldName: 'externalAndInternalCleaningOfComponents', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Pruebas finales de todas las funciones del equipo: ', fieldName: 'finalTestsOfAllEquipmentFunctions', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Validación y puesta en marcha del equipo: ', fieldName: 'validationAndCommissioningOfTheEquipment', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'El equipo queda Trabajando Bien: ', fieldName: 'theEquipIsWorkingWell', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                    { name: 'Acciones correctivas ver /Observaciones: ', fieldName: 'correctiveActionsSeeObservations', type: 'radio', checkboxes: [{ text: '', value: 'Marcar Todo' },] },
                                                ]}
                                                watchCreateAirAndFridgeServiceReport={watchCreateAirAndFridgeServiceReport}
                                                registerCreateAirAndFridgeServiceReport={registerCreateAirAndFridgeServiceReport}
                                                setValueCreateAirAndFridgeServiceReport={setValueCreateAirAndFridgeServiceReport}
                                                errorsCreateAirAndFridgeServiceReport={errorsCreateAirAndFridgeServiceReport}
                                                controlCreateAirAndFridgeServiceReport={controlCreateAirAndFridgeServiceReport}
                                            />
                                        </div>
                                    </div>
                                }
                                <p className='titleSection'>Descripción de la Intervención</p>
                                {!data && <p className='titleSection'>Cambio de Partes</p>}
                                {!data && <hr />}
                                {!data && <Radio.Group className='radioG' onChange={d => setChangeOrInstalation(d.target.value)} value={changeOrInstalation}>
                                    <Radio className='radio' value={1}>Si</Radio>
                                    <Radio onClick={() => setCompInfo([])} className='radio' value={2}>No</Radio>
                                </Radio.Group>}
                                {changeOrInstalation === 1 &&
                                    <div>
                                        <p className='titleSection'>Partes (Repuestos) Instalados</p>
                                        <div className='newComp'>
                                            <Button type="primary"
                                                className='boton'
                                                icon={<PlusOutlined />}
                                                style={{
                                                    backgroundColor: '#4E8E30',
                                                    borderRadius: '10px',
                                                    padding: 0
                                                }}
                                                onClick={() => setCompInfo([...compInfo, ['', '', ''/* , '' */]])}>
                                                Añadir repuesto
                                            </Button>
                                        </div>
                                    </div>}
                                {compInfo.map((data, i) => (
                                    <Comp
                                        key={i}
                                        index={i}
                                        values={data}
                                        compInfo={compInfo}
                                        setCompInfo={setCompInfo}
                                        registerCreateAirAndFridgeServiceReport={registerCreateAirAndFridgeServiceReport}
                                        watchCreateAirAndFridgeServiceReport={watchCreateAirAndFridgeServiceReport}
                                        setValueCreateAirAndFridgeServiceReport={setValueCreateAirAndFridgeServiceReport}
                                    />
                                ))}
                                <p className='titleSection'>Observaciones</p>
                                <Input
                                    {...registerCreateAirAndFridgeServiceReport('observations', {
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === '') {
                                                errors.push("Observaciones es requerido");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        },
                                    })}
                                    mr={"0.7vw"}
                                    mt={'-1vw'}
                                    width={'100%'}
                                    height={'8vw'}
                                    labelstyle='inputLabelBordered'
                                    placeholder={"Observaciones"}
                                    fontSize={'1vw'}
                                    errorFontSize={'0.7vw'}
                                    labelMB={'-0.1vw'}
                                    textArea
                                    va={watchCreateAirAndFridgeServiceReport('observations')}
                                    setData={e => setValueCreateAirAndFridgeServiceReport('observations', e)}
                                    error={errorsCreateAirAndFridgeServiceReport.observations && errorsCreateAirAndFridgeServiceReport.observations.message}
                                />
                                <p className='titleSection'>Estatus del servicio</p>
                                <div style={{ display: 'flex' }}>
                                    <Radio.Group
                                        className='radioGroup'
                                        {...registerCreateAirAndFridgeServiceReport('statusServices', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Es requerido elegir una opcion");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        style={{ marginLeft: '2vw' }}
                                        value={watchCreateAirAndFridgeServiceReport('statusServices')}
                                        onChange={e => setValueCreateAirAndFridgeServiceReport('statusServices', e.target.value)}
                                    >
                                        <Radio value={"Concluido"}>Concluido</Radio>
                                        <Radio value={"En proceso"}>En proceso</Radio>
                                        <Radio value={"Fuera de servicio"}>Fuera de servicio</Radio>
                                        <Radio value={"Otro"}>Otro</Radio>
                                    </Radio.Group>
                                    {errorsCreateAirAndFridgeServiceReport.statusServices && <p className='errorMessage'>{errorsCreateAirAndFridgeServiceReport.statusServices.message}</p>}
                                </div>
                                <p className='titleSection'>Estado del equipo</p>
                                <div style={{ display: 'flex' }}>
                                    <Radio.Group
                                        className='radioGroup'
                                        style={{ marginBottom: '2vw', marginLeft: '2vw' }}
                                        {...registerCreateAirAndFridgeServiceReport('statusEquipment', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Es requerido elegir una opcion");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        value={watchCreateAirAndFridgeServiceReport('statusEquipment')}
                                        onChange={e => setValueCreateAirAndFridgeServiceReport('statusEquipment', e.target.value)}
                                    >
                                        <Radio value={"Funcional"}>Funcional</Radio>
                                        <Radio value={"No funcional"}>No funcional</Radio>
                                        <Radio value={"No operativo"}>No operativo</Radio>
                                    </Radio.Group>
                                    {errorsCreateAirAndFridgeServiceReport.statusEquipment && <p className='errorMessage'>{errorsCreateAirAndFridgeServiceReport.statusEquipment.message}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
        },
    ];
    return StepsAirConditionerAndFridge
}