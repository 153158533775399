import { Steps, Button, Radio, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../Input/Input'
import { PlusOutlined, CloseCircleFilled, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { useForm, Controller } from "react-hook-form"
import { apiUrl } from '../../common/config/Environments'
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import SaveIcon from '../../assets/images/save.svg'
import { EquipmentIndustrialActions } from '../../services/EquipmentIndustrial/EquipmentIndustrialSlice';
import { EquipmentVehicleActions } from '../../services/EquipmentVehicle/EquipmentVehicleSlice';
import { Hidden } from '@material-ui/core';
import { ModalSearchEquipment } from '../ModalSearchEquipment/ModalSearchEquipment';
import { EquipmentCard } from '../EquipmentCard/EquipmentCard';

const Accessory = ({ category, options, nameColumns, watchCreateDailyCheckList, setValueCreateDailyCheckList, controlCreateDailyCheckList, registerCreateDailyCheckList, errorsCreateDailyCheckList, type }) => {
    return (
        <div className='accessory'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', visibility: category ? "visible" : "hidden", marginBottom: category ? '0' : '1.2vw' }}>
                <p className='category' style={{ fontSize: category && (category.length <= 19 ? '0.8vw' : category.length <= 24 ? '0.7vw' : '0.6vw') }}>{category}</p>
            </div>
            <div className='divColumnsNames'>
                {nameColumns && nameColumns.map(d => (
                    <p className='columnName'>{d}</p>
                ))}
            </div>
            <div className='divOptions'>
                {options.map((option, index) => (
                    <div className='option' key={index}>
                        <p className='nameOption' style={{ fontSize: option.name.length > 30 ? '0.8vw' : '0.8vw' }}>{option.name}</p>
                        {
                            (() => {
                                switch (option.type) {
                                    case 'radio':
                                        return (
                                            <Controller
                                                key={index}
                                                control={controlCreateDailyCheckList} // control viene de useForm()
                                                name={option.fieldName + "Status"}
                                                rules={{
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("Es requerido elegir una opcion");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <Radio.Group
                                                        key={index + "radio"}
                                                        className='radioGroup'
                                                        {...field}
                                                        onChange={e => {
                                                            setValueCreateDailyCheckList(option.fieldName + "Status", e.target.value)
                                                        }}
                                                    >
                                                        {option.radios && option.radios.map(d => (
                                                            <Radio value={d.value}>{d.text}</Radio>
                                                        ))}
                                                    </Radio.Group>
                                                )}
                                            />
                                        );
                                    case 'input':
                                        return (
                                            <Input
                                                {...registerCreateDailyCheckList(option.fieldName + 'Description', {
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("Requerido");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                })}
                                                label={"none"}
                                                width={'12vw'}
                                                height={'1.8vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                errorFontSize={'0.7vw'}
                                                labelMB={'-0.1vw'}
                                                ml={'4.5vw'}
                                                setData={e => setValueCreateDailyCheckList(option.fieldName + 'Description', e)}
                                                error={errorsCreateDailyCheckList[option.fieldName + 'Description'] && errorsCreateDailyCheckList[option.fieldName + 'Description'].message}
                                            />
                                        );
                                    case 'textArea':
                                        return (
                                            <Input
                                                {...registerCreateDailyCheckList(option.fieldName + 'Description', {
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("Requerido");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                })}
                                                label={"none"}
                                                width={'12vw'}
                                                height={'16vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                errorFontSize={'0.7vw'}
                                                labelMB={'-0.1vw'}
                                                textArea
                                                setData={e => setValueCreateDailyCheckList(option.fieldName + 'Description', e)}
                                                error={errorsCreateDailyCheckList[option.fieldName + 'Description'] && errorsCreateDailyCheckList[option.fieldName + 'Description'].message}
                                            />
                                        );
                                    case 'dobleInput':
                                        return (
                                            <div style={{ display: 'flex', position: 'relative', left: '4vw' }}>
                                                <Input
                                                    {...registerCreateDailyCheckList(option.fieldName + 'Value', {
                                                        validate: (value) => {
                                                            const errors = [];
                                                            if (!value || value === '') {
                                                                errors.push("Requerido");
                                                            }
                                                            return errors.length === 0 || errors.join(', ');
                                                        },
                                                    })}
                                                    label={"none"}
                                                    width={'7vw'}
                                                    height={'1.8vw'}
                                                    labelstyle='inputLabelBordered'
                                                    fontSize={'1vw'}
                                                    errorFontSize={'0.7vw'}
                                                    labelMB={'-0.1vw'}
                                                    mr={'1vw'}
                                                    setData={e => setValueCreateDailyCheckList(option.fieldName + 'Value', e)}
                                                    error={errorsCreateDailyCheckList[option.fieldName + 'Value'] && errorsCreateDailyCheckList[option.fieldName + 'Value'].message}
                                                />
                                                <Input
                                                    {...registerCreateDailyCheckList(option.fieldName + 'Unit', {
                                                        validate: (value) => {
                                                            const errors = [];
                                                            if (!value || value === '') {
                                                                errors.push("Requerido");
                                                            }
                                                            return errors.length === 0 || errors.join(', ');
                                                        },
                                                    })}
                                                    label={"none"}
                                                    width={'7vw'}
                                                    height={'1.8vw'}
                                                    labelstyle='inputLabelBordered'
                                                    fontSize={'1vw'}
                                                    errorFontSize={'0.7vw'}
                                                    labelMB={'-0.1vw'}
                                                    setData={e => setValueCreateDailyCheckList(option.fieldName + 'Unit', e)}
                                                    error={errorsCreateDailyCheckList[option.fieldName + 'Unit'] && errorsCreateDailyCheckList[option.fieldName + 'Unit'].message}
                                                />
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })()
                        }
                    </div>
                ))}
            </div>
        </div>
    )
};

export function useStepsDailyCheckList(
    registerCreateDailyCheckList,
    handleSubmitCreateDailyCheckList,
    setValueCreateDailyCheckList,
    watchCreateDailyCheckList,
    controlCreateDailyCheckList,
    errorsCreateDailyCheckList,
    triggerCreateDailyCheckList,
    equipSelected,
    setEquipSelected
) {

    const dispatch = useDispatch()
    const { equipmentsSearched } = useSelector(state => state.Equipment)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)

    const StepsDailyCheckList = [
        {
            title: 1,
            content:
                <div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <div style={{ width: '31%' }}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                {equipSelected &&
                                    <EquipmentCard
                                        onChange={() => setEquipSelected(false)}
                                        id={equipSelected.id}
                                        img={equipSelected.image}
                                        name={equipSelected.name}
                                        brand={equipSelected.brand}
                                        model={equipSelected.modell}
                                        serie={equipSelected.serie}
                                        location={equipSelected.location}
                                        moduleWork={equipSelected.moduleWork}
                                    >
                                    </EquipmentCard>
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '68%', flexDirection: 'column', marginLeft: '1%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw', boxShadow: '0px 4px 4px 0px #00000040', paddingLeft: '1vw', paddingRight: '1vw' }}>
                                <div style={{ display: 'flex' }}>
                                    <div className='divDate'>
                                        <p className='label'>Fecha de Reporte</p>
                                        <DatePicker
                                            value={watchCreateDailyCheckList("date")}
                                            {...registerCreateDailyCheckList("date", {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La fecha es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                            onChange={d => setValueCreateDailyCheckList("date", d)}
                                        />
                                        {errorsCreateDailyCheckList.date && <p className='errorMessage'>{errorsCreateDailyCheckList.date.message}</p>}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className='divDate'>
                                        <Input
                                            {...registerCreateDailyCheckList('mileage', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("Kilometraje es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            mr={"0.7vw"}
                                            mt={'1vw'}
                                            width={'10vw'}
                                            height={'2vw'}
                                            label={"Digite Kilometraje"}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            errorFontSize={'0.7vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchCreateDailyCheckList('mileage')}
                                            setData={e => setValueCreateDailyCheckList('mileage', e)}
                                            error={errorsCreateDailyCheckList.mileage && errorsCreateDailyCheckList.mileage.message}
                                        />
                                    </div>
                                </div>
                                <p className='titleSection'>Elementos del vehículo</p>
                                <div className='elementsDiv'>
                                    <p className='elementsName'>Botiquín</p>
                                    <div className='divDate'>
                                        <p className='label'>Fecha de Vencimiento</p>
                                        <DatePicker
                                            value={watchCreateDailyCheckList("firstAidKitDueDate")}
                                            {...registerCreateDailyCheckList("firstAidKitDueDate", {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La fecha es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                            onChange={d => setValueCreateDailyCheckList("firstAidKitDueDate", d)}
                                        />
                                        {errorsCreateDailyCheckList.firstAidKitDueDate && <p className='errorMessage'>{errorsCreateDailyCheckList.firstAidKitDueDate.message}</p>}
                                    </div>
                                </div>
                                <div className='elementsDiv'>
                                    <p className='elementsName'>Extintor</p>
                                    <div className='divDate'>
                                        <p className='label'>Fecha de Vencimiento</p>
                                        <DatePicker
                                            value={watchCreateDailyCheckList("extinguisherDueDate")}
                                            {...registerCreateDailyCheckList("extinguisherDueDate", {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La fecha es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                            onChange={d => setValueCreateDailyCheckList("extinguisherDueDate", d)}
                                        />
                                        {errorsCreateDailyCheckList.extinguisherDueDate && <p className='errorMessage'>{errorsCreateDailyCheckList.extinguisherDueDate.message}</p>}
                                    </div>
                                </div>
                                <div className='elementsDiv'>
                                    <p className='elementsName'>Bateria-Garantia</p>
                                    <div className='divDate'>
                                        <p className='label'>Fecha de Vencimiento</p>
                                        <DatePicker
                                            value={watchCreateDailyCheckList("batteryDueDate")}
                                            {...registerCreateDailyCheckList("batteryDueDate", {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La fecha es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                            onChange={d => setValueCreateDailyCheckList("batteryDueDate", d)}
                                        />
                                        {errorsCreateDailyCheckList.batteryDueDate && <p className='errorMessage'>{errorsCreateDailyCheckList.batteryDueDate.message}</p>}
                                    </div>
                                </div>

                                <p className='titleSection'>Estado inicial</p>
                                <div className='divAccessories' style={{ boxShadow: 0 }}>
                                    <div className='accessorySection'>
                                        <p className='text'>Puede seleccionar marcar el estado y cantidad de manera Total con un clic para todas las partes y accesorios del vehículo.</p>
                                    </div>
                                    <div className='accessorySection'>
                                        <div className='accessory yellow'>
                                            <div className='divColumnsNames'>
                                                <p className='columnName'>Descripción:</p>
                                                <p className='columnName'>Estado:</p>
                                            </div>
                                            <div className='divOptions'>
                                                <div className='option'>
                                                    <p className='nameOption'>Marcar Todo: </p>
                                                    <Radio.Group
                                                        className='radioGroup'
                                                        onChange={e => {
                                                            let aux = watchCreateDailyCheckList()
                                                            let options = Object.keys(aux).filter(key => key.endsWith('Status'))
                                                            options.forEach(option => {
                                                                setValueCreateDailyCheckList(option, e.target.value)
                                                            })
                                                        }}
                                                    >
                                                        <Radio value={"GOOD"}>Bueno</Radio>
                                                        <Radio value={"REGULAR"}>Regular</Radio>
                                                        <Radio value={"BAD"}>Malo</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='divAccessories' style={{ flexDirection: 'column', height: '315vw' }}>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={'Estado de presentación N°1'}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Aseo Interno: ', fieldName: 'internalToilet', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Aseo Externo: ', fieldName: 'externalToilet', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Latas: ', fieldName: 'cans', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Pintura: ', fieldName: 'paint', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={'Estado Comodidad N°2'}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Aire Acondicionado: ', fieldName: 'airCoinditioning', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Silletería (Anclaje, estado): ', fieldName: 'chairs', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Encendedor: ', fieldName: 'lighter', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Luz Interior o de techo: ', fieldName: 'interiorOrCeilingLight', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Niveles y perdidas de líquidos N°3"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Nivel de Aceite de motor: ', fieldName: 'engineOilLevel', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Nivel de liquido de frenos: ', fieldName: 'brakeFluidLevel', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Nivel de agua del radiador: ', fieldName: 'radiatorWaterLevel', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Nivel de agua de la bateria: ', fieldName: 'batteryWaterLevel', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Nivel de aceite hidráulico: ', fieldName: 'hydraulicOilLevel', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Fugas de A.C.P.M: ', fieldName: 'ACPMLeaks', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Fugas de agua: ', fieldName: 'waterLeaks', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Fugas de Aceite de transmisión: ', fieldName: 'transmissionOilLeaks', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Fuga aceite de caja: ', fieldName: 'boxOilLeaks', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Fugas de liquidos de frenos: ', fieldName: 'brakeFluidLeaks', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Tablero de Control N°4"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Instrumentos: ', fieldName: 'instrumentos', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Luces de Tablero: ', fieldName: 'tableLight', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Nivel de combustible: ', fieldName: 'fuelLevel', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Odómetro: ', fieldName: 'odometer', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Pito: ', fieldName: 'horn', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Tacómetro: ', fieldName: 'tachometer', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Velocímetro: ', fieldName: 'speedometer', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Indicador de Aceite: ', fieldName: 'oilIndicator', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Indicador de temperatura: ', fieldName: 'temperatureIndicator', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Seguridad Pasiva N°5"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Cinturones de Seguridad: ', fieldName: 'seatBelts', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Airbags: ', fieldName: 'airbags', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Chasis y carroceria: ', fieldName: 'chassisAndBody', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Cristales (Vidrios): ', fieldName: 'crystals', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Apoyacabezas: ', fieldName: 'headrest', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Estado espejos: ', fieldName: 'stateMirrors', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Espejo Lateral Derecho: ', fieldName: 'rightSideMirror', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Espejo Lateral Izquierdo: ', fieldName: 'leftSideMirror', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Espejo Retrovisor: ', fieldName: 'rearViewMirror', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Seguridad Activa N°6"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Estado de la Dirección: ', fieldName: 'turnSignalStatus', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Estado Suspensión Delantera Amortiguadores: ', fieldName: 'conditionFrontSuspensionShockAbsorber', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Estado Suspensión Trasera Amortiguadores: ', fieldName: 'conditionBackSuspensionShockAbsorber', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Estado Parabrisas: ', fieldName: 'windshieldStatus', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Vidrio Frontal: ', fieldName: 'frontalGlass', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Limpiabrisas Izquierdo: ', fieldName: 'leftWindshieldWiper', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Lavaparabrisas: ', fieldName: 'windshieldWasher', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Estado de Luces N°6.5"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Luces Medias: ', fieldName: 'mediumLights', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Luces Altas: ', fieldName: 'highLights', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Luces Bajas: ', fieldName: 'lowLights', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Direccional Izquie. Delant: ', fieldName: 'frontLeftTurnSignal', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Direccional Derec. Delant: ', fieldName: 'frontRightTurnSignal', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Direccional Izquie. Tras: ', fieldName: 'backLeftTurnSignal', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Direccional Derec. Tras: ', fieldName: 'backRightTurnSignal', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Luces de Parqueo: ', fieldName: 'parkingLights', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Luz Freno: ', fieldName: 'brakeLight', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Luz Reverso: ', fieldName: 'reverseLight', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'L. Antiniebla Exploradoras: ', fieldName: 'explorerFogLight', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Estado Llantas (Labrado 3mm, Presión de Aire, No Reencauchada) N° 6.6"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Delantera Derecha: ', fieldName: 'tireFrontRight', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Delantera Izquierda: ', fieldName: 'tireFrontLeft', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Trasera Derecha: ', fieldName: 'tireBackRight', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Trasera Izquierda: ', fieldName: 'tireBackLeft', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Repuesto: ', fieldName: 'tireSpare', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Presión Aire llanta: ', fieldName: 'tireAirPressure', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Fecha Fabricación DOT: ', fieldName: 'fabricationDateDOT', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Rines: ', fieldName: 'wheels', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Frenos N°6.7"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Estado de los Frenos: ', fieldName: 'brakeStatus', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Freno de Mano: ', fieldName: 'handBrake', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Pastillas: ', fieldName: 'brakePads', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Otros N°7"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Instalaciones eléctricas: ', fieldName: 'electricalInstallations', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Clutch: ', fieldName: 'clutch', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Exhosto: ', fieldName: 'exhaustPipe', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Alarma Sonora de Reversa: ', fieldName: 'reverseAudibleAlarm', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Salto de cambios: ', fieldName: 'shiftJump', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Cambios suaves: ', fieldName: 'smoothChanges', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Guaya del acelerador: ', fieldName: 'throttleGuide', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Sistema de embrague: ', fieldName: 'clutchSystem', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Encendido: ', fieldName: 'ignition', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Sincronización: ', fieldName: 'synchronization', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'Placas: ', fieldName: 'plates', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={'Equipo de Carretera N°8'}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: '1 gato con capacidad para elevar el vehículo: ', fieldName: 'oneJackWithCapacityToRaiseVehicle', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '2 tacos para bloquear el vehículo: ', fieldName: 'twoBlocksForVehicle', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 par de guantes de trabajo en lona: ', fieldName: 'aPairOfCanvasWorkGloves', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 cable de Iniciar: ', fieldName: 'aStarterCable', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 extinguidor de fuego (capacidad min. 10lb), Tipo BC preferiblemente CO2: ', fieldName: 'aFireExtinguisher', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 caja de herramientas (alicates, destornilladores de pala y estrella, llave de expansión y fijas)', fieldName: 'aToolbox', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={"Botiquín N°9"}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: '2 bolsas de suero fisiológico o de solución salina normal de 125ml: ', fieldName: 'twoBagsOfSerums', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '4 juegos de guantes quirúrgicos: ', fieldName: 'fourSetsOfSurgicalGloves', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 paq de vendas de algodón: ', fieldName: 'aPackageOfCottonBandages', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '10 aplicadores/copitos: ', fieldName: 'tenCopitos', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '3 venajes triangulares de tela 1,20 base x 70 altura: ', fieldName: 'threeTriangularFabricBandages', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '2 compresas: ', fieldName: 'twoCompresses', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 mascarilla facial bolsillo para reanimación cerebro cardiovascular: ', fieldName: 'aFaceMask', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '4 paquetes de gasa x 5 unidades: ', fieldName: 'fourPacketsOfGauze', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 cinta micropore 2 pulgadas: ', fieldName: 'microporeTape', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 esparadrapo 5 pulgadas: ', fieldName: 'aFiveInchTape', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '2 inmovilizadores maleables para extremidades inferiores: ', fieldName: 'twoMalleableImmobilizersForLower', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '2 inmovilizadores maleables para extremidades superiores: ', fieldName: 'twoMalleableImmobilizersForHigher', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '10 unidades de vendas adhesivas o curitas: ', fieldName: 'tenAdhesiveTape', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '10 tapabocas desechable con bolsa individual: ', fieldName: 'tenFaceMasks', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 Libreta : ', fieldName: 'aNotebook', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'listado de teléfonos de emergencia : ', fieldName: 'emergencyPhoneList', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: '1 cinta micropore 1 lapiz: ', fieldName: 'microporeTapePencil', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                                { name: 'manual o folleto de primeros auxilios: ', fieldName: 'firstAidManualBooklet', type: 'radio', radios: [{ text: 'Bueno', value: 'GOOD' }, { text: 'Regular', value: 'REGULAR' }, { text: 'Malo', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={'ELEMENTO A VERIFICAR POR COVID-19 N°10'}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: 'Elementos de Protección Personal (EPP) para la limpieza: monogafas, guantes de nitrilo y protección respiratoria : ', fieldName: 'EPPForCleaning', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                                { name: 'kit de limpieza guantes desechables, tapabocas, gel desin fectante, pañuelos o toallas desechables y un atomizador con alcohol al 70%: ', fieldName: 'cleaningKitGloves', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                                { name: 'Contenedor con bolsa color negro para recolección de residuos: ', fieldName: 'containerWithBlackBag', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                                { name: 'Señalización, marcado y/o bloqueo de sillas con cinta de seguridad: ', fieldName: 'signaling', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                    <div className='accessorySection'>
                                        <Accessory
                                            category={'Verificación Protocolo de Limpieza y Desinfección'}
                                            nameColumns={['Descripción:', 'Estado:']}
                                            options={[
                                                { name: '1. Realiza el lavado de manos antes de entrar al vehículo: ', fieldName: 'handWashing', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                                { name: '2. Limpia el interior del vehículo antes y después de cada uso: \n1. Manija puerta y Eleva vidrios \n2. Cinturón de seguridad \n3. Espejos \n4. Switch de encendido \n5. Comando de direccionales \n6. Comando de limpia parabrisas o panorámicos \n7. cabrilla o manubrio \n8. Palanca de cambios \n9. Freno de mano \n10.Radio \n11. Controles de aire \n12.Pedales: ', fieldName: 'interiorCleaning', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                                { name: '3. Desinfecta el vehículo, rociando al interior del vehículo,(idealmente con alcohol en concentraciones al 70% (alcohol antiséptico para el hogar))\nNota: Aspersión, dejándoloactuar durante 10 minutos y dejarlo secar naturalmente: ', fieldName: 'disinfectTheVehicle', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                                { name: '4. Realiza el lavado de manos después de realizar la limpieza al vehículo: ', fieldName: 'handWashingAfter', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                                { name: 'Validar que el vehículo no cuente con alfombras, tapetes, forros de sillas, protectores de cabrilla o palanca de cambios: ', fieldName: 'noRugs', type: 'radio', radios: [{ text: 'Cumple', value: 'GOOD' }, { text: 'No Cumple', value: 'BAD' }] },
                                            ]}
                                            watchCreateDailyCheckList={watchCreateDailyCheckList}
                                            registerCreateDailyCheckList={registerCreateDailyCheckList}
                                            setValueCreateDailyCheckList={setValueCreateDailyCheckList}
                                            errorsCreateDailyCheckList={errorsCreateDailyCheckList}
                                            controlCreateDailyCheckList={controlCreateDailyCheckList}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p className='titleSection'>Observacion</p>
                                    <Input
                                        {...registerCreateDailyCheckList('observation', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Observación es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        mr={"0.7vw"}
                                        mt={'-1vw'}
                                        width={'50vw'}
                                        height={'6vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        textArea
                                        va={watchCreateDailyCheckList('observation')}
                                        setData={e => setValueCreateDailyCheckList('observation', e)}
                                        error={errorsCreateDailyCheckList.observation && errorsCreateDailyCheckList.observation.message}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
        },
    ];
    return StepsDailyCheckList
}