import { Image, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import ArimoBold from '../../assets/fonts/Arimo-Bold.ttf';

Font.register({
    family: 'Arimo-Bold',
    src: ArimoBold,
});

export const PdfVehiclePapers = ({ fullDataEquipment, vehiclePapers, data, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const docs = {
        "Licencia": "Licencia de Transito del Vehiculo",
        "SOAT": "Seguro Obligatorio de Accidentes de Transito SOAT",
        "Póliza": "Póliza de REsponsabilidad Civil Extracontractual",
        "Certificado": "Certificado de Revisión Técnico Mecánica y de Gases",
    }

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            alignItems: 'center'
        },
        header: {
            border: '3px solid #000',
            marginTop: '3%',
            width: '95%',
            height: '8%',
            flexDirection: 'row'
        },
        headersub0: {
            width: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '3px solid #000'
        },
        headersub1: {
            width: '60%',
            borderRight: '3px solid #000'
        },
        headerRow: {
            height: '70%',
            width: '100%',
            borderBottom: '3px solid #000',
            alignItems: 'center',
            justifyContent: 'center'
        },
        headerRow1: {
            height: '30%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        bigText: {
            fontSize: '32px'
        },
        text: {
            fontSize: '18px'
        },
        generalInfo: {
            border: '3px solid #000',
            marginTop: '1%',
            width: '93%',
            height: '18%',
            flexDirection: 'row'
        },
        viewImg: {
            width: '25%',
            borderRight: '3px solid #000'
        },
        viewData: {
            width: '75%',
            flexDirection: 'column'
        }
    });

    return (
        <Page size="A1" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.headersub0}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={styles.headersub1}>
                    <View style={styles.headerRow}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>NAZHER CENTRO MEDICO ESPECIALIZADO S.A.S</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>FLORENCIA-CAQUETA</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>NIT: 901244204</Text>
                    </View>
                    <View style={styles.headerRow1}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>INVENTARIO FISICO</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>ESTADO DE VEHICULO</Text>
                    </View>
                </View>
                <View style={styles.headersub0}>
                    <View style={styles.headerRow}>
                        <View style={{ width: '100%', height: '60%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>CODIGO</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>000</Text>
                            </View>
                        </View>
                        <View style={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>VERSION</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>1</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.headerRow1}>
                        <View style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>PAGINAS</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>1 DE 1</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            {/* <View style={{ width: '95%', height: '3.5%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '2%' }}>
                <View style={{ width: '70%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Número de Placa de Inventario: </Text>{fullDataEquipment.licensePlate}</Text>
                </View>
                <View style={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ width: '100%', height: '50%', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Fecha</Text>
                    </View>
                    <View style={{ width: '100%', height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px' }}>{fullDataEquipment.createdDate ? padStr(new Date(fullDataEquipment.createdDate).getDate()) + "/" + padStr(1 + new Date(fullDataEquipment.createdDate).getMonth()) + "/" + padStr(new Date(fullDataEquipment.createdDate).getFullYear()) : ''}</Text>
                    </View>
                </View>
            </View> */}

            {vehiclePapers.length > 0 ? vehiclePapers[0].vehiclePaper.flatMap((d, i) => [
                <View key={i} style={{ width: '95%', height: '80%', border: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '1%' }}>
                    <Text style={{ fontSize: '30px' }}>{docs[d.type]}</Text>
                    <Image style={{ width: '100%', height: '100%' }} src={d.image}></Image>
                </View>,
                ((i + 1) % 1 === 0) ? <View key={i + 'break'} style={{ height: '20%' }} /> : null // This will force a page break after every two images
            ])
                :
                <View style={{ width: '95%', height: '80%', border: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '1%' }}>
                    <Text style={{ fontSize: '30px' }}>No hay documentos</Text>
                </View>}


            {/* <View style={{ marginTop: '0%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Image style={{ width: '25%', height: '100%' }} src={(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person.firm) ? fullDataEquipment.userCreator.person.firm : blanco}></Image>
            </View>

            <View style={{ marginTop: '1%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                
                <Text
                    style={{ fontSize: '20px', marginTop: '0.4%' }}>
                    {(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person) &&
                        fullDataEquipment.userCreator.person.name + " " +
                        fullDataEquipment.userCreator.person.secondName + " " +
                        fullDataEquipment.userCreator.person.lastName + " " +
                        fullDataEquipment.userCreator.person.secondLastName
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.position
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '21px', marginTop: '0.1%', fontFamily: 'Arimo-Bold' }}>AGENTE</Text>
            </View> */}
        </Page >
    )
}