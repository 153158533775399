import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"

import { EquipmentSystemActions } from './EquipmentSystemSlice'
import { TimelineActions } from '../Timeline/TimelineSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(EquipmentSystemActions.setMunicipalitys(muns.payload))
  }
  yield put(EquipmentSystemActions.setLoading(false))
} */

function* createEquipmentSystem({ payload }) {
  const e = yield Api.post('/equipment/system', payload)
  if (e.ok) {
    message.success(e.payload.message)
    yield put(EquipmentSystemActions.setEquipmentSystemCreatedId(e.payload.message))
  } else {
    message.error(e.payload.message)
  }
  yield put(EquipmentSystemActions.setLoadingCreateEquipmentSystem(false))
}

function* deleteEquipmentSystem({ payload }) {
  const EquipmentSystemDeleted = yield Api.post(`/EquipmentSystem/delete?idd=${payload.id}`)
  if (EquipmentSystemDeleted.ok) {
    message.success("Equipo eliminado")
  } else {
    message.error("Error al eliminar equipo")
  }
}

function* createBiomedicComponent({ payload }) {
  const bc = yield Api.post(`/biomedicComponent`, payload)
  if (bc.ok) {
    message.success('Componentes añadidos')
  } else {
    message.error("Error creando componentes")
  }
}

function* getEquipmentSystems({ payload }) {
  const eq = yield Api.get(`/EquipmentSystem/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  yield put(EquipmentSystemActions.setEquipmentSystemsSearched(eq.payload[0]))
  yield put(EquipmentSystemActions.setQuantity(eq.payload[1]))
  yield put(EquipmentSystemActions.setLoading(false))
}

function* getEquipmentSystemsByModuleWork({ payload }) {
  const eq = yield Api.get(`/EquipmentSystem/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  yield put(EquipmentSystemActions.setEquipmentSystemsSearched(eq.payload[0]))
  yield put(EquipmentSystemActions.setQuantity(eq.payload[1]))
  yield put(EquipmentSystemActions.setLoading(false))
}

function* searchEquipmentSystem({ payload }) {
  const eq = yield Api.get(`/EquipmentSystem/byDependencySearch?dependency=${payload.dependency}&nameEquip=${payload.nameEquip}&state=${payload.state}`)
  yield put(EquipmentSystemActions.setEquipmentSystemsSearched(eq.payload))
  yield put(EquipmentSystemActions.setLoading(false))
}

function* search({ payload }) {
  const eq = yield Api.get(`/EquipmentSystem/search?dependency=${payload.dependency}&nameEquip=${payload.nameEquip}&licensePlate=${payload.licensePlate}&year=${payload.year}&take=${payload.take}&page=${payload.page}`)
  if (eq.ok) {
    yield put(EquipmentSystemActions.setEquipmentSystemsSearched(eq.payload))
    yield put(EquipmentSystemActions.setIsFiltered(true))
  } else {
    message.error("Error obteniendo equipos filtrados")
  }
  yield put(EquipmentSystemActions.setLoading(false))
}

function* getStates() {
  const s = yield Api.get('/EquipmentSystem/distinctState')
  if (s.ok) {
    yield put(EquipmentSystemActions.setStates(s.payload))
  } else {
    message.error("Error obteniendo estados")
  }
}

function* getLocations() {
  const m = yield Api.get('/biomedicEquipmentSystem/distinctLocation')
  if (m.ok) {
    yield put(EquipmentSystemActions.setLocations(m.payload))
  } else {
    message.error("Error obteniendo ubicaciones")
  }
}

function* getEquipsWithCaseReports({ payload }) {
  const e = yield Api.get(`/EquipmentSystem/getLastCaseReport/byDependency?dependency=${payload.area}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentSystemActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentSystemActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentSystemActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* getEquipsWithCaseReportsByModuleWork({ payload }) {
  const e = yield Api.get(`/EquipmentSystem/getLastCaseReport/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentSystemActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentSystemActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentSystemActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* searchCaseReport({ payload }) {
  const c = yield Api.get(`/EquipmentSystem/getCaseReportSearch?dependency=${payload.dependency}&take=8&page=${payload.page}&name=${payload.name}&location=${payload.location}&state=${payload.state}&date=${payload.date[0]}&date1=${payload.date[1]}`)
  if (c.ok) {
    yield put(EquipmentSystemActions.setEquipsWithCaseReports(c.payload[0]))
    yield put(EquipmentSystemActions.setQuantityEquipsWithCaseReports(c.payload[1]))
    yield put(EquipmentSystemActions.setLoadingEquipsWithCaseReport(false))
    yield put(EquipmentSystemActions.setIsFiltered(true))
  } else {
    message.error("Error al realizar la busqueda")
  }
}

function* getEquipAndReportPDF({ payload }) {
  const e = yield Api.get(`/EquipmentSystem/getByIdAndReportsPDF?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentSystemActions.setEquipAndReportPDF(e.payload))
  } else {
    message.error("Error al obtener PDFs")
  }
}

function* getEquipsForTimeline({ payload }) {
  const e = yield Api.get(`/EquipmentSystem/getForTimeline?dependency=${payload.dependency}&string=${payload.string}`)
  if (e.ok) {
    if (payload.string === "") {
      yield put(EquipmentSystemActions.setAllEquipsForTimeline(e.payload))
    } else {
      yield put(EquipmentSystemActions.setEquipsForTimeline(e.payload))
    }
  } else {
    message.error("Error al obtener equipos buscados")
  }
  yield put(EquipmentSystemActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelines({ payload }) {
  const e = yield Api.get(`/EquipmentSystem/getEquipsWithTimelines/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0 })
    })
    yield put(TimelineActions.setTimelinesByEquipmentSystem(aux))
    yield put(EquipmentSystemActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentSystemActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the EquipmentSystems

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentSystemActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelinesByModuleWork({ payload }) {
  const e = yield Api.get(`/EquipmentSystem/getEquipsWithTimelines/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0 })
    })
    yield put(TimelineActions.setTimelinesByEquipmentSystem(aux))
    yield put(EquipmentSystemActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentSystemActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the EquipmentSystems

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentSystemActions.setLoadingEquipsForTimeline(false))
}

function* getFullDataEquipmentSystem({ payload }) {
  const e = yield Api.get(`/EquipmentSystem/fullDataEquipmentSystemById?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentSystemActions.setFullDataEquipmentSystem(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentSystemActions.setLoadingFullDataEquipmentSystem(false))
}

function* updateEquipmentSystem({ payload }) {
  const e = yield Api.post(`/equipment/updateComputersEquipment?id=${payload.id}`, payload.payload)
  if (e.ok) {
    message.success("Equipo actualizado")
  } else {
    message.error("Error al actualizar equipo")
  }
  yield put(EquipmentSystemActions.setLoadingCreateEquipmentSystem(false))
}

function* actionWatcher() {
  yield takeLatest(EquipmentSystemActions.createEquipmentSystem, createEquipmentSystem)
  yield takeLatest(EquipmentSystemActions.updateEquipmentSystem, updateEquipmentSystem)
  yield takeLatest(EquipmentSystemActions.getEquipmentSystems, getEquipmentSystems)
  yield takeLatest(EquipmentSystemActions.searchEquipmentSystem, searchEquipmentSystem)
  yield takeLatest(EquipmentSystemActions.deleteEquipmentSystem, deleteEquipmentSystem)
  yield takeLatest(EquipmentSystemActions.getStates, getStates)
  yield takeLatest(EquipmentSystemActions.getLocations, getLocations)
  yield takeLatest(EquipmentSystemActions.getEquipsWithCaseReports, getEquipsWithCaseReports)
  yield takeLatest(EquipmentSystemActions.searchCaseReport, searchCaseReport)
  yield takeLatest(EquipmentSystemActions.getEquipAndReportPDF, getEquipAndReportPDF)
  yield takeLatest(EquipmentSystemActions.getEquipsForTimeline, getEquipsForTimeline)
  yield takeLatest(EquipmentSystemActions.getEquipsWithTimelines, getEquipsWithTimelines)
  yield takeLatest(EquipmentSystemActions.getFullDataEquipmentSystem, getFullDataEquipmentSystem)
  yield takeLatest(EquipmentSystemActions.getEquipmentSystemsByModuleWork, getEquipmentSystemsByModuleWork)
  yield takeLatest(EquipmentSystemActions.search, search)
  yield takeLatest(EquipmentSystemActions.getEquipsWithCaseReportsByModuleWork, getEquipsWithCaseReportsByModuleWork)
  yield takeLatest(EquipmentSystemActions.getEquipsWithTimelinesByModuleWork, getEquipsWithTimelinesByModuleWork)
}

export default function* EquipmentSystemSaga() {
  yield all([actionWatcher()])
}