import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"
import { EnterpriseActions } from './EnterpriseSlice'


function* createEnterprise({ payload }) {
  const etp = yield Api.post("/enterprise", payload)
  if (etp.ok) {
    message.success("Organización creada")
    yield put(EnterpriseActions.setCreatedId(etp.payload.id))
  } else {
    message.error("Error al crear organización.")
  }
  yield put(EnterpriseActions.setLoading(false))
}

function* createHeadquarters({ payload }) {
  const hq = yield Api.post("/headquarter", payload)
  if (hq.ok) {
    message.success("Sedes creadas")
  } else {
    message.error("Error al crear sedes.")
  }
}

function* createAdmins({ payload }) {
  const hq = yield Api.post("/userAdmin", payload)
  if (hq.ok) {
    message.success("Administradores creados")
  } else {
    message.error("Error al crear administradores.")
  }
}

function* createArea({ payload }) {
  const data = {
    "name": payload[0].name,
    "dependencys": payload[0].dependency,
    "enterprise": payload[0].enterprise
  }
  const a = yield Api.post("/area", data)
  if (a.ok) {
    message.success("Area creada")
    yield put(EnterpriseActions.createUsers(payload[1]))
  } else {
    message.error("Error al crear area")
  }
}

function* createUsers({ payload }) {
  const a = yield Api.post("/user", payload)
  if (a.ok) {
    message.success("Usuarios creados")
    yield put(EnterpriseActions.setUsersCreated(true))
  } else {
    message.error("Error al crear usuarios")
  }
}

function* getEnterpriseImage({ payload }) {
  const a = yield Api.get(`/enterprise/imageById?id=${payload}`)
  if (a.ok) {
    yield put(EnterpriseActions.setEnterpriseImage(a.payload))
  } else {
    message.error("Error al obtener imagen")
  }
}

function* getHeadquarters({ payload }) {
  const a = yield Api.get(`/headquarter/byEnterprise?id=${payload}`)
  if (a.ok) {
    yield put(EnterpriseActions.setHeadquarters(a.payload))
  } else {
    message.error("Error al obtener sedes")
  }
}

function* actionWatcher() {
  yield takeLatest(EnterpriseActions.createEnterprise, createEnterprise)
  yield takeLatest(EnterpriseActions.createHeadquarters, createHeadquarters)
  yield takeLatest(EnterpriseActions.createAdmins, createAdmins)
  yield takeLatest(EnterpriseActions.createArea, createArea)
  yield takeLatest(EnterpriseActions.createUsers, createUsers)
  yield takeLatest(EnterpriseActions.getEnterpriseImage, getEnterpriseImage)
  yield takeLatest(EnterpriseActions.getHeadquarters, getHeadquarters)
}

export default function* EnterpriseSaga() {
  yield all([actionWatcher()])
}