import { useEffect, useRef, useState } from "react"
import React from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete, Button, DatePicker, message, Modal, Radio, Select, Transfer } from 'antd'
/* import { MenuItem, Select } from "@material-ui/core"
 */import Icon, { PlusOutlined, SearchOutlined, LoadingOutlined, CloseCircleFilled, SettingFilled, SyncOutlined, SolutionOutlined, DatabaseOutlined } from "@ant-design/icons";
/* import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl'; */
import { DepMunActions } from "../../../services/DepMun/DepMunSlice";
import { EquipmentActions } from "../../../services/Equipment/EquipmentSlice";
import { Input } from "../../../components/Input/Input";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { Document, Page, PDFDownloadLink, PDFViewer, Text, View } from "@react-pdf/renderer";
import { Equipment } from "../../../components/Equipment/Equipment";
import { EquipmentSystem } from "../../../components/EquipmentSystem/EquipmentSystem";
import { Controller, useForm } from "react-hook-form";
import { TransferActions } from "../../../services/Transfer/TransferSlice";
import { TransferCard } from "../../../components/TransferCard/TransferCard";
import { AreaActions } from "../../../services/Area/AreaSlice";

export const Transfers = () => {
    const [option, setOption] = useState('Search')
    const [edit, setEdit] = useState(false)
    const [equipData, setEquipData] = useState()
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalCreateTransfer, setVisibleModalCreateTransfer] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [textXLSX, setTextXLSX] = useState("")
    const [textPDF, setTextPDF] = useState("")
    const [nameEquipToFilter, setNameEquipToFilter] = useState(false)
    const [stateToFilter, setStateToFilter] = useState(false)
    const [locationToFilter, setLocationToFilter] = useState(false)
    const [yearToFilter, setYearToFilter] = useState(false)
    const [areaSelectedToFilter, setAreaSelectedToFilter] = useState(false)
    const [dependencySelectedToFilter, setDependencySelectedToFilter] = useState(false)
    const [licensePlateToSearchToFilter, setLicensePlateToSearchToFilter] = useState(false)
    const [equipSelectedToTransfer, setEquipSelectedToTransfer] = useState(null)
    const { dependenciesFromBoss } = useSelector(state => state.Area)
    const location = useLocation()

    const { Option } = Select;
    const dispatch = useDispatch()

    const { equipmentsSearched, equipmentsFiltered, loadingEquipment, quantity, fullDataEquipment, isFiltered } = useSelector(state => state.Equipment)
    const { dependency, moduleWork, dependenciesFromEnterprise } = useSelector(state => state.Area)
    const { dependencys, idUser, roleUser } = useSelector(state => state.Login)
    const { loadingCreateTransfer, transfers } = useSelector(state => state.Transfer)
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageFiltered, setCurrentPageFiltered] = useState(0);
    const [currentPageTransfer, setCurrentPageTransfer] = useState(0);

    useEffect(() => {
        if (location.state && enterpriseImage) {
            setVisibleModalCreateTransfer(true)
            setEquipSelectedToTransfer(location.state.equipSelectedToTransfer)
            dispatch(AreaActions.getDependenciesFromEnterprise(enterpriseImage[0].id))
            setOption('Transfer')
        }
    }, [location, enterpriseImage])

    useEffect(() => {
        !isFiltered && moduleWork && option === 'InStore' &&
            dispatch(EquipmentActions.getEquipmentsByModuleWorkAndState({
                "moduleWork": moduleWork[0].moduleWork.id,
                "take": 8,
                "page": currentPage,
                "dependency": 1004,
            }))
        !isFiltered && moduleWork && option === 'InStore' && dispatch(AreaActions.getDependenciesFromEnterprise(enterpriseImage[0].id))
    }, [currentPage, dispatch, moduleWork, option])

    useEffect(() => {
        !isFiltered && moduleWork && option === 'Transfer' &&
            dispatch(EquipmentActions.getEquipmentsByModuleWorkAndState({
                "moduleWork": moduleWork[0].moduleWork.id,
                "take": 8,
                "page": currentPage,
                "idUser": idUser,
                "role": 'Jefe',
                "state": 'Dado de baja',
            }))
    }, [currentPage, dispatch, moduleWork, option])

    useEffect(() => {
        !isFiltered && moduleWork && option === 'Search' &&
            dispatch(TransferActions.getTransfersByModuleWork({
                "moduleWork": moduleWork[0].moduleWork.id,
                "take": 8,
                "page": currentPage,
                "status": "Aprobado"
            }))
    }, [currentPage, dispatch, moduleWork, option])

    useEffect(() => {
        !isFiltered && moduleWork && option === 'Pendings' &&
            dispatch(TransferActions.getTransfersByModuleWork({
                "moduleWork": moduleWork[0].moduleWork.id,
                "take": 8,
                "page": currentPage,
                "status": "Pendiente",
                "dependencyTo": 1004,
            }))
    }, [currentPage, dispatch, moduleWork, option])

    useEffect(() => {
        !isFiltered && moduleWork && option === 'PendingsBoss' &&
            dispatch(TransferActions.getTransferByModuleWorkFromDependenciesTo({
                "moduleWork": moduleWork[0].moduleWork.id,
                "take": 8,
                "page": currentPage,
                "status": "Pendiente",
                "dependenciesFromBoss": dependenciesFromBoss,
            }))
    }, [currentPage, dispatch, moduleWork, option])

    useEffect(() => {
        !isFiltered && moduleWork && option === 'RequestedByAgent' &&
            dispatch(TransferActions.getTransferByModuleWorkFromDependenciesTo({
                "moduleWork": moduleWork[0].moduleWork.id,
                "take": 8,
                "page": currentPage,
                "status": "Pendiente Agente",
                "dependenciesFromBoss": dependenciesFromBoss,
            }))
    }, [currentPage, dispatch, moduleWork, option])

    useEffect(() => {
        isFiltered && dispatch(EquipmentActions.search(
            {
                "dependency": dependencySelectedToFilter,
                "nameEquip": nameEquipToFilter,
                "location": locationToFilter,
                "licensePlate": licensePlateToSearchToFilter,
                "take": 8,
                "page": currentPageFiltered,
                "moduleWork": moduleWork[0].moduleWork.id
            }))
    }, [currentPageFiltered, dispatch])

    useEffect(() => {
        dispatch(EquipmentActions.getLocations())
        dispatch(EquipmentActions.getStates())
    }, [dispatch])

    const onExitSearch = () => {
        setVisibleExitSearch(false)
        dispatch(EquipmentActions.setEquipmentsFiltered(false))
    }

    useEffect(() => {
        /* dispatch(dependencyActions.getdependencys()) */
        dispatch(DepMunActions.getDepartments())
    }, [dispatch])


    const buttons = (t) => {
        if (moduleWork !== false) {
            if (t === "SearchModal") {
                setVisibleModal(true);
                setTextXLSX("");
                setTextPDF("");
                setCurrentPage(0)
                setCurrentPageTransfer(0)
                setCurrentPageFiltered(0)
            } else {
                setOption(t);
                setEdit(false);
            }
        } else {
            message.warn("Debe elegir un módulo de trabajo")
        }
        dispatch(EquipmentActions.setIsFiltered(false))
    }

    const onSearch = (nameEquip, location, dependencySelected, licensePlate) => {
        setVisibleModal(false)
        dispatch(EquipmentActions.search(
            {
                "dependency": dependencySelected,
                "nameEquip": nameEquip,
                "location": location,
                "licensePlate": licensePlate,
                "take": 8,
                "page": currentPageFiltered,
                "moduleWork": moduleWork[0].moduleWork.id
            }))
        setDependencySelectedToFilter(dependencySelected)
        setNameEquipToFilter(nameEquip)
        setLocationToFilter(location)
        setLicensePlateToSearchToFilter(licensePlate)

    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    async function downloadXLSX() {
        if (textXLSX === "Descargar") {
            var data = [];
            equipmentsFiltered.map(d => {
                data.push({
                    nombre: d.biomedicEquipment.name,
                    marca: d.biomedicEquipment.brand,
                    modelo: d.biomedicEquipment.modell,
                    serie: d.biomedicEquipment.serie,
                    placa: d.licensePlate,
                    ubicacion: d.biomedicEquipment.location,
                    estado: d.state,
                    agente: d.userAssigned.person.name + " " + d.userAssigned.person.lastName
                })
                return null
            })
            let header = ["Nombre", "Marca", "Modelo", "Serie", "Placa", "Ubicación", "Estado", "Agente"];

            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
                const sheet1 = workbook.sheet(0);
                const sheetData = getSheetData(data, header);
                const totalColumns = sheetData[0].length;

                sheet1.cell("A1").value(sheetData);
                const range = sheet1.usedRange();
                const endColumn = String.fromCharCode(64 + totalColumns);
                sheet1.row(1).style("bold", true);
                sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
                range.style("border", true);
                return workbook.outputAsync().then((res) => {
                    saveAs(res, "BusquedaInventario.xlsx");
                });
            });
        }
    }

    const MyDocument = () => (
        <Document>
            <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View style={{ border: '3px solid #000', marginTop: '1%', width: '93%', height: '2%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>NOMBRE</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>MARCA</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>MODELO</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>SERIE</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>PLACA</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>UBICACIÓN</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>ESTADO</Text>
                    </View>
                    <View style={{ height: '100%', width: '12.5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '18px' }}>AGENTE</Text>
                    </View>
                </View>
                {equipmentsSearched.length > 0 && equipmentsSearched.map(d => (
                    <View style={{ border: '3px solid #000', width: '93%', height: '2%', flexDirection: 'row' }}>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.name}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.brand}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.modell}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.serie}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.licensePlate}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.biomedicEquipment.location}</Text>
                        </View>
                        <View style={{ borderRight: '3px solid #000', height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.state === "Fuera de servicio" ? "Fuera de servicio" : "Funcional"}</Text>
                        </View>
                        {/* <View style={{ height: '100%', width: '12.5%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px' }}>{d.dependency.dependencyUser[0].userAssigned.person.name + " " + d.case.userAssigned.person.lastName}</Text>
                        </View> */}
                    </View>
                ))}
            </Page>
        </Document>
    );

    const DownloadSvg = () => (
        <svg width="2vw" height="2vw" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.38247 4.4625V0H1.16852C0.755297 0 0.422852 0.351094 0.422852 0.7875V16.0125C0.422852 16.4489 0.755297 16.8 1.16852 16.8H11.6079C12.0212 16.8 12.3536 16.4489 12.3536 16.0125V5.25H8.12814C7.71802 5.25 7.38247 4.89562 7.38247 4.4625ZM9.75775 11.3977L6.762 14.5379C6.55539 14.7548 6.2217 14.7548 6.01509 14.5379L3.01935 11.3977C2.70399 11.0673 2.92521 10.5 3.36888 10.5H5.39401V7.875C5.39401 7.58494 5.61646 7.35 5.89112 7.35H6.88535C7.16001 7.35 7.38247 7.58494 7.38247 7.875V10.5H9.40759C9.85127 10.5 10.0725 11.0673 9.75775 11.3977ZM12.1361 3.44531L9.09441 0.229687C8.95459 0.0820312 8.76507 0 8.56622 0H8.3767V4.2H12.3536V3.99984C12.3536 3.79312 12.2759 3.59297 12.1361 3.44531Z" fill="white" />
        </svg>
    );

    const ButtonTab = ({ isSelected, onClick, loading, icon, children, extraStyle }) => (
        <Button style={extraStyle} className={isSelected ? "Selected" : "Unselected"} onClick={onClick}>
            {!loading ? icon : <LoadingOutlined className='plus' />}
            {children}
        </Button>
    );


    const ModalSearch = () => {
        const [nameEquip, setNameEquip] = useState(false)
        const [state, setState] = useState(false)
        const [location, setLocation] = useState(false)
        const [year, setYear] = useState(false)
        const [areaSelected, setAreaSelected] = useState(false)
        const [dependencySelected, setDependencySelected] = useState(false)
        const [licensePlateToSearch, setLicensePlateToSearch] = useState(false)

        const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];
        return (
            <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
                <p className="titleModal">Busqueda</p>
                <Input setData={setNameEquip} label={"Nombre del equipo"} width={"35vw"}></Input>
                <div className="selectsDependency">
                    <p className="label" style={{ marginTop: '-1vw', marginBottom: '0vw' }}>Area</p>
                    <Select
                        className="select"
                        placeholder='Area'
                        onChange={e => {
                            setAreaSelected(e)
                        }}
                    >
                        {dependencys && areas.map(d => (
                            <Option key={d.id} value={d.id} >{d.name}</Option>
                        ))}
                    </Select>
                    <p className="label" style={{ marginTop: '1vw', marginBottom: '0vw' }}>Dependencia</p>
                    {areaSelected &&
                        <Select
                            className="select"
                            placeholder='Dependencia'
                            onChange={e => setDependencySelected(e)}
                        >
                            {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>}
                </div>
                <Input setData={setLicensePlateToSearch} label={"Placa del equipo"} width={"35vw"}></Input>

                <div className="divButton">
                    <Button className="button" onClick={() => onSearch(nameEquip, location, dependencySelected, licensePlateToSearch)}><SearchOutlined className="plus" /> Buscar</Button>
                </div>
            </Modal >
        )
    }

    const ModalCreateTransfer = () => {
        const {
            register: registerCreateTransfer,
            handleSubmit: handleSubmitCreateTransfer,
            setValue: setValueCreateTransfer,
            watch: watchCreateTransfer,
            control: controlCreateTransfer,
            formState: { errors: errorCreateTransfer },
            trigger: triggetCreateTransfer,
            reset: resetCreateTransfer
        } = useForm({
            defaultValues: {
                reasonDescription: "",
                reasonReturn: "",
            }
        })

        const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];
        return (
            <Modal width={'44vw'} className='ModalSearchEquipment' visible={visibleModalCreateTransfer} footer={null} onCancel={() => setVisibleModalCreateTransfer(false)}>
                <p className="titleModal">Solicitud de Traslado de Equipos entre Areas</p>
                <Input
                    {...registerCreateTransfer('reasonDescription', {
                        validate: (value) => {
                            const errors = [];
                            if (!value || value === '') {
                                errors.push("La descripción es requerida");
                            }
                            return errors.length === 0 || errors.join(', ');
                        },
                    })}
                    textarea
                    va={watchCreateTransfer("reasonDescription")}
                    setData={e => setValueCreateTransfer("reasonDescription", e)}
                    error={errorCreateTransfer.reasonDescription && errorCreateTransfer.reasonDescription.message}
                    label={option === 'Transfer' ? "Devolucion por" : option === 'InStore' && "Asignación por"}
                    width={"38vw"}
                    height={'10vw'}
                />
                {option === 'Transfer' ?
                    <div style={{ marginBottom: '1vw', width: '100%' }}>
                        <h4>Selecciona Motivo:</h4>
                        <Radio.Group
                            {...registerCreateTransfer('reasonReturn', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("La razón es requerida");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            onChange={e => setValueCreateTransfer("reasonReturn", e.target.value)}
                            style={{ width: '80%', display: 'flex', justifyContent: 'space-around' }}>
                            <Radio value="Inservible">Inservible</Radio>
                            <Radio value="Traslado">Traslado</Radio>
                        </Radio.Group>
                        {errorCreateTransfer.reasonReturn && <p style={{ color: 'red', fontSize: '1.5vw' }}>{errorCreateTransfer.reasonReturn.message}</p>}
                    </div>
                    :
                    <div style={{ marginBottom: '1vw', width: '100%' }}>
                        <h4>Selecciona dependencia:</h4>
                        <Select
                            className="select"
                            placeholder='Dependencia'
                            value={watchCreateTransfer("dependencyTo")}
                            onChange={e => {
                                setValueCreateTransfer("dependencyTo", e)
                            }}
                        >
                            {dependenciesFromEnterprise && dependenciesFromEnterprise.map(d => (
                                <Option key={d.id} value={d.id} >{d.name}</Option>
                            ))}
                        </Select>
                        {errorCreateTransfer.dependencyTo && <p className='errorMessage'>{errorCreateTransfer.dependencyTo.message}</p>}
                    </div>
                }
                <div className="divButton">
                    <Button
                        className="button"
                        onClick={() => handleSubmitCreateTransfer((data) => {
                            if (window.confirm("¿Estás seguro de realizar esta solicitud de traslado?")) {
                                data.userCreator = idUser
                                data.idEquipment = equipSelectedToTransfer.id
                                data.dependencyFrom = equipSelectedToTransfer.dependency.id
                                if (location.state.agent) {
                                    data.status = 'Pendiente Agente'
                                    data.dependencyTo = equipSelectedToTransfer.dependency.id
                                } else {
                                    data.status = 'Pendiente'
                                }
                                if (!data.hasOwnProperty('dependencyTo')) {
                                    data.dependencyTo = 1004
                                }
                                dispatch(TransferActions.createTransfer(data))
                            }
                        })()}
                    >
                        {loadingCreateTransfer ? <LoadingOutlined className="plus" /> : <SettingFilled className="plus" />} Solicitud de traslado
                    </Button>
                </div>
            </Modal >
        )
    }

    const pageSize = 8
    const totalPages = Math.ceil(quantity / pageSize);
    const totalPagesTransfer = Math.ceil(transfers[1] / pageSize);


    const onSides = (n) => {
        if (isFiltered) {
            if (currentPageFiltered < totalPages - 1 & n === 1) {
                setCurrentPageFiltered(prev => prev + n)
            }
            if (currentPageFiltered > 0 & n === -1) {
                setCurrentPageFiltered(prev => prev + n)
            }
        } else {
            if (currentPage < totalPages - 1 & n === 1) {
                setCurrentPage(prev => prev + n)
            }
            if (currentPage > 0 & n === -1) {
                setCurrentPage(prev => prev + n)
            }
        }
    }

    const onSidesTransfer = (n) => {
        if (isFiltered) {
            if (currentPageFiltered < totalPages - 1 & n === 1) {
                setCurrentPageFiltered(prev => prev + n)
            }
            if (currentPageFiltered > 0 & n === -1) {
                setCurrentPageFiltered(prev => prev + n)
            }
        } else {
            if (currentPageTransfer < totalPages - 1 & n === 1) {
                setCurrentPageTransfer(prev => prev + n)
            }
            if (currentPageTransfer > 0 & n === -1) {
                setCurrentPageTransfer(prev => prev + n)
            }
        }
    }

    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPage === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPage) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPage) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    //tres botones
    //para los jefes de area uno donde salgan los equipos en BAJA
    //para cualquiera que muestre los traslados realizados
    //para los de almacen que muestre los traslados pendientes que han realizado los jefes de area

    return (
        <div className='TransfersDiv'>
            <div className='titleDiv'><p className='inventoryText'>Traslados</p></div>
            <div className='buttonsSection'>
                <div className="buttons">
                    {(roleUser.id === 1 || roleUser.id === 37) && dependenciesFromBoss && dependenciesFromBoss.some(dependency => (dependency.dependency.name.trim() === 'Almacen' && dependency.dependency.status === 'Jefe')) && (
                        <ButtonTab
                            isSelected={option === 'InStore'}
                            onClick={() => buttons(option !== 'InStore' ? 'InStore' : 'SearchModal')}
                            loading={loadingEquipment}
                            icon={<DatabaseOutlined className="plus" />}
                        >
                            Stock Almacen
                        </ButtonTab>
                    )}
                    {(roleUser.id === 1 || roleUser.id === 37) && dependenciesFromBoss && dependenciesFromBoss.some(dependency => dependency.dependency.name.trim() !== 'Almacen') && (
                        <ButtonTab
                            isSelected={option === 'Transfer'}
                            onClick={() => buttons(option !== 'Transfer' ? 'Transfer' : 'SearchModal')}
                            loading={loadingEquipment}
                            icon={<SyncOutlined className="plus" />}
                        >
                            Inservibles
                        </ButtonTab>
                    )}
                    {(roleUser.id === 1 || roleUser.id === 37) && dependenciesFromBoss && dependenciesFromBoss.some(dependency => (dependency.dependency.name.trim() === 'Almacen' && dependency.status === 'Jefe')) && (
                        <ButtonTab
                            isSelected={option === 'Pendings'}
                            onClick={() => buttons('Pendings')}
                            loading={loadingEquipment}
                            icon={<SolutionOutlined className="plus" />}
                            extraStyle={{ backgroundColor: '#CB1919' }}
                        >
                            Aceptar traslado
                        </ButtonTab>
                    )}
                    {(roleUser.id === 1 || roleUser.id === 37) && dependenciesFromBoss && dependenciesFromBoss.some(dependency => dependency.dependency.name.trim() !== 'Almacen') && (
                        <ButtonTab
                            isSelected={option === 'PendingsBoss'}
                            onClick={() => buttons(option !== 'PendingsBoss' ? 'PendingsBoss' : 'SearchModal')}
                            loading={loadingEquipment}
                            extraStyle={{ backgroundColor: '#CB1919' }}
                        >
                            Aceptar traslado
                        </ButtonTab>
                    )}
                    {roleUser.id === 37 && dependenciesFromBoss && dependenciesFromBoss.some(dependency => dependency.dependency.name.trim() !== 'Almacen') && (
                        <ButtonTab
                            isSelected={option === 'RequestedByAgent'}
                            onClick={() => buttons(option !== 'RequestedByAgent' ? 'RequestedByAgent' : 'SearchModal')}
                            loading={loadingEquipment}
                            extraStyle={{ backgroundColor: '#CB1919' }}
                        >
                            Solicitados por agentes
                        </ButtonTab>
                    )}
                    <ButtonTab
                        isSelected={option === 'Search'}
                        onClick={() => buttons('Search')}
                        loading={loadingEquipment}
                        icon={<SearchOutlined className="plus" />}
                    >
                        Buscar
                    </ButtonTab>
                    {visibleExitSearch && <CloseCircleFilled className="closeIcon" onClick={() => onExitSearch()} />}
                </div>
            </div>
            {(option === 'Search' || option === 'Pendings' || option === 'PendingsBoss' || option === 'RequestedByAgent') && transfers && !edit && <div className="divEquipmentsSearched">
                <div className="titleItems">
                    <p>Imagen</p>
                    <p>Nombre:</p>
                    <p>Marca:</p>
                    <p>Modelo:</p>
                    <p>Serie:</p>
                    <p>Placa:</p>
                    <p>Razón:</p>
                    <p>Fecha traslado:</p>
                    <p>Estado de traslado:</p>
                </div>

                {transfers && transfers[0].map(d => (
                    <TransferCard
                        key={d.id}
                        data={d}
                        setEdit={setEdit}
                        setEquipData={setEquipData}
                        extraButtons={option === 'RequestedByAgent' &&
                            <button className='button' onClick={() => {
                                if (window.confirm("¿Seguro deseas solicitar este traslado?")) {
                                    const dataToTransfer = {}
                                    dataToTransfer.userCreator = idUser
                                    dataToTransfer.idEquipment = d.equipment.id
                                    dataToTransfer.dependencyFrom = d.dependencyFrom
                                    dataToTransfer.status = 'Pendiente'
                                    dataToTransfer.dependencyTo = 1004
                                    dataToTransfer.reasonDescription = d.reasonDescription
                                    dataToTransfer.reasonReturn = d.reasonReturn
                                    dispatch(TransferActions.createTransfer(dataToTransfer))
                                    dispatch(TransferActions.updateTransfer({ id: d.id, body: { status: 'Solicitado' } }))
                                }
                            }}>
                                {/* loadingMakeTransfer ? <LoadingOutlined /> :  */"Solicitar traslado"}
                            </button>
                        }
                    />
                ))}
                <div className='showingText'>
                    Mostrando {((currentPageTransfer) * pageSize) + 1} - {Math.min((currentPageTransfer + 1) * pageSize, transfers[1])} de {transfers[1]} elementos
                </div>
                <div className='divButtonsPages'>
                    <div className='sidesButtons' onClick={() => onSidesTransfer(-1)}>Anterior</div>
                    {!isFiltered ?
                        <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPagesTransfer}>
                            {generateNButtons(totalPagesTransfer, setCurrentPageTransfer, currentPageTransfer, totalPagesTransfer)}
                        </Radio.Group>
                        :
                        <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                            {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
                        </Radio.Group>
                    }
                    <div className='sidesButtons' onClick={() => onSidesTransfer(1)}>Siguiente</div>
                </div>
            </div>}
            {(option === 'Transfer' || option === 'InStore') && equipmentsSearched && !edit && <div className="divEquipmentsSearched">
                <div className="titleItems">
                    <p>Imagen</p>
                    <p>Nombre:</p>
                    <p>Marca:</p>
                    <p>Modelo:</p>
                    <p>Serie:</p>
                    <p>Placa:</p>
                    <p>Ubicación:</p>
                    <p>Estado:</p>
                </div>

                {equipmentsSearched && equipmentsSearched.map(d => (
                    moduleWork[0].moduleWork.id === 1 ?
                        <Equipment
                            key={d.id}
                            data={d}
                            width={'80vw'}
                            height={'5vw'}
                            setEdit={setEdit}
                            setEquipData={setEquipData}
                            extraOnClick={d => {
                                setEquipSelectedToTransfer(d)
                                setVisibleModalCreateTransfer(true)
                            }}
                        />
                        :
                        moduleWork[0].moduleWork.id === 2 &&
                        <EquipmentSystem
                            key={d.id}
                            data={d}
                            setEdit={setEdit}
                            setEquipData={setEquipData}
                            width={'80vw'}
                            height={'5vw'}
                            extraOnClick={d => {
                                setEquipSelectedToTransfer(d)
                                setVisibleModalCreateTransfer(true)
                            }}
                        />
                ))}
                <div className='showingText'>
                    Mostrando {((currentPage) * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, quantity)} de {quantity} elementos
                </div>
                <div className='divButtonsPages'>
                    <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                    {!isFiltered ?
                        <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                            {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                        </Radio.Group>
                        :
                        <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                            {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
                        </Radio.Group>
                    }
                    <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                </div>
            </div>}
            <ModalSearch></ModalSearch>
            <ModalCreateTransfer />
        </div>
    )

}