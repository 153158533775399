import { useEffect, useState } from "react"
import React from "react";
import { Input } from '../../../components/Input/Input'
import { useDispatch, useSelector } from "react-redux";
import { PagActions } from "../../../services/common/Pag/PagSlice";
import { Button } from 'antd'
import { MenuItem, Select } from "@material-ui/core"
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { AreaActions } from "../../../services/Area/AreaSlice";



export const Petitions = () => {
    const [option, setOption] = useState('Search')
    const [group, setGroup] = useState('Grupo')
    const [type, setType] = useState('Tipo')
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const dispatch = useDispatch()
    const { areas, equipmentTypes } = useSelector(state => state.Area)

    useEffect(() => {
        group !== 'Grupo' && group !== "" && dispatch(AreaActions.getEquipmentTypes(group))
        group !== 'Grupo' && group !== "" && dispatch(AreaActions.getAgents(group))
    }, [group,dispatch])

    const handleChangeGroup = (event) => {
        setGroup(event.target.value);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };


    useEffect(() => {
        dispatch(PagActions.setIsLogin(true))
        dispatch(AreaActions.getAreas())
    }, [dispatch])


    const buttons = (t) => {
        setOption(t)
        setGroup('')
        setType('')
    }

    return (
        <div className='inventoryDiv'>
            <div className='titleDiv'><p className='inventoryText'>Peticiones</p><p className='hospitalText'>{enterpriseImage[0].name}</p></div>
            <div className='buttonsSection'>
                {option === 'Search' ? <div className="buttons">
                    <Button className="Unselected" onClick={() => buttons('Add')}>Añadir <PlusOutlined className="plus" /></Button>
                    <Button className="Selected" onClick={() => buttons('Search')}>Buscar <SearchOutlined className="plus" /></Button>
                </div>
                    :
                    option === 'Add' ? <div className="buttons">
                        <Button className="Selected" onClick={() => buttons('Add')}>Añadir <PlusOutlined className="plus" /></Button>
                        <Button className="Unselected" onClick={() => buttons('Search')}>Buscar <SearchOutlined className="plus" /></Button>
                    </div> : true}
                <div className="filters">
                    <Box sx={{ minWidth: '15vw' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" className='label'>Grupo</InputLabel>
                            <Select
                                className='select'
                                id="demo-simple-select"
                                value={group}
                                label="Grupo"
                                onChange={handleChangeGroup}
                            >
                                {areas.map(d => (
                                    <MenuItem key={d.name} value={d.name}>
                                        {d.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: '15vw' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" className='label'>Tipo</InputLabel>
                            <Select
                                className='select'
                                id="demo-simple-select"
                                value={type}
                                label="Grupo"
                                onChange={handleChangeType}
                            >
                                {equipmentTypes.map(d => (
                                    <MenuItem key={d.name} value={d.name}>
                                        {d.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {option === 'Search' && <div>
                        <Input labelstyle={'input'} placeholder='Buscar'></Input>
                    </div>}

                </div>
            </div>
            {/* {type === 'ftbiome-123' && option === 'Add' && <div className='body'> */}
                {/* <div className='form'>
                    <EquipmentCard equipment={EQUIPMENT}/>
                </div> */}
            {/* </div>} */}

        </div>
    )
}