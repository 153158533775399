import { message } from 'antd'
import { takeLatest, all, put, call } from 'redux-saga/effects'
import Api from "../../common/api"

import { TimelineActions } from './TimelineSlice'

function* getTimelineSearched({ payload }) {
  const t = yield Api.get(`/timeline/byDependency?dependency=${payload.dependency}&page=${payload.page}&take=10`)
  /*  const t = yield Api.get("/area/allWithDependency") */
  if (t.ok) {
    yield put(TimelineActions.setTimelineSearched(t.payload))
  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(TimelineActions.setLoadingTimeline(false))
}

function* createTimeline({ payload }) {
  const t = yield Api.post(`/timeline/`, payload)
  if (t.ok) {
    message.success("Cronograma creado correctamente.")
  } else {
    message.error("Error al crear cronograma.")
  }
  yield put(TimelineActions.setLoadingTimeline(false))
}

function* createMany({ payload }) {
  const t = yield Api.post(`/timeline/createMany`, payload)
  if (t.ok) {
    message.success("Cronogramas programados")
  } else {
    message.error("Error al programar")
  }
  yield put(TimelineActions.setLoadingEquipsToSubmit(false))
}

function* createTraining({ payload }) {
  const t = yield Api.post(`/training/createMany`, payload)
  if (t.ok) {
    message.success("Capacitacion creada correctamente.")
  } else {
    message.error("Error al crear cronograma.")
  }
  yield put(TimelineActions.setLoadingCreateTraining(false))
}

function* getTrainings({ payload }) {
  const t = yield Api.get(`/training/byDependency?dependency=${payload.dependency}&page=${payload.page}&take=10`)
  if (t.ok) {
    yield put(TimelineActions.setTrainings(t.payload))
  } else {
    message.error("Error al obtener capacitaciones	")
  }
  yield put(TimelineActions.setLoadingTraining(false))
}

function* getAllTrainings({ payload }) {
  const t = yield Api.get(`/training/all?take=20&page=${payload}`)
  if (t.ok) {
    yield put(TimelineActions.setTrainings(t.payload))
  } else {
    message.error("Error al obtener capacitaciones	")
  }
  yield put(TimelineActions.setLoadingTraining(false))
}

function* getTimelinesByEquipment({ payload }) {
  const effects = payload.tm.map(d => call(function* () {
    const t = yield Api.get(`/timeline/getByEquipment?equipment=${d.id}&page=${d.currentPage}&take=7&year=${payload.year}`);
    if (t.ok) {
      yield put(TimelineActions.updateTimelinesByEquipment({ "equipId": d.id, "timelines": t.payload[0], "quantity": t.payload[1], "currentPage": 0, year: payload.year ? payload.year : "undefined" }));
    } else {
      message.error("Error al obtener cronogramas");
    }
  }));
  const e = yield all(effects);
  console.log(e)
  yield put(TimelineActions.setLoadingTimelinesByEquipment('Done'));
}

function* deleteTraining({ payload }) {
  const t = yield Api.post(`/training/deleteTraining?id=${payload.id}`)
  if (t.ok) {
    message.success(t.payload.message)
  } else {
    message.error(t.payload.message)
  }
}

function* deleteTimeline({ payload }) {
  const t = yield Api.post(`/timeline/delete?id=${payload.id}`)
  if (t.ok) {
    message.success("Fecha eliminada correctemente")
  } else {
    message.error(t.payload.message)
  }
}

function* getTimelinesForThisMonth({ payload }) {
  const t = yield Api.get(`/timeline/getByMonth?month=${new Date()}&take=7&page=${payload.page}&moduleWork=${payload.moduleWork}`)
  if (t.ok) {
    yield put(TimelineActions.setTimelinesForThisMonth(t.payload))
  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(TimelineActions.setLoadingTimelinesForThisMonth(false))
}

function* actionWatcher() {
  yield takeLatest(TimelineActions.getTimelineSearched, getTimelineSearched)
  yield takeLatest(TimelineActions.createTimeline, createTimeline)
  yield takeLatest(TimelineActions.createMany, createMany)
  yield takeLatest(TimelineActions.createTraining, createTraining)
  yield takeLatest(TimelineActions.getTrainings, getTrainings)
  yield takeLatest(TimelineActions.getTimelinesByEquipment, getTimelinesByEquipment)
  yield takeLatest(TimelineActions.getAllTrainings, getAllTrainings)
  yield takeLatest(TimelineActions.deleteTraining, deleteTraining)
  yield takeLatest(TimelineActions.deleteTimeline, deleteTimeline)
  yield takeLatest(TimelineActions.getTimelinesForThisMonth, getTimelinesForThisMonth)
}

export default function* TimelineSaga() {
  yield all([actionWatcher()])
}