import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loadingCreateSpecializedCenter: false,
    specializedCentersSearched: false,
    loadingGetSpecializedCenters: false,
    isFilteredSpecializedCenters: false,
    loadingDeleteSpecializedCenter: false,
    fullDataSpecializedCenter: false,
    loadingGetFullDataSpecializedCenter: false,
};

const SpecializedCenterSlice = createSlice({
    name: "SpecializedCenter",
    initialState,
    reducers: {
        createSpecializedCenter(state) {
            state.loadingCreateSpecializedCenter = true;
        },
        setLoadinCreateSpecializedCenter(state, { payload }) {
            state.loadingCreateSpecializedCenter = payload;
        },
        getSpecializedCenters(state) {
            state.loadingGetSpecializedCenters = true;
        },
        setSpecializedCentersSearched(state, { payload }) {
            state.specializedCentersSearched = payload;
        },
        setLoadinGetSpecializedCenters(state, { payload }) {
            state.loadingGetSpecializedCenters = payload;
        },
        setFilteredSpecializedCenters(state, { payload }) {
            state.isFilteredSpecializedCenters = payload;
        },
        deleteSpecializedCenter(state) {
            state.loadingDeleteSpecializedCenter = true;
        },
        setLoadinDeleteSpecializedCenter(state, { payload }) {
            state.loadingDeleteSpecializedCenter = payload;
        },
        getFullDataSpecializedCenter(state) {
            state.loadingGetFullDataSpecializedCenter = true;
        },
        setFullDataSpecializedCenter(state, { payload }) {
            state.fullDataSpecializedCenter = payload;
        },
        setLoadinGetFullDataSpecializedCenter(state, { payload }) {
            state.loadingGetFullDataSpecializedCenter = payload;
        },
        updateSpecializedCenter(state) {
            state.loadingCreateSpecializedCenter = true;
        },
    },
});

const SpecializedCenterActions = SpecializedCenterSlice.actions;
const SpecializedCenterReducer = SpecializedCenterSlice.reducer;

export { SpecializedCenterActions, SpecializedCenterReducer };
