import { Modal, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { CaseReportActions } from "../../services/caseReport/CaseReportSlice";

export const ModalBitacoraNotFirmed = () => {
    const dispatch = useDispatch()
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { idUser } = useSelector(state => state.Login)
    const { dependenciesFromBoss, moduleWork } = useSelector(state => state.Area)
    const { visibleModalBitacoraNotFirmed, loadingFullDataCaseReport, currentPage, notFirmed, loadingFirmAllByDependency, modulesWorkToFirm, loadingNotFirmed } = useSelector(state => state.CaseReport)
    const [visibleItems, setVisibleItems] = useState(false);
    const [moduleWorkSelected, setModuleWorkSelected] = useState(false);

    useEffect(() => {
        currentPage >= 0 && dependenciesFromBoss && moduleWorkSelected && dispatch(CaseReportActions.getNotFirmed({ "take": 4, "page": currentPage, "payload": dependenciesFromBoss, moduleWork: moduleWorkSelected }))
    }, [currentPage, dispatch, moduleWorkSelected])

    useEffect(() => {
        notFirmed.length > 0 && setVisibleItems(notFirmed[0].map(() => true))
    }, [notFirmed])

    const handleClick = (index) => {
        setVisibleItems(visibleItems.map((visible, i) => i === index ? false : visible));
    }

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const pageSize = 4
    const totalPages = Math.ceil(notFirmed[1] / pageSize);

    const onSides = (n) => {
        if (currentPage < totalPages - 1 & n === 1) {
            dispatch(CaseReportActions.setCurrentPage(currentPage + n))
        }
        if (currentPage > 0 & n === -1) {
            dispatch(CaseReportActions.setCurrentPage(currentPage + n))
        }
    }

    const setCurrentPage = (n) => {
        dispatch(CaseReportActions.setCurrentPage(n))
    }

    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPage === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPage) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPage) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    return (
        <Modal className='ModalBitacoraNotFirmed' width={'85vw'} visible={visibleModalBitacoraNotFirmed} footer={null} onCancel={() => {
            dispatch(CaseReportActions.setVisibleModalBitacoraNotFirmed(false))
            dispatch(CaseReportActions.setAllCaseReportsByEquipmentId(false))
        }}>
            <div className="header">
                <p>Sin firmar</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1.2vw', paddingTop: '0.5vw', paddingRight: '4vw' }}>
                <div className="modules">
                    {modulesWorkToFirm && modulesWorkToFirm.map((d, i) => (
                        <button
                            key={i}
                            className={moduleWorkSelected === d.id ? "buttonModule1" : "buttonModule"}
                            onClick={() => setModuleWorkSelected(d.id)}
                        >
                            {loadingNotFirmed ? <LoadingOutlined /> : d.name}
                        </button>))}
                </div>
                <button
                    style={{
                        cursor: "pointer",
                        fontFamily: "Overpass",
                        fontWeight: '600',
                        fontSize: '1vw',
                        color: '#ffffff',
                        backgroundColor: '#10b759',
                        border: '1px solid #ffffff',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '5px',
                        height: '2vw',
                    }}
                    onClick={() => dispatch(CaseReportActions.firmAllByDependency({ "payload": dependenciesFromBoss, "idUser": idUser }))}
                >
                    {loadingFirmAllByDependency ? <LoadingOutlined /> : "Firmar todos"}
                </button>
            </div>
            <div>
                <div className="headers">
                    <p>Fecha</p>
                    <p>Nombre</p>
                    <p>Placa</p>
                    <p>Tipo de mantenimiento</p>
                    <p>Descripción</p>
                    <p>Nombre del agente</p>
                    <p>N° Reporte de servicio</p>
                    <p>Firmar</p>
                </div>
                <hr></hr>
                {notFirmed.length > 0 && notFirmed[0].map((d, i) => {

                    let solution = (d.biomedicCaseReport && d.biomedicCaseReport.descriptionAnswer) ||
                        (d.systemCaseReport && d.systemCaseReport.solutionDelivered);
                    let serviceType = (d.biomedicCaseReport && d.biomedicCaseReport.serviceType) ||
                        (d.systemCaseReport && d.systemCaseReport.service);
                    return (
                        visibleItems[i] && (
                            <div key={i}>
                                <div className="data">
                                    <p style={{ width: '10vw', fontSize: '0.9vw' }}>{padStr(new Date(d.date).getDate()) + "-" +
                                        padStr(1 + new Date(d.date).getMonth()) + "-" +
                                        padStr(new Date(d.date).getFullYear())}</p>
                                    <p style={{ width: '17%', marginRight: '1%', fontSize: '0.8vw' }}>{d.pdf !== null ? "Datos en el PDF" : d.equipment.name}</p>
                                    <p style={{ width: '17%', marginRight: '1%', position: 'relative', left: '-2.5vw' }}>{d.pdf !== null ? "Datos en el PDF" : d.equipment.licensePlate}</p>
                                    <p style={{ width: '17%', marginRight: '1%', position: 'relative', left: '-2.5vw' }}>{d.pdf !== null ? "Datos en el PDF" : serviceType}</p>
                                    <p style={{ width: '17%', marginRight: '2%', fontSize: '0.75vw' }}>{d.pdf !== null ? "Datos en el PDF" : solution}</p>
                                    <p style={{ marginRight: '8%', position: 'relative', left: '2vw' }}><p style={{ marginRight: '8%' }}>
                                        {
                                            [
                                                d.userCreator && d.userCreator.person.name,
                                                d.userCreator && d.userCreator.person.secondName,
                                                d.userCreator && d.userCreator.person.lastName,
                                                d.userCreator && d.userCreator.person.secondLastName
                                            ].filter(Boolean).join(' ')
                                        }
                                    </p></p>
                                    <button className="button"
                                        style={{ marginRight: '9%', cursor: "pointer", width: '14%', position: 'relative', left: '2vw' }}
                                        onClick={() => {
                                            window.open(`/ReporteAFirmar?id=${d.id}&moduleWork=${moduleWorkSelected}`, '_blank');
                                        }}>
                                        {loadingFullDataCaseReport ? <LoadingOutlined /> : "Ver Reporte"}
                                    </button>
                                    <button className="button"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            handleClick(i)
                                            dispatch(CaseReportActions.firmCaseReport({ "id": d.id, "user": idUser }))
                                        }}
                                    >Firmar</button>
                                </div>
                                <hr></hr>
                            </div>
                        )
                    )
                })}
                {notFirmed.length > 0 && <div className='showingText'>
                    Mostrando {((currentPage) * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, notFirmed[1])} de {notFirmed[1]} elementos
                </div>}
                {notFirmed.length > 0 && <div className='divButtonsPages'>
                    <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                    <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                        {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                    </Radio.Group>
                    <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                </div>}
            </div>
        </Modal >
    )
}
