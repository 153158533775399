
import React, { useEffect } from 'react'
import Noti from '../../../assets/images/Noti.png'
import timelineNoti from '../../../assets/images/timelineNoti.svg'
import icon from '../../../assets/images/LogoHeader.png'
import { Avatar, Layout, Popover, Tooltip, message } from "antd";
import { LoginActions } from '../../../services/Login/LoginSlices';
import { useDispatch, useSelector } from 'react-redux';
import { BellOutlined, BellFilled } from "@ant-design/icons";
import { AreaActions } from '../../../services/Area/AreaSlice';
import { CaseReportActions } from '../../../services/caseReport/CaseReportSlice';
import { ModalBitacoraNotFirmed } from '../../../components/ModalBitacoraNotFirmed/ModalBitacoraNotFirmed';
import blanco from '../../../assets/images/blanco.png'
import { useHistory } from 'react-router-dom';
import { TimelineActions } from '../../../services/Timeline/TimelineSlice';
import { ModalTimelinesThisMonth } from '../../../components/ModalTimelinesThisMonth/ModalTimelinesThisMonth';

const { Header } = Layout;

const PrivateHeader = () => {
  const dispatch = useDispatch()
  const { idUser, roleUser } = useSelector(state => state.Login)
  const { dependenciesFromBoss, dependenciesFromUser } = useSelector(state => state.Area)
  const { notFirmed, modulesWorkToFirm } = useSelector(state => state.CaseReport)
  const { timelinesForThisMonth } = useSelector(state => state.Timeline)
  const { moduleWork } = useSelector(state => state.Area)
  const history = useHistory();

  useEffect(() => {
    dispatch(LoginActions.verifyLogin())
  }, [dispatch])

  useEffect(() => {
    (roleUser.id === 1 || roleUser.id === 37) && idUser && dispatch(AreaActions.getDependenciesFromBoss(idUser))
  }, [roleUser, idUser, dispatch])

  useEffect(() => {
    if (moduleWork && roleUser.id !== 36) {
      dispatch(TimelineActions.getTimelinesForThisMonth({ take: 4, page: 0, moduleWork: moduleWork[0].moduleWork.id }))
    }
  }, [moduleWork])

  useEffect(() => {
    dependenciesFromBoss && dependenciesFromBoss.length > 0
      &&
      dispatch(CaseReportActions.getModulesWorkToFirm({ "take": 4, "page": 0, "payload": dependenciesFromBoss }))
  }, [dependenciesFromBoss])

  return (
    <Header className="private-header-layout">
      <div className='logohome'>
        <img src={icon} alt='icon'></img>
        <div className='texts'>
          <p className='zeus'>Zeus</p>
          <p className='helpdesk'>Help Desk</p>
        </div>
        <ModalBitacoraNotFirmed />
        {timelinesForThisMonth && timelinesForThisMonth[0].length > 0 && <ModalTimelinesThisMonth />}
      </div>
      <div className='rightPanel'>
        <Tooltip title={"Reportes sin firmar"}>
          {modulesWorkToFirm.length > 0 ?
            <img src={Noti} className='notiIcon alert' onClick={() => dispatch(CaseReportActions.setVisibleModalBitacoraNotFirmed(true))} /> :
            <img src={Noti} className='notiIcon noAlert' />
          }
        </Tooltip>
        <Tooltip title={"Cronogramas de este mes"}>
          {timelinesForThisMonth && timelinesForThisMonth[0].length > 0 ?
            <img src={timelineNoti} className='notiIcon alert' onClick={() => dispatch(TimelineActions.setVisibleModalTimelinesForThisMonth(true))} /> :
            <img src={timelineNoti} className='notiIcon noAlert' />
          }
        </Tooltip>
        {/* <div className='rightPanel'>
          <Avatar src={photo === null ? blanco : photo} className='avatar' onClick={() => history.push('/MiCuenta')} />
        </div> */}
      </div>
    </Header>
  );
};

export default PrivateHeader;
