import { createSlice } from "@reduxjs/toolkit";
import { update } from "firebase/database";

const initialState = {
    loadingTransfer: false,
    loadingCreateTransfer: false,
    transfers: false,
    loadingGetTransfers: false,
    loadingMakeTransfer: false,
};

const TransferSlice = createSlice({
    name: "Transfer",
    initialState,
    reducers: {
        getTransferSearched(state) {
            state.loadingTransfer = true
        },
        createTransfer(state) {
            state.loadingCreateTransfer = true
        },
        setLoadinCreateTransfer(state, { payload }) {
            state.loadingCreateTransfer = payload
        },
        getTransfersByModuleWork(state, { payload }) {
            state.loadingGetTransfers = true
        },
        setTransfers(state, { payload }) {
            state.transfers = payload
        },
        setLoadinGetTransfers(state, { payload }) {
            state.loadingGetTransfers = payload
        },
        makeTransfer(state) {
            state.loadingMakeTransfer = true
        },
        setLoadinMakeTransfer(state, { payload }) {
            state.loadingMakeTransfer = payload
        },
        getTransferByModuleWorkFromDependenciesTo(state) {
            state.loadingGetTransfers = true
        },
        updateTransfer(state) {},
    },
});

const TransferActions = TransferSlice.actions;
const TransferReducer = TransferSlice.reducer;

export { TransferActions, TransferReducer };
