import { Modal } from 'antd';
import { Document, PDFViewer } from '@react-pdf/renderer';
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { apiUrl } from '../../common/config/Environments'
import { useHistory } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { PdfInventory } from '../PdfInventory/PdfInventory';
import { PdfIndustrialEquipment } from '../PdfIndustrialEquipment/PdfIndustrialEquipment';
import { PdfAirConditionatersAndRefri } from '../PdfAirConditionatersAndRefri/PdfAirConditionatersAndRefri';

export const EquipmentIndustrial = ({ data, setEdit, setEquipData, extraOnClick, width, height }) => {
    const [visibleModal, setVisibleModal] = useState(false)
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const [pdf, setPdf] = useState(false)
    const { equipAndReportPDF, fullDataEquipment, loadingFullDataEquipment } = useSelector(state => state.Equipment)
    const [image, setImage] = useState(false)
    const { roleUser, fullUser, idUser } = useSelector((state) => state.Login);
    const history = useHistory()

    const dispatch = useDispatch()
    useEffect(() => {
        fetch(`${apiUrl}/industrialEquipment/imageById?id=${data.id}`)
            .then(response => response.json())
            .then(data => setImage(data))
            .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        pdf === 'all' && dispatch(EquipmentActions.getEquipAndReportPDF(data.id))
    }, [pdf])

    const MyDocument = () => (
        <Document>
            <PdfIndustrialEquipment fullDataEquipment={fullDataEquipment} data={data} enterprise={enterpriseImage} />
        </Document >
    );

    const ModalEquip = () => {
        const onClickEdit = () => {
            setEdit(true)
            setEquipData(data)
        }
        return (
            <Modal className='ModalEquip' width={fullDataEquipment ? '90vw' : '38vw'} visible={visibleModal} footer={null} onCancel={() => {
                setVisibleModal(false)
                dispatch(EquipmentActions.setFullDataEquipment(false))
                dispatch(EquipmentActions.setEquipAndReportPDF(false))
            }}>
                {!fullDataEquipment && <p className="title">{data.biomedicEquipment ? data.biomedicEquipment.name : ''}</p>}
                {!fullDataEquipment && <div className='div'>
                    <button className='button' onClick={() => {
                        dispatch(EquipmentActions.getFullDataIndustrialEquipment({ "id": data.id }))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Ver Hoja de Vida"}
                    </button>
                    
                    {/* <button className='button'>Bitacora</button> */}
                    {fullUser.canCreate && <button className='button' onClick={() => {
                        onClickEdit(true)
                        dispatch(EquipmentActions.getFullDataIndustrialEquipment({ "id": data.id }))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Editar Hoja de Vida"}
                    </button>}
                    {fullUser.canCreate && roleUser.id == 37 &&
                        <button className='button' onClick={() => {
                            history.push('/Traslados', { equipSelectedToTransfer: data })
                        }}>
                            {loadingFullDataEquipment ? <LoadingOutlined /> : "Trasladar"}
                        </button>}
                    {/* <button className='button' onClick={() => {
                        dispatch(EquipmentActions.setFullDataEquipment(
                            {
                                "biomedicEquipment": {
                                    "active": '',
                                    "brand": '',
                                    "serie": '',
                                    "adquisitionDate": null,
                                    "modell": '',
                                    "service": '',
                                    "instalationDate": null,
                                    "healthRegister": '',
                                    "fabricationDate": null,
                                    "startOperationDate": null,
                                    "location": '',
                                    "representative": '',
                                    "warrantyExpirationDate": null,
                                    "maker": '',
                                    "cost": '',
                                    "webPage": '',
                                    "makerCountry": '',
                                    "adquisitionWay": '',
                                    'voltageRange': '',
                                    'pressureRange': '',
                                    'powerSource': '',
                                    'currentRange': '',
                                    'speedRange': '',
                                    'riskClasification': '',
                                    'powerRange': '',
                                    'humidityRange': '',
                                    'frequency': '',
                                    'temperatureRange': '',
                                    'weight': '',
                                    'dimensions': '',
                                    'biomedicComponent': [{
                                        'name': '',
                                        'brand': '',
                                        'reference': '',
                                        'partNumber': '',
                                    }],
                                    "manual": '',
                                    "plane": '',
                                    'use': '',
                                    'biomedicClasification': '',
                                    'tecnology': '',
                                    'operation': '',
                                    'electricSecurity': '',
                                    'maintenance': '',
                                    'equipmentOwner': '',
                                    'irregularCause': '',
                                },
                                "licensePlate": '',
                                "name": '',
                                "municipality": null,

                            }
                        ))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Formato vacio"}
                    </button> */}
                    {/*  <button className='button' onClick={() => setPdf('all')}>Descargar </button> */}
                </div>}

                {fullDataEquipment && <PDFViewer className='pdf'>
                    <MyDocument></MyDocument>
                </PDFViewer>}
                {equipAndReportPDF &&
                    <div>
                        <PDFViewer className='pdf'>
                            <MyDocument></MyDocument>
                        </PDFViewer>
                        {equipAndReportPDF.caseReport && equipAndReportPDF.caseReport.map(d => (
                            <embed className='pdf' src={d.pdf}></embed>
                        ))}
                    </div>}

            </Modal >
        )
    }

    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este equipo?')) {
            const dataDelete = {
                "id": data.id
            }
            dispatch(EquipmentActions.deleteEquipment(dataDelete))
        }
    }

    // Obtén una referencia a todos los elementos
    const elements = document.querySelectorAll('.dataDiv');

    elements.forEach(element => {
        // Obtén una referencia al elemento padre
        const parent = element.parentElement;

        // Calcula el tamaño de la fuente como un porcentaje del ancho del elemento padre
        const fontSize = parent.offsetWidth * 0.014; // 1.4% del ancho del padre

        // Establece el tamaño de la fuente del elemento
        element.style.fontSize = `${fontSize}px`;
    });

    return (
        <div className='EquipmentIndustrial' style={{ width: width, height: height }}>
            {image ?
                <img className='equipmentImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                if (extraOnClick) {
                    extraOnClick(data)
                } else {
                    setVisibleModal(true)
                }
            }}>
                <div><p className='pFontData name'>{data.name}</p></div>
                <div><p className='pFontData'>{data.industrialEquipment ? data.industrialEquipment.brand : ''}</p></div>
                <div><p className='pFontData'>{data.industrialEquipment ? data.industrialEquipment.modell : ''}</p></div>
                <div><p className='pFontData'>{data.industrialEquipment ? data.industrialEquipment.serie : ''}</p></div>
                <div><p className='pFontData'>{data.licensePlate}</p></div>
                <div><p className='pFontData'>{data.location.name}</p></div>
                {data.state === "Funcional" ? <div className='status'><p className='pFontStatus'>Funcional</p></div>
                    : data.state === "No funcional" ? <div className='status1'><p className='pFontStatus'>{data.state}</p></div>
                        : data.state === "Dado de baja" && <div className='status2'><p className='pFontStatus'>Baja</p></div>}
            </div>
            {roleUser.id !== 36 && data.userCreator.id === idUser && <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>}
            <ModalEquip />
        </div >
    )
}