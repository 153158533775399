import { Image, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import ArimoBold from '../../assets/fonts/Arimo-Bold.ttf';

Font.register({
    family: 'Arimo-Bold',
    src: ArimoBold,
});

export const PdfVehicleEquipment = ({ fullDataEquipment, data, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const docs = {
        "Licencia": "Licencia de Transito del Vehiculo",
        "SOAT": "Seguro Obligatorio de Accidentes de Transito SOAT",
        "Póliza": "Póliza de REsponsabilidad Civil Extracontractual",
        "Certificado": "Certificado de Revisión Técnico Mecánica y de Gases",
    }

    const groups = fullDataEquipment.name === "Auto" && [
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'emblemsFront' ||
            d.description === 'frontBumper' ||
            d.description === 'units' ||
            d.description === 'blind' ||
            d.description === 'cocuyo' ||
            d.description === 'directionalFront'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'brandBattery' ||
            d.description === 'radiatorCover' ||
            d.description === 'oilCap' ||
            d.description === 'oilDipstick' ||
            d.description === 'fanBelts' ||
            d.description === 'horn' ||
            d.description === 'mermaids'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'panoramicGlass' ||
            d.description === 'wiperArms' ||
            d.description === 'wiperBlades' ||
            d.description === 'radioAntenna'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'sideWindowsLeft' ||
            d.description === 'handleLeft' ||
            d.description === 'locksLeft' ||
            d.description === 'wheelCupsLeft'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'stirrupsRight' ||
            d.description === 'stirrupsLeft'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'emblemsBack' ||
            d.description === 'backBumper' ||
            d.description === 'thirdStop' ||
            d.description === 'rearGlass' ||
            d.description === 'fuelTankCap' ||
            d.description === 'stopsBrakes' ||
            d.description === 'parkingLights' ||
            d.description === 'directionalBack' ||
            d.description === 'reverses'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'sideWindowsRight' ||
            d.description === 'handleRight' ||
            d.description === 'locksRight' ||
            d.description === 'wheelCupsRight'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'doorKeys' ||
            d.description === 'trunkKeys' ||
            d.description === 'ignitionKeys'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'console' ||
            d.description === 'radioBrand' ||
            d.description === 'gloveBox' ||
            d.description === 'doorLock' ||
            d.description === 'handleDoor' ||
            d.description === 'handleGlass' ||
            d.description === 'interiorLight' ||
            d.description === 'cushions' ||
            d.description === 'intercom' ||
            d.description === 'linings' ||
            d.description === 'mats' ||
            d.description === 'ashtray' ||
            d.description === 'armRest' ||
            d.description === 'stopsBrakesInside' ||
            d.description === 'parkingLightsInside' ||
            d.description === 'headRest' ||
            d.description === 'walkyTalky' ||
            d.description === 'rear-viewMirror'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'ignitionSwitch' ||
            d.description === 'headlightSwitch' ||
            d.description === 'hornInside' ||
            d.description === 'heating' ||
            d.description === 'cigarreteLighter' ||
            d.description === 'fuelGauge' ||
            d.description === 'oilMeter' ||
            d.description === 'parkinglightSwitch' ||
            d.description === 'directionalInside' ||
            d.description === 'mermaidsInside' ||
            d.description === 'tachometer' ||
            d.description === 'speedometer' ||
            d.description === 'temperatureMeter' ||
            d.description === 'beacon'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'hydraulicJack' ||
            d.description === 'crossheads' ||
            d.description === 'tweezers' ||
            d.description === 'levers' ||
            d.description === 'screwdrivers' ||
            d.description === 'startTool' ||
            d.description === 'fixedKeys' ||
            d.description === 'expansionKeys' ||
            d.description === 'mixed' ||
            d.description === 'pliers' ||
            d.description === 'lockingPliers' ||
            d.description === 'plugs' ||
            d.description === 'lamp' ||
            d.description === 'startCable'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'triangles' ||
            d.description === 'flashinglightLamp' ||
            d.description === 'extinguisher' ||
            d.description === 'blocks'
        ),
        fullDataEquipment.vehicleEquipment.vehiclePart.filter(d =>
            d.description === 'tankMicrochip' ||
            d.description === 'vehicleManual' ||
            d.description === 'firstaidKit'
        ),
    ]

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            alignItems: 'center'
        },
        header: {
            border: '3px solid #000',
            marginTop: '3%',
            width: '95%',
            height: '8%',
            flexDirection: 'row'
        },
        headersub0: {
            width: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '3px solid #000'
        },
        headersub1: {
            width: '60%',
            borderRight: '3px solid #000'
        },
        headerRow: {
            height: '70%',
            width: '100%',
            borderBottom: '3px solid #000',
            alignItems: 'center',
            justifyContent: 'center'
        },
        headerRow1: {
            height: '30%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        bigText: {
            fontSize: '32px'
        },
        text: {
            fontSize: '18px'
        },
        generalInfo: {
            border: '3px solid #000',
            marginTop: '1%',
            width: '93%',
            height: '18%',
            flexDirection: 'row'
        },
        viewImg: {
            width: '25%',
            borderRight: '3px solid #000'
        },
        viewData: {
            width: '75%',
            flexDirection: 'column'
        }
    });

    const titleGroupsParts = {
        0: "Frente Exterior",
        1: "Interior del Motor",
        2: "Frente Superior",
        3: "Costado Izquierdo",
        4: "Estribos",
        5: "Costado Trasero",
        6: "Costado Derecho",
        7: "Llaves",
        8: "Interior del Vehiculo",
        9: "Tablero de Controles",
        10: "Herramientas",
        11: "Señales de advertencia de peligro",
        12: "Otros",
    }

    const descriptionSpanish = {
        'emblemsFront': 'Emblemas',
        'frontBumper': 'Defensa Delantera',
        'units': "Unidades",
        'blind': "Persianas",
        'cocuyo': "Cocuyo",
        'directionalFront': "Direccionales",
        'brandBattery': "Bateria Marca",
        'radiatorCover': "Tapa Radiador",
        'oilCap': "Tapa Aceite",
        'oilDipstick': "Varilla Medidora de Aceite",
        'fanBelts': "Correas de Ventilador",
        'horn': "Pitos",
        'mermaids': "Sirenas",
        'panoramicGlass': "Vidrio Panoramico",
        'wiperArms': "Brazos Limpia Brisas",
        'wiperBlades': "Cuchillas Limpia Brisas",
        'radioAntenna': "Antena Radio",
        'sideWindowsLeft': "Vidrios Laterales",
        'handleLeft': "Manija",
        'locksLeft': "Cerraduras",
        'wheelCupsLeft': "Copas Ruedas",
        'stirrupsRight': "Derecho",
        'stirrupsLeft': "Izquierdo",
        'emblemsBack': "Emblemas",
        'backBumper': "Defensa Trasera",
        'thirdStop': "Tercer Stop",
        'rearGlass': "Vidrios Trasero",
        'fuelTankCap': "Tapa Tanque Gasolina",
        'stopsBrakes': "Stops Frenos",
        'parkingLights': "Luces de Parqueo",
        'directionalBack': "Direccionales",
        'reverses': "Reversos",
        'sideWindowsRight': "Vidrios Laterales",
        'handleRight': "Manija",
        'locksRight': "Cerraduras",
        'wheelCupsRight': "Copas Ruedas",
        'doorKeys': "Puertas",
        'trunkKeys': "Baúl",
        'ignitionKeys': "Ignición",
        'console': "Consola",
        'radioBrand': "Radio Marca",
        'gloveBox': "Guantera",
        'doorLock': "Seguro Puerta",
        'handleDoor': "Manija Puerta",
        'handleGlass': "Manija Vidrio",
        'interiorLight': "Luz Interior",
        'cushions': "Cojinería",
        'intercom': "Intercomunicador",
        'linings': "Forros",
        'mats': "Tapetes",
        'ashtray': "Cenicero",
        'armRest': "Descansa Brazos",
        'stopsBrakesInside': "Stops Frenos",
        'parkingLightsInside': "Luces de Parqueo",
        'headRest': "Descansa Cabezas",
        'walkyTalky': "Radio Teléfono",
        'rear-viewMirror': "Espejo Retrovisor",
        'ignitionSwitch': "Switch Ignición",
        'headlightSwitch': "Interruptor Luces Delanteras",
        'hornInside': "Pito",
        'heating': "Calefacción",
        'cigarreteLighter': "Encendedor Cigarrillos",
        'fuelGauge': "Medidor de Gasolina",
        'oilMeter': "Medidor de Aceite",
        'parkinglightSwitch': "Interruptor Luces de Parqueo",
        'directionalInside': "Direccionales",
        'mermaidsInside': "Sirenas",
        'tachometer': "Tacómetro",
        'speedometer': "Velocímetro",
        'temperatureMeter': "Medidor de Temperatura",
        'beacon': "Baliza",
        'hydraulicJack': "Gato",
        'crossheads': "Cruzetas",
        'tweezers': "Pinzas",
        'levers': "Palancas",
        'screwdrivers': "Destornilladores",
        'startTool': "Estrellas",
        'fixedKeys': "Llaves Fijas",
        'expansionKeys': "Llaves de Expansión",
        'mixed': "Mixtas",
        'pliers': "Alicate",
        'lockingPliers': "Hombresolo",
        'plugs': "Bujías",
        'lamp': "Lámpara (Pilas o Conexión)",
        'startCable': "Cable de Iniciar",
        'triangles': "Triángulos",
        'flashinglightLamp': "Lámpara de Luz Intermitente",
        'extinguisher': "Extintor",
        'blocks': "Tacos para Bloquear Vehiculo",
        'tankMicrochip': "Microchip de Tanqueo",
        'vehicleManual': "Manual de Vehiculo",
        'firstaidKit': "Botiquín",
        'electronicStart': "Encendido Electrónico",
        'defending': "Defensa",
        'keys': "Llaves",
        'highCoil': "Bobina de Alta",
        'battery': "Batería",
        'sparkPlug': "Bujía",
        'sparkPlugCap': "Capuchon Bujía",
        'drivePinionCover': "Tapa piñón Arrastre",
        'chainGuide': "Guia de Cadena",
        'tachometers': "Tacómetros",
        'tachometerKnob': "Perilla de Tacómetros",
        'guayasAndFondas': "Guayas y Fondas",
        'mudguard': "Guardabarros",
        'airGuides': "Guias de Aire",
        'mermaid': "Sirena",
        'tires': "Llantas",
        'handlebars': "Manillares",
        'toolsParts': "Herramientas Piezas",
        'rearPads': "Pastillas Traseras",
        'sideCovers': "Tapas Laterales",
        'exhaustPipe': "Exhosto",
        'exhaustPipeGrilles': "Rejillas Exhosto",
        'lightControl': "Control de Luces",
        'frontStrober': "Strober Delantero",
        'footrest': "Descarga Pies",
        'oilTank': "Tanque de Aceite",
        'oilTankCover': "Tapa Tanque Aceite",
        'reflectivePastes': "Pastas Reflectoras",
        'fairing': "Carenaje",
        "carburetor": "Carburador",
        "autoCarburetor": "Auto Carburador",
        "ignitionCoil": "Bobina de Encendido",
        "carburetorPunch": "Punzón Carburador",
        "parkingLeg": "Pata Estacionamiento",
        "dragKit": "Kit de Arrastre",
        "handlebarProtector": "Protector de Manilar",
        "chainGuard": "Guardacadena",
        "kickCrank": "Patada Crank",
        "switch": "Switch",
        "gearLever": "Palanca Cambios",
        "wheels": "Rines",
        "injectionSystem": "Sistema de Inyección",
        "throttleHandle": "Mango Acelerador",
        "handle": "Manubrio",
        "lightLeader": "Mando de Luces",
        "handles": "Maniguetas",
        "frontPads": "Pastillas Delanteras",
        "brakeBands": "Bandas Frenos",
        "gasolineTank": "Tanque de Gasolina",
        "telescopes": "Telescopios",
        "telescopesCovers": "Tapas Telescopios",
        "telescopesRubbers": "Cauchos Telescopios",
        "directional": "Direccionales",
        "scissors": "Tijera",
        "mirrors": "Espejos",
        "streetlight": "Farola",
        "currentRectifier": "Rectificador de Corriente",
        "flanker": "Flancher",
    }


    return (
        <Page size="A1" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.headersub0}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={styles.headersub1}>
                    <View style={styles.headerRow}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '16px' }}>NAZHER CENTRO MEDICO ESPECIALIZADO S.A.S</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '16px' }}>FLORENCIA-CAQUETA</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '16px' }}>NIT: 901244204</Text>
                    </View>
                    <View style={styles.headerRow1}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '16px' }}>INVENTARIO FISICO</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '16px' }}>ESTADO DE VEHICULO</Text>
                    </View>
                </View>
                <View style={styles.headersub0}>
                    <View style={styles.headerRow}>
                        <View style={{ width: '100%', height: '60%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '16px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>CODIGO</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>000</Text>
                            </View>
                        </View>
                        <View style={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '16px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>VERSION</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>1</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.headerRow1}>
                        <View style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '16px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>PAGINAS</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>1 DE 1</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            <View style={{ width: '95%', height: '3%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '2%' }}>
                <View style={{ width: '70%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px' }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Número de Placa de Inventario: </Text>{fullDataEquipment.licensePlate}</Text>
                </View>
                <View style={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ width: '100%', height: '50%', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>Fecha</Text>
                    </View>
                    <View style={{ width: '100%', height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px' }}>{fullDataEquipment.createdDate ? padStr(new Date(fullDataEquipment.createdDate).getDate()) + "/" + padStr(1 + new Date(fullDataEquipment.createdDate).getMonth()) + "/" + padStr(new Date(fullDataEquipment.createdDate).getFullYear()) : ''}</Text>
                    </View>
                </View>
            </View>

            <View style={{ width: '95%', height: '5%', border: '3px solid #000', borderBottom: 0, marginTop: '0.4%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={{ width: '100%', height: '34%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '21px', fontFamily: 'Arimo-Bold' }}>CLASE</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.class + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>MARCA</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.brand + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>TIPO</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.type + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>MODELO</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.modell + " "}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '33%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>PLACAS</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.vehiclePlate + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>COLOR</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.color + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>COMBUSTIBLE</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.fuel + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>CILINDROS</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.cylinders + " "}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'row', borderBottom: '3px solid #000' }}>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>N° DEL MOTOR</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.engineNumber + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>N° DE CHASIS</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.chassisNumber + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>N° DE SERIE</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.numberSerie + " "}</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>LINEA</Text>
                    </View>
                    <View style={{ width: '12.5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '16px' }}>{fullDataEquipment.vehicleEquipment.line + " "}</Text>
                    </View>
                </View>
            </View>
            <View style={{ width: '95%', minHeight: '50px', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '13%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>AREA</Text>
                </View>
                <View style={{ width: '21%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '16px' }}>{fullDataEquipment.dependency.area.name + " "}</Text>
                </View>
                <View style={{ width: '11%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>DEPENDENCIA</Text>
                </View>
                <View style={{ width: '23%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px' }}>{fullDataEquipment.dependency.name + " "}</Text>
                </View>
                <View style={{ width: '12%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>UBICACIÓN</Text>
                </View>
                <View style={{ width: '20%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '16px' }}>{fullDataEquipment.location.name + " "}</Text>
                </View>
            </View>

            <View style={{ width: '95%', height: '2%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DOCUMENTOS DEL VEHICULO</Text>
                </View>
                <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA COMPRA</Text>
                </View>
                <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA DE MATRICULA</Text>
                </View>
                <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>N° DE LICENCIA</Text>
                </View>
            </View>
            {fullDataEquipment.vehicleEquipment.vehiclePaper.filter(f => f.type === "Licencia").map((d, i) => (
                <View key={i} style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', }}>
                    <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{docs[d.type]}</Text>
                    </View>
                    <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.buyDate ? padStr(new Date(d.buyDate).getDate()) + "/" + padStr(1 + new Date(d.buyDate).getMonth()) + "/" + padStr(new Date(d.buyDate).getFullYear()) : ''}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.registrationDate ? padStr(new Date(d.registrationDate).getDate()) + "/" + padStr(1 + new Date(d.registrationDate).getMonth()) + "/" + padStr(new Date(d.registrationDate).getFullYear()) : padStr(new Date(d.expirationDate).getDate()) + "/" + padStr(1 + new Date(d.expirationDate).getMonth()) + "/" + padStr(new Date(d.expirationDate).getFullYear())}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.numberLicense || d.validity}</Text>
                    </View>
                </View>
            ))}

            <View style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DOCUMENTOS DEL VEHICULO</Text>
                </View>
                <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA COMPRA</Text>
                </View>
                <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>FECHA VENCIMIENTO</Text>
                </View>
                <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>VIGENCIA</Text>
                </View>
            </View>
            {fullDataEquipment.vehicleEquipment.vehiclePaper.filter(f => f.type !== "Licencia").map((d, i) => (
                <View key={i} style={{ width: '95%', height: '2%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', }}>
                    <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{docs[d.type]}</Text>
                    </View>
                    <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.buyDate ? padStr(new Date(d.buyDate).getDate()) + "/" + padStr(1 + new Date(d.buyDate).getMonth()) + "/" + padStr(new Date(d.buyDate).getFullYear()) : ''}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.registrationDate ? padStr(new Date(d.registrationDate).getDate()) + "/" + padStr(1 + new Date(d.registrationDate).getMonth()) + "/" + padStr(new Date(d.registrationDate).getFullYear()) : padStr(new Date(d.expirationDate).getDate()) + "/" + padStr(1 + new Date(d.expirationDate).getMonth()) + "/" + padStr(new Date(d.expirationDate).getFullYear())}</Text>
                    </View>
                    <View style={{ width: '17%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>{d.numberLicense || d.validity}</Text>
                    </View>
                </View>
            ))}

            {fullDataEquipment.name === "Auto" &&
                <View style={{ width: '95%', height: '3%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>LLANTAS</Text>
                    </View>
                    <View style={{ width: '32.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <View style={{ width: '100%', height: '50%', borderRight: '3px solid #000', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DELANTERAS</Text>
                        </View>
                        <View style={{ width: '100%', height: '50%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                            <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DERECHA</Text>
                            </View>
                            <View style={{ width: '50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>IZQUIERDA</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ width: '32.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <View style={{ width: '100%', height: '50%', borderRight: '3px solid #000', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>TRASERAS</Text>
                        </View>
                        <View style={{ width: '100%', height: '50%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                            <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DERECHA</Text>
                            </View>
                            <View style={{ width: '50%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>IZQUIERDA</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ width: '15%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>REPUESTO</Text>
                    </View>
                </View>}
            {fullDataEquipment.name === "Motocicleta" &&
                <View style={{ width: '95%', height: '3%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>LLANTAS</Text>
                    </View>
                    <View style={{ width: '40%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <View style={{ width: '100%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>DELANTERAS</Text>
                        </View>
                    </View>
                    <View style={{ width: '40%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <View style={{ width: '100%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>TRASERAS</Text>
                        </View>
                    </View>
                </View>}

            {fullDataEquipment.name === "Auto" &&
                <View style={{ width: '95%', height: '2.4%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>MARCA</Text>
                    </View>
                    <View style={{ width: '32.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Front Right')[0].brand + " "}</Text>
                        </View>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Front Left')[0].brand + " "}</Text>
                        </View>
                    </View>
                    <View style={{ width: '32.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Back Right')[0].brand + " "}</Text>
                        </View>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Back Left')[0].brand + " "}</Text>
                        </View>
                    </View>
                    <View style={{ width: '15%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Spare')[0].brand + " "}</Text>
                    </View>
                </View>
            }
            {fullDataEquipment.name === "Auto" &&
                <View style={{ width: '95%', height: '2.4%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>REFERENCIA</Text>
                    </View>
                    <View style={{ width: '32.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Front Right')[0].reference + " "}</Text>
                        </View>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Front Left')[0].reference + " "}</Text>
                        </View>
                    </View>
                    <View style={{ width: '32.5%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Back Right')[0].reference + " "}</Text>
                        </View>
                        <View style={{ width: '50%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Back Left')[0].reference + " "}</Text>
                        </View>
                    </View>
                    <View style={{ width: '15%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Spare')[0].reference + " "}</Text>
                    </View>
                </View>
            }

            {fullDataEquipment.name === "Motocicleta" &&
                <View style={{ width: '95%', height: '2.4%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>MARCA</Text>
                    </View>
                    <View style={{ width: '40%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '100%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Front')[0].brand + " "}</Text>
                        </View>
                    </View>
                    <View style={{ width: '40%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '100%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Back')[0].brand + " "}</Text>
                        </View>
                    </View>
                </View>
            }
            {fullDataEquipment.name === "Motocicleta" &&
                <View style={{ width: '95%', height: '2.4%', border: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>REFERENCIA</Text>
                    </View>
                    <View style={{ width: '40%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '100%', height: '100%', borderRight: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Front')[0].reference + " "}</Text>
                        </View>
                    </View>
                    <View style={{ width: '40%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '100%', height: '100%', borderRight: '3px solid #000', borderTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '24px' }}>{fullDataEquipment.vehicleEquipment.vehicleTire.filter(d => d.position === 'Back')[0].reference + " "}</Text>
                        </View>
                    </View>
                </View>
            }

            <View style={{ width: '95%', height: '2.5%', border: '3px solid #000', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>PARTES Y ACCESORIOS</Text>
                </View>
                <View style={{ width: '3.4%', height: '75%', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', transform: 'rotate(-90deg)', position: 'relative', top: '5px' }}>
                    <Text style={{ fontSize: '18px', fontFamily: 'Arimo-Bold' }}>Cant</Text>
                </View>
                <View style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <View style={{ width: '100%', height: '50%', borderRight: '3px solid #000', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>ESTADO</Text>
                    </View>
                    <View style={{ width: '100%', height: '40%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>B</Text>
                        </View>
                        <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>R</Text>
                        </View>
                        <View style={{ width: '33.4%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>M</Text>
                        </View>
                    </View>
                </View>
                <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>PARTES Y ACCESORIOS</Text>
                </View>
                <View style={{ width: '3.4%', height: '75%', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', transform: 'rotate(-90deg)', position: 'relative', top: '5px' }}>
                    <Text style={{ fontSize: '18px', fontFamily: 'Arimo-Bold' }}>Cant</Text>
                </View>
                <View style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <View style={{ width: '100%', height: '50%', borderRight: '3px solid #000', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>ESTADO</Text>
                    </View>
                    <View style={{ width: '100%', height: '40%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>B</Text>
                        </View>
                        <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>R</Text>
                        </View>
                        <View style={{ width: '33.4%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>M</Text>
                        </View>
                    </View>
                </View>
                <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>PARTES Y ACCESORIOS</Text>
                </View>
                <View style={{ width: '3.4%', height: '75%', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center', transform: 'rotate(-90deg)', position: 'relative', top: '5px' }}>
                    <Text style={{ fontSize: '18px', fontFamily: 'Arimo-Bold' }}>Cant</Text>
                </View>
                <View style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <View style={{ width: '100%', height: '50%', borderBottom: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>ESTADO</Text>
                    </View>
                    <View style={{ width: '100%', height: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>B</Text>
                        </View>
                        <View style={{ width: '33.3%', height: '100%', borderRight: '3px solid #000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>R</Text>
                        </View>
                        <View style={{ width: '33.4%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: '17px', fontFamily: 'Arimo-Bold' }}>M</Text>
                        </View>
                    </View>
                </View>
            </View>

            {fullDataEquipment.name === "Auto" &&
                <View style={{ width: '95%', height: '44.5%', border: '3px solid #000', borderTop: 0, borderBottom: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', flexWrap: 'wrap' }}>
                    {groups.map((d, i) => {
                        return [
                            <View key={"group-title-" + i} style={{ width: '33.4%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', backgroundColor: 'gray' }}>
                                <Text style={{ fontSize: '18px', fontFamily: 'Arimo-Bold', marginLeft: '4px' }}>{titleGroupsParts[i] + " "}</Text>
                            </View>,
                            ...d.flatMap((part, partIndex) => (
                                <View key={partIndex} style={{ width: '33.4%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    <View style={{ width: '60%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                        <Text style={{ fontSize: '18px', fontFamily: 'Arimo-Bold', marginLeft: '4px' }}>{descriptionSpanish[part.description] + " "}</Text>
                                    </View>
                                    <View style={{ width: '9%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: '18px', marginLeft: '4px' }}>{part.quantity ? part.quantity : " "}</Text>
                                    </View>
                                    <View style={{ width: '10.3%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: '18px', marginLeft: '4px' }}>{part.state === "Good" ? 'X' : ' '}</Text>
                                    </View>
                                    <View style={{ width: '10.3%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: '18px', marginLeft: '4px' }}>{part.state === "Regular" ? 'X' : ' '}</Text>
                                    </View>
                                    <View style={{ width: '10.4%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, borderRight: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ fontSize: '16px', marginLeft: '4px' }}>{part.state === "Bad" ? 'X' : ' '}</Text>
                                    </View>
                                </View>))]
                    }
                    )}
                </View>}
            {fullDataEquipment.name === "Motocicleta" &&
                <View style={{ width: '95%', height: '26%', border: '3px solid #000', borderTop: 0, borderBottom: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', flexWrap: 'wrap' }}>
                    {fullDataEquipment.vehicleEquipment.vehiclePart.map((d, i) => (
                        <View key={i} style={{ width: '33.4%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                            <View style={{ width: '60%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Text style={{ fontSize: '18px', fontFamily: 'Arimo-Bold', marginLeft: '4px' }}>{descriptionSpanish[d.description] + " "}</Text>
                            </View>
                            <View style={{ width: '9%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '18px', marginLeft: '4px' }}>{d.quantity ? d.quantity : " "}</Text>
                            </View>
                            <View style={{ width: '10.3%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '18px', marginLeft: '4px' }}>{d.state === "Good" ? 'X' : ' '}</Text>
                            </View>
                            <View style={{ width: '10.3%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '18px', marginLeft: '4px' }}>{d.state === "Regular" ? 'X' : ' '}</Text>
                            </View>
                            <View style={{ width: '10.4%', height: '30px', border: '3px solid #000', borderTop: 0, borderLeft: 0, borderRight: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '16px', marginLeft: '4px' }}>{d.state === "Bad" ? 'X' : ' '}</Text>
                            </View>
                        </View>
                    )
                    )}
                </View>}
            {fullDataEquipment.name === "Auto" &&
                <View style={{ width: '95%', minHeight: '10px', border: '3px solid #000', borderTop: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Text style={{ fontSize: '16px' }}>Observaciones: <Text style={{ fontFamily: 'Arimo-Bold' }}>{fullDataEquipment.vehicleEquipment.observation + " "}</Text></Text>
                </View>}

            <View style={{ marginTop: '1%', width: '95%', height: '10%', justifyContent: 'space-between', flexDirection: 'column',}}>
                <View style={{ width: '40%', height: '20%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    {/* <Image style={{ width: '30%', height: '150%' }} src={fullDataEquipment.dependency.dependencyUser[0].user.person.firm ? fullDataEquipment.dependency.dependencyUser[0].user.person.firm : blanco}></Image> */}
                    <Image style={{ width: '80%', height: '100%' }} src={(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person.firm) ? fullDataEquipment.userCreator.person.firm : blanco}></Image>
                </View>

                <View style={{ width: '40%', height: '16%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    {/* <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View> */}
                    <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                </View>
                <View style={{ width: '40%', height: '16%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    {/* <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            fullDataEquipment.dependency.dependencyUser[0].user.person.firm && fullDataEquipment.dependency.dependencyUser[0].user.person.name + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.secondName + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.lastName + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.secondLastName
                        }
                    </Text> */}
                    <Text
                        style={{ fontSize: '20px', }}>
                        {(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person) &&
                            fullDataEquipment.userCreator.person.name + " " +
                            fullDataEquipment.userCreator.person.secondName + " " +
                            fullDataEquipment.userCreator.person.lastName + " " +
                            fullDataEquipment.userCreator.person.secondLastName
                        }
                    </Text>
                </View>
                <View style={{ width: '40%', height: '16%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', color: 'black' }}>
                        {
                            fullDataEquipment.userCreator && fullDataEquipment.userCreator.position
                        }
                    </Text>
                </View>
                <View style={{ width: '40%', height: '16%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', color: 'black' }}>
                        {
                            fullDataEquipment.userCreator && fullDataEquipment.userCreator.profesion
                        }
                    </Text>
                </View>
                <View style={{ width: '40%', height: '16%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    {/* <Text style={{ fontSize: '29px', marginTop: '0.7%' }}>JEFE DE DEPENDENCIA</Text> */}
                    <Text style={{ fontSize: '20px', fontFamily: 'Arimo-Bold' }}>AGENTE</Text>
                </View>
            </View>
        </Page >
    )
}