import { Modal, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { TimelineActions } from "../../services/Timeline/TimelineSlice";
import moment from "moment";

export const ModalTimelinesThisMonth = () => {
    const dispatch = useDispatch()
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { idUser } = useSelector(state => state.Login)
    const { dependenciesFromBoss, moduleWork } = useSelector(state => state.Area)
    const { timelinesForThisMonth, visibleModalTimelinesForThisMonth, currentPageTimelinesForThisMonth } = useSelector(state => state.Timeline)
    const [visibleItems, setVisibleItems] = useState(false);

    useEffect(() => {
        if (moduleWork) {
            currentPageTimelinesForThisMonth >= 0 && dispatch(TimelineActions.getTimelinesForThisMonth({ take: 4, page: currentPageTimelinesForThisMonth, moduleWork: moduleWork[0].moduleWork.id }))
        }
    }, [currentPageTimelinesForThisMonth, dispatch, moduleWork])

    const handleClick = (index) => {
        setVisibleItems(visibleItems.map((visible, i) => i === index ? false : visible));
    }

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const pageSize = 7
    const totalPages = Math.ceil((timelinesForThisMonth ? timelinesForThisMonth[1] : 1) / pageSize);

    const onSides = (n) => {
        if (currentPageTimelinesForThisMonth < totalPages - 1 & n === 1) {
            dispatch(TimelineActions.setCurrentPageTimelinesForThisMonth(currentPageTimelinesForThisMonth + n))
        }
        if (currentPageTimelinesForThisMonth > 0 & n === -1) {
            dispatch(TimelineActions.setCurrentPageTimelinesForThisMonth(currentPageTimelinesForThisMonth + n))
        }
    }

    const setCurrentPage = (n) => {
        dispatch(TimelineActions.setCurrentPageTimelinesForThisMonth(n))
    }

    const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];

    const generateNButtons = (N, onClick, currentPageTimelinesForThisMonth, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPageTimelinesForThisMonth === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPageTimelinesForThisMonth) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPageTimelinesForThisMonth) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    return (
        <Modal className='ModalTimelinesThisMonth' width={'85vw'} visible={visibleModalTimelinesForThisMonth} footer={null} onCancel={() => {
            dispatch(TimelineActions.setVisibleModalTimelinesForThisMonth(false))
            /* dispatch(TimelineActions.setTimelinesForThisMonth(false)) */
        }}>
            <div className="header">
                <p>Cronogramas para este mes</p>
            </div>
            <div>
                <div className="headers">
                    <p>Placa</p>
                    <p>
                        Nombre
                    </p>
                    <p>
                        Marca
                    </p>
                    <p>
                        Serie
                    </p>
                    <p>
                        Ubicación
                    </p>
                    <p>
                        Dias restantes
                    </p>
                </div>
                <hr></hr>
                {timelinesForThisMonth && timelinesForThisMonth[0].map((t, i) => (
                    /* visibleItems[i] && */ (
                        <div key={i} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="data">
                                <p>{t.equipment.licensePlate}</p>
                                <p>{t.equipment.name}</p>
                                <p>
                                    {t.equipment.biomedicEquipment.brand}
                                </p>
                                <p>
                                    {t.equipment.biomedicEquipment.serie}
                                </p>
                                <p>
                                    {t.equipment.biomedicEquipment.location}
                                </p>
                                <p>
                                    {(moment.utc().diff(moment.utc(t.month), 'days') * -1) > 0 ? moment.utc().diff(moment.utc(t.month), 'days') * -1 : "Terminado"}
                                </p>
                            </div>
                            <hr></hr>
                        </div>
                    )
                ))}
                {timelinesForThisMonth && <div className='showingText'>
                    Mostrando {((currentPageTimelinesForThisMonth) * pageSize) + 1} - {Math.min((currentPageTimelinesForThisMonth + 1) * pageSize, timelinesForThisMonth[1])} de {timelinesForThisMonth[1]} elementos
                </div>}
                {timelinesForThisMonth && <div className='divButtonsPages'>
                    <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                    <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                        {generateNButtons(totalPages, setCurrentPage, currentPageTimelinesForThisMonth, totalPages)}
                    </Radio.Group>
                    <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                </div>}
            </div>
        </Modal >
    )
}
