import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    option: 'Search',
    loadingCreateCaseReportPowerPlant: false,
    CaseReportPowerPlantCreatedId: false,
    personAssign: false,
    areaShared: false,
    CaseReportPowerPlantsSearched: false,
    quantity: false,
    loadingCaseReportPowerPlant: false,
    loadingPDF: false,
    fullDataCaseReportPowerPlant: false,
    loadingFullDataCaseReportPowerPlant: false,
    allCaseReportPowerPlantsByEquipmentId: false,
    loadingAllCaseReportPowerPlantsByEquipmentId: false,
    idEquipmentSelected: false,
    visibleModalBitacora: false,
    visibleModalBitacoraNotFirmed: false,
    notFirmed: [],
    currentPage: 0,
    loadingFirmCaseReportPowerPlant: false,
    masiveReports: false,
    loadingMasiveReports: false,
    loadingFirmAllByDependency: false,
    lastNumber: false,
    createAirAndFridgeReport: false,
    loadingAirAndFridgeReport: false,
};

const CaseReportPowerPlantSlice = createSlice({
    name: "CaseReportPowerPlant",
    initialState,
    reducers: {
        setOption(state, { payload }) {
            state.option = payload
        },
        setCaseReportPowerPlantCreatedId(state, { payload }) {
            state.CaseReportPowerPlantCreatedId = payload
        },
        createCaseReportPowerPlant(state, { payload }) {
            state.loadingCreateCaseReportPowerPlant = true
        },
        setLoadingCreateCaseReportPowerPlant(state, { payload }) {
            state.loadingCreateCaseReportPowerPlant = payload
        },
        createCaseReportPowerPlantPDF(state) {
            state.loadingPDF = true
        },
        createTimelinePDF(state) {
            state.loadingPDF = true
        },
        setLoadingPDF(state, { payload }) {
            state.loadingPDF = payload
        },
        updateCaseReportPowerPlant() { },
        createReportComponent() { },
        deleteCaseReportPowerPlant() { },
        setPersonAssign(state, { payload }) {
            state.personAssign = payload
        },
        setAreaShared(state, { payload }) {
            state.areaShared = payload
        },
        setCaseReportPowerPlantsSearched(state, { payload }) {
            state.CaseReportPowerPlantsSearched = payload
        },
        setQuantity(state, { payload }) {
            state.quantity = payload
        },
        getCaseReportPowerPlants(state) {
            state.loadingCaseReportPowerPlant = true
            state.CaseReportPowerPlantsSearched = false
        },
        setLoading(state, { payload }) {
            state.loadingCaseReportPowerPlant = payload
        },
        createCaseReportPowerPlantUser() { },
        createImageReport() { },
        getFullDataCaseReportPowerPlant(state) {
            state.loadingFullDataCaseReportPowerPlant = true
        },
        setFullDataCaseReportPowerPlant(state, { payload }) {
            state.fullDataCaseReportPowerPlant = payload
        },
        setLoadingFullDataCaseReportPowerPlant(state, { payload }) {
            state.loadingFullDataCaseReportPowerPlant = payload
        },
        getAllCaseReportPowerPlantsByEquipmentId(state) {
            state.loadingAllCaseReportPowerPlantsByEquipmentId = true
        },
        setAllCaseReportPowerPlantsByEquipmentId(state, { payload }) {
            state.allCaseReportPowerPlantsByEquipmentId = payload
        },
        setLoadingAllCaseReportPowerPlantsByEquipmentId(state) {
            state.loadingAllCaseReportPowerPlantsByEquipmentId = false
        },
        setIdEquipmentSelected(state, { payload }) {
            state.idEquipmentSelected = payload
        },
        setVisibleModalBitacora(state, { payload }) {
            state.visibleModalBitacora = payload
        }, setVisibleModalBitacoraNotFirmed(state, { payload }) {
            state.visibleModalBitacoraNotFirmed = payload
        },
        getNotFirmed(state) {
        },
        setNotFirmed(state, { payload }) {
            state.notFirmed = payload
        },
        setCurrentPage(state, { payload }) {
            state.currentPage = payload
        },
        firmCaseReportPowerPlant(state, payload) {
            state.loadingFirmCaseReportPowerPlant = true
        },
        setLoadingFirmCaseReportPowerPlant(state, { payload }) {
            state.loadingFirmCaseReportPowerPlant = payload
        },
        getMasiveReports(state, { payload }) {
            state.loadingMasiveReports = true;
        },
        setMasiveReports(state, { payload }) {
            state.masiveReports = payload
        },
        setLoadingMasiveReports(state, { payload }) {
            state.loadingMasiveReports = payload;
        },
        firmAllByDependency(state, { payload }) {
            state.loadingFirmAllByDependency = true
        },
        setLoadingFirmAllByDependency(state, { payload }) {
            state.loadingFirmAllByDependency = payload
        },
        getLastNumber(state, { payload }) {
        },
        setLastNumber(state, { payload }) {
            state.lastNumber = payload
        },
        createAirAndFridgeReport(state, { payload }) {
            state.loadingAirAndFridgeReport = true
        },
        setAirAndFridgeReport(state, { payload }) {
            state.createAirAndFridgeReport = payload
        },
        setLoadingCreateAirAndFridge(state, { payload }) {
            state.loadingAirAndFridgeReport = payload
        }
    },
});

const CaseReportPowerPlantActions = CaseReportPowerPlantSlice.actions;
const CaseReportPowerPlantReducer = CaseReportPowerPlantSlice.reducer;

export { CaseReportPowerPlantActions, CaseReportPowerPlantReducer };
