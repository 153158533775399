import { Modal, Select } from 'antd';
import { Document, PDFViewer } from '@react-pdf/renderer';
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { apiUrl } from '../../common/config/Environments'


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { PdfVehicleEquipment } from '../PdfVehicleEquipment/PdfVehicleEquipment';
import { EquipmentVehicleActions } from '../../services/EquipmentVehicle/EquipmentVehicleSlice';
import { PdfVehicleImages } from '../PdfVehicleEquipment/PdfVehicleImages';
import { PdfVehiclePapers } from '../PdfVehicleEquipment/PdfVehiclePapers';

export const EquipmentVehicle = ({ data, setEdit, setEquipData, extraOnClick, toAssign, width, height }) => {
    const [visibleModal, setVisibleModal] = useState(false)
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const [pdf, setPdf] = useState(false)
    const { equipAndReportPDF, fullDataEquipment, loadingFullDataEquipment } = useSelector(state => state.Equipment)
    const { loadingGetVehiclePapers, loadingGetVehicleImages, vehiclePapers, vehicleImages } = useSelector(state => state.EquipmentVehicle)
    const [image, setImage] = useState(false)
    const { roleUser, idUser } = useSelector(state => state.Login)
    const [letsAssign, setLetsAssign] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        fetch(`${apiUrl}/vehicleEquipment/imageById?id=${data.id}`)
            .then(response => response.json())
            .then(data => setImage(data))
            .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        pdf === 'all' && dispatch(EquipmentActions.getEquipAndReportPDF(data.id))
    }, [pdf])

    /* const MyDocument = () => (
        <Document>
            <PdfIndustrialEquipment fullDataEquipment={fullDataEquipment} data={data} enterprise={enterpriseImage} />
        </Document >
    ); */

    const MyDocument = () => (
        <Document>
            <PdfVehicleEquipment fullDataEquipment={fullDataEquipment} data={data} enterprise={enterpriseImage} />
        </Document >
    );

    const MyDocumentImages = () => (
        <Document>
            <PdfVehicleImages fullDataEquipment={fullDataEquipment} vehicleImages={vehicleImages} data={data} enterprise={enterpriseImage} />
        </Document >
    );

    const MyDocumentPapers = () => (
        <Document>
            <PdfVehiclePapers fullDataEquipment={fullDataEquipment} vehiclePapers={vehiclePapers} data={data} enterprise={enterpriseImage} />
        </Document >
    );

    const ModalEquip = () => {
        const onClickEdit = () => {
            setEdit(true)
            setEquipData(data)
        }
        return (
            <Modal className='ModalEquip' width={(fullDataEquipment || vehicleImages || vehiclePapers) ? '90vw' : '38vw'} visible={visibleModal} footer={null} onCancel={() => {
                setVisibleModal(false)
                dispatch(EquipmentActions.setFullDataEquipment(false))
                dispatch(EquipmentVehicleActions.setVehicleImages(false))
                dispatch(EquipmentVehicleActions.setVehiclePapers(false))
                dispatch(EquipmentActions.setEquipAndReportPDF(false))
            }}>
                {!(fullDataEquipment || vehicleImages || vehiclePapers || letsAssign) && <p className="title">{data.biomedicEquipment ? data.biomedicEquipment.name : ''}</p>}
                {!(fullDataEquipment || vehicleImages || vehiclePapers || letsAssign) && <div className='div'>
                    <button className='button' onClick={() => {
                        dispatch(EquipmentActions.getFullDataVehicleEquipment({ "id": data.id }))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Ver Hoja de Vida"}
                    </button>
                    <button className='button' onClick={() => {
                        dispatch(EquipmentVehicleActions.getVehicleImages({ "id": data.id }))
                    }}>
                        {loadingGetVehicleImages ? <LoadingOutlined /> : "Ver Imagenes del vehículo"}
                    </button>
                    <button className='button' onClick={() => {
                        dispatch(EquipmentVehicleActions.getVehiclePapers({ "id": data.id }))
                    }}>
                        {loadingGetVehiclePapers ? <LoadingOutlined /> : "Ver Documentos del vehículo"}
                    </button>
                    {toAssign &&
                        <button className='button' onClick={() => {
                            setLetsAssign(true)
                        }}>
                            Asignar conductor
                        </button>}
                        
                    {/* <button className='button'>Bitacora</button> */}
                    {/* {fullUser.canCreate && <button className='button' onClick={() => {
                        onClickEdit(true)
                        dispatch(EquipmentActions.getFullDataIndustrialEquipment({ "id": data.id }))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Editar Hoja de Vida"}
                    </button>} */}
                    {/* <button className='button' onClick={() => {
                        dispatch(EquipmentActions.setFullDataEquipment(
                            {
                                "biomedicEquipment": {
                                    "active": '',
                                    "brand": '',
                                    "serie": '',
                                    "adquisitionDate": null,
                                    "modell": '',
                                    "service": '',
                                    "instalationDate": null,
                                    "healthRegister": '',
                                    "fabricationDate": null,
                                    "startOperationDate": null,
                                    "location": '',
                                    "representative": '',
                                    "warrantyExpirationDate": null,
                                    "maker": '',
                                    "cost": '',
                                    "webPage": '',
                                    "makerCountry": '',
                                    "adquisitionWay": '',
                                    'voltageRange': '',
                                    'pressureRange': '',
                                    'powerSource': '',
                                    'currentRange': '',
                                    'speedRange': '',
                                    'riskClasification': '',
                                    'powerRange': '',
                                    'humidityRange': '',
                                    'frequency': '',
                                    'temperatureRange': '',
                                    'weight': '',
                                    'dimensions': '',
                                    'biomedicComponent': [{
                                        'name': '',
                                        'brand': '',
                                        'reference': '',
                                        'partNumber': '',
                                    }],
                                    "manual": '',
                                    "plane": '',
                                    'use': '',
                                    'biomedicClasification': '',
                                    'tecnology': '',
                                    'operation': '',
                                    'electricSecurity': '',
                                    'maintenance': '',
                                    'equipmentOwner': '',
                                    'irregularCause': '',
                                },
                                "licensePlate": '',
                                "name": '',
                                "municipality": null,

                            }
                        ))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Formato vacio"}
                    </button> */}
                    {/*  <button className='button' onClick={() => setPdf('all')}>Descargar </button> */}
                </div>}

                {fullDataEquipment &&
                    <PDFViewer className='pdf'>
                        <MyDocument></MyDocument>
                    </PDFViewer>
                }
                {vehicleImages &&
                    <PDFViewer className='pdf'>
                        <MyDocumentImages></MyDocumentImages>
                    </PDFViewer>
                }
                {vehiclePapers &&
                    <PDFViewer className='pdf'>
                        <MyDocumentPapers></MyDocumentPapers>
                    </PDFViewer>
                }
                {equipAndReportPDF &&
                    <div>
                        <PDFViewer className='pdf'>
                            <MyDocument></MyDocument>
                        </PDFViewer>
                        {equipAndReportPDF.caseReport && equipAndReportPDF.caseReport.map(d => (
                            <embed className='pdf' src={d.pdf}></embed>
                        ))}
                    </div>}
                {letsAssign &&
                    <div>
                        <p>Conductores Activos</p>
                        <div className='div'>
                            <Select
                                className='selectDriver'
                            >

                            </Select>
                        </div>
                    </div>
                }
            </Modal >
        )
    }

    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este equipo?')) {
            const dataDelete = {
                "id": data.id
            }
            dispatch(EquipmentActions.deleteEquipment(dataDelete))
        }
    }

    // Obtén una referencia a todos los elementos
    const elements = document.querySelectorAll('.dataDiv');

    elements.forEach(element => {
        // Obtén una referencia al elemento padre
        const parent = element.parentElement;

        // Calcula el tamaño de la fuente como un porcentaje del ancho del elemento padre
        const fontSize = parent.offsetWidth * 0.014; // 1.4% del ancho del padre

        // Establece el tamaño de la fuente del elemento
        element.style.fontSize = `${fontSize}px`;
    });

    return (
        <div className='EquipmentVehicle' style={{ width: width, height: height }}>
            {image ?
                <img className='equipmentImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                if (extraOnClick) {
                    extraOnClick(data)
                } else {
                    setVisibleModal(true)
                }
            }}>
                <div><p className='pFontData name'>{data.name}</p></div>
                <div><p className='pFontData'>{data.vehicleEquipment ? data.vehicleEquipment.brand : ''}</p></div>
                <div><p className='pFontData'>{data.vehicleEquipment ? data.vehicleEquipment.modell : ''}</p></div>
                <div><p className='pFontData'>{data.vehicleEquipment ? data.vehicleEquipment.engineNumber : ''}</p></div>
                <div><p className='pFontData'>{data.licensePlate}</p></div>
                <div><p className='pFontData'>{data.location.name}</p></div>
                {data.state === "Funcional" ? <div className='status'><p className='pFontStatus'>Funcional</p></div>
                    : data.state === "No funcional" ? <div className='status1'><p className='pFontStatus'>{data.state}</p></div>
                        : data.state === "Dado de baja" && <div className='status2'><p className='pFontStatus'>Baja</p></div>}
                {toAssign && <div><p className='pFontData'>{"No Asignado"}</p></div>}
            </div>
            {roleUser.id !== 36 && data.userCreator.id === idUser && <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>}
            <ModalEquip />
        </div >
    )
}