import { Modal } from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image as PdfImage } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import blanco from '../../assets/images/blanco.png'
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { SummaryActions } from '../../services/Summary/SummarySlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';

export const SummaryCard = ({ data, setEdit, setEquipData }) => {
    const dispatch = useDispatch()
    const [visibleModal, setVisibleModal] = useState(false)
    const { moduleWork } = useSelector(state => state.Area)
    const { idUser, enterprise } = useSelector(state => state.Login)
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const [components, setComponents] = useState([])
    const [pdf, setPdf] = useState(false)

    const { summarySearched, loadingSummarySearched } = useSelector(state => state.Summary)
    console.log(summarySearched)
    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getEnterpriseImage(enterprise.id))
    }, [enterprise])

    useEffect(() => {
        visibleModal && dispatch(SummaryActions.getSummarySearched(
            {
                "id": data.id,
                "moduleWork": moduleWork[0].moduleWork.id,
            }
        ))
    }, [visibleModal])

    useEffect(() => {
        let aux = []
        summarySearched && summarySearched.summaryCaseReport.map(d => {
            if (d.reportComponent && d.reportComponent.length > 0) {
                aux.push({ "equipment": d.equipment, "reportComponents": d.reportComponent })
            }
        })
        setComponents(aux)
        if (summarySearched) {
            setPdf(true)
        }
    }, [summarySearched])

    let emails = new Set();
    if (summarySearched) {
        summarySearched.summary.supervisor.dependencyUser.map((d) => {
            if (d.dependency.email !== null) {
                emails.add(d.dependency.email);
            }
        })
    }
    emails = Array.from(emails).join(',');

    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este equipo?')) {
            dispatch(SummaryActions.deleteSummary(data.id))
        }
    }

    const MyDocument = () => (
        <Document>
            <Page orientation="landscape" size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View fixed style={{ border: '2.5px solid #000', marginTop: '3%', marginBottom: '1.2%', width: '95%', height: '6%', flexDirection: 'row' }}>
                    <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center', borderRight: '2.5px solid #000', flexDirection: 'row' }}>
                        <PdfImage style={{ width: '40%', height: '40%', opacity: 0.7, marginRight: '3%' }} src={enterpriseImage[0].image}></PdfImage>
                        <Text style={{ fontSize: '18px', width: '56%', opacity: 0.7 }}>{enterprise.name + " "}</Text>
                    </View>
                    <View style={{ width: '60%', borderRight: '2.5px solid #000' }}>
                        <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '28px', fontWeight: '700' }}>INFORME MENSUAL</Text></View>
                    </View>
                    <View style={{ width: '10%', alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000' }}>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '15px' }}>VERSION</Text></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '15px' }}>VERSION</Text></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '15px' }}>CODIGO</Text></View>
                        <View style={{ height: '25%', alignItems: 'center', justifyContent: 'center' }}></View>
                    </View>
                    <View style={{ width: '10%', alignItems: 'center', justifyContent: 'center' }}>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}></View>
                        <View style={{ height: '25%', width: '100%', borderBottom: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text>{summarySearched && summarySearched.summary.summaryNumber + " "}</Text></View>
                        <View style={{ height: '25%', alignItems: 'center', justifyContent: 'center' }}></View>
                    </View>
                </View>

                <View style={{ border: '8px solid #000', width: '95%', height: '43%', flexDirection: 'column' }}>
                    <View style={{ borderBottom: '8px solid #000', width: '100%', height: '8%', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: "#95b3d7" }}><Text style={{ fontSize: '26px' }}>INFORME MENSUAL</Text></View>
                    <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '8%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '1%' }}>CONTRATO NÚMERO: {summarySearched && summarySearched.summary.contractNumber + " "}</Text></View>

                    <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '13%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '2.5px solid #000', width: '16%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>FECHA: </Text></View>
                        <View style={{ borderRight: '2.5px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>DIA </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>{summarySearched && new Date(summarySearched.summary.dateCreated).getDate()} </Text></View>
                        </View>
                        <View style={{ borderRight: '2.5px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>MES </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>{summarySearched && new Date(summarySearched.summary.dateCreated).getMonth() + 1} </Text></View>
                        </View>
                        <View style={{ borderRight: '2.5px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>AÑO </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginTop: '3%' }}>{summarySearched && new Date(summarySearched.summary.dateCreated).getFullYear()}</Text></View>
                        </View>
                        <View style={{ borderRight: '2.5px solid #000', width: '36.5%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>DEPARTAMENTO </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>FLORENCIA </Text></View>
                        </View>
                        <View style={{ width: '36.5%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>CIUDAD </Text></View>
                            <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px' }}>CAQUETA </Text></View>
                        </View>
                    </View>

                    <View style={{ borderBottom: '8px solid #000', width: '100%', height: '13%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>CLIENTE: {enterprise.name + " "} </Text></View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>DIRECCIÓN: CR 10 9 A 72 BRR LA COOPERATIVA </Text></View>
                    </View>
                    <View style={{ borderBottom: '8px solid #000', width: '100%', height: '13%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>NIT: 901244204 </Text></View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>TELEFONO: 3146059072</Text></View>
                    </View>
                    <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '13%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            <Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>
                                CONTACTO: {
                                    summarySearched.summary?.supervisor.person &&
                                    summarySearched.summary.supervisor.person.name + " " +
                                    summarySearched.summary.supervisor.person.secondName + " " +
                                    summarySearched.summary.supervisor.person.lastName + " " +
                                    summarySearched.summary.supervisor.person.secondLastName
                                }
                            </Text>
                        </View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>CELULAR: {summarySearched.summary && summarySearched.summary.supervisor.person && summarySearched.summary.supervisor.person.phone ? summarySearched.summary.supervisor.person.phone : " "}</Text></View>
                    </View>
                    <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '13%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <View style={{ borderRight: '8px solid #000', width: '40%', height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>CARGO: {summarySearched.summary && summarySearched.summary.supervisor && summarySearched.summary.supervisor.position + " "}</Text></View>
                        <View style={{ width: '60%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ fontSize: '26px', marginLeft: '3%' }}>EMAIL: {emails + " "} </Text></View>
                    </View>
                    <View style={{ width: '100%', height: '17%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                        <Text style={{ marginLeft: '1%', fontSize: '22.5px' }}>Se agradece la oportunidad que nos brinda contratando nuestros servicios especializados de ingeniería y asesoría Biomédica, para trabajar enfocados en cubrir las necesidades de su institución y garantizar la seguridad de sus pacientes.  ¡Tú calidad es nuestra prioridad!
                        </Text>
                        <Text style={{ marginTop: '1.2%', marginLeft: '1%', fontSize: '22.5px' }}>A continuación, nos permitimos darles a conocer los hallazgos y recomendaciones generales de las visitas de mantenimiento, para realizar mantenimientos preventivos, correctivos, predictivos, entrega de repuestos, asistencia técnica vía telefónica y bajas de equipos biomédicos. Y así tener en óptimo estado los equipos biomédicos y a la vez puedan brindar calidad en el servicio. Con el fin de apoyar su gestión en la administración de la tecnología biomédica de su institución </Text>
                    </View>
                </View>

                <View style={{ marginTop: '1.4%', border: '8px solid #000', borderBottom: '0p', width: '95%', minHeight: '75px', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: "#95b3d7" }}><Text style={{ fontSize: '28px' }}>DESCRIPCIÓN DE LAS ACTIVIDADES Y HALLAZGOS</Text></View>
                <View style={{ border: '8px solid #000', borderBottom: '0', width: '95%', minHeight: '270px', alignItems: 'flex-start', justifyContent: 'flex-start', paddingBottom: '0' }}><Text style={{ fontSize: '28px', marginTop: '1%', marginLeft: '0.4%', width: '95%' }}>{summarySearched && summarySearched.summary.description + " "}</Text></View>
                <View style={{ border: '8px solid #000', width: '95%', minHeight: '80px', alignItems: 'flex-start', justifyContent: 'flex-start', paddingBottom: '0' }}><Text style={{ fontSize: '28px', marginTop: '0.8%', marginLeft: '0.4%', width: '95%' }}>Observaciones: {summarySearched && summarySearched.summary.observations + " "}</Text></View>
            </Page>
            {summarySearched && summarySearched.summaryCaseReport &&
                summarySearched.summaryCaseReport.reduce((result, image, index) => {
                    if (index % 4 === 0) result.push([]);
                    result[result.length - 1].push(image);
                    return result;
                }, []).map((group, index) => (
                    <Page orientation="landscape" size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center', paddingTop: '15%' }} key={index}>
                        {group.map((caser, indexx) => {
                            let { brand, serie, image } = caser.equipment.biomedicEquipment ||
                                caser.equipment.industrialEquipment ||
                                caser.equipment.networkingAndCommunicationsEquipment ||
                                caser.equipment.peripheralsAndAccessoriesEquipment ||
                                caser.equipment.computersEquipment
                            if (!brand) {
                                brand = caser.equipment.computersEquipment.brandAllInOne ||
                                    caser.equipment.computersEquipment.brandCPU ||
                                    caser.equipment.computersEquipment.brandLaptop
                            }
                            if (!serie) {
                                serie = caser.equipment.computersEquipment.serieAllInOne ||
                                    caser.equipment.computersEquipment.serieCPU ||
                                    caser.equipment.computersEquipment.serieLaptop
                            }
                            let solution = (caser.biomedicCaseReport && caser.biomedicCaseReport.descriptionAnswer) ||
                                (caser.systemCaseReport && caser.systemCaseReport.solutionDelivered);
                            let serviceType = (caser.biomedicCaseReport && caser.biomedicCaseReport.serviceType) ||
                                (caser.systemCaseReport && caser.systemCaseReport.service);
                            return (
                                <View key={indexx} style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center', width: '100%', paddingTop: 0 }}>
                                    {indexx === 0 && index === 0 && (
                                        <>
                                            <View style={{ backgroundColor: "#95b3d7", marginTop: '-8%', border: '6px solid #000', width: '95%', height: '100px', alignItems: 'center', justifyContent: 'center' }}>
                                                <Text style={{ fontSize: '28px', fontWeight: 'bold' }}>RELACIÓN DE EQUIPOS A LOS QUE SE LE REALIZO MANTENIMIENTO CORRECTIVO EN EL {enterpriseImage[0].name + " "}</Text>
                                            </View>

                                            <View style={{ marginTop: '2%', border: '2.5px solid #000', width: '95%', height: '14%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Sede</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Dependencia</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Ubicación</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Nombre Equipo</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Marca</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '11%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Serie</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Placa</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Fecha Mantenimiento</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Tipo Mantenimiento</Text></View>
                                                <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Servicio Ejecutado</Text></View>
                                                <View style={{ width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Foto</Text></View>
                                            </View>
                                        </>)}
                                    <View style={{ marginTop: indexx === 1 && index === 0 ? '-5.6%' : '0%', border: '2.5px solid #000', borderTop: 0, width: '95%', height: '330px', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                        <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.headquarter ? caser.equipment.headquarter.name + " " : "Sede principal"}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.dependency.name + " "}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.location.name + " "}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.name + " "}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{brand + " "}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '11%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{serie + " "}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '7%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{caser.equipment.licensePlate + " "}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{new Date(caser.date).getDate() + "/" + (new Date(caser.date).getMonth() + 1) + "/" + new Date(caser.date).getFullYear()}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>{serviceType ? serviceType + " " : 'Pdf'}</Text></View>
                                        <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Text style={{ textAlign: 'center', fontSize: '30px', marginLeft: '3%', marginTop: '2%' }}>
                                                {solution ? solution.replace(/\n/g, ' ') + " " : 'Pdf'}
                                            </Text>
                                        </View>
                                        <View style={{ width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>{image ? <PdfImage style={{ width: '100%', height: '100%' }} src={image ? image : blanco} ></PdfImage> : <Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Pdf</Text>}</View>
                                    </View>
                                </View>
                            )
                        })}
                    </Page>
                ))
            }

            <Page orientation="landscape" size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View style={{ backgroundColor: "#95b3d7", marginTop: '2%', border: '6px solid #000', width: '95%', height: '5%', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Text style={{ fontSize: '28px', fontWeight: 'bold' }}>RELACIÓN DE EQUIPOS A LOS QUE SE LE INSTALARON REPUESTOS EN EL {enterpriseImage[0].name + " "}</Text>
                </View>

                <View style={{ marginTop: '2%', border: '2.5px solid #000', width: '95%', height: '5%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Sede</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Dependencia</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Ubicación</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Nombre Equipo</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Marca</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '12%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Serie</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '8%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Placa</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Cantidad</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>Nombre del repuesto</Text></View>
                    <View style={{ borderRight: '2.5px solid #000', width: '11%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>N° de parte</Text></View>
                </View>

                {/* {components && components.length > 0 &&
                    components.reduce((result, image, index) => {
                        if (index % 4 === 0) result.push([]);
                        result[result.length - 1].push(image);
                        return result;
                    }, []).map((group, index) => (
                        <Page orientation="landscape" size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center', paddingTop: '15%' }} key={index}>
                            {group.map((d, indexx) => {
                                return d.reportComponents.map((c, i) => {
                                    return (
                                        <View key={i} style={{ marginTop: '0%', border: '2.5px solid #000', borderTop: 0, width: '95%', height: '17%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                            <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.headquarter ? d.equipment.headquarter.name : "Sede principal"}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.dependency.name + " "}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.location + " "}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.name + " "}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.brand + " "}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '12%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.serie + " "}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '8%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.licensePlate + ' '}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.quantity + " "}</Text></View>
                                            <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.name + " "}</Text></View>
                                            <View style={{ width: '11%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.partNumber + " "}</Text></View>
                                        </View>)
                                })
                            })
                            }
                        </Page>
                    ))
                } */}
                {components && components.length > 0 && components.map(d => {
                    return d.reportComponents.map((c, i) => {
                        return (
                            <View key={i} style={{ marginTop: '0%', border: '2.5px solid #000', borderTop: 0, width: '95%', height: '17%', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.headquarter ? d.equipment.headquarter.name : "Sede principal"}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.dependency.name + " "}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '9%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.location + " "}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.name + " "}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.brand + " "}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '12%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.biomedicEquipment.serie + " "}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '8%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{d.equipment.licensePlate + ' '}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.quantity + " "}</Text></View>
                                <View style={{ borderRight: '2.5px solid #000', width: '10%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.name + " "}</Text></View>
                                <View style={{ width: '11%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}><Text style={{ textAlign: 'center', fontSize: '26px', marginLeft: '3%', marginTop: '2%' }}>{c.partNumber + " "}</Text></View>
                            </View>)
                    })
                })
                }
            </Page>
            {
                summarySearched && summarySearched.summary.summaryImage &&
                summarySearched.summary.summaryImage.reduce((result, image, index) => {
                    if (index % 1 === 0) result.push([]);
                    result[result.length - 1].push(image);
                    return result;
                }, []).map((group, index) => (
                    <Page orientation="landscape" size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }} key={index}>
                        {group.map((d, index) => (
                            <View key={index} style={{ border: '8px solid #000', marginTop: '1.4%', width: '95%', height: '1000px', flexDirection: 'column' }}>
                                <View style={{ width: '100%', height: '80%', flexDirection: 'column' }}>
                                    <View style={{ borderBottom: '8px solid #000', width: '100%', height: '100px', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: "#95b3d7" }}><Text style={{ fontSize: '28px' }}>REGISTRO FOTOGRÁFICO DEL MANTENIMIENTO</Text></View>
                                    <View style={{ borderBottom: '2.5px solid #000', width: '100%', height: '900px', alignItems: 'center', justifyContent: 'center' }}><PdfImage style={{ width: '30%', height: '100%', opacity: 0.7, marginRight: '3%' }} src={d.image}></PdfImage></View>
                                </View>
                                <View style={{ backgroundColor: "#95b3d7", width: '100%', minHeight: '10px', flexDirection: 'column' }}>
                                    <View style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}><Text style={{ fontSize: '28px', marginTop: '0.8%', marginLeft: '0.4%' }}>Descripción: {d.description + " "}</Text></View>
                                </View>
                            </View>
                        ))}
                    </Page>
                ))
            }

            <Page orientation="landscape" size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
                <View style={{ marginTop: '1%', marginBottom: '-3.5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <PdfImage style={{ width: '30%', height: '100px' }} src={summarySearched.summary && summarySearched.summary.userCreator.person ? summarySearched.summary.userCreator.person.firm : blanco}></PdfImage>
                </View>
                <View style={{ marginTop: '3%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <View style={{ width: '30%', borderBottom: '2.5px solid #00' }}></View>
                </View>
                <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            summarySearched.summary && summarySearched.summary.userCreator.person
                                ? summarySearched.summary.userCreator.person.name + " " +
                                summarySearched.summary.userCreator.person.secondName + " " +
                                summarySearched.summary.userCreator.person.lastName + " " +
                                summarySearched.summary.userCreator.person.secondLastName
                                : ''
                        }
                    </Text>
                </View>
                <View style={{ marginTop: '1%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            summarySearched.summary && summarySearched.summary.userCreator && summarySearched.summary.userCreator.position
                        }
                    </Text>
                </View>
                <View style={{ marginTop: '1%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            summarySearched.summary && summarySearched.summary.userCreator && summarySearched.summary.userCreator.profesion
                        }
                    </Text>
                </View>
                <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text style={{ fontSize: '29px', marginTop: '1%' }}>AGENTE</Text>
                </View>
            </Page>
        </Document >
    )

    const ModalSummary = () => {
        return (
            <Modal className='ModalEquip' width={summarySearched ? '90vw' : '38vw'} visible={visibleModal} footer={null} onCancel={() => {
                setVisibleModal(false)
                dispatch(SummaryActions.setSummarySearched(false))
                setPdf(false)
            }}>
                {summarySearched && enterpriseImage ? <PDFViewer className='pdf'>
                    <MyDocument></MyDocument>
                </PDFViewer>
                    :
                    loadingSummarySearched && <LoadingOutlined />}
            </Modal >
        )
    }

    //enum months
    const months = {
        0: "Enero",
        1: "Febrero",
        2: "Marzo",
        3: "Abril",
        4: "Mayo",
        5: "Junio",
        6: "Julio",
        7: "Agosto",
        8: "Septiembre",
        9: "Octubre",
        10: "Noviembre",
        11: "Diciembre"
    }

    return (
        <div className='SummaryCard' >
            <div className='dataDiv' onClick={() => setVisibleModal(true)}>
                <div><p className='pFontData name'>{data.summaryNumber}</p></div>
                <div><p className='pFontData'>{data.contractNumber}</p></div>
                <div><p className='pFontData'>{data.userCreator.person.name + " " + data.userCreator.person.lastName}</p></div>
                <div><p className='pFontData'>{data.userCreator.position}</p></div>
                <div><p className='pFontData'>{months[new Date(data.month).getMonth()]}</p></div>
                <div><p className='pFontData'>{new Date(data.month).getFullYear()}</p></div>
                <div style={{ paddingRight: '2vw' }}>
                    <p className='pFontData'>
                        {new Date(data.month).getDate().toString().padStart(2, '0') + "/" +
                            (new Date(data.month).getMonth() + 1).toString().padStart(2, '0') + "/" +
                            new Date(data.month).getFullYear()}
                    </p>
                </div>
            </div>
            {idUser === data.userCreator.id ?
                <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>
                :
                <div className='deleteDiv'><CloseOutlined className='deleteIcon' style={{ visibility: 'hidden' }} /></div>}
            <ModalSummary />
        </div>
    )
}