import { Document, PDFViewer } from "@react-pdf/renderer";
import { Modal, Radio } from "antd";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { EquipmentActions } from "../../services/Equipment/EquipmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { CaseReportActions } from "../../services/caseReport/CaseReportSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { ModalBitacora } from "../ModalBitacora/ModalBitacora";
import { apiUrl } from '../../common/config/Environments'
import { PdfCaseReportPowerPlant } from "../PdfCaseReportPowerPlant/PdfCaseReportPowerPlant";
import { PdfAirConditionatersAndRefri } from "../PdfAirConditionatersAndRefri/PdfAirConditionatersAndRefri";

export const CaseReportIndustrial = ({ data, setEdit, setEquipData }) => {
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const { moduleWork } = useSelector(state => state.Area)
    const [visibleModal, setVisibleModal] = useState(false)
    const [caseReportHigh, setCaseReportHigh] = useState(false)
    const [firm, setFirm] = useState(false)
    const [firm1, setFirm1] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [image, setImage] = useState(false)
    const [visibleModalBitacora, setVisibleModalBitacora] = useState(false)

    const { idUser } = useSelector(state => state.Login)
    const { fullDataCaseReport, loadingFullDataCaseReport, allCaseReportsByEquipmentId, loadingAllCaseReportsByEquipmentId, idEquipmentSelected } = useSelector(state => state.CaseReport)
    const dispatch = useDispatch()

    useEffect(() => {
        let aux = false
        aux = data.caseReport.map(d => new Date(d.date))
        var maximumDate = new Date(Math.max.apply(null, aux));
        for (let index = 0; index < data.caseReport.length; index++) {
            if (new Date(data.caseReport[index].date).getTime() == maximumDate.getTime()) {
                setCaseReportHigh(data.caseReport[index])
            }
        }
    }, [])

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    useEffect(() => {
        fetch(`${apiUrl}/industrialEquipment/imageById?id=${data.id}`)
            .then(response => response.json())
            .then(data => setImage(data))
            .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        visibleModal === false && dispatch(CaseReportActions.setFullDataCaseReport(false))
    }, [visibleModal])

    useEffect(() => {
        fullDataCaseReport && fullDataCaseReport.equipment.id === data.id && setVisibleModal(true)
    }, [fullDataCaseReport])

    useEffect(() => {
        if (allCaseReportsByEquipmentId && allCaseReportsByEquipmentId[0][0].id === data.caseReport[0].id) {
            setVisibleModal(false)
            setVisibleModalBitacora(true)
        }
    }, [allCaseReportsByEquipmentId])

    const ModalEquip = () => {
        const onClickEdit = () => {
            setEdit(true)
            setEquipData(data)
        }
        /* const onClickEdit = () => {
            setEdit(true)
            setEquipData(data)
        } */


        return (
            <Modal
                className='ModalEquip'
                width={fullDataCaseReport ? '90vw' : '40vw'}
                visible={visibleModal} footer={null}
                onCancel={() => {
                    setVisibleModal(false)
                    dispatch(CaseReportActions.setIdEquipmentSelected(false))
                }}
            >
                {!fullDataCaseReport && <p className="title">{data.name}</p>}
                {!fullDataCaseReport && <div className='div'>
                    <button className='button' onClick={() => dispatch(CaseReportActions.getFullDataCaseReport({ "id": caseReportHigh.id, "moduleWork": moduleWork[0].moduleWork.id }))
                    }>
                        {loadingFullDataCaseReport ? <LoadingOutlined /> : "Ver reporte"}
                    </button>
                    {/* {idUser == data.caseReport[0].userCreator.id && <button className='button' onClick={() => onClickEdit(true)}>Editar</button>} */}
                    <button className='button' onClick={() => {
                        dispatch(CaseReportActions.getAllCaseReportsByEquipmentId({ "id": idEquipmentSelected, "page": 0 }))
                    }}>{loadingAllCaseReportsByEquipmentId ? <LoadingOutlined /> : "Bitacora"}</button>

                </div>}
                {fullDataCaseReport && fullDataCaseReport.pdf === null ?
                    <PDFViewer className='pdf'>
                        <Document>
                            {fullDataCaseReport && fullDataCaseReport.equipment.name === "Planta eléctrica" ?
                                <PdfCaseReportPowerPlant
                                    fullDataCaseReport={fullDataCaseReport}
                                    enterprise={enterpriseImage}
                                />
                                :
                                <PdfAirConditionatersAndRefri fullDataCaseReport={fullDataCaseReport} enterprise={enterpriseImage} />
                            }
                            {/* <PdfCaseReportPowerPlant
                                fullDataCaseReport={
                                    {
                                        "id": 3519,
                                        "idM": null,
                                        "date": "",
                                        "endDate": "",
                                        "createdDate": "",
                                        "dbState": true,
                                        "statusEquip": null,
                                        "pdf": null,
                                        "firmedDate": null,
                                        "equipment": {
                                            "id": 787,
                                            "idM": null,
                                            "licensePlate": "",
                                            "dbState": true,
                                            "state": null,
                                            "createdDate": "",
                                            "sectorCode": null,
                                            "nit": null,
                                            "number": null,
                                            "name": "",
                                            "dependency": {
                                                "id": 983,
                                                "name": "",
                                                "email": "",
                                                "area": {
                                                    "id": 425,
                                                    "name": ""
                                                }
                                            },
                                            "municipality": null,
                                            "computersEquipment": {
                                                "id": 108,
                                                "equipType": "",
                                                "SO": "",
                                                "version": "",
                                                "ipAddress": "",
                                                "macAddress": "",
                                                "brandLaptop": null,
                                                "modellLaptop": null,
                                                "serieLaptop": null,
                                                "licensePlateLaptop": null,
                                                "brandAllInOne": "",
                                                "modellAllInOne": "",
                                                "serieAllInOne": "",
                                                "licensePlateAllInOne": " ",
                                                "brandCPU": null,
                                                "modellCPU": null,
                                                "serieCPU": null,
                                                "licensePlateCPU": null,
                                                "diskComputer": [
                                                    {
                                                        "id": 97,
                                                        "type": "",
                                                        "capacity": "",
                                                        "brand": "",
                                                        "serie": "",
                                                        "status": ""
                                                    }
                                                ],
                                                "ramComputer": [
                                                    {
                                                        "id": 97,
                                                        "type": "",
                                                        "capacity": "",
                                                        "brand": "",
                                                        "serie": "",
                                                        "status": ""
                                                    }
                                                ]
                                            },
                                            "peripheralsAndAccessoriesEquipment": null,
                                            "networkingAndCommunicationsEquipment": null
                                        },
                                        "coordinatorAreaWhenDone": {
                                            "id": 222,
                                            "profesion": "",
                                            "position": "",
                                            "email": "",
                                            "dbState": true,
                                            "canCreate": false,
                                            "password": "",
                                            "person": {
                                                "id": 148,
                                                "name": "",
                                                "secondName": "",
                                                "lastName": "",
                                                "secondLastName": "",
                                                "cardId": "",
                                                "phone": "",
                                                "firm": "",
                                                "photo": null
                                            }
                                        },
                                        "bossDependencyWhenDone": null,
                                        "reportComponent": [],
                                        "userCreator": {
                                            "id": 276,
                                            "profesion": "",
                                            "position": "",
                                            "email": "",
                                            "dbState": true,
                                            "canCreate": true,
                                            "password": "",
                                            "role": {
                                                "id": 37,
                                                "name": ""
                                            },
                                            "person": {
                                                "id": 202,
                                                "name": "",
                                                "secondName": "",
                                                "lastName": "",
                                                "secondLastName": "",
                                                "cardId": "",
                                                "phone": "",
                                                "firm": "",
                                                "photo": ""
                                            }
                                        },
                                        "systemCaseReport": {
                                            "idNumber": 47,
                                            "number": 1,
                                            "processors": null,
                                            "newProcessors": null,
                                            "isTampered": "",
                                            "mttoType": "",
                                            "physicStatus": "",
                                            "service": "",
                                            "solutionDelivered": "",
                                            "testsPerformed": "",
                                            "whyIsTampered": "",
                                            "anyDeskNumber": "",
                                            "anyDeskPass": "",
                                            "brandBarcodeReader": null,
                                            "brandDiadems": null,
                                            "brandFingerprintReader": null,
                                            "brandSignaturePad": null,
                                            "brandSource": null,
                                            "brandWebcam": null,
                                            "brandMouse": "",
                                            "brandPowerAdapter": "",
                                            "modellPowerAdapter": "",
                                            "seriePowerAdapter": "",
                                            "licensePlatePowerAdapter": "",
                                            "imagePowerAdapter": null,
                                            "imagePowerAdapterSerial": null,
                                            "statusPowerAdapter": null,
                                            "validityPowerAdapter": null,
                                            "lastMttoPowerAdapter": null,
                                            "noveltyPowerAdapter": null,
                                            "imageAllInOne": "",
                                            "imageLaptop": null,
                                            "imageLaptopSerial": null,
                                            "imageBarcodeReader": "{}",
                                            "imageBarcodeReaderSerial": "{}",
                                            "imageDiadems": "{}",
                                            "imageDiademsSerial": "{}",
                                            "imageFingerprintReader": "{}",
                                            "imageFingerprintReaderSerial": "{}",
                                            "imageSignaturePad": "{}",
                                            "imageSignaturePadSerial": "{}",
                                            "imageSource": "{}",
                                            "imageSourceSerial": "{}",
                                            "imageWebcam": "{}",
                                            "imageWebcamSerial": "{}",
                                            "ipAddress": "",
                                            "licensePlateBarcodeReader": "",
                                            "licensePlateAllInOne": null,
                                            "licensePlateLaptop": null,
                                            "licensePlateDiadems": "",
                                            "licensePlateFingerprintReader": "",
                                            "licensePlateSignaturePad": "",
                                            "licensePlateSource": "",
                                            "licensePlateWebcam": "",
                                            "modellBarcodeReader": "",
                                            "modellDiadems": "",
                                            "modellAllInOne": "",
                                            "modellLaptop": null,
                                            "modellFingerprintReader": "",
                                            "modellSignaturePad": "",
                                            "modellSource": "",
                                            "modellWebcam": "",
                                            "nameEquip": "",
                                            "passEquip": "",
                                            "serieBarcodeReader": "",
                                            "serieDiadems": "",
                                            "serieAllInOne": "",
                                            "serieLaptop": null,
                                            "serieFingerprintReader": "",
                                            "serieSignaturePad": "",
                                            "serieSource": "",
                                            "serieWebcam": "",
                                            "statusBarcodeReader": null,
                                            "statusDiadems": null,
                                            "statusAllInOne": null,
                                            "statusLaptop": null,
                                            "statusFingerprintReader": null,
                                            "statusSignaturePad": null,
                                            "statusSource": null,
                                            "statusWebcam": null,
                                            "brandCPU": null,
                                            "brandAllInOne": "",
                                            "brandLaptop": null,
                                            "brandKeyboard": "",
                                            "brandMonitor": null,
                                            "imageCPU": null,
                                            "imageCPUSerial": null,
                                            "imageKeyboard": "",
                                            "licensePlateCPU": null,
                                            "licensePlateKeyboard": "",
                                            "licensePlateMonitor": null,
                                            "licensePlateMouse": "",
                                            "modellCPU": null,
                                            "modellKeyboard": "",
                                            "modellMonitor": null,
                                            "modellMouse": "",
                                            "serieCPU": null,
                                            "serieKeyboard": "",
                                            "serieMonitor": null,
                                            "serieMouse": "",
                                            "statusCPU": null,
                                            "statusKeyboard": null,
                                            "statusMonitor": null,
                                            "statusMouse": null,
                                            "clientObservation": "",
                                            "diagnostic": "",
                                            "failReported": "",
                                            "noveltyCPU": null,
                                            "noveltyKeyboard": null,
                                            "noveltyMonitor": null,
                                            "noveltyMouse": null,
                                            "noveltyAllInOne": null,
                                            "noveltyLaptop": null,
                                            "noveltyBarcodeReader": null,
                                            "noveltyDiadems": null,
                                            "noveltyFingerprintReader": null,
                                            "noveltySignaturePad": null,
                                            "noveltySource": null,
                                            "noveltyWebcam": null,
                                            "computerReportComponent": [],
                                            "computerReportSoftware": [],
                                            "diskComputer": [],
                                            "ramComputer": []
                                        }
                                    }
                                }
                                enterprise={enterpriseImage}
                            /> */}
                        </Document>
                    </PDFViewer> :
                    fullDataCaseReport && <embed className="pdf" src={fullDataCaseReport.pdf}></embed>}
            </Modal >
        )
    }

    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este reporte?')) {
            const dataDelete = {
                "id": caseReportHigh.id
/*                 "id": data.userAssigned.dependencyUser !== undefined ? data.id : data.userAssigned.person1
 */            }
            dispatch(CaseReportActions.deleteCaseReport(dataDelete))
        }
    }

    return (
        <div className='CaseReportIndustrial' >
            {image ?
                <img className='equipmentImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                setVisibleModal(true)
                dispatch(CaseReportActions.setIdEquipmentSelected(data.id))
            }}>
                <div><p className='pFontData name'>{data.name}</p></div>
                <div><p className='pFontData'>{data.industrialEquipment.brand}</p></div>
                <div><p className='pFontData'>{data.industrialEquipment.modell}</p></div>
                <div><p className='pFontData'>{data.industrialEquipment.serie}</p></div>
                <div><p className='pFontData'>{data.licensePlate}</p></div>
                <div><p className='pFontData'>{data.location.name}</p></div>
                {
                    caseReportHigh && caseReportHigh.systemCaseReport && caseReportHigh.systemCaseReport.physicStatus === "Bueno" ?
                        <div className='status'><p className='pFontStatus'>Bueno</p></div>
                        :
                        caseReportHigh && caseReportHigh.systemCaseReport && caseReportHigh.systemCaseReport.physicStatus === "Malo" &&
                        <div className='status2'><p className='pFontStatus'>Malo</p></div>
                }
                <div>
                    <p className='pFontData'>
                        {caseReportHigh && padStr(new Date(caseReportHigh.date).getDate()) + "/" +
                            padStr(1 + new Date(caseReportHigh.date).getMonth()) + "/" +
                            padStr(new Date(caseReportHigh.date).getFullYear())}
                    </p>
                </div>
            </div>
            <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>
            <ModalEquip />
            <ModalBitacora
                data={allCaseReportsByEquipmentId}
                setEdit={setEdit}
                setEquipData={setEquipData}
                visibleModalBitacora={visibleModalBitacora}
                setVisibleModalBitacora={setVisibleModalBitacora}
            />

        </div>
    )
}