import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"
import { CaseReportPowerPlantActions } from './caseReportPowerPlantSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(CaseReportPowerPlantActions.setMunicipalitys(muns.payload))
  }
  yield put(CaseReportPowerPlantActions.setLoading(false))
} */

function* createCaseReportPowerPlant({ payload }) {
  const e = yield Api.post('/caseReport/powerPlant', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
  yield put(CaseReportPowerPlantActions.setLoadingCreateCaseReportPowerPlant(false))
}

function* createReportComponent({ payload }) {
  const bc = yield Api.post(`/reportComponent`, payload)
  if (bc.ok) {
    message.success('Componentes añadidos')
  } else {
    message.error("Error creando componentes")
  }
}

function* getCaseReportPowerPlants({ payload }) {
  const eq = yield Api.get(`/CaseReportPowerPlant/byGroup/${payload.area}?take=${payload.take}&page=${payload.page}`)
  yield put(CaseReportPowerPlantActions.setCaseReportPowerPlantsSearched(eq.payload[0]))
  yield put(CaseReportPowerPlantActions.setQuantity(eq.payload[1]))
  yield put(CaseReportPowerPlantActions.setLoading(false))
}

function* createImageReport({ payload }) {
  const im = yield Api.post('/imageReport', payload)
  if (im.ok) {
    message.success("Imagenes añadidas correctamente")
  } else {
    message.error("Error al añadir imagenes")
  }
}

function* createCaseReportPowerPlantUser({ payload }) {
  yield put(CaseReportPowerPlantActions.setCaseReportPowerPlantCreatedId(false))
  const cru = yield Api.post('/CaseReportPowerPlantUser', payload)
  if (!cru.ok) {
    message.error('Error al asignar usuarios al reporte')
  } else {
    message.success("Reporte vinculado")
  }
}

function* deleteCaseReportPowerPlant({ payload }) {
  const CaseReportPowerPlantDeleted = yield Api.post(`/CaseReportPowerPlant/delete?idd=${payload.id}`)
  if (CaseReportPowerPlantDeleted.ok) {
    message.success("Reporte eliminado")
  } else {
    message.error("Error al eliminar reporte")
  }
}

function* updateCaseReportPowerPlant({ payload }) {
  const e = yield Api.post('/CaseReportPowerPlant/update', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
}

function* createCaseReportPowerPlantPDF({ payload }) {
  const CaseReportPowerPlant = yield Api.post('/CaseReportPowerPlant/createPdf', payload)
  if (CaseReportPowerPlant.ok) {
    message.success(CaseReportPowerPlant.payload.message)
  } else {
    message.error(CaseReportPowerPlant.payload.message)
  }
}

function* getFullDataCaseReportPowerPlant({ payload }) {
  const c = yield Api.get(`/CaseReportPowerPlant/getFullData/byId?id=${payload}`)
  if (c.ok) {
    yield put(CaseReportPowerPlantActions.setFullDataCaseReportPowerPlant(c.payload))
    yield put(CaseReportPowerPlantActions.setLoadingFullDataCaseReportPowerPlant(false))
  } else {
    message.error("Error al obtener pdf")
  }
}

function* getAllCaseReportPowerPlantsByEquipmentId({ payload }) {
  const c = yield Api.get(`/CaseReportPowerPlant/allByEquipmentId?take=3&page=${payload.page}&id=${payload.id}`)
  if (c.ok) {
    yield put(CaseReportPowerPlantActions.setAllCaseReportPowerPlantsByEquipmentId(c.payload))
    yield put(CaseReportPowerPlantActions.setLoadingAllCaseReportPowerPlantsByEquipmentId(false))
  } else {
    message.error("Error al obtener reportes del equipo")
  }
}

function* getNotFirmed({ payload }) {
  let dataUrl = ""
  payload.payload.forEach((element, index) => {
    if (element.status === "Jefe") {
      dataUrl += `&id${index}=${element.dependency.id}`
    }
  })
  const c = yield Api.get(`/CaseReportPowerPlant/notFirmed?take=${payload.take}&page=${payload.page}` + dataUrl)
  if (c.ok) {
    yield put(CaseReportPowerPlantActions.setNotFirmed(c.payload))
  } else {
    message.error("Error al obtener no firmados")
  }
}

function* firmCaseReportPowerPlant({ payload }) {
  const c = yield Api.post(`/CaseReportPowerPlant/firm?id=${payload.id}&user=${payload.user}`)
  if (c.ok) {
    message.success(c.payload.message)
  } else {
    message.error(c.payload.message)
  }
}

function* getMasiveReports({ payload }) {
  const c = yield Api.get(`/CaseReportPowerPlant/getFullDataMasiveByDependencyAndMonth?month=${payload.month}&dependency=${payload.dependency}`)
  if (c.ok) {
    yield put(CaseReportPowerPlantActions.setMasiveReports(c.payload))
  } else {
    message.error("Error al obtener reportes masivos")
  }
  yield put(CaseReportPowerPlantActions.setLoadingMasiveReports(false))
}

function* firmAllByDependency({ payload }) {
  let dataUrl = ""
  payload.payload.forEach((element, index) => {
    dataUrl += `&id${index}=${element.dependency.id}`
  })
  const c = yield Api.post(`/CaseReportPowerPlant/firmAllByDependency?idUser=${payload.idUser}` + dataUrl)
  if (c.ok) {
    message.success("Firmados correctamente")
  } else {
    message.error("Error al firmar todos")
  }
  yield put(CaseReportPowerPlantActions.setLoadingFirmAllByDependency(false))
}

function* getLastNumber({ payload }) {
  const c = yield Api.get(`/systemCaseReport/lastNumber`)
  if (c.ok) {
    yield put(CaseReportPowerPlantActions.setLastNumber(c.payload))
  } else {
    message.error("Error al obtener numero")
  }
}

function* createAirAndFridgeReport({ payload }) {
  const c = yield Api.post('/caseReport/airConditionerAndFridge', payload)
  if (c.ok) {
    message.success("Reporte de neveras o aires creado correctamente")
  } else {
    message.error("Error al crear reporte de neveras o aires")
  }
  yield put(CaseReportPowerPlantActions.setLoadingCreateAirAndFridge(false))
}

function* actionWatcher() {
  yield takeLatest(CaseReportPowerPlantActions.createCaseReportPowerPlant, createCaseReportPowerPlant)
  yield takeLatest(CaseReportPowerPlantActions.createReportComponent, createReportComponent)
  yield takeLatest(CaseReportPowerPlantActions.getCaseReportPowerPlants, getCaseReportPowerPlants)
  yield takeLatest(CaseReportPowerPlantActions.createImageReport, createImageReport)
  yield takeLatest(CaseReportPowerPlantActions.createCaseReportPowerPlantUser, createCaseReportPowerPlantUser)
  yield takeLatest(CaseReportPowerPlantActions.updateCaseReportPowerPlant, updateCaseReportPowerPlant)
  yield takeLatest(CaseReportPowerPlantActions.deleteCaseReportPowerPlant, deleteCaseReportPowerPlant)
  yield takeLatest(CaseReportPowerPlantActions.createCaseReportPowerPlantPDF, createCaseReportPowerPlantPDF)
  yield takeLatest(CaseReportPowerPlantActions.getFullDataCaseReportPowerPlant, getFullDataCaseReportPowerPlant)
  yield takeLatest(CaseReportPowerPlantActions.getAllCaseReportPowerPlantsByEquipmentId, getAllCaseReportPowerPlantsByEquipmentId)
  yield takeLatest(CaseReportPowerPlantActions.getNotFirmed, getNotFirmed)
  yield takeLatest(CaseReportPowerPlantActions.firmCaseReportPowerPlant, firmCaseReportPowerPlant)
  yield takeLatest(CaseReportPowerPlantActions.getMasiveReports, getMasiveReports)
  yield takeLatest(CaseReportPowerPlantActions.firmAllByDependency, firmAllByDependency)
  yield takeLatest(CaseReportPowerPlantActions.getLastNumber, getLastNumber)
  yield takeLatest(CaseReportPowerPlantActions.createAirAndFridgeReport, createAirAndFridgeReport)
}

export default function* CaseReportPowerPlantSaga() {
  yield all([actionWatcher()])
}