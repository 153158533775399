import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"

import { EquipmentActions } from './EquipmentSlice'
import { TimelineActions } from '../Timeline/TimelineSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(EquipmentActions.setMunicipalitys(muns.payload))
  }
  yield put(EquipmentActions.setLoading(false))
} */

function* createEquipment({ payload }) {
  const e = yield Api.post('/equipment', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
  yield put(EquipmentActions.setLoadingCreateEquipment(false))
}

function* deleteEquipment({ payload }) {
  const equipmentDeleted = yield Api.post(`/equipment/delete?idd=${payload.id}`)
  if (equipmentDeleted.ok) {
    message.success("Equipo eliminado")
  } else {
    message.error("Error al eliminar equipo")
  }
}

function* updateEquipment({ payload }) {
  const e = yield Api.post(`/equipment/update?id=${payload.id}`, payload.payload)
  if (e.ok) {
    message.success("Equipo actualizado")
  } else {
    message.error("Error al actualizar equipo")
  }
  yield put(EquipmentActions.setLoadingUpdateEquipment(false))
}

function* createBiomedicComponent({ payload }) {
  const bc = yield Api.post(`/biomedicComponent`, payload)
  if (bc.ok) {
    message.success('Componentes añadidos')
  } else {
    message.error("Error creando componentes")
  }
}

function* getEquipments({ payload }) {
  const eq = yield Api.get(`/equipment/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  yield put(EquipmentActions.setEquipmentsSearched(eq.payload[0]))
  yield put(EquipmentActions.setQuantity(eq.payload[1]))
  yield put(EquipmentActions.setLoading(false))
}

function* getEquipmentsByModuleWork({ payload }) {
  const eq = yield Api.get(`/equipment/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}&dependency=${payload.dependency}`)
  yield put(EquipmentActions.setEquipmentsSearched(eq.payload[0]))
  yield put(EquipmentActions.setQuantity(eq.payload[1]))
  yield put(EquipmentActions.setLoading(false))
}

function* getEquipmentsToXLSX({ payload }) {
  const eq = yield Api.get(`/equipment/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}&dependency=${payload.dependency}`)
  yield put(EquipmentActions.setEquipmentsToXLSX(eq.payload[0]))
  yield put(EquipmentActions.setQuantity(eq.payload[1]))
  yield put(EquipmentActions.setLoading(false))
  yield put(EquipmentActions.setTextXLSX("Descargar"))
}

function* getEquipmentsByModuleWorkAndState({ payload }) {
  const eq = yield Api.get(`/equipment/byModuleWorkAndState?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}&state=${payload.state}&idUser=${payload.idUser}&role=${payload.role}&dependency=${payload.dependency}`)
  yield put(EquipmentActions.setLoading(false))
  if (!eq.ok) {
    message.error("Error inesperado")
  } else {
    yield put(EquipmentActions.setEquipmentsSearched(eq.payload[0]))
    yield put(EquipmentActions.setQuantity(eq.payload[1]))
  }
}

function* searchEquipment({ payload }) {
  const eq = yield Api.get(`/equipment/byDependencySearch?dependency=${payload.dependency}&nameEquip=${payload.nameEquip}&state=${payload.state}`)
  yield put(EquipmentActions.setEquipmentsSearched(eq.payload))
  yield put(EquipmentActions.setLoading(false))
}

function* search({ payload }) {
  const eq = yield Api.get(`/equipment/search?dependency=${payload.dependency}&area=${payload.area}&nameEquip=${payload.nameEquip}&licensePlate=${payload.licensePlate}&year=${payload.year}&take=${payload.take}&page=${payload.page}&moduleWork=${payload.moduleWork}&plateOrSerie=${payload.plateOrSerie}&isForTimeline=${payload.isForTimeline}&vehiclePlate=${payload.vehiclePlate}&equipType=${payload.equipType}`)
  if (eq.ok) {
    yield put(EquipmentActions.setEquipmentsSearched(eq.payload[0]))
    yield put(EquipmentActions.setQuantity(eq.payload[1]))
    yield put(EquipmentActions.setIsFiltered(true))
  } else {
    message.error("Error obteniendo equipos filtrados")
  }
  yield put(EquipmentActions.setLoading(false))
}

function* getStates() {
  const s = yield Api.get('/equipment/distinctState')
  if (s.ok) {
    yield put(EquipmentActions.setStates(s.payload))
  } else {
    message.error("Error obteniendo estados")
  }
}

function* getLocations() {
  const m = yield Api.get('/biomedicEquipment/distinctLocation')
  if (m.ok) {
    yield put(EquipmentActions.setLocations(m.payload))
  } else {
    message.error("Error obteniendo ubicaciones")
  }
}

function* getEquipsWithCaseReports({ payload }) {
  const e = yield Api.get(`/equipment/getLastCaseReport/byDependency?dependency=${payload.area}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* getEquipsWithCaseReportsByModuleWork({ payload }) {
  const e = yield Api.get(`/equipment/getLastCaseReport/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* getEquipsWithDailyVehicle({ payload }) {
  const e = yield Api.get(`/equipment/getLastDailyVehicle?take=${payload.take}&page=${payload.page}&moduleWork=${payload.moduleWork}`)
  if (e.ok) {
    yield put(EquipmentActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener check list diarios")
  }
}

function* getEquipsWithCalibrationCertificate({ payload }) {
  const e = yield Api.get(`/equipment/getLastCalibrationCertificate/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* searchCaseReport({ payload }) {
  const c = yield Api.get(`/equipment/getCaseReportSearch?dependency=${payload.dependency}&take=8&page=${payload.page}&name=${payload.name}&location=${payload.location}&state=${payload.state}&date=${payload.date[0]}&date1=${payload.date[1]}&moduleWork=${payload.moduleWork}&licensePlate=${payload.licensePlate}`)
  if (c.ok) {
    yield put(EquipmentActions.setEquipsWithCaseReports(c.payload[0]))
    yield put(EquipmentActions.setQuantityEquipsWithCaseReports(c.payload[1]))
    yield put(EquipmentActions.setLoadingEquipsWithCaseReport(false))
    yield put(EquipmentActions.setIsFiltered(true))
  } else {
    message.error("Error al realizar la busqueda")
  }
}

function* getEquipAndReportPDF({ payload }) {
  const e = yield Api.get(`/equipment/getByIdAndReportsPDF?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentActions.setEquipAndReportPDF(e.payload))
  } else {
    message.error("Error al obtener PDFs")
  }
}

function* getEquipsForTimeline({ payload }) {
  const e = yield Api.get(`/equipment/getForTimeline?dependency=${payload.dependency}&string=${payload.search}&moduleWork=${payload.moduleWork}&headquarter=${payload.headquarter}`)
  if (e.ok) {
    if (payload.string === "") {
      yield put(EquipmentActions.setAllEquipsForTimeline(e.payload))
    } else {
      yield put(EquipmentActions.setEquipsForTimeline(e.payload))
    }
  } else {
    message.error("Error al obtener equipos buscados")
  }
  yield put(EquipmentActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelines({ payload }) {
  const e = yield Api.get(`/equipment/getEquipsWithTimelines/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0 })
    })
    yield put(TimelineActions.setTimelinesByEquipment(aux))
    yield put(EquipmentActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the equipments

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelinesByModuleWork({ payload }) {
  const e = yield Api.get(`/equipment/getEquipsWithTimelines/byModuleWork?dependency=${payload.dependency}&area=${payload.area}&nameEquip=${payload.nameEquip}&licensePlate=${payload.licensePlate}&year=${payload.year}&take=${payload.take}&page=${payload.page}&moduleWork=${payload.moduleWork}&plateOrSerie=${payload.plateOrSerie}&year=${payload.year}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0, year: 'undefined' })
    })
    yield put(TimelineActions.setTimelinesByEquipment(aux))
    yield put(EquipmentActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the equipments

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentActions.setLoadingEquipsForTimeline(false))
}

function* getFullDataEquipment({ payload }) {
  const e = yield Api.get(`/equipment/fullDataEquipmentById?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentActions.setFullDataEquipment(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentActions.setLoadingFullDataEquipment(false))
}

function* getFullDataNetworkingAndCommunicationsEquipment({ payload }) {
  const e = yield Api.get(`/equipment/fullDataNetworkingAndCommunicationsEquipmentById?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentActions.setFullDataEquipment(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentActions.setLoadingFullDataEquipment(false))
}

function* getFullDataPeripheralsAndAccessoriesEquipment({ payload }) {
  const e = yield Api.get(`/equipment/fullDataPeripheralsAndAccessoriesEquipmentById?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentActions.setFullDataEquipment(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentActions.setLoadingFullDataEquipment(false))
}

function* getFullDataComputersEquipment({ payload }) {
  const e = yield Api.get(`/equipment/fullDataComputersEquipmentById?id=${payload.id}&parts=${payload.parts}`)
  if (e.ok) {
    yield put(EquipmentActions.setFullDataEquipment(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentActions.setLoadingFullDataEquipment(false))
}

function* getFullDataIndustrialEquipment({ payload }) {
  const e = yield Api.get(`/equipment/fullDataIndustrialEquipmentById?id=${payload.id}`)
  if (e.ok) {
    yield put(EquipmentActions.setFullDataEquipment(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentActions.setLoadingFullDataEquipment(false))
}

function* getPartsComputersEquipment({ payload }) {
  const e = yield Api.get(`/equipment/getPartsComputersEquipmentById?id=${payload.id}`)
  if (e.ok) {
    yield put(EquipmentActions.setPartsComputersEquipment(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentActions.setLoadingPartsComputersEquipment(false))
}

function* getMasiveInventory({ payload }) {
  const e = yield Api.get(`/equipment/getFullDataMasiveByDependency?dependency=${payload.dependency}&moduleWork=${payload.moduleWork}`)
  if (e.ok) {
    yield put(EquipmentActions.setMasiveInventory(e.payload))
  } else {
    message.error("Error al obtener los inventarios masivos")
  }
  yield put(EquipmentActions.setLoadingMasiveInventory(false))
}

function* getFullDataVehicleEquipment({ payload }) {
  const e = yield Api.get(`/equipment/fullDataVehicleEquipmentById?id=${payload.id}`)
  if (e.ok) {
    yield put(EquipmentActions.setFullDataEquipment(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentActions.setLoadingFullDataEquipment(false))
}

function* getAnnexesPdf({ payload }) {
  const e = yield Api.get(`/equipmentAnnexes/pdfById?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentActions.setAnnexesPdf(e.payload))
  } else {
    message.error("Error al obtener los anexos")
  }
  yield put(EquipmentActions.setLoadingAnnexesPdf(false))
}

function* actionWatcher() {
  yield takeLatest(EquipmentActions.createEquipment, createEquipment)
  yield takeLatest(EquipmentActions.updateEquipment, updateEquipment)
  yield takeLatest(EquipmentActions.createBiomedicComponent, createBiomedicComponent)
  yield takeLatest(EquipmentActions.getEquipments, getEquipments)
  yield takeLatest(EquipmentActions.searchEquipment, searchEquipment)
  yield takeLatest(EquipmentActions.deleteEquipment, deleteEquipment)
  yield takeLatest(EquipmentActions.getStates, getStates)
  yield takeLatest(EquipmentActions.getLocations, getLocations)
  yield takeLatest(EquipmentActions.getEquipsWithCaseReports, getEquipsWithCaseReports)
  yield takeLatest(EquipmentActions.searchCaseReport, searchCaseReport)
  yield takeLatest(EquipmentActions.getEquipAndReportPDF, getEquipAndReportPDF)
  yield takeLatest(EquipmentActions.getEquipsForTimeline, getEquipsForTimeline)
  yield takeLatest(EquipmentActions.getEquipsWithTimelines, getEquipsWithTimelines)
  yield takeLatest(EquipmentActions.getFullDataEquipment, getFullDataEquipment)
  yield takeLatest(EquipmentActions.getFullDataNetworkingAndCommunicationsEquipment, getFullDataNetworkingAndCommunicationsEquipment)
  yield takeLatest(EquipmentActions.getFullDataPeripheralsAndAccessoriesEquipment, getFullDataPeripheralsAndAccessoriesEquipment)
  yield takeLatest(EquipmentActions.getFullDataComputersEquipment, getFullDataComputersEquipment)
  yield takeLatest(EquipmentActions.getEquipmentsByModuleWork, getEquipmentsByModuleWork)
  yield takeLatest(EquipmentActions.getEquipmentsByModuleWorkAndState, getEquipmentsByModuleWorkAndState)
  yield takeLatest(EquipmentActions.search, search)
  yield takeLatest(EquipmentActions.getEquipsWithCaseReportsByModuleWork, getEquipsWithCaseReportsByModuleWork)
  yield takeLatest(EquipmentActions.getEquipsWithTimelinesByModuleWork, getEquipsWithTimelinesByModuleWork)
  yield takeLatest(EquipmentActions.getPartsComputersEquipment, getPartsComputersEquipment)
  yield takeLatest(EquipmentActions.getMasiveInventory, getMasiveInventory)
  yield takeLatest(EquipmentActions.getEquipsWithCalibrationCertificate, getEquipsWithCalibrationCertificate)
  yield takeLatest(EquipmentActions.getFullDataIndustrialEquipment, getFullDataIndustrialEquipment)
  yield takeLatest(EquipmentActions.getFullDataVehicleEquipment, getFullDataVehicleEquipment)
  yield takeLatest(EquipmentActions.getEquipsWithDailyVehicle, getEquipsWithDailyVehicle)
  yield takeLatest(EquipmentActions.getAnnexesPdf, getAnnexesPdf)
  yield takeLatest(EquipmentActions.getEquipmentsToXLSX, getEquipmentsToXLSX)
}

export default function* EquipmentSaga() {
  yield all([actionWatcher()])
}