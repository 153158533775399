import { Document, Image, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfCaseReport = ({ fullDataCaseReport, enterprise }) => {
    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    return (
        <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
            <View style={{ border: '3px solid #000', marginTop: '5%', width: '95%', height: '8%', flexDirection: 'row' }}>
                <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center', borderRight: '3px solid #000' }}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={{ width: '75%', borderRight: '3px solid #000' }}>
                    <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>PROCESO DE GESTION ADMINISTRACIÓN DEL RIESGO</Text></View>
                    <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '30px' }}>Reporte de servicio</Text></View>
                </View>
                <View style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ height: '50%', width: '100%', borderBottom: '3px solid #000', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                    <View style={{ height: '50%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '32px' }}>{" "}</Text></View>
                </View>
            </View>
            <View style={{ border: '3px solid #000', width: '95%', height: '2.3%', flexDirection: 'row', marginTop: '1%' }}>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '27px' }}>FECHA:</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.date ? padStr(new Date(fullDataCaseReport.date).getDate()) + "/" + padStr(1 + new Date(fullDataCaseReport.date).getMonth()) + "/" + padStr(new Date(fullDataCaseReport.date).getFullYear()) : ''}</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '27px' }}>CIUDAD:</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.equipment.municipality && fullDataCaseReport.equipment.municipality.nombre + " "}</Text></View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '27px' }}>INGENIERO:</Text></View>
                <View style={{ height: '100%', width: '26%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.userCreator && (fullDataCaseReport.userCreator.person.name + " " + fullDataCaseReport.userCreator.person.lastName)}</Text></View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '2.3%', backgroundColor: "#ebedf0", marginTop: '2%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: '29px' }}>DATOS DEL EQUIPO</Text>
            </View>

            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '9.5%' }}>
                <View style={{ borderBottom: '3px solid #000', flexDirection: 'row', width: '100%', height: '33.3%' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '27px' }}>NOMBRE:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '29%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.equipment.name + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '27px' }}>MARCA:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.equipment.biomedicEquipment.brand + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '27px' }}>MODELO:</Text></View>
                    <View style={{ height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.equipment.biomedicEquipment.modell + " "}</Text></View>
                </View>
                <View style={{ borderBottom: '3px solid #000', flexDirection: 'row', width: '100%', height: '33.3%' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '27px' }}>SERIE:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '29%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.equipment.biomedicEquipment.serie + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '27px' }}>ACTIVO:</Text></View>
                    <View style={{ height: '100%', width: '44%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.equipment.biomedicEquipment.active!=="null" ? fullDataCaseReport.equipment.biomedicEquipment.active : " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '100%', height: '33.3%' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '27px' }}>DEPENDENCIA:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '29%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{fullDataCaseReport.dependencyWhenDone.name + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '16%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0" }}><Text style={{ fontSize: '27px' }}>RESPONSABLE:</Text></View>
                    <View style={{ height: '100%', width: '40%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>
                        {fullDataCaseReport.bossDependencyWhenDone ? fullDataCaseReport.bossDependencyWhenDone.person.name + " "
                            + fullDataCaseReport.bossDependencyWhenDone.person.secondName + " "
                            + fullDataCaseReport.bossDependencyWhenDone.person.lastName + " "
                            + fullDataCaseReport.bossDependencyWhenDone.person.secondLastName
                            :
                            ""}
                    </Text></View>
                </View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '2.3%', backgroundColor: "#ebedf0", marginTop: '2%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: '29px' }}>TIPO DE SERVICIO</Text>
            </View>

            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '5%', flexDirection: 'row' }}>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '28px' }}>CORRECTIVO</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.serviceType === "Correctivo" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '28px' }}>PREVENTIVO</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.serviceType === "Preventivo" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '28px' }}>INSTALACIÓN</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.serviceType === "Instalación" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '15%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '28px' }}>INDUCCIÓN</Text>
                </View>
                <View style={{ height: '100%', width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.serviceType === "Inducción" ? "X" : " "}</Text>
                </View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '2.3%', backgroundColor: "#ebedf0", marginTop: '2%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: '29px' }}>DESCRIPCIÓN DEL SERVICIO</Text>
            </View>

            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '15%' }}>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '37%', flexDirection: 'row' }}><Text style={{ fontSize: '29px' }}>SERVICIO SOLICITADO Y/O REPORTADO: {fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.descriptionAsking + " "}</Text></View>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '37%', flexDirection: 'row' }}><Text style={{ fontSize: '29px' }}>SERVICIO EJECUTADO: {fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.descriptionAnswer + " "}</Text></View>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '13%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>TERMINADO</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.status === "Terminado" ? "X" : " "}</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>EN PROCESO</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.status === "En proceso" ? "X" : " "}</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '22%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>FUERA DE SERVICIO</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.status === "Fuera de servicio" ? "X" : " "}</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '14%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>OTRO</Text>
                    </View>
                    <View style={{ height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.status === "Otro" ? "X" : " "}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '13%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '40%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>CAMBIO O INSTALACIÓN DE PARTES</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>SI</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>{fullDataCaseReport.reportComponent.length > 0 ? "X" : " "}</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>NO</Text>
                    </View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '28px' }}>{fullDataCaseReport.reportComponent.length === 0 ? "X" : " "}</Text>
                    </View>
                </View>
            </View>
            <View style={{ borderBottom: '3px solid #000', borderRight: '3px solid #000', width: '95%', height: '1.95%', flexDirection: 'row' }}>
                <View style={{ borderRight: '3px solid #000', borderLeft: '3px solid #000', height: '100%', width: '18%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '28px' }}>FUNCIONAL</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.statusEquip === "Funcional" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '28px' }}>NO FUNCIONAL</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.statusEquip === "No funcional" ? "X" : " "}</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '22%', backgroundColor: "#ebedf0", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '28px' }}>DADO DE BAJA</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '36px' }}>{fullDataCaseReport.statusEquip === "Dado de baja" ? "X" : " "}</Text>
                </View>
            </View>

            <View style={{ border: '3px solid #000', width: '95%', height: '2.3%', backgroundColor: "#ebedf0", marginTop: '2%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: '29px' }}>REPUESTOS INSTALADOS</Text>
            </View>

            {fullDataCaseReport.reportComponent.length > 0 && fullDataCaseReport.reportComponent.map((d, i) => (
                <View key={i} style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '2.3%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '10%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '23px' }}>NOMBRE:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '43%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{d.name}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '23px' }}>CANTIDAD:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{d.quantity}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '23px' }}>No. PARTE:</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '23px' }}>{d.partNumber}</Text></View>
                    {/* <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', alignItems: 'center', justifyContent: 'center', backgroundColor: "#ebedf0", }}><Text style={{ fontSize: '27px' }}>VALOR:</Text></View>
                        <View style={{ height: '100%', width: '16%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ fontSize: '27px' }}>{d.value}</Text></View> */}
                </View>
            ))}


            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '5%' }}><Text style={{ fontSize: '29px' }}>DESCRIPCION: {fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.processDescription + " "}</Text></View>
            <View style={{ border: '3px solid #000', borderTop: 0, width: '95%', height: '10%' }}><Text style={{ fontSize: '29px' }}>OBSERVACIONES: {fullDataCaseReport.biomedicCaseReport && fullDataCaseReport.biomedicCaseReport.observations + " "}</Text></View>

            <View style={{ marginTop: '3%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Image style={{ width: '30%', height: '150%' }} src={fullDataCaseReport.bossDependencyWhenDone && fullDataCaseReport.bossDependencyWhenDone.person.firm ? fullDataCaseReport.bossDependencyWhenDone.person.firm : blanco}></Image>
                <Image style={{ width: '30%', height: '150%' }} src={fullDataCaseReport.userCreator.person.firm ? fullDataCaseReport.userCreator.person.firm : blanco}></Image>
            </View>
            <View style={{ marginTop: '3%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-3%' }}>
                <Text
                    style={{ fontSize: '18px', color: 'black' }}>
                    {
                        fullDataCaseReport.bossDependencyWhenDone ? fullDataCaseReport.bossDependencyWhenDone.profesion : " "
                    }
                </Text>
                <Text
                    style={{ fontSize: '18px', color: 'black' }}>
                    {
                        fullDataCaseReport.userCreator ? fullDataCaseReport.userCreator.profesion : " "
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '-2.7%' }}>
                <Text
                    style={{ fontSize: '18px' }}>
                    {fullDataCaseReport.bossDependencyWhenDone ? fullDataCaseReport.bossDependencyWhenDone.person && fullDataCaseReport.bossDependencyWhenDone.person.name + " " +
                        fullDataCaseReport.bossDependencyWhenDone.person.secondName + " " +
                        fullDataCaseReport.bossDependencyWhenDone.person.lastName + " " +
                        fullDataCaseReport.bossDependencyWhenDone.person.secondLastName
                        :
                        " "}
                </Text>
                <Text
                    style={{ fontSize: '18px' }}>
                    {(fullDataCaseReport.userCreator.person && fullDataCaseReport.userCreator.person.firm) ? fullDataCaseReport.userCreator.person.name + " " +
                        fullDataCaseReport.userCreator.person.secondName + " " +
                        fullDataCaseReport.userCreator.person.lastName + " " +
                        fullDataCaseReport.userCreator.person.secondLastName
                        :
                        " "
                    }
                </Text>
            </View>

            <View style={{ width: '95%', marginTop: '-1%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '23px' }}>JEFE DE DEPENDENCIA</Text>
                <Text style={{ fontSize: '23px' }}>AGENTE QUE REALIZA EL REPORTE</Text>
            </View>
        </Page>
    )
}