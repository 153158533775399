import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lastNumber: false,
};

const TicketSlice = createSlice({
    name: "Ticket",
    initialState,
    reducers: {
        getLastNumber(state) {
        },
        setLastNumber(state, { payload }) {
            state.lastNumber = payload
        }
    },
});

const TicketActions = TicketSlice.actions;
const TicketReducer = TicketSlice.reducer;

export { TicketActions, TicketReducer };
