import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PagActions } from "./PagSlice";
import { message } from "antd";
import Api from "../../../common/api";

function* listener(payload) {

  const { type } = payload
  
  const matches = /(Request|Success|Fail)/.test(type);
  if (!matches) return
  // console.log(type)
  yield put(PagActions.setPag(type))
}

function* getSelectOptions() {
  const se= yield Api.get("/selectOptions/all")
  if (se.ok) {
    yield put(PagActions.setSelectOptions(se.payload))
  } else {
    message.error("Error al cargar opciones.")
  }
}


function* actionWatcher() {
  yield takeEvery('*', listener)
  yield takeLatest(PagActions.getSelectOptions, getSelectOptions)
}

export default function* PagSaga() {
  yield all([actionWatcher()])
}
