import { fork, all } from "redux-saga/effects";
import AreasSaga from "../services/Area/AreaSaga";
import CaseReportSaga from "../services/caseReport/CaseReportSaga";
import PagSaga from '../services/common/Pag/PagSaga'
import DepMunSaga from "../services/DepMun/DepMunSaga";
import EnterpriseSaga from "../services/Enterprise/EnterpriseSaga";
import EquipmentSaga from "../services/Equipment/EquipmentSaga";
import LoginSaga from "../services/Login/LoginSaga";
import TimelineSaga from "../services/Timeline/TimelineSaga";
import SummarySaga from "../services/Summary/SummarySaga";
import EquipmentSystemSaga from "../services/EquipmentSystem/EquipmentSystemSaga";
import CaseReportSystemSaga from "../services/caseReportSystem/caseReportSystemSaga";
import TransferSaga from "../services/Transfer/TransferSaga";
import TicketSaga from "../services/Ticket/TicketSaga";
import EquipmentIndustrialSaga from "../services/EquipmentIndustrial/EquipmentIndustrialSaga";
import EquipmentVehicleSaga from "../services/EquipmentVehicle/EquipmentVehicleSaga";
import CaseReportPowerPlantSaga from "../services/caseReportPowerPlant/caseReportPowerPlantSaga";
import dailyVehicleSaga from "../services/dailyVehicle/dailyVehicleSaga";
import SpecializedCenterSaga from "../services/SpecializedCenter/SpecializedCenterSaga";

export default function* rootSaga() {
  yield all(
    [
      fork(PagSaga),
      fork(AreasSaga),
      fork(DepMunSaga),
      fork(LoginSaga),
      fork(EquipmentSaga),
      fork(CaseReportSaga),
      fork(TimelineSaga),
      fork(EnterpriseSaga),
      fork(SummarySaga),
      fork(EquipmentSystemSaga),
      fork(CaseReportSystemSaga),
      fork(TransferSaga),
      fork(TicketSaga),
      fork(EquipmentIndustrialSaga),
      fork(EquipmentVehicleSaga),
      fork(CaseReportPowerPlantSaga),
      fork(dailyVehicleSaga),
      fork(SpecializedCenterSaga),
    ]);
}
