import { Steps, Button, Radio, DatePicker, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../Input/Input'
import photo from '../../assets/images/photo.png'
import { useSelector } from 'react-redux';
import { Controller } from "react-hook-form"

const PaperSection = ({ fieldName, name, watchMoto, setValueMoto, controlMoto, registerMoto, errorsMoto }) => {
    return (
        <div className='papersSection'>
            <p className='papersName'>{name}</p>
            <Controller
                control={controlMoto} // control viene de useForm()
                name={fieldName}
                rules={{
                    validate: (value) => {
                        const errors = [];
                        if (!value || value === '') {
                            errors.push("Es requerido elegir una opcion");
                        }
                        return errors.length === 0 || errors.join(', ');
                    },
                }}
                render={({ field }) => (
                    <Radio.Group
                        className='radioGroup'
                        style={{ marginLeft: '2vw' }}
                        {...field} // field contiene onChange, onBlur, name, y value
                        onChange={e => {
                            setValueMoto(fieldName, e.target.value)
                            setValueMoto(fieldName + 'BuyDate', "")
                            setValueMoto(fieldName + 'ExpirationDate', "")
                            setValueMoto(fieldName + 'Validity', "")
                        }}
                    >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                )}
            />
            <div style={{ visibility: watchMoto(fieldName) ? 'visible' : 'hidden' }} className='divInputsPapers'>
                <div className='divDate'>
                    <p className='label'>Fecha de Compra</p>
                    <DatePicker
                        value={watchMoto(fieldName + 'BuyDate')}
                        {...registerMoto(fieldName + 'BuyDate', {
                        })}
                        style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                        onChange={d => setValueMoto(fieldName + 'BuyDate', d)}
                    />
                </div>
                <div className='divDate'>
                    <p className='label'>Fecha Inicio Vigencia</p>
                    <DatePicker
                        value={watchMoto(fieldName + 'StartExpirationDate')}
                        {...registerMoto(fieldName + 'StartExpirationDate', {
                        })}
                        style={{ width: '9vw', height: '2.4vw', marginRight: '0.7vw' }}
                        onChange={d => setValueMoto(fieldName + 'StartExpirationDate', d)}
                    />
                </div>
                <div className='divDate'>
                    <p className='label'>Fecha A Vencer</p>
                    <DatePicker
                        value={watchMoto(fieldName + 'ExpirationDate')}
                        {...registerMoto(fieldName + 'ExpirationDate', {
                        })}
                        style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                        onChange={d => setValueMoto(fieldName + 'ExpirationDate', d)}
                    />
                </div>
            </div>
        </div>
    );
};

const TireSection = ({ fieldName, name, watchMoto, setValueMoto, controlMoto, registerMoto, errorsMoto }) => {
    return (
        <div className='tireSection'>
            <p className='tireName'>{name}</p>
            <Input
                {...registerMoto(fieldName + 'Brand', {
                    validate: (value) => {
                        const errors = [];
                        if (!value || value === '') {
                            errors.push("La marca es requerida");
                        }
                        return errors.length === 0 || errors.join(', ');
                    },
                })}
                label={"Marca"}

                mt={'0.2vw'}
                width={'7vw'}
                height={'2.4vw'}
                labelstyle='inputLabelBordered'
                fontSize={'1vw'}
                errorFontSize={'0.7vw'}
                labelMB={'-0.1vw'}
                va={watchMoto(fieldName + 'Brand')}
                setData={e => setValueMoto(fieldName + 'Brand', e)}
                error={errorsMoto[fieldName + 'Brand'] && errorsMoto[fieldName + 'Brand'].message}
            />
            <Input
                {...registerMoto(fieldName + 'Reference', {
                    validate: (value) => {
                        const errors = [];
                        if (!value || value === '') {
                            errors.push("La referencia es requerida");
                        }
                        return errors.length === 0 || errors.join(', ');
                    },
                })}
                label={"Referencia"}
                mr={"1.3vw"}
                mt={'0.2vw'}
                width={'7vw'}
                height={'2.4vw'}
                labelstyle='inputLabelBordered'
                fontSize={'1vw'}
                errorFontSize={'0.7vw'}
                labelMB={'-0.1vw'}
                va={watchMoto(fieldName + 'Reference')}
                setData={e => setValueMoto(fieldName + 'Reference', e)}
                error={errorsMoto[fieldName + 'Reference'] && errorsMoto[fieldName + 'Reference'].message}
            />
        </div>
    )
};

const Accessory = ({ category, options, watchMoto, setValueMoto, controlMoto, registerMoto, errorsMoto }) => {
    return (
        <div className='accessory'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', visibility: category ? "visible" : "hidden", marginBottom: category ? '0' : '1.2vw' }}>
                <p className='category' style={{ fontSize: category && (category.length <= 19 ? '0.8vw' : category.length <= 24 ? '0.7vw' : '0.6vw') }}>{category}</p>
            </div>
            <div className='divColumnsNames'>
                <p className='columnName'>Descripción:</p>
                <p className='columnName'>Estado:</p>
                <p className='columnName'>Cantidad:</p>
            </div>
            <div className='divOptions'>
                {options.map((option, index) => (
                    <div className='option' key={index}>
                        <p className='nameOption'>{option.name}</p>
                        <Controller
                            control={controlMoto} // control viene de useForm()
                            name={option.fieldName + "Status"}
                            rules={{
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("Es requerido elegir una opcion");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            }}
                            render={({ field }) => (
                                <Radio.Group
                                    className='radioGroup'
                                    {...field}
                                    onChange={e => {
                                        setValueMoto(option.fieldName + "Status", e.target.value)
                                    }}
                                >
                                    <Radio value={"GOOD"}>Bueno</Radio>
                                    <Radio value={"REGULAR"}>Regular</Radio>
                                    <Radio value={"BAD"}>Malo</Radio>
                                </Radio.Group>
                            )}
                        />
                        <Input
                            {...registerMoto(option.fieldName + "Quantity", {
                            })}
                            label={"none"}
                            width={'3.2vw'}
                            height={'2vw'}
                            labelstyle='inputLabelBordered'
                            fontSize={'1vw'}
                            errorFontSize={'0.7vw'}
                            labelMB={'-0.1vw'}
                            type={"number"}
                            va={watchMoto(option.fieldName + "Quantity")}
                            setData={e => {
                                setValueMoto(option.fieldName + "Quantity", e)
                                if (Number(e) === 0) {
                                    setValueMoto(option.fieldName + "Status", null)
                                }
                            }}
                            error={errorsMoto[option.fieldName + "Quantity"] && errorsMoto[option.fieldName + "Quantity"].message}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
};

export function useStepsMoto(
    registerMoto,
    handleSubmitMoto,
    setValueMoto,
    watchMoto,
    controlMoto,
    errorsMoto,
    triggerMoto,
) {

    const frontalCanvas = useRef(null)
    const backCanvas = useRef(null)
    const rightCanvas = useRef(null)
    const leftCanvas = useRef(null)
    const transitLicenseCanvas = useRef(null)
    const soatCanvas = useRef(null)
    const policyCanvas = useRef(null)
    const mechaTechReviewCanvas = useRef(null)

    const [frontalImage, setFrontalImage] = useState(photo)
    const [backImage, setBackImage] = useState(photo)
    const [leftImage, setLeftImage] = useState(photo)
    const [rightImage, setRightImage] = useState(photo)
    const [transitLicenseImage, setTransitLicenseImage] = useState(photo)
    const [soatImage, setSoatImage] = useState(photo)
    const [policyImage, setPolicyImage] = useState(photo)
    const [mechaTechReviewImage, setMechaTechReviewImage] = useState(photo)
    const { Option } = Select;


    const { departments, municipalitys } = useSelector(state => state.DepMun)

    const uploadImage = (e, imageType) => {
        const canvass = {
            "frontalImage": frontalCanvas,
            "backImage": backCanvas,
            "leftImage": leftCanvas,
            "rightImage": rightCanvas,
            "transitLicenseImage": transitLicenseCanvas,
            "SOATImage": soatCanvas,
            "policyImage": policyCanvas,
            "mechaTechReviewImage": mechaTechReviewCanvas
        }
        const setState = {
            "frontalImage": setFrontalImage,
            "backImage": setBackImage,
            "leftImage": setLeftImage,
            "rightImage": setRightImage,
            "transitLicenseImage": setTransitLicenseImage,
            "SOATImage": setSoatImage,
            "policyImage": setPolicyImage,
            "mechaTechReviewImage": setMechaTechReviewImage
        }

        const setImage = setState[imageType];
        const canvas = canvass[imageType];

        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueMoto(imageType, dataImg) }, 1000)
    };

    const StepsMoto = [
        {
            title: 1,
            content:
                <div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <div style={{ width: '31%' }}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen de frente del vehiculo</p>
                                <Controller
                                    name="frontalImage"
                                    control={controlMoto}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen frontal es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={frontalCanvas} className='canvas'> </canvas>
                                            <p>Imagen frontal</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setFrontalImage(photo)
                                                        setValueMoto('frontalImage', undefined)
                                                        var ctx = frontalCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        frontalCanvas.current.width = 350
                                                        frontalCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = frontalCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'frontalImage');
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsMoto.frontalImage && <p className='errorMessage'>{errorsMoto.frontalImage.message}</p>}
                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen trasera del vehiculo</p>
                                <Controller
                                    name="backImage"
                                    control={controlMoto}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen trasera es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={backCanvas} className='canvas'> </canvas>
                                            <p>Imagen trasera</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setBackImage(photo)
                                                        setValueMoto('backImage', undefined)
                                                        var ctx = backCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        backCanvas.current.width = 350
                                                        backCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = backCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'backImage');
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsMoto.backImage && <p className='errorMessage'>{errorsMoto.backImage.message}</p>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '68%', flexDirection: 'column', marginLeft: '1%' }}>
                            <p className='titleSection'>Datos del vehiculo</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw', boxShadow: '0px 4px 4px 0px #00000040', paddingLeft: '1vw', paddingRight: '1vw' }}>
                                <div style={{ display: 'flex', gap: '1vw' }}>
                                    <Input
                                        {...registerMoto('licensePlate', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La placa de inventario es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Placa Inventario"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('licensePlate')}
                                        setData={e => setValueMoto('licensePlate', e)}
                                        error={errorsMoto.licensePlate && errorsMoto.licensePlate.message}
                                    />
                                    <Input
                                        {...registerMoto('class', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La clase es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Clase"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('class')}
                                        setData={e => setValueMoto('class', e)}
                                        error={errorsMoto.class && errorsMoto.class.message}
                                    />
                                    <Input
                                        {...registerMoto('color', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El color es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Color"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('color')}
                                        setData={e => setValueMoto('color', e)}
                                        error={errorsMoto.color && errorsMoto.color.message}
                                    />
                                    <div className='inputDiv'>
                                        <p className='label'>Combustible</p>
                                        <Controller
                                            name="fuel"
                                            control={controlMoto}
                                            className="select"
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={watchMoto('fuel')}
                                                    className="select"
                                                >
                                                    <Option value={"Gasolina"}>Gasolina</Option>
                                                    <Option value={"ACPM"}>ACPM</Option>
                                                </Select>
                                            )}
                                        />
                                    </div>
                                    <Input
                                        {...registerMoto('vehiclePlate', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Placas del vehiculo");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Placas del Vehiculo"}

                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('vehiclePlate')}
                                        setData={e => setValueMoto('vehiclePlate', e)}
                                        error={errorsMoto.vehiclePlate && errorsMoto.vehiclePlate.message}
                                    />
                                </div>
                                <div style={{ display: 'flex', gap: '1vw' }}>
                                    <Input
                                        {...registerMoto('cylinders', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("Cilindros es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Cilindros"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('cylinders')}
                                        setData={e => setValueMoto('cylinders', e)}
                                        error={errorsMoto.cylinders && errorsMoto.cylinders.message}
                                    />
                                    <Input
                                        {...registerMoto('engineNumber', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El numero de motor es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Numero de Motor"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('engineNumber')}
                                        setData={e => setValueMoto('engineNumber', e)}
                                        error={errorsMoto.engineNumber && errorsMoto.engineNumber.message}
                                    />
                                    <Input
                                        {...registerMoto('numberSerie', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El numero de serie es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Numero De Serie"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('numberSerie')}
                                        setData={e => setValueMoto('numberSerie', e)}
                                        error={errorsMoto.numberSerie && errorsMoto.numberSerie.message}
                                    />
                                    <Input
                                        {...registerMoto('chassisNumber', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El numero de chasis es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Numero De Chasis"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('chassisNumber')}
                                        setData={e => setValueMoto('chassisNumber', e)}
                                        error={errorsMoto.chassisNumber && errorsMoto.chassisNumber.message}
                                    />
                                    <div className='inputDiv'>
                                        <p className='label'>Marca</p>
                                        <Controller
                                            name="brand"
                                            control={controlMoto}
                                            className="select"
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={watchMoto('brand')}
                                                    className="select"
                                                >
                                                    <Option value={"Toyota"}>Toyota</Option>
                                                    <Option value={"Chevrolet"}>Chevrolet</Option>
                                                    <Option value={"Mazda"}>Mazda</Option>
                                                    <Option value={"Renault"}>Renault</Option>
                                                    <Option value={"Nissan"}>Nissan</Option>
                                                    <Option value={"Kia"}>Kia</Option>
                                                    <Option value={"Hyundai"}>Hyundai</Option>
                                                    <Option value={"Suzuki"}>Suzuki</Option>
                                                    <Option value={"Honda"}>Honda</Option>
                                                    <Option value={"Yamaha"}>Yamaha</Option>
                                                    <Option value={"Bajaj"}>Bajaj</Option>
                                                    <Option value={"Otra"}>Otra</Option>
                                                </Select>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: '1vw' }}>
                                    <Input
                                        {...registerMoto('line', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La linea es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Linea"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('line')}
                                        setData={e => setValueMoto('line', e)}
                                        error={errorsMoto.line && errorsMoto.line.message}
                                    />
                                    <Input
                                        {...registerMoto('modell', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El modelo es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Modelo"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('modell')}
                                        setData={e => setValueMoto('modell', e)}
                                        error={errorsMoto.modell && errorsMoto.modell.message}
                                    />
                                    <Input
                                        {...registerMoto('type', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El tipo es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        label={"Tipo"}

                                        mt={'0.2vw'}
                                        width={'9vw'}
                                        height={'2.4vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        errorFontSize={'0.7vw'}
                                        labelMB={'-0.1vw'}
                                        va={watchMoto('type')}
                                        setData={e => setValueMoto('type', e)}
                                        error={errorsMoto.type && errorsMoto.type.message}
                                    />
                                </div>
                                <p className='titleSection'>Documentos del vehiculo</p>
                                <div className='papersSection'>
                                    <p className='papersName'>Licencia de Transito del Vehiculo</p>
                                    <Radio.Group
                                        className='radioGroup'
                                        {...registerMoto('transitLicense', {
                                        })}
                                        style={{ marginLeft: '2vw' }}
                                        value={watchMoto('transitLicense')}
                                        onChange={e => {
                                            setValueMoto('transitLicense', e.target.value)
                                            setValueMoto('transitLicenseBuyDate', "")
                                            setValueMoto('transitLicenseRegistrationDate', "")
                                            setValueMoto('numberLicense', "")

                                        }}>
                                        <Radio value={true}>Si</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                    <div style={{ visibility: watchMoto('transitLicense') ? 'visible' : 'hidden' }} className='divInputsPapers'>
                                        <div className='divDate'>
                                            <p className='label'>Fecha de Compra</p>
                                            <DatePicker
                                                value={watchMoto('transitLicenseBuyDate')}
                                                {...registerMoto('transitLicenseBuyDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueMoto('transitLicenseBuyDate', d)}
                                            />
                                        </div>
                                        <div className='divDate'>
                                            <p className='label'>Fecha de Matricula</p>
                                            <DatePicker
                                                value={watchMoto('transitLicenseRegistrationDate')}
                                                {...registerMoto('transitLicenseRegistrationDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueMoto('transitLicenseRegistrationDate', d)}
                                            />
                                        </div>
                                        <Input
                                            {...registerMoto('numberLicense', {
                                            })}
                                            label={"Numero de licencia"}

                                            mt={'0.2vw'}
                                            width={'8vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            errorFontSize={'0.7vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchMoto('numberLicense')}
                                            setData={e => setValueMoto('numberLicense', e)}
                                            error={errorsMoto.numberLicense && errorsMoto.numberLicense.message}
                                        />
                                    </div>
                                </div>
                                <PaperSection fieldName='SOAT' name='Seguro Obligatorio de Accidentes de Transito SOAT' watchMoto={watchMoto} registerMoto={registerMoto} setValueMoto={setValueMoto} errorsMoto={errorsMoto} controlMoto={controlMoto} />
                                <PaperSection fieldName='mechaTechReview' name='Certificado de Revisión Técnico Mecánica y de Gases' watchMoto={watchMoto} registerMoto={registerMoto} setValueMoto={setValueMoto} errorsMoto={errorsMoto} controlMoto={controlMoto} />

                                <p className='titleSection'>Llantas</p>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <TireSection fieldName='tireFrontal' name='Llanta Delantera' watchMoto={watchMoto} registerMoto={registerMoto} setValueMoto={setValueMoto} errorsMoto={errorsMoto} controlMoto={controlMoto} />
                                    <TireSection fieldName='tireBack' name='Llanta Trasera' watchMoto={watchMoto} registerMoto={registerMoto} setValueMoto={setValueMoto} errorsMoto={errorsMoto} controlMoto={controlMoto} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        },
        {
            title: 2,
            content:
                <div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <div style={{ width: '31%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen Derecha del vehiculo</p>
                                <Controller
                                    name="rightImage"
                                    control={controlMoto}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen derecha es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={rightCanvas} className='canvas'> </canvas>
                                            <p>Imagen Derecha</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setRightImage(photo)
                                                        setValueMoto('rightImage', undefined)
                                                        var ctx = rightCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        rightCanvas.current.width = 350
                                                        rightCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = rightCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'rightImage');
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsMoto.rightImage && <p className='errorMessage'>{errorsMoto.rightImage.message}</p>}
                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                <p className='titleSection'>Imagen Izquierda del vehiculo</p>
                                <Controller
                                    name="leftImage"
                                    control={controlMoto}
                                    rules={{
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === photo) {
                                                errors.push("La imagen izquierda es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        }
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={leftCanvas} className='canvas'> </canvas>
                                            <p>Imagen Izquierda</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setLeftImage(photo)
                                                        setValueMoto('leftImage', undefined)
                                                        var ctx = leftCanvas.current.getContext('2d')
                                                        var img = new Image()
                                                        leftCanvas.current.width = 350
                                                        leftCanvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = leftCanvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e, 'leftImage');
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsMoto.leftImage && <p className='errorMessage'>{errorsMoto.leftImage.message}</p>}
                            </div>
                            <p className='titleSection'>Cargue Documentos del Vehiculo</p>
                            {watchMoto("transitLicense") &&
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                    <p className='titleSection'>LICENCIA DE TRANSITO DEL VEHICULO</p>
                                    <Controller
                                        name="transitLicenseImage"
                                        control={controlMoto}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === photo) {
                                                    errors.push("La imagen de la licencia de transito es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            }
                                        }}
                                        render={({ field }) => (
                                            <div className='imageDiv'>
                                                <canvas ref={transitLicenseCanvas} className='canvas'> </canvas>
                                                <p>Imagen Licencia de Transito</p>
                                                <div className='imageButtons'>
                                                    <Button
                                                        onClick={() => {
                                                            setTransitLicenseImage(photo)
                                                            setValueMoto('transitLicenseImage', undefined)
                                                            var ctx = rightCanvas.current.getContext('2d')
                                                            var img = new Image()
                                                            transitLicenseCanvas.current.width = 350
                                                            transitLicenseCanvas.current.height = 280
                                                            img.onload = function () {
                                                                ctx.drawImage(img, 0, 0, 350, 280)
                                                            }
                                                            var dataImg;
                                                            setTimeout(function () { dataImg = transitLicenseCanvas.current.toDataURL() }, 1000)
                                                            img.src = photo
                                                        }}
                                                        style={{
                                                            backgroundColor: '#ffff',
                                                            color: '#C4C4C4',
                                                            borderRadius: '10px',
                                                            fontSize: '1vw'
                                                        }}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <label className="custom-file-upload">
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            onClick={(event) => { event.target.value = null }}
                                                            onChange={e => {
                                                                uploadImage(e, 'transitLicenseImage');
                                                            }}
                                                            type='file'
                                                        ></input>
                                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errorsMoto.transitLicenseImage && <p className='errorMessage'>{errorsMoto.transitLicenseImage.message}</p>}
                                </div>}
                            {watchMoto("SOAT") &&
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                    <p className='titleSection'>SEGURO OBLIGATORIO DE ACCIDENTES DE TRANSITO SOAT</p>
                                    <Controller
                                        name="SOATImage"
                                        control={controlMoto}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === photo) {
                                                    errors.push("La imagen del SOAT es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            }
                                        }}
                                        render={({ field }) => (
                                            <div className='imageDiv'>
                                                <canvas ref={soatCanvas} className='canvas'> </canvas>
                                                <p>Imagen SOAT</p>
                                                <div className='imageButtons'>
                                                    <Button
                                                        onClick={() => {
                                                            setSoatImage(photo)
                                                            setValueMoto('soat', undefined)
                                                            var ctx = rightCanvas.current.getContext('2d')
                                                            var img = new Image()
                                                            soatCanvas.current.width = 350
                                                            soatCanvas.current.height = 280
                                                            img.onload = function () {
                                                                ctx.drawImage(img, 0, 0, 350, 280)
                                                            }
                                                            var dataImg;
                                                            setTimeout(function () { dataImg = soatCanvas.current.toDataURL() }, 1000)
                                                            img.src = photo
                                                        }}
                                                        style={{
                                                            backgroundColor: '#ffff',
                                                            color: '#C4C4C4',
                                                            borderRadius: '10px',
                                                            fontSize: '1vw'
                                                        }}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <label className="custom-file-upload">
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            onClick={(event) => { event.target.value = null }}
                                                            onChange={e => {
                                                                uploadImage(e, 'SOATImage');
                                                            }}
                                                            type='file'
                                                        ></input>
                                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errorsMoto.SOATImage && <p className='errorMessage'>{errorsMoto.SOATImage.message}</p>}
                                </div>
                            }
                            {watchMoto("mechaTechReview") &&
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '1%' }}>
                                    <p className='titleSection'>CERTFICADO DE REVISION TECNICO MECANICA Y DE GASES</p>
                                    <Controller
                                        name="mechaTechReviewImage"
                                        control={controlMoto}
                                        rules={{
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === photo) {
                                                    errors.push("La imagen de la Revisión Tecnico Mecanica es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            }
                                        }}
                                        render={({ field }) => (
                                            <div className='imageDiv'>
                                                <canvas ref={mechaTechReviewCanvas} className='canvas'> </canvas>
                                                <p>Imagen Revisión Tecnico Mecanica</p>
                                                <div className='imageButtons'>
                                                    <Button
                                                        onClick={() => {
                                                            setSoatImage(photo)
                                                            setValueMoto('mechaTechReviewImage', undefined)
                                                            var ctx = rightCanvas.current.getContext('2d')
                                                            var img = new Image()
                                                            mechaTechReviewCanvas.current.width = 350
                                                            mechaTechReviewCanvas.current.height = 280
                                                            img.onload = function () {
                                                                ctx.drawImage(img, 0, 0, 350, 280)
                                                            }
                                                            var dataImg;
                                                            setTimeout(function () { dataImg = mechaTechReviewCanvas.current.toDataURL() }, 1000)
                                                            img.src = photo
                                                        }}
                                                        style={{
                                                            backgroundColor: '#ffff',
                                                            color: '#C4C4C4',
                                                            borderRadius: '10px',
                                                            fontSize: '1vw'
                                                        }}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <label className="custom-file-upload">
                                                        <input
                                                            accept="image/png, image/jpeg"
                                                            onClick={(event) => { event.target.value = null }}
                                                            onChange={e => {
                                                                uploadImage(e, 'mechaTechReviewImage');
                                                            }}
                                                            type='file'
                                                        ></input>
                                                        <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errorsMoto.mechaTechReviewImage && <p className='errorMessage'>{errorsMoto.mechaTechReviewImage.message}</p>}
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex', width: '68%', flexDirection: 'column', marginLeft: '1%' }}>
                            <p className='titleSection'>Partes y Accesorios</p>
                            <div className='divAccessories'>
                                <div className='accessorySection'>
                                    <p className='text'>Puede seleccionar marcar el estado y cantidad de manera Total con un clic para todas las partes y accesorios del vehículo.</p>
                                </div>
                                <div className='accessorySection'>
                                    <div className='accessory yellow'>
                                        <div className='divColumnsNames'>
                                            <p className='columnName'>Descripción:</p>
                                            <p className='columnName'>Estado:</p>
                                            <p className='columnName'>Cantidad:</p>
                                        </div>
                                        <div className='divOptions'>
                                            <div className='option'>
                                                <p className='nameOption'>Marcar Todo: </p>
                                                <Radio.Group
                                                    className='radioGroup'
                                                    onChange={e => {
                                                        let aux = watchMoto()
                                                        let options = Object.keys(aux).filter(key => key.endsWith('Status'))
                                                        options.forEach(option => {
                                                            setValueMoto(option, e.target.value)
                                                        })
                                                    }}
                                                >
                                                    <Radio value={"GOOD"}>Bueno</Radio>
                                                    <Radio value={"REGULAR"}>Regular</Radio>
                                                    <Radio value={"BAD"}>Malo</Radio>
                                                </Radio.Group>
                                                <Input
                                                    label={"none"}
                                                    width={'3.2vw'}
                                                    height={'2vw'}
                                                    labelstyle='inputLabelBordered'
                                                    fontSize={'1vw'}
                                                    errorFontSize={'0.7vw'}
                                                    labelMB={'-0.1vw'}
                                                    type={"number"}
                                                    setData={e => {
                                                        //find all the options and set the value
                                                        let aux = watchMoto()
                                                        let options = Object.keys(aux).filter(key => key.endsWith('Quantity'))
                                                        let optionsStatus = Object.keys(aux).filter(key => key.endsWith('Status'))
                                                        if (Number(e) === 0) {
                                                            optionsStatus.forEach(option => {
                                                                setValueMoto(option, 0)
                                                            })
                                                        }
                                                        options.forEach(option => {
                                                            setValueMoto(option, e)
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Encendido Electrónico: ', fieldName: 'electronicStart' },
                                            { name: 'Defensa: ', fieldName: 'defending' },
                                            { name: 'Llaves: ', fieldName: 'keys' },
                                            { name: 'Bobina de Alta: ', fieldName: 'highCoil' },
                                            { name: 'Batería: ', fieldName: 'battery' },
                                            { name: 'Bujía: ', fieldName: 'sparkPlug' },
                                            { name: 'Capuchon Bujía: ', fieldName: 'sparkPlugCap' },
                                            { name: 'Tapa piñón Arrastre: ', fieldName: 'drivePinionCover' },
                                            { name: 'Guia de Cadena: ', fieldName: 'chainGuide' },
                                            { name: 'Tacómetros: ', fieldName: 'tachometers' },
                                            { name: 'Perilla de Tacómetros: ', fieldName: 'tachometerKnob' },
                                            { name: 'Guayas y Fondas: ', fieldName: 'guayasAndFondas' },
                                            { name: 'Guardabarros: ', fieldName: 'mudguard' },
                                            { name: 'Guias de Aire: ', fieldName: 'airGuides' },
                                            { name: 'Sirena: ', fieldName: 'mermaid' },
                                            { name: 'Llantas: ', fieldName: 'tires' },
                                            { name: 'Manillares: ', fieldName: 'handlebars' },
                                            { name: 'Herramientas Piezas: ', fieldName: 'toolsParts' },
                                            { name: 'Pastillas Traseras: ', fieldName: 'rearPads' },
                                            { name: 'Tapas Laterales: ', fieldName: 'sideCovers' },
                                            { name: 'Tapa Tanque Gasolina: ', fieldName: 'fuelTankCap' },
                                            { name: 'Exhosto: ', fieldName: 'exhaustPipe' },
                                            { name: 'Rejillas Exhosto: ', fieldName: 'exhaustPipeGrilles' },
                                            { name: 'Control de Luces: ', fieldName: 'lightControl' },
                                            { name: 'Strober Delantero: ', fieldName: 'frontStrober' },
                                            { name: 'Descarga Pies: ', fieldName: 'footrest' },
                                            { name: 'Tanque de Aceite: ', fieldName: 'oilTank' },
                                            { name: 'Tapa Tanque Aceite: ', fieldName: 'oilTankCover' },
                                            { name: 'Pastas Reflectoras: ', fieldName: 'reflectivePastes' },
                                            { name: 'Carenaje: ', fieldName: 'fairing' },
                                        ]}
                                        watchMoto={watchMoto}
                                        registerMoto={registerMoto}
                                        setValueMoto={setValueMoto}
                                        errorsMoto={errorsMoto}
                                        controlMoto={controlMoto}
                                    />
                                </div>
                                <div className='accessorySection'>
                                    <Accessory
                                        options={[
                                            { name: 'Carburador: ', fieldName: 'carburetor' },
                                            { name: 'Auto Carburador: ', fieldName: 'autoCarburetor' },
                                            { name: 'Bobina de Encendido: ', fieldName: 'ignitionCoil' },
                                            { name: 'Punzón Carburador: ', fieldName: 'carburetorPunch' },
                                            { name: 'Pata Estacionamiento: ', fieldName: 'parkingLeg' },
                                            { name: 'Kit de Arrastre: ', fieldName: 'dragKit' },
                                            { name: 'Protector de Manilar: ', fieldName: 'handlebarProtector' },
                                            { name: 'Guardacadena: ', fieldName: 'chainGuard' },
                                            { name: 'Patada Crank: ', fieldName: 'kickCrank' },
                                            { name: 'Switch: ', fieldName: 'switch' },
                                            { name: 'Palanca Cambios: ', fieldName: 'gearLever' },
                                            { name: 'Rines: ', fieldName: 'wheels' },
                                            { name: 'Sistema de Inyección: ', fieldName: 'injectionSystem' },
                                            { name: 'Mango Acelerador: ', fieldName: 'throttleHandle' },
                                            { name: 'Manubrio: ', fieldName: 'handle' },
                                            { name: 'Mando de Luces: ', fieldName: 'lightLeader' },
                                            { name: 'Maniguetas: ', fieldName: 'handles' },
                                            { name: 'Pastillas Delanteras: ', fieldName: 'frontPads' },
                                            { name: 'Bandas Frenos: ', fieldName: 'brakeBands' },
                                            { name: 'Tanque de Gasolina: ', fieldName: 'gasolineTank' },
                                            { name: 'Telescopios: ', fieldName: 'telescopes' },
                                            { name: 'Tapas Telescopios: ', fieldName: 'telescopesCovers' },
                                            { name: 'Cauchos Telescopios: ', fieldName: 'telescopesRubbers' },
                                            { name: 'Direccionales: ', fieldName: 'directional' },
                                            { name: 'Tijera: ', fieldName: 'scissors' },
                                            { name: 'Espejos: ', fieldName: 'mirrors' },
                                            { name: 'Farola: ', fieldName: 'streetlight' },
                                            { name: 'Rectificador de Corriente: ', fieldName: 'currentRectifier' },
                                            { name: 'Flancher: ', fieldName: 'flanker' },
                                        ]}
                                        watchMoto={watchMoto}
                                        registerMoto={registerMoto}
                                        setValueMoto={setValueMoto}
                                        errorsMoto={errorsMoto}
                                        controlMoto={controlMoto}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        },
    ];
    return StepsMoto
}