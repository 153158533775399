import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfComputersReportComponent = ({ fullDataEquipment, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    let brand, serie, modell, licensePlate, type, photo, status, validity, lastMtto, novelty, image, imageSerial;
    if (fullDataEquipment.computersEquipment) {
        if (fullDataEquipment.computersEquipment.brandCPU) {
            brand = fullDataEquipment.computersEquipment.brandCPU;
            serie = fullDataEquipment.computersEquipment.serieCPU;
            modell = fullDataEquipment.computersEquipment.modellCPU;
            type = 'CPU'
            licensePlate = fullDataEquipment.computersEquipment.licensePlateCPU
            photo = fullDataEquipment.computersEquipment.photoCPU
            status = fullDataEquipment.computersEquipment.statusCPU
            validity = fullDataEquipment.computersEquipment.validityCPU
            lastMtto = fullDataEquipment.computersEquipment.lastMttoCPU
            novelty = fullDataEquipment.computersEquipment.noveltyCPU
            image = fullDataEquipment.computersEquipment.photoCPU
            imageSerial = fullDataEquipment.computersEquipment.photoCPUSerial
        } else if (fullDataEquipment.computersEquipment.brandAllInOne) {
            brand = fullDataEquipment.computersEquipment.brandAllInOne;
            serie = fullDataEquipment.computersEquipment.serieAllInOne;
            modell = fullDataEquipment.computersEquipment.modellAllInOne;
            licensePlate = fullDataEquipment.computersEquipment.licensePlateAllInOne;
            type = 'EQUIPO TODO EN UNO INTEGRADO'
            photo = fullDataEquipment.computersEquipment.photoAllInOne
            status = fullDataEquipment.computersEquipment.statusAllInOne
            validity = fullDataEquipment.computersEquipment.validityAllInOne
            lastMtto = fullDataEquipment.computersEquipment.lastMttoAllInOne
            novelty = fullDataEquipment.computersEquipment.noveltyAllInOne
            image = fullDataEquipment.computersEquipment.photoAllInOne
            imageSerial = fullDataEquipment.computersEquipment.photoAllInOneSerial
        } else if (fullDataEquipment.computersEquipment.brandLaptop) {
            brand = fullDataEquipment.computersEquipment.brandLaptop;
            serie = fullDataEquipment.computersEquipment.serieLaptop;
            modell = fullDataEquipment.computersEquipment.modellLaptop;
            licensePlate = fullDataEquipment.computersEquipment.licensePlateLaptop;
            type = 'PORTATIL'
            photo = fullDataEquipment.computersEquipment.photoLaptop
            status = fullDataEquipment.computersEquipment.statusLaptop
            validity = fullDataEquipment.computersEquipment.validityLaptop
            lastMtto = fullDataEquipment.computersEquipment.lastMttoLaptop
            novelty = fullDataEquipment.computersEquipment.noveltyLaptop
            image = fullDataEquipment.computersEquipment.photoLaptop
            imageSerial = fullDataEquipment.computersEquipment.photoLaptopSerial
        }
    }
    image = image
    imageSerial = imageSerial


    let serieMouse;
    if (fullDataEquipment.computersEquipment.serieMouse) {
        var result = '';
        var str = fullDataEquipment.computersEquipment.serieMouse;
        while (str.length > 12) {
            var pos = str.substring(0, 12).lastIndexOf(' ');
            pos = pos <= 0 ? 12 : pos;
            result += str.substring(0, pos) + '\n';
            str = str.substring(pos);
        }
        serieMouse = result + str;
    }
    let serieKeyboard;
    if (fullDataEquipment.computersEquipment.serieKeyboard) {
        var result = '';
        var str = fullDataEquipment.computersEquipment.serieKeyboard;
        while (str.length > 12) {
            var pos = str.substring(0, 12).lastIndexOf(' ');
            pos = pos <= 0 ? 12 : pos;
            result += str.substring(0, pos) + '\n';
            str = str.substring(pos);
        }
        serieKeyboard = result + str;
    }
    let serieSource;
    if (fullDataEquipment.computersEquipment.serieSource) {
        var result = '';
        var str = fullDataEquipment.computersEquipment.serieSource;
        while (str.length > 12) {
            var pos = str.substring(0, 12).lastIndexOf(' ');
            pos = pos <= 0 ? 12 : pos;
            result += str.substring(0, pos) + '\n';
            str = str.substring(pos);
        }
        serieSource = result + str;
    }
    let seriePowerAdapter;
    if (fullDataEquipment.computersEquipment.seriePowerAdapter) {
        var result = '';
        var str = fullDataEquipment.computersEquipment.seriePowerAdapter;
        while (str.length > 12) {
            var pos = str.substring(0, 12).lastIndexOf(' ');
            pos = pos <= 0 ? 12 : pos;
            result += str.substring(0, pos) + '\n';
            str = str.substring(pos);
        }
        seriePowerAdapter = result + str;
    }
    if (fullDataEquipment.computersEquipment.serieCPU) {
        var result = '';
        var str = fullDataEquipment.computersEquipment.serieCPU;
        while (str.length > 12) {
            var pos = str.substring(0, 12).lastIndexOf(' ');
            pos = pos <= 0 ? 12 : pos;
            result += str.substring(0, pos) + '\n';
            str = str.substring(pos);
        }
        serie = result + str;
    }
    if (fullDataEquipment.computersEquipment.serieAllInOne) {
        var result = '';
        var str = fullDataEquipment.computersEquipment.serieAllInOne;
        while (str.length > 12) {
            var pos = str.substring(0, 12).lastIndexOf(' ');
            pos = pos <= 0 ? 12 : pos;
            result += str.substring(0, pos) + '\n';
            str = str.substring(pos);
        }
        serie = result + str;
    }
    if (fullDataEquipment.computersEquipment.serieLaptop) {
        var result = '';
        var str = fullDataEquipment.computersEquipment.serieLaptop;
        while (str.length > 12) {
            var pos = str.substring(0, 12).lastIndexOf(' ');
            pos = pos <= 0 ? 12 : pos;
            result += str.substring(0, pos) + '\n';
            str = str.substring(pos);
        }
        serie = result + str;
    }

    let computerReportComponents = fullDataEquipment.caseReport.map((report) => report.systemCaseReport.computerReportComponent);
    let flattenedComponents = [].concat(...computerReportComponents);

    return (
        <Page size="A1" style={{ flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center' }}>
            <View style={{ width: '100%', height: '3.7%', flexDirection: 'row', borderBottom: '3.2px solid #000' }}>
                <View style={{ width: '20%', height: '100%', borderRight: '2px solid #000', alignItems: 'center', justifyContent: 'center' }}><Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image></View>
                <View style={{ width: '80%', height: '100%', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>Partes Reemplazadas</Text></View>
            </View>
            <View style={{ width: '100%', height: '1.8%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>DATOS DEL EQUIPO</Text>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Fecha Inicio Garantia</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.computersEquipment.startWarranty ? (new Date(fullDataEquipment.computersEquipment.startWarranty).getDate() + "/" + (new Date(fullDataEquipment.computersEquipment.startWarranty).getMonth() + 1) + "/" + new Date(fullDataEquipment.computersEquipment.startWarranty).getFullYear()) : " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Fecha Fin Garantia</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.computersEquipment.endWarranty ? (new Date(fullDataEquipment.computersEquipment.endWarranty).getDate() + "/" + (new Date(fullDataEquipment.computersEquipment.endWarranty).getMonth() + 1) + "/" + new Date(fullDataEquipment.computersEquipment.endWarranty).getFullYear()) : " "}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>IP</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.computersEquipment.ipAddress + " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Mac</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.computersEquipment.macAddress + " "}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '65%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '35%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Placa de inventario</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '65%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.computersEquipment.ipAddress + " "}</Text></View>
                </View>
                <View style={{ flexDirection: 'row', width: '35%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '50%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>{licensePlate}</Text></View>
                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{" "}</Text></View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', height: '2.2%', borderBottom: '3.2px solid #000', }}>
                <View style={{ flexDirection: 'row', width: '100%', height: '100%' }}>
                    <View style={{ backgroundColor: '#525252', borderRight: '2px solid #000', width: '23.076923076923077%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}><Text style={{ color: 'white', textAlign: 'left', fontSize: '22px', fontWeight: 'bolder', marginLeft: '5%' }}>Correo electrónico:</Text></View>
                    <View style={{ borderRight: '2px solid #000', width: '76.92307692307693%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{fullDataEquipment.dependency.email === null ? " " : fullDataEquipment.dependency.email + " "}</Text></View>
                </View>
            </View>

            <View style={{ width: '100%', height: '1.8%', flexDirection: 'row', backgroundColor: '#525252', borderBottom: '2px solid #000' }}>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Tipo</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Marca</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '14%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Modelo (Version)</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Serial</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Placa de Inventario</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Registro fotografico</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Registro fotografico Serial</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '7%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Estado</Text></View>
                <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '8%' }}><Text style={{ color: 'white', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Novedad</Text></View>
            </View>
            <View style={{ width: '100%', height: '1.8%', flexDirection: 'row', borderBottom: '3.2px solid #000', backgroundColor: '#525252', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: '33px', fontWeight: 'bolder' }}>HARDWARE</Text>
            </View>
            {flattenedComponents && flattenedComponents.map((component, i) => (
                <View key={i} style={{ width: '100%', height: '4.3%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{component.type + " "}</Text></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '11%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{component.brand + " "}</Text></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '14%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{component.modell + " "}</Text></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{component.serie + " "}</Text></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '10%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{component.licensePlate + " "}</Text></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Image style={{ width: '100%', height: '100%' }} src={component.image ? component.image : blanco}></Image></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '15%' }}><Image style={{ width: '100%', height: '100%' }} src={component.imageSerial ? component.imageSerial : blanco}></Image></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '7%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{component.status + " "}</Text></View>
                    <View style={{ alignItems: 'center', justifyContent: 'center', borderRight: '2px solid #000', heigth: '100%', width: '8%' }}><Text style={{ color: 'black', textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{component.novelty + " "}</Text></View>
                </View>
            ))}
        </Page>
    )
}