import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    EquipmentVehicleCreatedId: false,
    personAssign: false,
    areaShared: false,
    EquipmentVehiclesSearched: false,
    quantity: false,
    EquipmentVehiclesFiltered: false,
    loadingEquipmentVehicle: false,
    states: false,
    locations: false,
    equipsWithCaseReports: false,
    loadingEquipsWithCaseReport: false,
    quantityEquipsWithCaseReports: false,
    equipAndReportPDF: false,
    equipsForTimeline: false,
    loadingEquipsForTimeline: false,
    allEquipsForTimeline: false,
    equipsWithTimelines: false,
    loadingEquipsWithTimeline: false,
    quantityEquipsWithTimelines: false,
    fullDataEquipmentVehicle: false,
    loadingFullDataEquipmentVehicle: false,
    loadingUpdateEquipmentVehicle: false,
    loadingCreateEquipmentVehicle: false,
    isFiltered: false,
    vehicleImages: false,
    loadingGetVehicleImages: false,
    vehiclePapers: false,
    loadingGetVehiclePapers: false,
};

const EquipmentVehicleSlice = createSlice({
    name: "EquipmentVehicle",
    initialState,
    reducers: {
        setEquipmentVehicleCreatedId(state, { payload }) {
            state.EquipmentVehicleCreatedId = payload
        },
        createEquipmentVehicle(state, { payload }) {
            state.loadingCreateEquipmentVehicle = true
        },
        updateEquipmentVehicle(state, { payload }) {
            state.loadingCreateEquipmentVehicle = true
        },
        deleteEquipmentVehicle() { },
        setPersonAssign(state, { payload }) {
            state.personAssign = payload
        },
        setAreaShared(state, { payload }) {
            state.areaShared = payload
        },
        setEquipmentVehiclesSearched(state, { payload }) {
            state.EquipmentVehiclesSearched = payload
        },
        searchEquipmentVehicle(state) {
            state.loadingEquipmentVehicle = true
        },
        setQuantity(state, { payload }) {
            state.quantity = payload
        },
        setEquipmentVehiclesFiltered(state, { payload }) {
            state.EquipmentVehiclesFiltered = payload
        },
        getEquipmentVehicles(state, { payload }) {
            state.loadingEquipmentVehicle = true
            state.EquipmentVehiclesSearched = false
        },
        setLoading(state, { payload }) {
            state.loadingEquipmentVehicle = payload
        },
        getStates() { },
        getLocations() { },
        setStates(state, { payload }) {
            state.states = payload
        },
        setLocations(state, { payload }) {
            state.locations = payload
        },
        getEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = false
            state.loadingEquipsWithCaseReport = true;
        },
        setEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = payload
        },
        setQuantityEquipsWithCaseReports(state, { payload }) {
            state.quantityEquipsWithCaseReports = payload
        },
        setLoadingEquipsWithCaseReport(state, { payload }) {
            state.loadingEquipsWithCaseReport = payload
        },
        searchCaseReport(state) {
            state.loadingEquipsWithCaseReport = true
        },
        getEquipAndReportPDF() { },
        setEquipAndReportPDF(state, { payload }) {
            state.equipAndReportPDF = payload
        },
        getEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = true
        },
        setEquipsForTimeline(state, { payload }) {
            state.equipsForTimeline = payload
        },
        setAllEquipsForTimeline(state, { payload }) {
            state.allEquipsForTimeline = payload
        },
        setLoadingEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = payload
        },
        getEquipsWithTimelines(state, { payload }) {
            state.loadingEquipsWithTimeline = true
        },
        setEquipsWithTimelines(state, { payload }) {
            state.equipsWithTimelines = payload
            state.loadingEquipsWithTimeline = false
        },
        setQuantityEquipsWithTimelines(state, { payload }) {
            state.quantityEquipsWithTimelines = payload
        },
        getFullDataEquipmentVehicle(state, { payload }) {
            state.loadingFullDataEquipmentVehicle = true
        },
        setFullDataEquipmentVehicle(state, { payload }) {
            state.fullDataEquipmentVehicle = payload
        },
        setLoadingFullDataEquipmentVehicle(state, { payload }) {
            state.loadingFullDataEquipmentVehicle = payload
        },
        setLoadingUpdateEquipmentVehicle(state, { payload }) {
            state.loadingUpdateEquipmentVehicle = payload
        },
        setLoadingCreateEquipmentVehicle(state, { payload }) {
            state.loadingCreateEquipmentVehicle = payload
        },
        getEquipmentVehiclesByModuleWork() { },
        search(state, { payload }) {
            state.loadingEquipmentVehicle = true
        },
        setIsFiltered(state, { payload }) {
            state.isFiltered = payload
        },
        getEquipsWithCaseReportsByModuleWork() { },
        getEquipsWithTimelinesByModuleWork() { },
        getVehicleImages(state, { payload }) {
            state.loadingGetVehicleImages = true
        },
        setVehicleImages(state, { payload }) {
            state.vehicleImages = payload
        },
        setLoadingGetVehicleImages(state, { payload }) {
            state.loadingGetVehicleImages = payload
        },
        getVehiclePapers(state, { payload }) {
            state.loadingGetVehiclePapers = true
        },
        setVehiclePapers(state, { payload }) {
            state.vehiclePapers = payload
        },
        setLoadingGetVehiclePapers(state, { payload }) {
            state.loadingGetVehiclePapers = payload
        },
    },
});

const EquipmentVehicleActions = EquipmentVehicleSlice.actions;
const EquipmentVehicleReducer = EquipmentVehicleSlice.reducer;

export { EquipmentVehicleActions, EquipmentVehicleReducer };
