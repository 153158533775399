import PrivateLayout from './Private/PrivateLayout'
import PublicLayout from './Public/PublicLayout'

const Referee = ({ children }) => {

    // Login placeholder
    // useSelector(loginSelector(), shallowEqual)
    const isLogin = localStorage.getItem('token')

    if (isLogin)
        return (
            <PrivateLayout>
                {children}
            </PrivateLayout>
        )

    return (
        <PublicLayout>
            {children}
        </PublicLayout>
    )
}

export default Referee
