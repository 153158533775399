// Importamos los íconos necesarios
import { MailOutlined, KeyOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import React, { forwardRef } from 'react';
// Definimos el componente Input
export const Input = forwardRef((props, ref) => {
    const {
        va,
        setData,
        heightDiv,
        label,
        labelcolor,
        labelsize,
        labelstyle,
        placeholder,
        textArea,
        email,
        lock,
        eye,
        width,
        height,
        mr,
        ml,
        type,
        dmt,
        border,
        error,
        disabled,
        fontSize,
        fontWeight,
        mt,
        color,
        bgColor,
        textAlign,
        labelMB,
        errorFontSize,
        display
    } = props;

    const commonStyles = {
        width: width,
        height: height,
        marginRight: mr,
        marginLeft: ml,
        border: border,
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color,
        backgroundColor: bgColor,
        textAlign: textAlign,
    };

    const handleChange = (e) => {
        setData(e.target.value);
    };

    const handleBlur = (e) => {
        setData(e.target.value.trim());
    };

    return (
        <div className='inputDiv' style={{ height: heightDiv, display: display }}>
            {label ?
                label !== 'none' &&
                <p style={{ color: labelcolor, fontSize: labelsize, marginTop: mt, marginBottom: labelMB, width: width }} className='label'>{label}</p>
                :
                <p style={{ color: labelcolor, fontSize: labelsize, marginTop: mt, marginBottom: labelMB }} className='label'> </p>
            }
            <div className='divInput'>
                {textArea ?
                    <textarea
                        ref={ref}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={va}
                        className={labelstyle}
                        placeholder={placeholder}
                        disabled={disabled}
                        style={commonStyles}
                    />

                    :
                    <input
                        ref={ref}
                        type={type}
                        className={labelstyle}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={va}
                        disabled={disabled}
                        style={commonStyles}
                    />
                }
                <p className='errorMessage' style={{ color: 'red', fontSize: errorFontSize }}>{error}</p>
                <div className='iconDiv'>
                    {email && <MailOutlined className='iconEmail' />}
                    {lock && <KeyOutlined className='iconEmail' />}
                    {eye && <EyeInvisibleOutlined className='iconEye' />}
                </div>
            </div>
        </div>
    )
});
