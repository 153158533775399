import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"
import { TicketActions } from './TicketSlice'

function* getLastNumber({ payload }) {
    const c = yield Api.get(`/ticket/lastNumber`)
    if (c.ok) {
        yield put(TicketActions.setLastNumber(c.payload))
    } else {
        message.error("Error al obtener numero")
    }
}

function* actionWatcher() {
    yield takeLatest(TicketActions.getLastNumber, getLastNumber)
}

export default function* TicketSaga() {
    yield all([actionWatcher()])
}